import { Social as SocialModel } from "cobranetics-types/types";
import { PathsAPI } from "../routes/paths";
import { axiosInstance } from "../shared/axios";

export const getSocial = async (id: number): Promise<SocialModel[]> => {
  const response = await axiosInstance.get(`${PathsAPI.social}`, {
    params: {
      person_id: id,
    },
  });

  return response.data.data;
};
