import {
  Lifeform as LifeformModel,
  LifeformCard as LifeformCardModel,
} from "cobranetics-types/types";
import { ListOutput } from "../actions";

export enum SexTypes {
  Male = "male",
  Female = "female",
  Unknown = "unknown",
}
export enum KingdomTypes {
  Animalia = "Animalia",
  Plantae = "Plantae",
  Fungi = "Fungi",
  Protista = "Protista",
  Archaea = "Archaea",
  Bacteria = "Bacteria",
}

export interface AnimalAction {
  animals: ListOutput<LifeformCardModel>;
}

export interface LifeformAction {
  lifeform: LifeformModel;
}
