/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { InventoryTransaction } from 'cobranetics-types/types';
import moment from 'moment';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { getCheck } from '../../actions/check';
import { CHECK } from '../../actions';
import { FailedAuthCheck } from '../../shared/errors';
import { MainAction } from '../../typings/main_context_reducer';
import { useMainContext } from '../../context/context';
import {
  DateTimeInput,
  NumberInput,
  selectStyle,
} from '../../components/input';
import { SelectListOption } from '../../typings/common';
import { formatDate, getOption } from '../../shared/utils';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { Paths } from '../../routes/paths';
import { Table } from '../../components/tables';
import { DeleteModal } from '../../components/modals/delete';
import {
  FetchInventoryTransactionParams,
  createInventoryTransaction,
  deleteInventoryTransaction,
  getInventory,
  getInventoryCards,
  getInventoryTransaction,
  getInventoryTransactions,
  updateInventoryTransaction,
} from '../../actions/inventory';
import { successNotification } from '../../components/notification';

export const AddInventoryTransaction = (): JSX.Element => {
  const { paramsID } = useParams();
  const isEdit = paramsID !== undefined;
  const [, dispatch] = useMainContext();
  const navigate = useNavigate();
  const pageID = isEdit ? Number.parseInt(paramsID) : 0;
  const [isBusy, setBusy] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);

  const [inventoryOptions, setInventoryOptions] = useState<SelectListOption[]>(
    [],
  );
  const [selectedInventoryOption, setSelectedInventoryOption] = useState<
    SelectListOption | undefined
  >(undefined);

  const [date, time] = formatDate(new Date()).split(' ');
  const [dateTime, setDateTime] = useState(date + 'T' + time);

  const [quantity, setQuantity] = useState(1);
  const [costPerItem, setCostPerItem] = useState<number | undefined>(undefined);

  const [inventoryTransactions, setInventoryTransactions] = useState(
    [] as InventoryTransaction[],
  );
  const transactionColumns = React.useMemo(
    () => [
      {
        Header: '242341cac232',
        hideHeader: true,
        columns: [
          {
            Header: '',
            accessor: 'action',
          },
          {
            Header: 'Cost Per Item',
            accessor: 'costPerItem',
          },
          {
            Header: 'Quantity',
            accessor: 'quantity',
          },
          {
            Header: 'Date',
            accessor: 'date',
          },
        ],
      },
    ],
    [],
  );

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      if (!paramsID) return;

      const inventoryTransaction = await getInventoryTransaction(paramsID);
      await deleteInventoryTransaction(paramsID);
      navigate(`${Paths.viewInventory}/${inventoryTransaction.inventory_id}`);
    },
    modalIsOpen: openModal,
    category: 'inventory transaction',
    name: '',
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);

        const inventoryParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
          account_id: check.account.id,
          is_archived: false,
        };
        const inventories = await getInventoryCards(inventoryParams);
        const inventoryOpts = inventories.data.map((inventory) => ({
          value: inventory.id + '',
          label: inventory.item_name,
        }));
        setInventoryOptions(inventoryOpts);

        if (isEdit) {
          if (Number.isNaN(pageID)) {
            return;
          }

          const transaction = await getInventoryTransaction(paramsID);
          const inventory = await getInventory(transaction.inventory_id);

          setSelectedInventoryOption(
            getOption(inventory.id + '', inventoryOpts),
          );
          setInventoryTransactions([transaction]);

          setCostPerItem(transaction.cost_per_item);
          setQuantity(transaction.change_count);
        } else if (searchParams.get('inventory_id')) {
          setSelectedInventoryOption(
            getOption(searchParams.get('inventory_id') ?? '', inventoryOpts),
          );
        } else {
          setSelectedInventoryOption(inventoryOpts[0]);
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (isEdit) {
        return;
      }

      const transactionParams: FetchInventoryTransactionParams = {
        currentPage: 1,
        perPage: 20,
        sort_by: '-recorded_on',
        inventory_id:
          selectedInventoryOption?.value ??
          searchParams.get('inventory_id') ??
          undefined,
      };

      const transactions = await getInventoryTransactions(transactionParams);
      setInventoryTransactions(transactions.data);
    };

    loadData();
  }, [selectedInventoryOption]);

  return (
    <div className="add-inventory-transaction">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="add-inventory-transaction-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title={`${isEdit ? 'Edit' : 'Add'} Inventory Transaction`}
                showBackBtn={true}
                activeTab={''}
              />
              <section className="common-section">
                <div className="top">
                  <div className="add-recording-wrapper">
                    <div className="title-input-wrapper">
                      <p className="title">Inventory</p>
                      <Select
                        options={inventoryOptions}
                        styles={selectStyle}
                        placeholder="Select an inventory"
                        name="inventoryOptions"
                        id="inventoryOptions"
                        value={selectedInventoryOption}
                        onChange={async (row) => {
                          setSelectedInventoryOption(
                            row
                              ? getOption(row?.value, inventoryOptions)
                              : undefined,
                          );
                        }}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="dual-input-wrapper">
                      <div className="title-input-wrapper">
                        <p className="title">Recorded On</p>
                        <DateTimeInput
                          dateTime={dateTime}
                          setDateTime={setDateTime}
                          placeholder=""
                          inputName="recording-time"
                        />
                      </div>
                    </div>
                    <div className="triple-input-wrapper">
                      <div className="title-input-wrapper">
                        <p className="title">Quantity</p>
                        <NumberInput
                          setNumber={setQuantity}
                          placeholder="1"
                          inputName="quantity"
                          value={quantity === 0 ? '' : quantity}
                        />
                      </div>
                      <div className="title-input-wrapper">
                        <p className="title">Cost Per Item</p>
                        <NumberInput
                          setNumber={setCostPerItem}
                          placeholder="0"
                          inputName="costPerItem"
                          value={costPerItem === 0 ? '' : costPerItem}
                        />
                      </div>
                    </div>
                    {!isEdit ? (
                      <>
                        <button
                          className="btn-done btn-add-inventory-transaction"
                          onClick={async () => {
                            if (!selectedInventoryOption?.value) {
                              return;
                            }

                            const inventory = await getInventory(
                              selectedInventoryOption.value,
                            );

                            const newTransaction = await createInventoryTransaction(
                              {
                                change_count: quantity,
                                is_increment: quantity > 0,
                                cost_per_item: costPerItem,
                                recorded_on: dateTime,
                                inventory_id: inventory.id,
                              },
                            );

                            setInventoryTransactions([
                              newTransaction,
                              ...inventoryTransactions,
                            ]);
                            setCostPerItem(0);
                            setQuantity(1);

                            successNotification(
                              'Successfully added a new feeding',
                            );
                          }}
                        >
                          <AddIcon className="add-icon" />
                        </button>

                        {inventoryTransactions.length ? (
                          <div className="title-input-wrapper">
                            <p className="title">Recent Transactions</p>
                            <Table
                              columns={transactionColumns}
                              data={inventoryTransactions.map((row) => ({
                                action: (
                                  <div className="table-actions">
                                    <button
                                      className="btn-default btn-edit"
                                      onClick={() => {
                                        navigate(
                                          `${Paths.editInventoryTransaction}/${row.id}`,
                                        );
                                      }}
                                    >
                                      <EditIcon className="edit-icon" />
                                    </button>
                                  </div>
                                ),
                                costPerItem: `$${row.cost_per_item}`,
                                quantity: row.change_count,
                                date: moment(row.recorded_on).format(
                                  'MMM DD, YYYY',
                                ),
                              }))}
                            />
                          </div>
                        ) : (
                          <div className="common-row">
                            <div className="empty-placeholder">
                              <h5>Empty</h5>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {isEdit ? (
                  <div className="bottom two">
                    <button
                      className="btn-done"
                      onClick={async () => {
                        // update
                        await updateInventoryTransaction({
                          id: paramsID,
                          change_count: quantity,
                          is_increment: quantity > 0,
                          cost_per_item: costPerItem,
                          recorded_on: dateTime,
                        });
                        navigate(-1);
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn-delete"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <div className="bottom">
                    <button
                      className="btn-next"
                      onClick={async () => {
                        if (selectedInventoryOption) {
                          const inventory = await getInventory(
                            selectedInventoryOption.value,
                          );
                          navigate(`${Paths.viewInventory}/${inventory.id}`);
                        } else {
                          navigate(`${Paths.home}`);
                        }
                      }}
                    >
                      Done
                    </button>
                  </div>
                )}
              </section>
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
