/* eslint-disable react/jsx-key */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Inventory,
  InventoryTransaction,
  SearchResult,
} from 'cobranetics-types/types';
import { Table } from 'antd';

import { CHECK } from '../../actions';
import {
  FetchInventoryTransactionParams,
  InventoryAttribute,
  deleteInventory,
  getInventory,
  getInventoryAttributes,
  getInventoryTransactions,
} from '../../actions/inventory';
import { ArchiveOnCard } from '../../components/archive';
import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { useMainContext } from '../../context/context';
import { DEFAULT_IMAGES } from '../../shared/constants';
import { MainAction } from '../../typings/main_context_reducer';
import { authRefresh } from '../../actions/check';
import { formateDateSimple, roundCurrency } from '../../shared/utils';
import { FailedAuthCheck } from '../../shared/errors';
import { getInventoryMenus } from '../../shared/menu';
import { getPeople } from '../../actions/person';
import { Paths } from '../../routes/paths';
import { DeleteModal } from '../../components/modals/delete';
import { handleScroll } from '../../shared/window';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { AttributeCard } from '../../components/attributes';
import { errorNotification } from '../../components/notification';

export const ViewInventoryItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const { paramsID } = useParams();
  const navigate = useNavigate();
  const perPage = 20;

  const [isBusy, setBusy] = useState(true);
  const [inventory, setInventory] = useState({} as Inventory);
  const [username, setUsername] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [searchPagination, setSearchPagination] = useState({
    currentPage: 1,
    perPage,
  });
  const [reachedBottom, setReachedBottom] = useState(false);
  const [noDataLeft, setNoDataLeft] = useState(false);
  const [searchData, setSearchData] = useState<SearchResult[]>([]);

  const pageID = Number.parseInt(paramsID ?? '');
  const is_archived = inventory?.is_archived ?? false;
  const commonRowMenus = getInventoryMenus(
    navigate,
    paramsID ?? '',
    mainState?.account?.id === inventory?.account_id,
    username,
    () => {
      setOpenModal(true);
    },
  );
  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteInventory(`${pageID}`);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'inventory',
    name: inventory.item_name,
  };

  const inventoryTransactionsPerPage = 20;
  const [totalInventoryTransactions, setTotalInventoryTransactions] = useState(
    0,
  );
  const [
    currentInventoryTransactionPage,
    setCurrentInventoryTransactionPage,
  ] = useState(1);
  const [inventoryTransactions, setInventoryTransactions] = useState<
    InventoryTransaction[]
  >([]);
  const inventoryTransactionsData: {
    changeCount: string;
    costPerItem: string;
    recordedOn: string;
  }[] = inventoryTransactions.map((row) => ({
    recordedOn: formateDateSimple(row.recorded_on, 'ago', true),
    changeCount: `${row.is_increment ? '+' : '-'} ${row.change_count}`,
    costPerItem: `$${row.cost_per_item}`,
  }));
  let columns: { title: string; dataIndex: string; key: string }[] = [];

  const [inventoryAttributes, setInventoryAttributes] = useState<
    InventoryAttribute[]
  >([]);

  if (!!inventoryTransactions.length) {
    columns = [
      {
        title: 'Date / Time',
        dataIndex: 'recordedOn',
        key: 'recordedOn',
      },
      {
        title: 'Count',
        dataIndex: 'changeCount',
        key: 'changeCount',
      },
      {
        title: 'Cost Per Item',
        dataIndex: 'costPerItem',
        key: 'costPerItem',
      },
    ];
  }

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;
        dispatch(checkAction);

        if (Number.isNaN(pageID)) {
          return;
        }

        const inventoryTransactionParams: FetchInventoryTransactionParams = {
          currentPage: currentInventoryTransactionPage,
          perPage: inventoryTransactionsPerPage,
          sort_by: '-recorded_on',
          inventory_id: paramsID,
        };

        const inventory = await getInventory(pageID);
        const transactions = await getInventoryTransactions(
          inventoryTransactionParams,
        );
        const person = (await getPeople({ account_id: inventory.account_id }))
          .data[0];
        const attributes = await getInventoryAttributes(`${paramsID}`);

        setInventoryTransactions(transactions.data);
        setInventory(inventory);
        setUsername(person.username);
        setTotalInventoryTransactions(
          transactions.total || totalInventoryTransactions,
        );
        setInventoryAttributes(attributes);
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
    window.addEventListener('scroll', handleScroll(setReachedBottom));

    return () =>
      window.removeEventListener('scroll', handleScroll(setReachedBottom));
  }, []);

  useEffect(() => {
    const loadData = async () => {
      const inventoryTransactionParams: FetchInventoryTransactionParams = {
        ...searchPagination,
        sort_by: '-id',
        inventory_id: paramsID,
      };
      const inventoryTransactions = await getInventoryTransactions(
        inventoryTransactionParams,
      );
      const transactions: any[] = inventoryTransactions.data.map((row) => ({
        ...row,
        filterTypeOption: 'inventoryTransaction',
      }));

      transactions.sort(
        (a, b) => moment(b.recorded_on).unix() - moment(a.recorded_on).unix(),
      );

      setSearchData([...searchData, ...transactions]);
      setNoDataLeft(transactions.length < perPage);
    };

    loadData();
  }, [searchPagination]);

  useEffect(() => {
    if (reachedBottom && !noDataLeft) {
      setSearchPagination({
        currentPage: searchPagination.currentPage + 1,
        perPage: perPage + searchPagination.perPage,
      });
    }
  }, [reachedBottom]);

  return (
    <div className="view-inventory">
      <Header hideLinks={false} title={'Inventory'} />
      {!isBusy ? (
        <>
          <div className="desktop-wrapper full-width">
            <section className="view-inventory-background">
              <PageHeader
                title={inventory.item_name}
                subTitle={inventory.category}
                showBackBtn={true}
                activeTab={''}
                menu={commonRowMenus.mainMenu}
              />
              <section className="view-inventory-item">
                <div className="main-info">
                  <div className="image-wrapper">
                    {is_archived ? (
                      <ArchiveOnCard is_archived={is_archived} />
                    ) : (
                      <></>
                    )}
                    <img
                      src={inventory.image_url || DEFAULT_IMAGES.inventory}
                      alt=""
                      className="display-image"
                    />
                  </div>
                  <section className="info">
                    <div className="info-item">
                      <p className="title">Quantity</p>
                      <p className="value">{inventory.quantity}</p>
                    </div>
                    <div className="info-item">
                      <p className="title">Avg Value</p>
                      <p className="value">
                        ${roundCurrency(inventory.average_cost_per_item)}
                      </p>
                    </div>
                    <div className="info-item">
                      <p className="title">Balance</p>
                      <p className="value">
                        $
                        {roundCurrency(
                          inventory.average_cost_per_item * inventory.quantity,
                        )}
                      </p>
                    </div>
                  </section>
                </div>

                {!!inventoryAttributes.length && (
                  <section className="attributes-wrapper">
                    <h5 className="title">Stats</h5>
                    <div className="attributes-inner-wrapper">
                      {inventoryAttributes.map((row) => (
                        <AttributeCard
                          key={`${row.key?.replaceAll(
                            ' ',
                            '',
                          )}-${row.value?.replaceAll(' ', '')}`}
                          keyAttribute={row.key}
                          value={row.value}
                        />
                      ))}
                    </div>
                  </section>
                )}

                {!!inventoryTransactions.length ? (
                  <section className="transactions-table-wrapper">
                    <div className="title-btn-wrapper">
                      <h5 className="title">Transactions</h5>
                      {mainState?.account?.id === inventory?.account_id ? (
                        <a
                          href={`${Paths.addInventoryTransaction}?inventory_id=${pageID}`}
                        >
                          <button className="btn-add">
                            <AddIcon className="icon" />
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="inner-table-wrapper">
                      <Table
                        dataSource={inventoryTransactionsData.map(
                          (row, index) => ({
                            ...row,
                            key: `${row.recordedOn}-${index}`,
                          }),
                        )}
                        columns={columns}
                        pagination={{
                          total: totalInventoryTransactions,
                          pageSize: inventoryTransactionsPerPage,
                          onChange: async (page, pageSize) => {
                            const transactionsParams: FetchInventoryTransactionParams = {
                              currentPage: page,
                              perPage: pageSize,
                              sort_by: '-recorded_on',
                              inventory_id: `${paramsID}`,
                            };

                            const moreInventoryTransactions = await getInventoryTransactions(
                              transactionsParams,
                            );
                            setCurrentInventoryTransactionPage(page);
                            setInventoryTransactions(
                              moreInventoryTransactions.data,
                            );
                          },
                        }}
                      />
                    </div>
                  </section>
                ) : (
                  <></>
                )}
              </section>
            </section>
          </div>
        </>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
