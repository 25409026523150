import { Paths } from "../routes/paths";

export interface CreatePages {
  Animal: string;
  Enclosure: string;
  Inventory: string;
  Observation: string;
  Feeding: string;
}

export const CREATE_PAGES: CreatePages = {
  Animal: Paths.addAnimal,
  Enclosure: Paths.createEnv,
  Inventory: Paths.addInventory,
  Observation: Paths.addObservation,
  Feeding: Paths.addFeeding,
};

export const isCreatePage = (currentPage: string): boolean => {
  return (
    currentPage.includes(CREATE_PAGES.Animal) ||
    currentPage.includes(CREATE_PAGES.Enclosure) ||
    currentPage.includes(CREATE_PAGES.Inventory) ||
    currentPage.includes(CREATE_PAGES.Observation) ||
    currentPage.includes(CREATE_PAGES.Feeding)
  );
};
