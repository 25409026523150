import React from 'react';
import { Inventory, InventoryTransaction } from 'cobranetics-types/types';

import { Paths } from '../routes/paths';
import { DEFAULT_IMAGES } from '../shared/constants';
import { formateDateSimple } from '../shared/utils';
import { ReactComponent as InventoryIcon } from '../icons/inventory.svg';
import { ReactComponent as MenuIcon } from '../icons/three-dot-menu.svg';

export const InventoryTransactionCard = ({
  change_count,
  is_increment,
  cost_per_item,
  recorded_on,
}: InventoryTransaction): JSX.Element => {
  return (
    <div className="inventory-transaction-card common-card">
      <div className="row">
        <div className="quantity-wrapper sub-section">
          <p className="title">Change</p>
          <h4 className={`value ${is_increment ? 'positive' : 'negative'}`}>{`${
            is_increment ? '+' : '-'
          }${change_count}`}</h4>
        </div>

        <div className="value-wrapper sub-section">
          <p className="title">Value</p>
          <h4 className={`value ${is_increment ? 'positive' : 'negative'}`}>{`${
            is_increment ? '+' : '-'
          }$${cost_per_item}`}</h4>
        </div>
      </div>

      <div className="date-wrapper sub-section">
        <p className="title">Date</p>
        <h6 className="value">{formateDateSimple(recorded_on, 'ago', true)}</h6>
      </div>
    </div>
  );
};

export const InventoryCard = ({
  id,
  image_url,
  item_name,
  quantity,
  average_cost_per_item,
}: Inventory): JSX.Element => {
  return (
    <div className="inventory-card common-card standard-card">
      <section className="top-content">
        <a href={`${Paths.viewInventory}/${id}`}>
          <div className="card-identifier">
            <InventoryIcon />
          </div>
        </a>

        <div className="card-menu">
          <MenuIcon />
        </div>
      </section>
      <div className="card-content-wrapper">
        <a href={`${Paths.viewInventory}/${id}`}>
          <section className="main-content">
            <img src={image_url || DEFAULT_IMAGES.inventory} />
          </section>
        </a>

        <section className="bottom-content">
          <div className="titles">
            <div className="bottom-card-title">
              <p>{item_name}</p>
            </div>
            <div className="bottom-card-sub-titles">
              <p>{quantity} items</p>
              <p>${average_cost_per_item}</p>
            </div>
          </div>
          <div className="action-btn-wrapper"></div>
        </section>
      </div>
    </div>
  );
};
