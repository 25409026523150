import { AxiosResponse } from 'axios';
import imageCompression from 'browser-image-compression';
import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { LifeformGallery } from 'cobranetics-types/types';
import { Spin } from 'antd';

import {
  deleteLifeformGallery,
  updateLifeformGallery,
} from '../../actions/gallery';
import { useMainContext } from '../../context/context';
import { Paths, PathsAPI } from '../../routes/paths';
import { axiosInstance } from '../../shared/axios';
import { AddAnimalTabs, ImageProps } from '../../typings/animal';
import { CommonRow } from '../common-wrapper';
import { ReactComponent as DeleteIcon } from '../../icons/delete2.svg';
import { DEFAULT_IMAGES } from '../../shared/constants';
import { infoNotification } from '../notification';

export const uploadLifeformImages = async (props: {
  lifeformID: string;
  compressedImages: any[];
  images: { src: string }[];
  setImages: (images: any[]) => void;
  setActiveImage: (image: string) => void;
  setLoadingImages: (loadingImages: boolean) => void;
}) => {
  const uploadedImagesURLs: { id: string; src: string }[] = [];

  props.setLoadingImages(true);

  for (const compressedImage of props.compressedImages) {
    const formData = new FormData();
    formData.append('files', compressedImage);

    const uploadedImages: AxiosResponse<{
      data: LifeformGallery[];
      message: string;
    }> = await axiosInstance.post(
      `${PathsAPI.uploadLifeformImage}/${props.lifeformID}`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    const reformatedImageURLs = uploadedImages.data.data.map((image) => ({
      id: image.id,
      src: image.image_url,
    }));
    uploadedImagesURLs.push(...reformatedImageURLs);
  }

  props.setImages([...uploadedImagesURLs, ...props.images]);

  if (props.images.length === 0) {
    await updateLifeformGallery({
      id: `${uploadedImagesURLs[0].id}`,
      is_default_image: true,
    });
    props.setActiveImage(uploadedImagesURLs[0].src);
  }

  props.setLoadingImages(false);
};

export const ImageSection = (props: ImageProps): JSX.Element => {
  const {
    images,
    activeImage,
    setImages,
    setActiveImage,
    imageCount,
    navigate,
  } = props;
  const [loadingImages, setLoadingImages] = useState(false);
  const [mainState] = useMainContext();

  const countLimit =
    mainState.membershipConfig?.countLimits.lifeformImages || 0;
  const reachedLimit = imageCount >= countLimit;

  useEffect(() => {
    if (reachedLimit) {
      infoNotification(
        `Upgrade Membership: Sorry, you have reached your animal image limit for your account.`,
      );
    }
  }, [imageCount]);

  const onDrop = async (acceptedImages: any) => {
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1080,
        useWebWorker: true,
      };
      const compressedImages = [];

      for (const acceptedImage of acceptedImages) {
        const compressedImage = await imageCompression(acceptedImage, options);
        compressedImages.push(compressedImage);
      }

      uploadLifeformImages({
        lifeformID: mainState?.lifeform?.id ?? '',
        compressedImages,
        images,
        setImages,
        setActiveImage,
        setLoadingImages,
      });
    } catch (error) {
      console.log(error);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'],
    disabled: loadingImages,
  });

  return (
    <section className="image-section common-section">
      <div className="top">
        {!reachedLimit ? (
          <div className="dropzone-images dropzone" {...getRootProps()}>
            {loadingImages ? (
              <h6>
                <Spin /> Loading Images
              </h6>
            ) : (
              <h6>Drop your images here or click to browse</h6>
            )}

            <input {...getInputProps()} />
          </div>
        ) : (
          <></>
        )}

        {activeImage.length !== 0 ? (
          <CommonRow
            title={'Current Default'}
            showAddBtn={false}
            addBtnClick={() => {}}
          >
            <div
              className="image-wrapper full"
              style={{ backgroundImage: `url(${activeImage})` }}
            />
          </CommonRow>
        ) : (
          <></>
        )}

        {activeImage.length !== 0 ? (
          <CommonRow title="Gallery" showAddBtn={false} addBtnClick={() => {}}>
            {images.length > 0 ? (
              <div className="gallery">
                {images.map((image, i) => (
                  <div
                    key={'image-card-' + i}
                    className="card-menu-wrapper image-card-wrapper"
                  >
                    <button className="btn-transparent btn-delete">
                      <DeleteIcon
                        className="delete-icon"
                        onClick={async () => {
                          const updatedImagesList = images.filter(
                            (img) => img.id !== image.id,
                          );
                          await deleteLifeformGallery(image.id);
                          setImages(updatedImagesList);

                          if (
                            activeImage === image.src &&
                            updatedImagesList[0] !== undefined
                          ) {
                            await updateLifeformGallery({
                              id: `${updatedImagesList[0].id}`,
                              is_default_image: true,
                            });
                            setActiveImage(updatedImagesList[0].src);
                          } else if (activeImage === image.src) {
                            setActiveImage(DEFAULT_IMAGES.animal);
                          }
                        }}
                      />
                    </button>
                    <div
                      className="image-wrapper"
                      style={{ backgroundImage: `url(${image.src})` }}
                      onClick={async () => {
                        await updateLifeformGallery({
                          id: `${image.id}`,
                          is_default_image: true,
                        });
                        setActiveImage(image.src);
                      }}
                    ></div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="empty-placeholder">
                <h5>Empty</h5>
              </div>
            )}
          </CommonRow>
        ) : (
          <></>
        )}
      </div>
      <div className="bottom">
        <button
          className="btn-next"
          onClick={() => {
            props.setActiveTab(AddAnimalTabs.characteristic);
          }}
        >
          Next
        </button>
        <button
          className="btn-done"
          onClick={() => {
            navigate(`${Paths.viewAnimal}/${mainState?.lifeform?.id ?? 0}`);
          }}
        >
          Done
        </button>
      </div>
    </section>
  );
};
