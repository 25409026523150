import React, { useState } from 'react';
import { Paths, PathsAPI } from '../routes/paths';
import { DEFAULT_IMAGES } from '../shared/constants';
import { ReactComponent as FacebookIcon } from '../icons/social/fb.svg';
import { ReactComponent as InstagramIcon } from '../icons/social/instagram2.svg';
import { ReactComponent as MorphmarketIcon } from '../icons/social/morphmarket.svg';
import { ReactComponent as PinterestIcon } from '../icons/social/pinterest.svg';
import { ReactComponent as RedditIcon } from '../icons/social/reddit.svg';
import { ReactComponent as TiktokIcon } from '../icons/social/tiktok.svg';
import { ReactComponent as TwitterIcon } from '../icons/social/twitter.svg';
import { SocialModel } from '../typings/social';

import { ReactComponent as ProfileIcon } from '../icons/profile.svg';
import { ReactComponent as MenuIcon } from '../icons/three-dot-menu.svg';
import { axiosInstance } from '../shared/axios';
import { FollowPairCard } from 'cobranetics-types/types';

export interface ProfileInterface {
  id: number;
  followPairID: string;
  name?: string;
  username: string;
  bio?: string;
  image?: string;
  isFollowing: boolean;
  isCurrentUser?: boolean;
  hideFollowBtn?: boolean;
  onClick: (isFollowing: boolean) => Promise<void>;
}

export const ProfileCard = (props: ProfileInterface): JSX.Element => {
  const [isFollowing, setIsFollowing] = useState(props.isFollowing);
  const [followPairID, setFollowPairID] = useState(props.followPairID);

  return (
    <div className="profile-card common-card standard-card">
      <section className="top-content">
        <a href={`${Paths.viewProfile}/${props.username}`}>
          <div className="card-identifier">
            <ProfileIcon />
          </div>
        </a>

        <div className="card-menu">
          <MenuIcon />
        </div>
      </section>
      <div className="card-content-wrapper">
        <a href={`${Paths.viewProfile}/${props.username}`}>
          <section className="main-content">
            <img src={props.image || DEFAULT_IMAGES.person} />
          </section>
        </a>

        <section className="bottom-content">
          <div className="titles">
            <div className="bottom-card-title">
              <p>{props.name}</p>
            </div>
            <div className="bottom-card-sub-titles">
              <p>{props.bio}</p>
            </div>
          </div>
          <div className="action-btn-wrapper">
            {props.hideFollowBtn || props.followPairID === '0' ? (
              <></>
            ) : (
              <>
                {!props.isCurrentUser ? (
                  <>
                    {isFollowing ? (
                      <button
                        className="following-btn"
                        onClick={async () => {
                          await axiosInstance.delete(
                            `${PathsAPI.followPair}/${followPairID}`,
                          );

                          setIsFollowing(false);
                          setFollowPairID('');
                        }}
                      >
                        Following
                      </button>
                    ) : (
                      <button
                        className="follow-btn"
                        onClick={async () => {
                          const followPair: {
                            data: FollowPairCard;
                          } = await axiosInstance.post(
                            `${PathsAPI.followPair}`,
                            {
                              person_id: props.id,
                            },
                          );

                          setIsFollowing(true);
                          setFollowPairID(followPair.data.id);
                        }}
                      >
                        Follow
                      </button>
                    )}
                  </>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export const SocialSection = (props: { social: SocialModel }): JSX.Element => {
  const { social } = props;

  return (
    <div className="social-btns-wrapper">
      {social.facebook ? (
        <a
          href={social.facebook ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookIcon />
        </a>
      ) : (
        <></>
      )}
      {social.instagram ? (
        <a
          href={social.instagram ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon />
        </a>
      ) : (
        <></>
      )}
      {social.morph_market ? (
        <a
          href={social.morph_market ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <MorphmarketIcon />
        </a>
      ) : (
        <></>
      )}
      {social.pinterest ? (
        <a
          href={social.pinterest ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <PinterestIcon />
        </a>
      ) : (
        <></>
      )}
      {social.reddit ? (
        <a href={social.reddit ?? ''} target="_blank" rel="noopener noreferrer">
          <RedditIcon />
        </a>
      ) : (
        <></>
      )}
      {social.tiktok ? (
        <a href={social.tiktok ?? ''} target="_blank" rel="noopener noreferrer">
          <TiktokIcon />
        </a>
      ) : (
        <></>
      )}
      {social.twitter ? (
        <a
          href={social.twitter ?? ''}
          target="_blank"
          rel="noopener noreferrer"
        >
          <TwitterIcon />
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};
