/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  EnclosureGallery,
  Enclosure as EnclosureModel,
} from 'cobranetics-types/types';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { ArchiveOnCard } from '../../components/archive';
import { FetchLifeformParams, getAnimalCards } from '../../actions/animals';
import { CHECK, GET_ANIMALS, GET_ENCLOSURE_GALLERY } from '../../actions';
import { getEnclosureGalleryCards } from '../../actions/gallery';
import { MainAction } from '../../typings/main_context_reducer';
import { deleteEnclosure, getEnclosure } from '../../actions/enclosure';
import { useMainContext } from '../../context/context';
import { FavoriteEnclosureButton } from '../../components/favorites';
import {
  DEFAULT_IMAGES,
  DEFAULT_IMAGES_PER_PAGE,
} from '../../shared/constants';
import { authRefresh } from '../../actions/check';
import { FailedAuthCheck } from '../../shared/errors';
import { getEnclosureMenus } from '../../shared/menu';
import { Paths } from '../../routes/paths';
import { getPeople } from '../../actions/person';
import { DeleteModal } from '../../components/modals/delete';
import { AnimalCard } from '../../components/animals';
import { GalleryCard } from '../../components/gallery';
import { errorNotification } from '../../components/notification';

export const ViewEnclosureItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');

  const [isBusy, setBusy] = useState(true);
  const [enclosure, setEnclosure] = useState({} as EnclosureModel);
  const [isFavoriteEnclosure, setIsFavoriteEnclosure] = useState(false);
  const [favoriteEnclosureID, setFavoriteEnclosureID] = useState('');
  const [username, setUsername] = useState('');
  const [openModal, setOpenModal] = useState(false);

  const [enclosureImages, setEnclosureImages] = useState<EnclosureGallery[]>(
    [],
  );
  const [hasMoreImages, setHasMoreImages] = useState(false);
  const [loadMoreImages, setLoadMoreImages] = useState(false);
  const [currentImagePage, setCurrentImagePage] = useState(1);

  const default_image = mainState?.enclosureGallery?.data.filter(
    (image) => image.is_default_image,
  )[0];
  const commonRowMenus = getEnclosureMenus(
    navigate,
    paramsID ?? '',
    mainState?.account?.id === enclosure?.account_id,
    username,
    () => {
      setOpenModal(true);
    },
  );
  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteEnclosure(`${pageID}`);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'enclosure',
    name: enclosure.name,
  };

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;
        dispatch(checkAction);

        if (Number.isNaN(pageID)) {
          return;
        }

        const animalCardParams: FetchLifeformParams = {
          currentPage: 1,
          perPage: 20,
          sort_by: '-id',
          enclosure_id: paramsID,
        };
        const enclosureGalleryParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
          enclosure_ids: `${pageID}`,
        };

        const animals = await getAnimalCards(animalCardParams);
        const enclosureGallery = await getEnclosureGalleryCards(
          enclosureGalleryParams,
        );
        const enclosure = await getEnclosure(paramsID ?? '');
        const person = (await getPeople({ account_id: enclosure.account_id }))
          .data[0];

        const enclosureImageList = await getEnclosureGalleryCards({
          enclosure_ids: `${pageID}`,
          perPage: DEFAULT_IMAGES_PER_PAGE,
          currentPage: currentImagePage,
        });

        const animalAction = {
          type: GET_ANIMALS,
          animalAction: {
            animals,
          },
        } as MainAction;
        const enclosureGalleryAction = {
          type: GET_ENCLOSURE_GALLERY,
          enclosureGalleryAction: {
            enclosureGallery,
          },
        } as MainAction;

        dispatch(animalAction);
        dispatch(enclosureGalleryAction);
        setEnclosure(enclosure);
        setUsername(person.username);
        setEnclosureImages(enclosureImageList.data);
        setHasMoreImages(!!enclosureImageList.nextPage.length);
        setCurrentImagePage(currentImagePage + 1);
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.error(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (!hasMoreImages || !loadMoreImages) {
        return;
      }

      // Fetch more images
      const enclosureImageList = await getEnclosureGalleryCards({
        enclosure_ids: `${pageID}`,
        perPage: DEFAULT_IMAGES_PER_PAGE,
        currentPage: currentImagePage,
      });

      setEnclosureImages([...enclosureImages, ...enclosureImageList.data]);
      setCurrentImagePage(currentImagePage + 1);
      setLoadMoreImages(false);
      setHasMoreImages(
        enclosureImageList.data.length === DEFAULT_IMAGES_PER_PAGE,
      );
    };

    loadData();
  }, [loadMoreImages]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      setLoadMoreImages(true);
    }
  };

  return (
    <div className="view-enclosure">
      <Header hideLinks={false} title={'Enclosure'} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section
            className="view-enclosure-background enclosure-item-background"
            onScroll={(e: any) => {
              handleScroll(e);
            }}
          >
            <PageHeader
              title={enclosure.name ?? 'Unknown'}
              showBackBtn={true}
              activeTab={''}
              menu={commonRowMenus.mainMenu}
            />
            <section className="view-enclosure-item">
              <div className="main-info">
                <div className="image-wrapper">
                  {enclosure.is_archived ? (
                    <ArchiveOnCard is_archived={enclosure.is_archived} />
                  ) : (
                    <></>
                  )}
                  {mainState.account?.id ? (
                    <FavoriteEnclosureButton
                      {...{
                        isFavoriteEnclosure,
                        favoriteEnclosureID,
                        enclosureID: enclosure.id,
                        accountID: mainState.account?.id ?? 0,
                        setIsFavoriteEnclosure,
                        setFavoriteEnclosureID,
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  <img
                    src={default_image?.image_url || DEFAULT_IMAGES.enclosure}
                    alt=""
                    className="display-image"
                  />
                </div>
                <div className="info">
                  <div className="info-item">
                    <p className="title">Temperature</p>
                    <p className="value">
                      {enclosure.temperature ?? 'Unknown'}
                    </p>
                  </div>
                  <div className="info-item">
                    <p className="title">Dimensions</p>
                    <p className="value">{enclosure.dimensions ?? 'Unknown'}</p>
                  </div>
                  <div className="info-item">
                    <p className="title">Humidity</p>
                    <p className="value">{enclosure.humidity ?? 'Unknown'}</p>
                  </div>
                </div>
              </div>

              <section className="enclosure-animal-section">
                <h5 className="title">Animals</h5>
                <div className="enclosure-animal-wrapper">
                  {mainState.animals?.data.map((animal) => (
                    <AnimalCard key={`animal-card-${animal.id}`} {...animal} />
                  ))}
                </div>
              </section>

              {!!enclosureImages.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Images</h5>
                  <div className="inner-wrapper">
                    {enclosureImages.map((row) => (
                      <GalleryCard
                        key={`image-card-${row.image_url}`}
                        id={row.id}
                        image={row.image_url ?? ''}
                        category={'enclosure'}
                      />
                    ))}
                  </div>
                </section>
              )}
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
