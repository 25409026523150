import { PathsAPI } from "../routes/paths";
import { axiosInstance } from "../shared/axios";

export const forgotPassword = async (email: string): Promise<void> => {
  try {
    await axiosInstance.post(PathsAPI.forgotPassword, { email });
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = async (body: {
  token: string;
  newPassword: string;
  confirmPassword: string;
}): Promise<void> => {
  return axiosInstance.post(PathsAPI.resetPassword, body);
};
