/* istanbul ignore file */

export { PathsAPI } from 'cobranetics-types';

export enum Paths {
  // Auth
  login = '/login',
  signup = '/sign-up',
  forgotPassword = '/forgot-password',
  passwordReset = '/password-reset',
  logout = 'logout',

  // Base
  home = '/',
  browse = '/browse',

  // Create / Edit
  settings = '/settings',
  editProfile = '/edit-profile/:id',
  editProfileBase = '/edit-profile',

  createEnv = '/add-enclosure',
  editEnv = '/edit-enclosure/:id',
  editEnvBase = '/edit-enclosure',

  addAnimal = '/add-animal',
  editAnimal = '/edit-animal/:id',
  editAnimalBase = '/edit-animal',

  addSensor = '/add-sensor',
  editSensor = '/edit-sensor/:id',
  editSensorBase = '/edit-sensor',

  addInventory = '/add-inventory',
  editInventory = '/edit-inventory/:id',
  editInventoryBase = '/edit-inventory',

  addInventoryTransaction = '/add-inventory-transaction',
  editInventoryTransaction = '/edit-inventory-transaction',

  addReading = '/add-reading',
  editReading = '/edit-reading',

  addFeeding = '/add-feeding',
  editFeeding = '/edit-feeding',

  addFeedingScheduleGroup = '/add-feedings',

  addObservation = '/add-observation',
  editObservation = '/edit-observation/:id',
  editObservationBase = '/edit-observation',

  addObservationReading = '/add-observation-recording',
  editObservationReading = '/edit-observation-recording/:id',
  editObservationReadingBase = '/edit-observation-recording',

  bulk = '/bulk',

  // Views
  enclosure = '/enclosure',

  viewInventory = '/inventory',
  viewInventoryItem = '/inventory/:id',

  viewEnclosure = '/enclosure',
  viewEnclosureItem = '/enclosure/:id',

  viewAnimal = '/animal',
  viewAnimalItem = '/animal/:id',

  viewSensor = '/sensor',
  viewSensorItem = '/sensor/:id',

  viewProfile = '/profile',
  viewProfileItem = '/profile/:id',

  viewFeedingSchedule = '/feeding-schedule',
  viewFeedingScheduleItem = '/feeding-schedule/:id',

  viewGallery = '/gallery',
  viewEnclosureGalleryItem = 'enclosure/:paramsID',
  viewLifeformGalleryItem = 'lifeform/:paramsID',
  viewObservationGalleryItem = 'observation/:paramsID',

  viewEnclosureGalleryRootPage = '/gallery/enclosure',
  viewLifeformGalleryRootPage = '/gallery/lifeform',
  viewObservationGalleryRootPage = '/gallery/observation',

  viewObservation = '/observation',
  viewObservationItem = '/observation/:id',

  archived = '/archived',
  favorites = '/favorites',
}
