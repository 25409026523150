import React from 'react';
import { Attribute } from '../typings/attribute';

export const AttributeCard = (props: Attribute): JSX.Element => {
  const { keyAttribute, value } = props;

  const textLength = keyAttribute?.length + value?.length ?? 0;
  const maxCharacters = 35;
  const isLarge = textLength > maxCharacters;

  return (
    <div
      className={`attribute-card ${isLarge ? 'large' : ''}`}
      key={`${keyAttribute?.replaceAll(' ', '')}-${value?.replaceAll(' ', '')}`}
    >
      <h6 className="key">{keyAttribute}</h6>
      <p className="value">{value}</p>
    </div>
  );
};
