import React from 'react';
import { EnclosureCard as EnclosureCardModel } from 'cobranetics-types/types';

import { Paths } from '../routes/paths';
import { DEFAULT_IMAGES } from '../shared/constants';
import { DropDownOptions } from '../typings/menu-dropdown';
import { MenuBtnThreeDots } from './menu-dropdown';
import { ReactComponent as EnclosureIcon } from '../icons/enclosure.svg';
import { ReactComponent as MenuIcon } from '../icons/three-dot-menu.svg';

export const determineNamesToShow = (names: {
  pet_names: string[] | undefined;
  common_names: string[] | undefined;
  scientific_names: string[] | undefined;
}): string[] => {
  const { pet_names, common_names, scientific_names } = names;
  const animalNames: string[] = [];
  const allSameLength =
    ((pet_names?.length === common_names?.length) !== false) ===
    ((common_names?.length === scientific_names?.length) !== false);

  if (allSameLength && common_names?.length) {
    animalNames.push(...common_names);
    return animalNames;
  }

  const container = [pet_names, common_names, scientific_names];

  container.sort((a, b) => {
    if (a === undefined || b === undefined) {
      return 0;
    }
    return b.length - a.length;
  });

  return container[0] || [''];
};

export const EnclosureWrapper = (props: {
  showHeader: boolean;
  className?: string;
}): JSX.Element => {
  const enclosures: EnclosureCardModel[] = [];
  const enclosureMenu: DropDownOptions[] = [
    {
      text: 'View Enclosure',
      onClick: () => {},
    },
    {
      text: 'Edit Enclosure',
      onClick: () => {},
    },
  ];

  return (
    <div className={`enclosures-wrapper ${props.className}`}>
      <div className="enclosures-inner-wrapper">
        {enclosures.map((enclosure) => (
          <div key={enclosure.name} className="enclosure-outer-card">
            <MenuBtnThreeDots dropDownOptions={enclosureMenu} />
            {EnclosureCard(enclosure)}
          </div>
        ))}
      </div>
    </div>
  );
};

export const EnclosureCard = (enclosure: EnclosureCardModel): JSX.Element => {
  return (
    <div className="enclosure-card common-card standard-card">
      <section className="top-content">
        <a href={`${Paths.viewEnclosure}/${enclosure.id}`}>
          <div className="card-identifier">
            <EnclosureIcon />
          </div>
        </a>

        <div className="card-menu">
          <MenuIcon />
        </div>
      </section>
      <div className="card-content-wrapper">
        <a href={`${Paths.viewEnclosure}/${enclosure.id}`}>
          <section className="main-content">
            <img src={enclosure.image_url || DEFAULT_IMAGES.enclosure} />
          </section>
        </a>

        <section className="bottom-content">
          <div className="titles">
            <div className="bottom-card-title">
              <p>{enclosure.name}</p>
            </div>
            <div className="bottom-card-sub-titles">
              <p>{enclosure.dimensions}</p>
            </div>
          </div>
          <div className="action-btn-wrapper"></div>
        </section>
      </div>
    </div>
  );
};
