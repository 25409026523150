import { ListOutput } from "../actions";
import { Inventory, InventoryTransaction } from "cobranetics-types/types";

export enum InventoryCategories {
  Food = "food",
  LightingHeating = "lighting & heating",
  Enclosures = "enclosures",
  Sensors = "sensors",
  Accessories = "accessories",
  Substrate = "substrate",
  Cleaning = "cleaning supplies",
  Equipment = "equipment",
  Medicine = "medicine",
  Supplements = "supplements",
}

export interface InventoryAction {
  inventory: ListOutput<Inventory>;
}

export interface InventoryTransactionAction {
  inventoryTransactions: ListOutput<InventoryTransaction>;
}

export interface ImageProps {
  inventoryID: number;
  activeImage: string;
  file: any;
  setFile: (file: any) => void;
}
