/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Table } from 'antd';

import { SearchResult } from 'cobranetics-types/types/search';
import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { NameInput, NumberInput, selectStyle } from '../../components/input';
import { useMainContext } from '../../context/context';
import { getCheck } from '../../actions/check';
import { CHECK } from '../../actions';
import { MainAction } from '../../typings/main_context_reducer';
import { FailedAuthCheck } from '../../shared/errors';
import { InventoryCategories } from '../../typings/inventory';
import { toTitleCase } from '../../shared/utils';
import {
  FetchInventoryTransactionParams,
  createInventory,
  deleteInventory,
  getInventory,
  getInventoryCards,
  getInventoryTransactions,
  updateInventory,
} from '../../actions/inventory';
import { ImageSection } from '../../components/inventory/images';
import { Paths, PathsAPI } from '../../routes/paths';
import { axiosInstance } from '../../shared/axios';
import { ResultsWrapper } from '../../components/results-wrapper';
import { handleScroll } from '../../shared/window';
import { DeleteModal } from '../../components/modals/delete';
import { infoNotification } from '../../components/notification';
import { formatDefaultDateTime } from '../../utils/dates';

export const AddInventory = (): JSX.Element => {
  const { paramsID } = useParams();
  const isEdit = paramsID !== undefined;
  const perPage = 20;

  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const pageID = isEdit ? Number.parseInt(paramsID) : 0;
  const [isBusy, setBusy] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  const [name, setName] = useState('');
  const visibilityOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];
  const [visibilityOpt, setVisibilityOpt] = useState(visibilityOptions[0]);
  const itemTypeOptions = Object.values(InventoryCategories).map((val) => ({
    value: val,
    label: toTitleCase(val),
  }));
  const [itemTypeOpt, setItemTypeOpt] = useState(itemTypeOptions[0]);
  const [costPerItem, setCostPerItem] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [activeImage, setActiveImage] = useState('');
  const [file, setFile] = useState({} as any);
  const [searchPagination, setSearchPagination] = useState({
    currentPage: 1,
    perPage,
  });
  const [reachedBottom, setReachedBottom] = useState(false);
  const [noDataLeft, setNoDataLeft] = useState(false);
  const [searchData, setSearchData] = useState<SearchResult[]>([]);
  const [reachedLimit, setReachedLimit] = useState(false);
  const [totalTransactions, setTotalTransactions] = useState(0);

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteInventory(pageID);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'inventory',
    name,
  };

  const columns = [
    {
      title: 'Date / Time',
      dataIndex: 'recorded_on',
      key: 'recordedOn',
    },
    {
      title: 'Count',
      dataIndex: 'change_count',
      key: 'changeCount',
    },
    {
      title: 'Cost Per Item',
      dataIndex: 'cost_per_item',
      key: 'costPerItem',
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);

        if (isEdit) {
          if (Number.isNaN(pageID)) {
            return;
          }

          const inventory = await getInventory(pageID);

          setName(inventory.item_name);
          setCostPerItem(inventory.average_cost_per_item);
          setQuantity(inventory.quantity);
          setItemTypeOpt({
            value: inventory.category,
            label: toTitleCase(inventory.category),
          });
          setVisibilityOpt({
            value: inventory.is_public,
            label: inventory.is_public ? 'Yes' : 'No',
          });

          if (inventory.image_url) {
            setActiveImage(inventory.image_url);
          }
        } else {
          const rows = await getInventoryCards({
            account_id: check.account.id,
            include_empty: true,
            ignore_archived: true,
          });
          const count = rows.total || 0;
          const countLimit = check.membershipConfig.countLimits.inventoryGroup;
          const overCountLimit = count >= countLimit;

          if (overCountLimit) {
            infoNotification(
              `Upgrade Membership: Sorry, you have reached your inventory group limit.`,
            );
          }

          setReachedLimit(overCountLimit);
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
    window.addEventListener('scroll', handleScroll(setReachedBottom));

    return () =>
      window.removeEventListener('scroll', handleScroll(setReachedBottom));
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (!paramsID) return;

      const inventoryTransactionParams: FetchInventoryTransactionParams = {
        ...searchPagination,
        sort_by: '-id',
        inventory_id: paramsID,
      };
      const inventoryTransactions = await getInventoryTransactions(
        inventoryTransactionParams,
      );
      const transactions: any[] = inventoryTransactions.data.map((row) => ({
        ...row,
        filterTypeOption: 'inventoryTransaction',
      }));

      transactions.sort(
        (a, b) => moment(a.recorded_on).unix() - moment(b.recorded_on).unix(),
      );

      setSearchData([...transactions]);
      setNoDataLeft(transactions.length < perPage);
      setTotalTransactions(inventoryTransactions.total || 0);
    };

    loadData();
  }, [searchPagination]);

  useEffect(() => {
    if (reachedBottom && !noDataLeft) {
      setSearchPagination({
        currentPage: searchPagination.currentPage + 1,
        perPage: perPage + searchPagination.perPage,
      });
    }
  }, [reachedBottom]);

  console.log({ searchData });

  return (
    <div className="add-inventory">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="add-inventory-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title={`${isEdit ? 'Edit' : 'Add'} Inventory Group`}
                showBackBtn={true}
                activeTab={''}
              />
              <section className="common-section">
                <div className="top">
                  <ImageSection
                    inventoryID={pageID}
                    activeImage={activeImage}
                    file={file}
                    setFile={setFile}
                  />
                  <div className="title-input-wrapper">
                    <p className="title">Name</p>
                    <NameInput
                      name={name}
                      setName={setName}
                      placeholder="Name"
                      inputName="inventory-item-name"
                    />
                  </div>
                  <div className="title-input-wrapper">
                    <p className="title">Category</p>
                    <Select
                      name="itemType"
                      id="itemType"
                      styles={selectStyle}
                      options={itemTypeOptions}
                      onChange={(val) => {
                        if (val) {
                          setItemTypeOpt(val);
                        }
                      }}
                      value={itemTypeOpt}
                    />
                  </div>
                  <div className="dual-input">
                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Cost per item (USD)</p>
                      <NumberInput
                        setNumber={setCostPerItem}
                        placeholder="$0.00"
                        inputName="cost-per-item"
                        value={costPerItem === 0 ? '' : costPerItem}
                      />
                    </div>

                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Quantity</p>
                      <NumberInput
                        setNumber={setQuantity}
                        placeholder="1"
                        inputName="quantity"
                        value={quantity === 0 ? '' : quantity}
                      />
                    </div>
                  </div>

                  {mainState.membershipConfig?.flags.privacy ? (
                    <div className="title-input-wrapper">
                      <p className="title">Public</p>
                      <Select
                        name="visibility"
                        id="visibility"
                        styles={selectStyle}
                        options={visibilityOptions}
                        value={visibilityOpt}
                        onChange={(val) => {
                          if (val) {
                            setVisibilityOpt(val);
                          }
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {isEdit ? (
                  <div className="bottom two">
                    <button
                      className="btn-done"
                      onClick={async () => {
                        const inventory = await updateInventory({
                          id: paramsID,
                          item_name: name,
                          is_archived: false,
                          is_public: visibilityOpt.value,
                          category: itemTypeOpt.value,
                          quantity: quantity,
                          average_cost_per_item: costPerItem,
                        });

                        if (Object.keys(file).length) {
                          const formData = new FormData();
                          formData.append('file', file);

                          await axiosInstance.post(
                            `${PathsAPI.uploadInventoryImage}/${inventory.id}`,
                            formData,
                            {
                              headers: {
                                'Content-Type': 'multipart/form-data',
                              },
                            },
                          );
                        }

                        navigate(
                          Paths.viewInventoryItem.replace(
                            ':id',
                            `${inventory.id}`,
                          ),
                        );
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn-delete"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <div className="bottom full">
                    <button
                      className="btn-done"
                      disabled={reachedLimit}
                      onClick={async () => {
                        const inventory = await createInventory({
                          item_name: name,
                          is_archived: false,
                          is_public: visibilityOpt.value,
                          category: itemTypeOpt.value,
                          image_url: '',
                          quantity: quantity,
                          average_cost_per_item: costPerItem,
                        });

                        if (Object.keys(file).length) {
                          const formData = new FormData();
                          formData.append('file', file);

                          await axiosInstance.post(
                            `${PathsAPI.uploadInventoryImage}/${inventory.id}`,
                            formData,
                            {
                              headers: {
                                'Content-Type': 'multipart/form-data',
                              },
                            },
                          );
                        }

                        navigate(
                          Paths.viewInventoryItem.replace(
                            ':id',
                            `${inventory.id}`,
                          ),
                        );
                      }}
                    >
                      Save
                    </button>
                  </div>
                )}
              </section>
            </section>
          </section>

          {searchData.length > 0 ? (
            <div className="inner-table-wrapper">
              <Table
                dataSource={searchData.map((row: any, index) => ({
                  ...row,
                  recorded_on: formatDefaultDateTime(moment(row.recorded_on)),
                  cost_per_item: `$${(
                    Math.round(row.cost_per_item * 100) / 100
                  ).toFixed(2)}`,
                  key: `${row.recordedOn}-${index}`,
                }))}
                columns={columns}
                pagination={{
                  total: totalTransactions,
                  pageSize: searchPagination.perPage,
                  onChange: async (page, pageSize) => {
                    if (reachedBottom && !noDataLeft) {
                      setSearchPagination({
                        currentPage: page,
                        perPage: pageSize,
                      });
                    }
                  },
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
