import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as ArrowIcon } from "../icons/arrow-back.svg";
import { DropDownOptions } from "../typings/menu-dropdown";
import { MenuBtnThreeDots } from "./menu-dropdown";

export interface Tab {
  title: string;
  clickTab: () => any;
  isDisabled?: boolean;
}

interface PageHeaderInterface {
  title: string;
  showBackBtn: boolean;
  tabs?: Tab[];
  activeTab: string;
  useSubHeader?: boolean;
  tabGroups?: Tab[][];
  subTitle?: string;
  menu?: DropDownOptions[];
}

export const PageHeader = (props: PageHeaderInterface): JSX.Element => {
  const navigate = useNavigate();

  return (
    <div
      className={`${props.useSubHeader ? "sub " : ""}page-header ${
        props.showBackBtn ? "less-pad" : ""
      }`}
    >
      {!props.title ? (
        <></>
      ) : (
        <>
          <div className="top-page-header-row">
            {props.showBackBtn ? (
              <button className="back-btn" onClick={() => navigate(-1)}>
                <ArrowIcon className="arrow-icon" />
                Back
              </button>
            ) : (
              <></>
            )}
            {props.menu ? (
              <MenuBtnThreeDots dropDownOptions={props.menu} />
            ) : (
              <></>
            )}
          </div>
          {props.useSubHeader ? (
            <h6 className="sub-page-title">{props.title}</h6>
          ) : (
            <h5 className="page-title">{props.title}</h5>
          )}
        </>
      )}

      {props.subTitle ? <h6 className="sub-title">{props.subTitle}</h6> : <></>}

      {props.tabs ? (
        <ul className="page-tabs">
          {props.tabs.map((tab) => (
            <li
              key={`tab-${tab.title}`}
              className={`${
                tab.title === props.activeTab ? "active-tab" : ""
              } ${tab.isDisabled ? "disabled" : ""}`}
            >
              <button
                onClick={() => {
                  if (tab.isDisabled) {
                    return;
                  }
                  tab.clickTab();
                }}
              >
                {tab.title}
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <></>
      )}

      {props.tabGroups ? (
        <>
          {props.tabGroups.map((tabGroup, tgIndex) => (
            <ul key={`tab-group-${tgIndex}`} className="page-tabs grouped-tabs">
              {tabGroup.map((tab) => (
                <li
                  key={`tab-${tab.title}`}
                  className={`${
                    tab.title === props.activeTab ? "active-tab" : ""
                  }`}
                >
                  <button onClick={tab.clickTab}>{tab.title}</button>
                </li>
              ))}
            </ul>
          ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
