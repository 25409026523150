import React from 'react';
import { Modal } from 'antd';
import { ModalProps } from '../../typings/modal';

export interface DeleteModalProps extends ModalProps {
  category: string;
  name: string;
  deleteItem(): void;
}

export const DeleteModal = ({
  closeModal,
  deleteItem,
  modalIsOpen,
  category,
  name,
}: DeleteModalProps) => {
  return (
    <Modal
      open={modalIsOpen}
      onCancel={closeModal}
      title="Delete"
      className="delete-modal"
      footer={null}
    >
      <h5 className="title">{`Are you sure you want to delete this ${category}?`}</h5>
      <p className="name">{name}</p>
      <div className="btn-wrapper">
        <button className="btn-cancel btn-default" onClick={closeModal}>
          Cancel
        </button>
        <button className="btn-delete" onClick={deleteItem}>
          Delete
        </button>
      </div>
    </Modal>
  );
};
