import React, { useState } from 'react';
import { ReactComponent as MenuIcon } from '../icons/more-dots.svg';
import { ReactComponent as EditIcon } from '../icons/edit.svg';
import { ReactComponent as ArchiveIcon } from '../icons/archived.svg';
import { ReactComponent as DeleteIcon } from '../icons/delete2.svg';
import { ReactComponent as KeeperIcon } from '../icons/profile.svg';
import { ReactComponent as AddIcon } from '../icons/add.svg';
import { ReactComponent as AnimalIcon } from '../icons/pet.svg';
import { ReactComponent as ReadingIcon } from '../icons/observation.svg';
import { ReactComponent as EnclosureIcon } from '../icons/enclosure.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as SettingIcon } from '../icons/settings.svg';
import { ReactComponent as ImportIcon } from '../icons/import.svg';
import { ReactComponent as ExportIcon } from '../icons/export.svg';
import {
  MenuDropDownProps,
  MenuBtnThreeDotsProps,
} from '../typings/menu-dropdown';

export const MenuBtnThreeDots = (props: MenuBtnThreeDotsProps) => {
  const [showMenu, setShowMenu] = useState(false);

  return (
    <button
      className="btn-menu"
      onClick={() => {
        setShowMenu(!showMenu);
      }}
    >
      <MenuIcon className="three-dot-icon" />
      <MenuDropdown
        options={props.dropDownOptions}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
    </button>
  );
};

export const icons = (title: string) => {
  let icon = <></>;

  switch (title) {
    case 'Edit':
      icon = <EditIcon className="icon edit-icon" />;
      break;
    case 'Archive':
      icon = <ArchiveIcon className="icon archive-icon" />;
      break;
    case 'Delete':
      icon = <DeleteIcon className="icon delete-icon" />;
      break;
    case 'Keeper':
      icon = <KeeperIcon className="icon keeper-icon" />;
      break;
    case 'Add Feeding':
    case 'Add Observation':
    case 'Add Transaction':
    case 'Add':
      icon = <AddIcon className="icon add-icon" />;
      break;
    case 'Animal':
      icon = <AnimalIcon className="icon animal-icon" />;
      break;
    case 'Enclosure':
      icon = <EnclosureIcon className="icon enclosure-icon" />;
      break;
    case 'Observation':
    case 'Reading':
      icon = <ReadingIcon className="icon reading-icon" />;
      break;

    case 'Profile':
      icon = <ReadingIcon className="icon profile-icon" />;
      break;
    case 'Settings':
      icon = <SettingIcon className="icon settings-icon" />;
      break;
    case 'Import':
      icon = <ImportIcon className="icon import-icon" />;
      break;
    case 'Export':
      icon = <ExportIcon className="icon export-icon" />;
      break;
    case 'Logout':
      icon = <LogoutIcon className="icon logout-icon" />;
      break;
    default:
      break;
  }

  return icon;
};

export const MenuDropdown = (props: MenuDropDownProps): JSX.Element => {
  return (
    <ul
      className={`menu-dropdown ${props.showMenu ? 'show-menu' : ''}`}
      onClick={() => {
        props.setShowMenu(true);
      }}
      onMouseLeave={() => {
        props.setShowMenu(false);
      }}
    >
      {props.options.map((opts) => (
        <li
          key={`menu-options-${opts.text}`}
          onClick={async () => {
            props.setShowMenu(false);
            await opts.onClick();
          }}
        >
          {opts.link ? (
            <a href={opts.link}>
              {icons(opts.text)}
              {opts.text}
            </a>
          ) : (
            <div className="menu-opt-wrapper">
              {icons(opts.text)}
              {opts.text}
            </div>
          )}
        </li>
      ))}
    </ul>
  );
};
