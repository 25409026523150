/* istanbul ignore file */

// Grid
export const CHANGE_ENTITIES = 'change entities';
export const CHANGE_GALLERY_IMAGE = 'change gallery image';
export const SIGN_UP = 'sign up';
export const LOGIN = 'login';
export const CHECK = 'check';

export const GET_FEEDINGS = 'feedings';
export const GET_FEEDING_SCHEDULE = 'feedingSchedules';

export const GET_ANIMALS = 'animals';
export const GET_LIFEFORM = 'lifeform';
export const GET_ENCLOSURES = 'enclosures';
export const GET_ENCLOSURE = 'enclosure';

export const GET_INVENTORY = 'inventory';
export const GET_INVENTORY_TRANSACTION = 'inventory_transactions';

export const GET_SENSORS = 'sensors';
export const GET_READINGS = 'readings';

export const GET_OBSERVATIONS = 'observations';
export const GET_OBSERVATION_READINGS = 'observation_reading';
export const GET_OBSERVATION_GALLERY = 'observationGallery';

export const GET_ENCLOSURE_GALLERY = 'enclosure_gallery';
export const GET_LIFEFORM_GALLERY = 'lifeform_gallery';

export const GET_FOLLOW_PAIR = 'following';
export const HOME_ACTION = 'home';
export const PROFILE_ACTION = 'profile';
export const FAVORITE_PAGE_ACTION = 'fav_page';
export const ARCHIVED_PAGE_ACTION = 'archived_page';
export const BROWSE_ACTION = 'browse_page';
export const PERSON_ACTION = 'people';
export const FOLLOW_PAIR_ACTION = 'followPair';

export const ENCLOSURE_HUMIDITY_READING_ACTION = 'enclosureHumidityReadings';
export const ENCLOSURE_LIGHT_LUX_READING_ACTION = 'enclosureLightLuxReadings';
export const ENCLOSURE_LIGHT_UV_READING_ACTION = 'enclosureLightUvReadings';
export const ENCLOSURE_TEMPERATURE_READING_ACTION =
  'enclosureTemperatureReadings';
export const LIFEFORM_SIZE_READING_ACTION = 'lifeformSizeReadings';
export const LIFEFORM_WEIGHT_READING_ACTION = 'lifeformWeightReadings';

export interface ListOutput<T> {
  data: T[];
  nextPage: string;
  prevPage: string;
  total?: number;
}

export interface Params {
  currentPage?: number;
  perPage?: number;
  sort_by?: string;
}

export interface RequiredParams {
  currentPage: number;
  perPage: number;
  sort_by?: string;
}
