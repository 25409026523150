/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { formatDate, getOption, getSearchParams } from '../../shared/utils';
import { getCheck } from '../../actions/check';
import { CHECK } from '../../actions';
import { FailedAuthCheck } from '../../shared/errors';
import { MainAction } from '../../typings/main_context_reducer';
import { useMainContext } from '../../context/context';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  createObservation,
  getObservationCard,
  updateObservation,
} from '../../actions/observation';
import { SelectListOption } from '../../typings/common';
import { getAnimals } from '../../actions/animals';
import { Paths } from '../../routes/paths';
import { ObservationCategories } from '../../typings/observation';
import { DeleteModal } from '../../components/modals/delete';
import { AddObservationTabs } from '../../typings/observation';
import { GeneralSection } from '../../components/create-observation/general';
import { ImageSection } from '../../components/create-observation/images';
import { DEFAULT_IMAGES } from '../../shared/constants';
import {
  getObservationGalleryCards,
  getObservationGalleryImages,
} from '../../actions/gallery';
import {
  errorNotification,
  successNotification,
} from '../../components/notification';
import { formateDateTimeForAPI } from '../../utils/dates';

export const AddObservation = (): JSX.Element => {
  const { paramsID } = useParams();
  const isEdit = paramsID !== undefined;

  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = getSearchParams(searchParams);

  const [observationID, setObservationID] = useState('');
  const [isBusy, setBusy] = useState(true);

  const [, dispatch] = useMainContext();
  const navigate = useNavigate();
  const pageID = isEdit ? Number.parseInt(paramsID) : 0;

  // Tabs
  let tab = AddObservationTabs.general;
  switch (queryParams.tab) {
    case AddObservationTabs.images:
      tab = AddObservationTabs.images;
      break;
    default:
      break;
  }
  const [activeTab, setActiveTab] = useState(
    isEdit ? tab : AddObservationTabs.general,
  );

  // General
  const [name, setName] = useState('');
  const [recording, setRecording] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [animalOptions, setAnimalOptions] = useState<SelectListOption[]>([]);
  const [selectedAnimalOption, setSelectedAnimalOption] = useState<
    SelectListOption | undefined
  >(undefined);

  const [selectedType, setSelectedType] = useState<
    SelectListOption | undefined
  >(undefined);
  const observationType = Object.values(ObservationCategories).map((val) => {
    const row = {
      value: val,
      label: val as string,
    };

    if (val === ObservationCategories.Ecdysis) {
      row.label = 'Shed / Molt';
    }

    return row;
  });
  const [date, time] = formatDate(new Date()).split(' ');
  const [dateTime, setDateTime] = useState(date + 'T' + time);

  // Images
  const [images, setImages] = useState([] as { id: string; src: string }[]);
  const [activeImage, setActiveImage] = useState(DEFAULT_IMAGES.observation);
  const [imageCount, setImageCount] = useState(0);

  const saveObservation = async () => {
    const observedOn = formateDateTimeForAPI(new Date(dateTime));

    const observationBody = {
      title: name,
      category:
        (selectedType?.value as ObservationCategories) ||
        ObservationCategories.General,
      lifeform_id: selectedAnimalOption?.value,
      is_public: true,
      recording,
      created_on: observedOn,
    };
    const id = observationID ?? 0;

    if (isEdit) {
      await updateObservation({ ...observationBody, id: observationID });
      navigate(`${Paths.viewObservation}/${id}`);
    } else {
      const observation = await createObservation(observationBody);
      setObservationID(observation.id);
    }

    successNotification('Successfully Updated');
  };

  const generalProps = {
    name,
    setName,
    selectedType,
    setSelectedType,
    animalOptions,
    selectedAnimalOption,
    setSelectedAnimalOption,
    isEdit,
    recording,
    setRecording,
    setOpenDeleteModal,
    saveObservation,
    observationType,
    setActiveTab,
    dateTime,
    setDateTime,
  };

  const imageProps = {
    images,
    activeImage,
    setImages,
    setActiveImage,
    setActiveTab,
    isEdit,
    observationID,
    navigate,
    imageCount,
  };

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenDeleteModal(false);
    },
    deleteItem: async () => {
      navigate(Paths.home);
    },
    modalIsOpen: openDeleteModal,
    category: 'observation',
    name,
  };

  const tabs = [
    {
      title: AddObservationTabs.general,
      clickTab: () => {
        setActiveTab(AddObservationTabs.general);
        if (isEdit) {
          setSearchParams({ tab: AddObservationTabs.general });
        }
      },
    },
    {
      title: AddObservationTabs.images,
      clickTab: () => {
        if (name.length === 0 || !selectedType || !selectedAnimalOption) {
          errorNotification('You must add a name and select a category.');
          return;
        }

        setActiveTab(AddObservationTabs.images);

        if (isEdit) {
          setSearchParams({ tab: AddObservationTabs.images });
        }
      },
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);

        const animalParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
          account_id: check.account.id,
          is_archived: false,
        };
        const animals = await getAnimals(animalParams);
        const animalOpts = animals.data.map((animal) => ({
          value: animal.id + '',
          label: `${animal.pet_name} (${
            check.settings.name_preference
              ? animal.scientific_name
              : animal.common_name
          })`,
        }));
        setAnimalOptions(animalOpts);

        const imageRows = await getObservationGalleryImages({
          account_id: check.account.id,
          perPage: 1,
        });
        setImageCount(imageRows.total || 0);

        if (isEdit) {
          if (Number.isNaN(pageID)) {
            return;
          }

          const observation = await getObservationCard(pageID);
          const observationGalleryParams = {
            currentPage: 1,
            perPage: 10,
            sort_by: '-id',
          };
          const observationGallery = await getObservationGalleryCards({
            ...observationGalleryParams,
            observation_ids: `${observation.id}`,
          });
          const activeImage =
            observationGallery.data.filter((img) => img.is_default_image)[0]
              ?.image_url || DEFAULT_IMAGES.animal;

          setObservationID(observation.id);
          setName(observation.title);
          setSelectedType(getOption(observation.category, observationType));
          setSelectedAnimalOption(
            getOption(observation.lifeform_id + '', animalOpts),
          );
          setRecording(observation.recording);

          setImages(
            observationGallery.data.map((galleryImage) => ({
              id: galleryImage.id,
              src: galleryImage.image_url,
            })),
          );
          setActiveImage(activeImage);
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="add-observation">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="add-observation-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title={`${isEdit ? 'Edit' : 'Add'} Observation`}
                showBackBtn={true}
                tabs={tabs}
                activeTab={activeTab}
              />

              {activeTab === AddObservationTabs.general ? (
                <GeneralSection {...generalProps} />
              ) : (
                <></>
              )}
              {activeTab === AddObservationTabs.images ? (
                <ImageSection {...imageProps} />
              ) : (
                <></>
              )}
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
