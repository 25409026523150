import { PathsAPI } from '../routes/paths';
import { FilterTypeOptions, FullSearchResults } from '../typings/search';

export const DEFAULT_IMAGES = {
  animal: '/images/default/animal-default.png',
  enclosure: '/images/default/enclosure-default.png',
  person: '/images/default/person-default.png',
  inventory: '/images/default/inventory-default.png',
  observation: '/images/default/observation-default.png',
};

export const DESKTOP_WIDTH = 768; // px

export const REACT_APP_UI_BASE_PATH = process.env.REACT_APP_UI_BASE_PATH;

export const BRAND_NAME = 'EcoZoo';

export const DEFAULT_SEARCH_PER_PAGE = 20;
export const DEFAULT_HOME_PER_PAGE = 20;
export const DEFAULT_IMAGES_PER_PAGE = 20;
export const DEFAULT_FEEDING_GROUP_ROWS_PER_PAGE = 1000;

export const SEARCH_TEMPLATE: FullSearchResults = {
  animals: {
    data: [],
    hasNextPage: true,
    endpoint: PathsAPI.lifeformCards,
    filterTypeOption: FilterTypeOptions.animals,
  },
  enclosures: {
    data: [],
    hasNextPage: true,
    endpoint: PathsAPI.enclosureCards,
    filterTypeOption: FilterTypeOptions.enclosures,
  },
  observations: {
    data: [],
    hasNextPage: true,
    endpoint: PathsAPI.observationCards,
    filterTypeOption: FilterTypeOptions.observations,
  },
  inventory: {
    data: [],
    hasNextPage: true,
    endpoint: PathsAPI.inventory,
    filterTypeOption: FilterTypeOptions.inventory,
  },
  keepers: {
    data: [],
    hasNextPage: true,
    endpoint: PathsAPI.personCards,
    filterTypeOption: FilterTypeOptions.keepers,
  },
  animalImages: {
    data: [],
    hasNextPage: true,
    endpoint: PathsAPI.lifeformGallery,
    filterTypeOption: FilterTypeOptions.images,
  },
  enclosureImages: {
    data: [],
    hasNextPage: true,
    endpoint: PathsAPI.enclosureGallery,
    filterTypeOption: FilterTypeOptions.images,
  },
};
