import React from "react";
import { Store } from "react-notifications-component";
import {
  deleteFavoriteEnclosureGalleries,
  deleteFavoriteEnclosures,
  deleteFavoriteLifeformGalleries,
  deleteFavoriteLifeforms,
  postFavoriteEnclosureGalleries,
  postFavoriteEnclosures,
  postFavoriteLifeformGalleries,
  postFavoriteLifeforms,
} from "../actions/favorites";
import { ReactComponent as FavoriteNoFillIcon } from "../icons/favorite-no-fill.svg";
import { ReactComponent as FavoriteWithFillIcon } from "../icons/favorite.svg";

export interface FavoriteLifeformButtonProps {
  isFavoriteLifeform: boolean;
  favoriteLifeformID: string;
  lifeformID: string;
  accountID: number;
  setIsFavoriteLifeform: (flag: boolean) => void;
  setFavoriteLifeformID: (id: string) => void;
}

export interface FavoriteEnclosureButtonProps {
  isFavoriteEnclosure: boolean;
  favoriteEnclosureID: string;
  enclosureID: string;
  accountID: number;
  setIsFavoriteEnclosure: (flag: boolean) => void;
  setFavoriteEnclosureID: (id: string) => void;
}

export interface FavoriteLifeformGalleryButtonProps {
  isFavoriteLifeformGallery: boolean;
  favoriteLifeformGalleryID: string;
  lifeformGalleryID: string;
  accountID: number;
  setIsFavoriteLifeformGallery: (flag: boolean) => void;
  setFavoriteLifeformGalleryID: (id: string) => void;
}

export interface FavoriteEnclosureGalleryButtonProps {
  isFavoriteEnclosureGallery: boolean;
  favoriteEnclosureGalleryID: string;
  enclosureGalleryID: string;
  accountID: number;
  setIsFavoriteEnclosureGallery: (flag: boolean) => void;
  setFavoriteEnclosureGalleryID: (id: string) => void;
}

export const FavoriteLifeformButton = (
  props: FavoriteLifeformButtonProps
): JSX.Element => {
  const {
    isFavoriteLifeform,
    favoriteLifeformID,
    lifeformID,
    accountID,
    setIsFavoriteLifeform,
    setFavoriteLifeformID,
  } = props;

  return (
    <button
      className="btn-fav"
      onClick={async () => {
        try {
          if (isFavoriteLifeform) {
            await deleteFavoriteLifeforms(favoriteLifeformID);
            setIsFavoriteLifeform(false);
          } else {
            const favoriteLifeform = await postFavoriteLifeforms({
              lifeform_id: lifeformID,
              account_id: accountID,
            });
            setIsFavoriteLifeform(true);
            setFavoriteLifeformID(favoriteLifeform.id);
          }
        } catch (error: any) {
          console.error(error);
          Store.addNotification({
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      }}
    >
      {isFavoriteLifeform ? (
        <>
          <FavoriteWithFillIcon className="fav-icon" />
        </>
      ) : (
        <>
          <FavoriteNoFillIcon className="fav-icon" />
        </>
      )}
    </button>
  );
};

export const FavoriteEnclosureButton = (
  props: FavoriteEnclosureButtonProps
): JSX.Element => {
  const {
    isFavoriteEnclosure,
    favoriteEnclosureID,
    enclosureID,
    accountID,
    setIsFavoriteEnclosure,
    setFavoriteEnclosureID,
  } = props;

  return (
    <button
      className="btn-fav"
      onClick={async () => {
        try {
          if (isFavoriteEnclosure) {
            await deleteFavoriteEnclosures(favoriteEnclosureID);
            setIsFavoriteEnclosure(false);
          } else {
            const favoriteEnclosure = await postFavoriteEnclosures({
              enclosure_id: enclosureID,
              account_id: accountID,
            });
            setIsFavoriteEnclosure(true);
            setFavoriteEnclosureID(favoriteEnclosure.id);
          }
        } catch (error: any) {
          console.error(error);
          Store.addNotification({
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      }}
    >
      {isFavoriteEnclosure ? (
        <>
          <FavoriteWithFillIcon className="fav-icon" />
        </>
      ) : (
        <>
          <FavoriteNoFillIcon className="fav-icon" />
        </>
      )}
    </button>
  );
};

export const FavoriteLifeformGalleryButton = (
  props: FavoriteLifeformGalleryButtonProps
): JSX.Element => {
  const {
    isFavoriteLifeformGallery,
    favoriteLifeformGalleryID,
    lifeformGalleryID,
    accountID,
    setIsFavoriteLifeformGallery,
    setFavoriteLifeformGalleryID,
  } = props;

  return (
    <button
      className="btn-fav"
      onClick={async () => {
        try {
          if (isFavoriteLifeformGallery) {
            await deleteFavoriteLifeformGalleries(favoriteLifeformGalleryID);
            setIsFavoriteLifeformGallery(false);
          } else {
            const favoriteLifeformGallerie = await postFavoriteLifeformGalleries(
              {
                lifeform_gallery_id: lifeformGalleryID,
                account_id: accountID,
              }
            );
            setIsFavoriteLifeformGallery(true);
            setFavoriteLifeformGalleryID(favoriteLifeformGallerie.id);
          }
        } catch (error: any) {
          console.error(error);
          Store.addNotification({
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      }}
    >
      {isFavoriteLifeformGallery ? (
        <>
          <FavoriteWithFillIcon className="fav-icon" />
          Liked
        </>
      ) : (
        <>
          <FavoriteNoFillIcon className="fav-icon" />
          Like
        </>
      )}
    </button>
  );
};

export const FavoriteEnclosureGalleryButton = (
  props: FavoriteEnclosureGalleryButtonProps
): JSX.Element => {
  const {
    isFavoriteEnclosureGallery,
    favoriteEnclosureGalleryID,
    enclosureGalleryID,
    accountID,
    setIsFavoriteEnclosureGallery,
    setFavoriteEnclosureGalleryID,
  } = props;

  return (
    <button
      className="btn-fav"
      onClick={async () => {
        try {
          if (isFavoriteEnclosureGallery) {
            await deleteFavoriteEnclosureGalleries(favoriteEnclosureGalleryID);
            setIsFavoriteEnclosureGallery(false);
          } else {
            const favoriteEnclosureGallerie = await postFavoriteEnclosureGalleries(
              {
                enclosure_gallery_id: enclosureGalleryID,
                account_id: accountID,
              }
            );
            setIsFavoriteEnclosureGallery(true);
            setFavoriteEnclosureGalleryID(favoriteEnclosureGallerie.id);
          }
        } catch (error: any) {
          console.error(error);
          Store.addNotification({
            title: "Error",
            message: error.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      }}
    >
      {isFavoriteEnclosureGallery ? (
        <>
          <FavoriteWithFillIcon className="fav-icon" />
          Liked
        </>
      ) : (
        <>
          <FavoriteNoFillIcon className="fav-icon" />
          Like
        </>
      )}
    </button>
  );
};
