/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';

import { Header } from '../../components/header';

import { PageHeader } from '../../components/page-header';
import { CommonRow } from '../../components/common-wrapper';
import { Paths } from '../../routes/paths';
import { useParams, useNavigate } from 'react-router-dom';
import { useMainContext } from '../../context/context';
import { MainAction } from '../../typings/main_context_reducer';
import {
  CHECK,
  GET_ENCLOSURE_GALLERY,
  GET_LIFEFORM_GALLERY,
  GET_OBSERVATION_GALLERY,
} from '../../actions';
import {
  deleteEnclosureGallery,
  deleteLifeformGallery,
  deleteObservationGallery,
  getEnclosureGallery,
  getEnclosureGalleryCards,
  getLifeformGallery,
  getLifeformGalleryCards,
} from '../../actions/gallery';
import {
  getFavoriteEnclosureGalleries,
  getFavoriteLifeformGalleries,
} from '../../actions/favorites';
import { getEnclosure } from '../../actions/enclosure';
import { DEFAULT_IMAGES } from '../../shared/constants';
import { getAnimal } from '../../actions/animals';
import { authRefresh } from '../../actions/check';
import {
  FetchObservationGalleryParams,
  getObservation,
  getObservationGallery,
  getObservationGalleryItem,
} from '../../actions/observation';
import { FailedAuthCheck } from '../../shared/errors';
import { getImageMenus } from '../../shared/menu';
import { DropDownOptions } from '../../typings/menu-dropdown';
import { DeleteModal } from '../../components/modals/delete';
import { errorNotification } from '../../components/notification';

interface ViewGalleryItemProps {
  id: string;
  category: 'lifeform' | 'enclosure' | 'observation_reading';
  pageTitle: string;
  pageSubTitle: string;
  isFavorite: boolean;
  gallery: { id: string; image: string }[];
  mainImage: string;
  accountID: number;
  favoriteID: string;
  parentID: string;
  ownsEntry: boolean;
  setIsFavorite: (x: boolean) => void;
  setFavoriteID: (x: string) => void;
  setOpenModal: (isOpen: boolean) => void;
}

export const ViewEnclosureGalleryItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();

  const [isBusy, setBusy] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteID, setFavoriteID] = useState('');
  const [pageTitle, setPageTitle] = useState('Unknown');
  const [mainImage, setMainImage] = useState(DEFAULT_IMAGES.enclosure);
  const [parentID, setParentID] = useState('');
  const [ownsEntry, setOwnsEntry] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteEnclosureGallery(`${pageID}`);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'enclosure gallery',
    name: pageTitle,
  };

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;
        dispatch(checkAction);

        if (Number.isNaN(pageID)) {
          throw new Error('Enclosure Image ID must be a number');
        }

        const enclosureGallery = await getEnclosureGallery(pageID);

        if (!enclosureGallery) {
          throw new Error('No Enclosure Image found.');
        }

        setMainImage(enclosureGallery.image_url);

        const favoriteGalleryParams = {
          currentPage: 1,
          perPage: 1,
          sort_by: '-id',
          enclosure_gallery: pageID,
        };

        const enclosure = await getEnclosure(enclosureGallery.enclosure_id);

        if (check?.account.id) {
          const favoriteEnclosureGallery = await getFavoriteEnclosureGalleries(
            favoriteGalleryParams,
          );

          setIsFavorite(favoriteEnclosureGallery.length > 0);
          setFavoriteID(favoriteEnclosureGallery[0]?.id ?? 0);
        }

        setParentID(enclosure.id);
        setOwnsEntry(check?.account.id === enclosure.account_id);
        setPageTitle(enclosure.name ?? 'Unknown');

        const galleryParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
          enclosure_ids: `${enclosureGallery.enclosure_id}`,
        };
        const enclosureGalleries = await getEnclosureGalleryCards(
          galleryParams,
        );

        const galleryAction = {
          type: GET_ENCLOSURE_GALLERY,
          enclosureGalleryAction: {
            enclosureGallery: enclosureGalleries,
          },
        } as MainAction;

        dispatch(galleryAction);
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  const gallery =
    mainState?.enclosureGallery?.data.map((galleryImage) => ({
      id: galleryImage.id,
      image: galleryImage.image_url,
    })) ?? [];

  return (
    <>
      {!isBusy ? (
        <ViewGalleryItem
          id={paramsID ?? ''}
          category={'enclosure'}
          pageTitle={pageTitle}
          pageSubTitle={'Enclosure Gallery'}
          isFavorite={isFavorite}
          gallery={gallery}
          mainImage={mainImage}
          accountID={mainState?.account?.id ?? 0}
          setIsFavorite={setIsFavorite}
          setFavoriteID={setFavoriteID}
          favoriteID={favoriteID}
          parentID={parentID}
          ownsEntry={ownsEntry}
          setOpenModal={setOpenModal}
        />
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </>
  );
};

export const ViewLifeformGalleryItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');

  const [isBusy, setBusy] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteID, setFavoriteID] = useState('');
  const [pageTitle, setPageTitle] = useState('Unknown');
  const [mainImage, setMainImage] = useState(DEFAULT_IMAGES.enclosure);
  const [parentID, setParentID] = useState('');
  const [ownsEntry, setOwnsEntry] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteLifeformGallery(`${pageID}`);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'animal gallery',
    name: pageTitle,
  };

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  useEffect(() => {
    const loadData = async () => {
      const check = await authRefresh();
      const checkAction = {
        type: CHECK,
        checkAction: {
          account: check?.account,
          person: check?.person,
          settings: check?.settings,
        },
      } as MainAction;
      dispatch(checkAction);

      try {
        if (Number.isNaN(pageID)) {
          throw new Error('Lifeform Image ID must be a number');
        }

        const lifeformGallery = await getLifeformGallery(pageID);

        if (!lifeformGallery) {
          throw new Error('No Lifeform Image found.');
        }

        setMainImage(lifeformGallery.image_url);

        const lifeform = await getAnimal(lifeformGallery.lifeform_id);

        if (check?.account.id) {
          const favoriteGalleryParams = {
            currentPage: 1,
            perPage: 1,
            sort_by: '-id',
            lifeform_gallery: pageID,
          };
          const favoriteLifeformGallery = await getFavoriteLifeformGalleries(
            favoriteGalleryParams,
          );

          setIsFavorite(favoriteLifeformGallery.length > 0);
          setFavoriteID(favoriteLifeformGallery[0]?.id ?? 0);
        }

        setParentID(lifeform.id);
        setOwnsEntry(check?.account.id === lifeform.account_id);

        setPageTitle(
          lifeform.pet_name ??
            lifeform.common_name ??
            lifeform.scientific_name ??
            'Unknown',
        );

        const galleryParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
          lifeform_ids: `${lifeformGallery.lifeform_id}`,
        };
        const lifeformGalleries = await getLifeformGalleryCards(galleryParams);

        const galleryAction = {
          type: GET_LIFEFORM_GALLERY,
          lifeformGalleryAction: {
            lifeformGallery: lifeformGalleries,
          },
        } as MainAction;

        dispatch(galleryAction);
      } catch (error: any) {
        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  const gallery =
    mainState?.lifeformGallery?.data.map((galleryImage) => ({
      id: galleryImage.id,
      image: galleryImage.image_url,
    })) ?? [];

  return (
    <>
      {!isBusy ? (
        <ViewGalleryItem
          id={paramsID ?? ''}
          category={'lifeform'}
          pageTitle={pageTitle}
          pageSubTitle={'Animal Gallery'}
          isFavorite={isFavorite}
          gallery={gallery}
          mainImage={mainImage}
          accountID={mainState?.account?.id ?? 0}
          setIsFavorite={setIsFavorite}
          setFavoriteID={setFavoriteID}
          favoriteID={favoriteID}
          parentID={parentID}
          ownsEntry={ownsEntry}
          setOpenModal={setOpenModal}
        />
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </>
  );
};

export const ViewObservationReadingGalleryItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');

  const [isBusy, setBusy] = useState(true);
  const [isFavorite, setIsFavorite] = useState(false);
  const [favoriteID, setFavoriteID] = useState('');
  const [pageTitle, setPageTitle] = useState('Unknown');
  const [mainImage, setMainImage] = useState(DEFAULT_IMAGES.observation);
  const [parentID, setParentID] = useState('');
  const [ownsEntry, setOwnsEntry] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteObservationGallery(`${pageID}`);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'observation reading gallery',
    name: pageTitle,
  };

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;
        dispatch(checkAction);

        if (Number.isNaN(pageID)) {
          throw new Error('Observation Recording Image ID must be a number');
        }

        const observationGallery = await getObservationGalleryItem(
          paramsID ?? '',
        );
        const observation = await getObservation(
          observationGallery.observation_id,
        );

        if (!observationGallery) {
          throw new Error('No Observation Recording Image found.');
        }

        setOwnsEntry(check?.account.id === observation.account_id);
        setMainImage(observationGallery.image_url);
        setParentID(observationGallery.observation_id);
        setPageTitle(observation.title ?? 'Unknown');

        const observationGalleryParams: FetchObservationGalleryParams = {
          currentPage: 1,
          perPage: 20,
          sort_by: '-id',
          observation_ids: `${observationGallery.observation_id}`,
        };
        const observationGalleries = await getObservationGallery(
          observationGalleryParams,
        );

        const galleryAction = {
          type: GET_OBSERVATION_GALLERY,
          observationGalleryAction: {
            observationGallery: observationGalleries,
          },
        } as MainAction;

        dispatch(galleryAction);
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  const gallery =
    mainState?.observationGallery?.data.map((galleryImage) => ({
      id: galleryImage.id,
      image: galleryImage.image_url,
    })) ?? [];

  return (
    <>
      {!isBusy ? (
        <ViewGalleryItem
          id={paramsID ?? ''}
          category={'observation_reading'}
          pageTitle={pageTitle}
          pageSubTitle={'Observation Recording Gallery'}
          isFavorite={isFavorite}
          gallery={gallery}
          mainImage={mainImage}
          accountID={mainState?.account?.id ?? 0}
          setIsFavorite={setIsFavorite}
          setFavoriteID={setFavoriteID}
          favoriteID={favoriteID}
          parentID={parentID}
          ownsEntry={ownsEntry}
          setOpenModal={setOpenModal}
        />
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </>
  );
};

export const ViewGalleryItem = (props: ViewGalleryItemProps): JSX.Element => {
  const navigate = useNavigate();
  const commonRowMenus = getImageMenus(
    navigate,
    props.id,
    props.ownsEntry,
    props.parentID,
    () => {
      props.setOpenModal(true);
    },
  );

  let pageTitle = '';
  let menu: DropDownOptions[] | undefined = undefined;

  if (props.category === 'lifeform') {
    pageTitle = 'Animal Images';
    menu = commonRowMenus.mainMenuAnimal;
  } else if (props.category === 'enclosure') {
    pageTitle = 'Enclosure Images';
    menu = commonRowMenus.mainMenuEnclosure;
  } else if (props.category === 'observation_reading') {
    pageTitle = 'Observation Images';
    menu = commonRowMenus.mainMenuObservation;
  }

  return (
    <div className="view-gallery">
      <Header hideLinks={false} title={`${pageTitle}`} />
      <div className="desktop-wrapper">
        <section className="view-gallery-background gallery-item-background">
          <PageHeader
            title={props.pageTitle}
            showBackBtn={true}
            activeTab={''}
            menu={menu}
          />
          <section className="view-gallery-item">
            <section className="main-image-fav-wrapper">
              <div className="main-info">
                <img src={props.mainImage} />
              </div>
              <section className="view-gallery-rows">
                <CommonRow
                  title="Related Images"
                  showAddBtn={props.gallery.length === 0}
                  addBtnClick={() => {}}
                >
                  {props.gallery.length > 0 ? (
                    <div className="gallery-wrapper">
                      {props.gallery.map((galleryImg, galleryIndex) => (
                        <a
                          key={`gallery-image-${galleryIndex}`}
                          href={`${
                            props.category === 'enclosure'
                              ? Paths.viewEnclosureGalleryRootPage
                              : Paths.viewLifeformGalleryRootPage
                          }/${galleryImg.id}`}
                        >
                          <img
                            src={galleryImg.image}
                            className="gallery-image"
                          />
                        </a>
                      ))}
                    </div>
                  ) : (
                    <div className="empty-placeholder">
                      <h5>Empty</h5>
                    </div>
                  )}
                </CommonRow>
              </section>
            </section>
          </section>
        </section>
      </div>
    </div>
  );
};
