import {
  EnclosureCard as EnclosureCardModel,
  Enclosure as EnclosureModel,
} from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { CreateEnclosureParams } from '../typings/enclosure';

export interface FetchEnclosureParams extends Params {
  name?: string;
  is_archived?: boolean;
  account_id?: number;
  ids?: string;
  ignore_archived?: boolean;
}

export interface UpdateEnclosureParams {
  id: string;
  is_archived?: boolean;
  is_public?: boolean;
  dimensions?: string;
  name?: string;
}

export interface BulkCreateEnclosureParams {
  is_archived?: boolean;
  is_public?: boolean;
  dimensions?: string;
  name: string;
  temperature?: string;
  humidity?: string;
}

export const getEnclosureCards = async (
  params: FetchEnclosureParams,
): Promise<ListOutput<EnclosureCardModel>> => {
  const response = await axiosInstance.get(PathsAPI.enclosureCards, { params });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getEnclosures = async (
  params: FetchEnclosureParams,
): Promise<ListOutput<EnclosureModel>> => {
  const response = await axiosInstance.get(PathsAPI.enclosure, { params });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getEnclosure = async (id: string): Promise<EnclosureModel> => {
  const response = await axiosInstance.get(`${PathsAPI.enclosure}/${id}`);

  return response.data;
};

export const createEnclosure = async (
  createEnclosureParams: CreateEnclosureParams,
): Promise<EnclosureModel> => {
  const response = await axiosInstance.post(
    `${PathsAPI.enclosure}`,
    createEnclosureParams,
  );

  return response.data;
};

export const updateEnclosure = async (
  updateEnclosureParams: Partial<EnclosureModel>,
) => {
  const response = await axiosInstance.put(
    `${PathsAPI.enclosure}`,
    updateEnclosureParams,
  );

  return response.data;
};

export const deleteEnclosure = async (id: string): Promise<void> => {
  await axiosInstance.delete(`${PathsAPI.enclosure}/${id}`);
};

export const bulkCreateEnclosure = async (
  createParams: BulkCreateEnclosureParams[],
): Promise<EnclosureModel[]> => {
  const response = await axiosInstance.post(`${PathsAPI.enclosureBulk}`, {
    data: createParams,
  });

  return response.data;
};
