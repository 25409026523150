/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Papa from 'papaparse';
import { Table } from 'antd';
import { useDropzone } from 'react-dropzone';
import moment from 'moment';

import { useMainContext } from '../../context/context';
import { selectStyle } from '../../components/input';
import { getOption, toTitleCase } from '../../shared/utils';
import { bulkCreateLifeform, getAnimals } from '../../actions/animals';
import { bulkCreateEnclosure, getEnclosures } from '../../actions/enclosure';
import {
  bulkCreateInventory,
  getInventoryCards,
} from '../../actions/inventory';
import { bulkCreateFeeding } from '../../actions/feeding';
import {
  bulkCreateObservation,
  getObservations,
} from '../../actions/observation';
import {
  errorNotification,
  infoNotification,
  successNotification,
} from '../../components/notification';
import { Lifeform } from 'cobranetics-types/types/lifeform';
import { SexTypes } from '../../typings/lifeform';

export const BulkImport = (): JSX.Element => {
  const categoryOptions = [
    { value: 'animal', label: 'Animal' },
    { value: 'enclosure', label: 'Enclosure' },
    { value: 'feeding', label: 'Feeding' },
    { value: 'inventory', label: 'Inventory' },
    { value: 'observation', label: 'Observation' },
  ];
  const categoryInfo = {
    [categoryOptions[0].value]: [
      {
        key: '1',
        field: 'is_archived',
        required: 'no',
        fieldType: 'boolean',
        default: 'false',
      },
      {
        key: '2',
        field: 'is_public',
        required: 'no',
        fieldType: 'boolean',
        default: 'true',
      },
      {
        key: '3',
        field: 'pet_name',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '4',
        field: 'common_name',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '5',
        field: 'scientific_name',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '6',
        field: 'sex',
        required: 'no',
        fieldType: 'male, female, unknown',
        default: '',
      },
      {
        key: '7',
        field: 'dob',
        required: 'no',
        fieldType: 'MM/DD/YYYY',
        default: '',
      },
      {
        key: '8',
        field: 'enclosure_id',
        required: 'no',
        fieldType: 'string',
        default: '',
      },
      {
        key: '9',
        field: 'kingdom',
        required: 'no',
        fieldType: 'Animalia, Plantae, Fungi, Protista, Archaea, Bacteria',
        default: 'Animalia',
      },
    ],
    [categoryOptions[1].value]: [
      {
        key: '10',
        field: 'is_archived',
        required: 'no',
        fieldType: 'boolean',
        default: 'false',
      },
      {
        key: '11',
        field: 'is_public',
        required: 'no',
        fieldType: 'boolean',
        default: 'true',
      },
      {
        key: '12',
        field: 'dimensions',
        required: 'no',
        fieldType: 'string',
        default: '',
      },
      {
        key: '13',
        field: 'name',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '14',
        field: 'temperature',
        required: 'no',
        fieldType: 'string',
        default: '',
      },
      {
        key: '15',
        field: 'humidity',
        required: 'no',
        fieldType: 'string',
        default: '',
      },
    ],
    [categoryOptions[2].value]: [
      {
        key: '16',
        field: 'feed_on',
        required: 'yes',
        fieldType: 'MM/DD/YYYY h:mm:ss a',
        default: '',
      },
      {
        key: '17',
        field: 'meal',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '18',
        field: 'ate',
        required: 'no',
        fieldType: 'boolean',
        default: 'true',
      },
      {
        key: '20',
        field: 'lifeform_id',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '23',
        field: 'inventory_id',
        required: 'no',
        fieldType: 'string',
        default: '',
      },
    ],
    [categoryOptions[3].value]: [
      {
        key: '24',
        field: 'item_name',
        required: 'yes',
        fieldType: '',
        default: '',
      },
      {
        key: '25',
        field: 'is_archived',
        required: 'no',
        fieldType: 'boolean',
        default: 'false',
      },
      {
        key: '26',
        field: 'is_public',
        required: 'no',
        fieldType: 'boolean',
        default: 'true',
      },
      {
        key: '27',
        field: 'image_url',
        required: 'no',
        fieldType: 'string',
        default: '',
      },
      {
        key: '28',
        field: 'quantity',
        required: 'yes',
        fieldType: 'number',
        default: '',
      },
      {
        key: '29',
        field: 'average_cost_per_item',
        required: 'yes',
        fieldType: 'number',
        default: '',
      },
      {
        key: '30',
        field: 'category',
        required: 'yes',
        fieldType:
          'food, lighting & heating, enclosures, sensors, accessories, substrate, cleaning supplies, equipment, medicine, supplements',
        default: '',
      },
    ],
    [categoryOptions[4].value]: [
      {
        key: '31',
        field: 'title',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '32',
        field: 'category',
        required: 'yes',
        fieldType:
          'General, Activity, Ecdysis, Behavior, Eating, Basking, Sleeping',
        default: '',
      },
      {
        key: '33',
        field: 'lifeform_id',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
      {
        key: '34',
        field: 'is_public',
        required: 'no',
        fieldType: 'boolean',
        default: 'true',
      },
      {
        key: '35',
        field: 'recording',
        required: 'yes',
        fieldType: 'string',
        default: '',
      },
    ],
  };

  const fieldColumns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Required',
      dataIndex: 'required',
      key: 'required',
    },
    {
      title: 'Type',
      dataIndex: 'fieldType',
      key: 'fieldType',
    },
    {
      title: 'Default',
      dataIndex: 'default',
      key: 'default',
    },
  ];

  const importedColumns = {
    [categoryOptions[0].value]: categoryInfo[categoryOptions[0].value].map(
      (row) => ({
        title: toTitleCase(row.field.replaceAll('_', ' ')),
        dataIndex: row.field,
        key: `${categoryOptions[0].value}-${row.field}`,
        render: (text: any) => String(text),
      }),
    ),
    [categoryOptions[1].value]: [
      categoryInfo[categoryOptions[1].value].map((row) => ({
        title: toTitleCase(row.field.replaceAll('_', ' ')),
        dataIndex: row.field,
        key: `${categoryOptions[1].value}-${row.field}`,
        render: (text: any) => String(text),
      })),
    ],
    [categoryOptions[2].value]: [
      categoryInfo[categoryOptions[2].value].map((row) => ({
        title: toTitleCase(row.field.replaceAll('_', ' ')),
        dataIndex: row.field,
        key: `${categoryOptions[2].value}-${row.field}`,
        render: (text: any) => String(text),
      })),
    ],
    [categoryOptions[3].value]: [
      categoryInfo[categoryOptions[3].value].map((row) => ({
        title: toTitleCase(row.field.replaceAll('_', ' ')),
        dataIndex: row.field,
        key: `${categoryOptions[3].value}-${row.field}`,
        render: (text: any) => String(text),
      })),
    ],
    [categoryOptions[4].value]: [
      categoryInfo[categoryOptions[4].value].map((row) => ({
        title: toTitleCase(row.field.replaceAll('_', ' ')),
        dataIndex: row.field,
        key: `${categoryOptions[4].value}-${row.field}`,
        render: (text: any) => String(text),
      })),
    ],
  };
  const [mainState] = useMainContext();
  const [reachedLimit, setReachedLimit] = useState(false);
  const [category, setCategory] = useState(categoryOptions[0].value);
  const [animalData, setAnimalData] = useState([] as any[]);
  const [enclosureData, setEnclosureData] = useState([] as any[]);
  const [feedingData, setFeedingData] = useState([] as any[]);
  const [inventoryData, setInventoryData] = useState([] as any[]);
  const [ObservationData, setObservationData] = useState([] as any[]);

  useEffect(() => {
    const loadData = async () => {
      let count = 0;
      let countLimit = 10;

      switch (category) {
        case 'animal': {
          const rows = await getAnimals({
            account_id: mainState.account?.id,
            ignore_archived: true,
          });

          count = rows.total || 0;
          countLimit = mainState.membershipConfig?.countLimits.lifeform || 0;
          break;
        }
        case 'enclosure': {
          const rows = await getEnclosures({
            account_id: mainState.account?.id,
            ignore_archived: true,
          });

          count = rows.total || 0;
          countLimit = mainState.membershipConfig?.countLimits.enclosures || 0;
          break;
        }
        case 'inventory': {
          const rows = await getInventoryCards({
            account_id: mainState.account?.id,
            include_empty: true,
            ignore_archived: true,
          });

          count = rows.total || 0;
          countLimit =
            mainState.membershipConfig?.countLimits.inventoryGroup || 0;
          break;
        }
        case 'observation': {
          const rows = await getObservations({
            account_id: mainState.account?.id,
          });

          count = rows.total || 0;
          countLimit =
            mainState.membershipConfig?.countLimits.observations || 0;
          break;
        }
        default:
          break;
      }

      const overCountLimit = count >= countLimit;

      if (overCountLimit) {
        infoNotification(
          `Upgrade Membership: Sorry, you have reached your ${category} limit.`,
        );
      }

      setReachedLimit(overCountLimit);
    };
    loadData();
  }, [category]);

  const onDrop = async (acceptedFiles: any) => {
    try {
      if (!!acceptedFiles.length) {
        Papa.parse(acceptedFiles[0], {
          header: true,
          skipEmptyLines: true,
          complete: async (results: any) => {
            try {
              const rows = results.data.map((row: { [key: string]: any }) => {
                const updatedRow: { [key: string]: any } = {};
                const keys = Object.keys(row);
                const numberKeys = ['quantity', 'average_cost_per_item'];
                const dateKeys = ['dob', 'feed_on'];

                for (const key of keys) {
                  if (key === 'is_public') {
                    updatedRow[key] =
                      row[key].toLowerCase() === 'true' ||
                      row[key].length === 0;
                    continue;
                  } else if (key === 'is_archived') {
                    updatedRow[key] = row[key].toLowerCase() === 'true';
                    continue;
                  }

                  if (numberKeys.includes(key)) {
                    updatedRow[key] = parseFloat(row[key]);
                    continue;
                  }

                  if (dateKeys.includes(key)) {
                    updatedRow[key] = moment(row[key]).toISOString();
                    continue;
                  }

                  updatedRow[key] = row[key];
                }

                return updatedRow;
              });

              switch (category) {
                case 'animal': {
                  const modifiedRows: Partial<Lifeform>[] = rows.map(
                    (row: Lifeform) => ({
                      ...row,
                      sex:
                        row.sex.trim() === ''
                          ? SexTypes.Unknown
                          : row.sex.toLowerCase(),
                    }),
                  );
                  const animals = await bulkCreateLifeform(modifiedRows as any);
                  setAnimalData(
                    animals.map((row) => ({
                      ...row,
                      key: `${category}-${row.id}-table-row`,
                    })),
                  );
                  break;
                }
                case 'enclosure': {
                  const enclosures = await bulkCreateEnclosure(rows as any);
                  setEnclosureData(
                    enclosures.map((row) => ({
                      ...row,
                      key: `${category}-${row.id}-table-row`,
                    })),
                  );
                  break;
                }

                case 'inventory': {
                  const inventory = await bulkCreateInventory(rows as any);
                  setInventoryData(
                    inventory.map((row) => ({
                      ...row,
                      key: `${category}-${row.id}-table-row`,
                    })),
                  );
                  break;
                }

                case 'feeding': {
                  const feedings = await bulkCreateFeeding(rows as any);
                  setFeedingData(
                    feedings.map((row) => ({
                      ...row,
                      key: `${category}-${row.id}-table-row`,
                    })),
                  );
                  break;
                }

                case 'observation': {
                  const observations = await bulkCreateObservation(rows as any);
                  setObservationData(
                    observations.map((row) => ({
                      ...row,
                      key: `${category}-${row.id}-table-row`,
                    })),
                  );
                  break;
                }

                default:
                  break;
              }

              successNotification(`Imported ${toTitleCase(category)} Data`);
            } catch (error: any) {
              errorNotification(error?.message);
            }
          },
        });
      }
    } catch (error: any) {
      errorNotification(error?.message);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['text/csv'],
  });

  return (
    <div className="bulk-import">
      <section className="bulk-import-background common-background">
        <section className="common-inner-wrapper">
          <section className="common-section">
            <Select
              name="category"
              id="category"
              styles={selectStyle}
              options={categoryOptions}
              value={getOption(category, categoryOptions)}
              onChange={(target) =>
                setCategory(target?.value ?? categoryOptions[0].value)
              }
            />
            <a
              href={`/templates/${category}-import-template.csv`}
              download={`${category}-import-template.csv`}
              className="download-link"
            >
              <button className="btn-download">Download Template</button>
            </a>
            <div className="table-wrapper">
              <Table
                dataSource={categoryInfo[category] ?? []}
                columns={fieldColumns}
                pagination={{ hideOnSinglePage: true }}
              />
            </div>

            {!reachedLimit ? (
              <div className="dropzone-images dropzone" {...getRootProps()}>
                <h6>Drop your csv to import</h6>
                <input {...getInputProps()} />
              </div>
            ) : (
              <></>
            )}

            {category === categoryOptions[0].value && !!animalData.length ? (
              <div className="table-wrapper">
                <Table
                  dataSource={animalData}
                  columns={importedColumns[category] as any}
                  pagination={{ hideOnSinglePage: true }}
                />
              </div>
            ) : (
              <></>
            )}

            {category === categoryOptions[1].value && !!enclosureData.length ? (
              <div className="table-wrapper">
                <Table
                  dataSource={enclosureData}
                  columns={importedColumns[category] as any}
                  pagination={{ hideOnSinglePage: true }}
                />
              </div>
            ) : (
              <></>
            )}

            {category === categoryOptions[2].value && !!feedingData.length ? (
              <div className="table-wrapper">
                <Table
                  dataSource={feedingData}
                  columns={importedColumns[category] as any}
                  pagination={{ hideOnSinglePage: true }}
                />
              </div>
            ) : (
              <></>
            )}

            {category === categoryOptions[3].value && !!inventoryData.length ? (
              <div className="table-wrapper">
                <Table
                  dataSource={inventoryData}
                  columns={importedColumns[category] as any}
                  pagination={{ hideOnSinglePage: true }}
                />
              </div>
            ) : (
              <></>
            )}

            {category === categoryOptions[4].value &&
            !!ObservationData.length ? (
              <div className="table-wrapper">
                <Table
                  dataSource={ObservationData}
                  columns={importedColumns[category] as any}
                  pagination={{ hideOnSinglePage: true }}
                />
              </div>
            ) : (
              <></>
            )}
          </section>
        </section>
      </section>
    </div>
  );
};
