import React from 'react';
import { ObservationCard as ObservationCardModel } from 'cobranetics-types/types';

import { Paths } from '../routes/paths';
import { ReactComponent as ObservationIcon } from '../icons/observation.svg';
import { ReactComponent as MenuIcon } from '../icons/three-dot-menu.svg';
import { ReactComponent as MaleIcon } from '../icons/male.svg';
import { ReactComponent as FemaleIcon } from '../icons/female.svg';

export const ObservationCard = (props: ObservationCardModel): JSX.Element => {
  return (
    <div className="observation-card common-card standard-card">
      <section className="top-content">
        <a href={`${Paths.viewObservation}/${props.id}`}>
          <div className="card-identifier">
            <ObservationIcon />
          </div>
        </a>

        <div className="card-menu">
          <MenuIcon />
        </div>
      </section>
      <div className="card-content-wrapper">
        <a href={`${Paths.viewObservation}/${props.id}`}>
          <section className="main-content">
            <div className="text-wrapper">
              <h5>{props.title}</h5>
              <p>{props.recording}</p>
            </div>
          </section>
        </a>

        <section className="bottom-content">
          <div className="titles">
            <div className="bottom-card-title">
              <p>{props.pet_name}</p>
              {props.sex === 'male' ? (
                <MaleIcon className="icon male-icon" />
              ) : (
                <></>
              )}
              {props.sex === 'female' ? (
                <FemaleIcon className="icon female-icon" />
              ) : (
                <></>
              )}
            </div>
            <div className="bottom-card-sub-titles">
              <p>{props.common_name}</p>
              <p>{props.scientific_name}</p>
            </div>
          </div>
          <div className="action-btn-wrapper"></div>
        </section>
      </div>
    </div>
  );
};
