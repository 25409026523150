export const getInitials = (str: string): string => {
  const names = str.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  }

  return initials;
};

export const addSpace = (str: string): string => {
  return str.replace(/([A-Z])/g, ' $1').trim();
};

export const splitFromFirstSpace = (str: string): string[] => {
  return [
    str.substring(0, str.indexOf(' ')),
    str.substring(str.indexOf(' ') + 1),
  ];
};
