import { Moment } from 'moment';

export const formatDateString = (date: Moment): string => {
  return date.format('YYYY-MM-DDT00:00:00.000');
};

export const formatDefaultDateTime = (dateTime: Moment): string => {
  return dateTime.format('MMM DD, YY HH:mm a');
};

export const formatGraphDateTime = (dateTime: Moment): string => {
  return dateTime.format('M/D/YY HH:mm');
};

export const formatDateNoTime = (dateTime: Moment, separator = '/'): string => {
  return dateTime.format(`MM${separator}DD${separator}YY`);
};

export const formatDate = (dateTime: Moment, separator = '-'): string => {
  return dateTime.format(`YYYY${separator}MM${separator}DD`);
};

export const formateDateTimeForAPI = (inputDateTime: Date) => {
  const userTimezoneOffset = inputDateTime.getTimezoneOffset() * 60000;

  return new Date(inputDateTime.getTime() - userTimezoneOffset).toISOString();
};
