import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components/header';
import { EmailInput } from '../../components/input';
import packageJson from '../../../package.json';
import { Paths } from '../../routes/paths';
import { validateEmail } from '../../shared/validation';
import { forgotPassword } from '../../actions/auth';
import { BRAND_NAME } from '../../shared/constants';
import {
  errorNotification,
  successNotification,
} from '../../components/notification';

export const ForgotPassword = (): JSX.Element => {
  const [email, setEmail] = useState('');
  const [sentEmail, setSentEmail] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="login forgot-password">
      <Header hideLinks={false} />
      <section className="login-background">
        <div className="login-overlay">
          <div className="page-header">
            <h4>Forgot Your Password</h4>
            {!sentEmail ? (
              <h6>
                Please enter your email address associated with your account and
                we&apos;ll send you a link to reset your password.
              </h6>
            ) : (
              <h6>Email has been sent!</h6>
            )}
          </div>
          <div className="form-wrapper">
            {!sentEmail ? (
              <form
                className="forgot-password-form"
                onSubmit={async (event) => {
                  event.preventDefault();
                }}
              >
                <EmailInput email={email} setEmail={setEmail} />
                <div className="btn-wrapper">
                  <button
                    className="btn-default btn-cancel"
                    onClick={() => {
                      navigate(Paths.login);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn-next"
                    onClick={async () => {
                      const isEmailValid = validateEmail(email);

                      if (isEmailValid) {
                        await forgotPassword(email);
                        setSentEmail(true);
                        successNotification(
                          'Successfully sent password reset.',
                        );
                      } else {
                        errorNotification('You must provide a valid email.');
                      }
                    }}
                  >
                    Send Reset Link
                  </button>
                </div>
              </form>
            ) : (
              <div className="email-sent">
                <button
                  className="btn-primary"
                  onClick={() => {
                    navigate(Paths.login);
                  }}
                >
                  Login
                </button>
                <p>
                  Didn&apos;t receive the link?{' '}
                  <a
                    onClick={async () => {
                      await forgotPassword(email);
                      successNotification('Successfully sent password reset.');
                    }}
                  >
                    Resend
                  </a>
                </p>
              </div>
            )}
          </div>
          <footer>
            <p>
              © {new Date().getFullYear()} {BRAND_NAME}
            </p>
            <p className="app-version">v {packageJson.version}</p>
          </footer>
        </div>
      </section>
    </div>
  );
};
