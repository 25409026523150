import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ARCHIVED_PAGE_ACTION, CHECK } from '../actions';
import { getAnimalCards } from '../actions/animals';
import { getCheck } from '../actions/check';
import { getEnclosureCards } from '../actions/enclosure';
import { AnimalCard } from '../components/animals';
import { CommonRow } from '../components/common-wrapper';
import { EnclosureCard } from '../components/enclosures';
import { Header } from '../components/header';

import { useMainContext } from '../context/context';
import { Paths } from '../routes/paths';
import { FailedAuthCheck } from '../shared/errors';
import { getArchivedMenus } from '../shared/menu';
import { MainAction } from '../typings/main_context_reducer';
import { errorNotification } from '../components/notification';

export const Archived = (): JSX.Element => {
  const [isBusy, setBusy] = useState(true);
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const commonRowMenus = getArchivedMenus(navigate);

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const baseParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
        };

        const animalParams = {
          ...baseParams,
          account_id: check.account.id,
          is_archived: true,
        };
        const enclosureParams = {
          ...baseParams,
          account_id: check.account.id,
          is_archived: true,
        };
        const animals = await getAnimalCards(animalParams);
        const enclosures = await getEnclosureCards(enclosureParams);

        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        const homeAction = {
          type: ARCHIVED_PAGE_ACTION,
          archivedAction: {
            animals,
            enclosures,
          },
        } as MainAction;

        dispatch(checkAction);
        dispatch(homeAction);
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.error(error);
        errorNotification('Failed to load data.');
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="archived">
      <Header hideLinks={false} title={'Archived'} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="archived-background">
            <CommonRow
              title="Animals"
              titleLink={Paths.viewAnimal}
              showAddBtn={mainState?.animals?.data.length === 0}
              addBtnClick={() => {}}
              menu={commonRowMenus.animalMenu}
            >
              {(mainState?.animals?.data.length ?? 0) > 0 ? (
                <div className="common-row-inner">
                  {mainState?.animals?.data.map((animal, i) => (
                    <AnimalCard key={'animal-card-' + i} {...animal} />
                  ))}
                </div>
              ) : (
                <div className="empty-placeholder">
                  <h5>Empty</h5>
                </div>
              )}
            </CommonRow>
            <CommonRow
              title="Enclosures"
              titleLink={Paths.viewEnclosure}
              showAddBtn={mainState?.enclosures?.data.length === 0}
              addBtnClick={() => {}}
              menu={commonRowMenus.enclosureMenu}
            >
              {(mainState?.enclosures?.data.length ?? 0) > 0 ? (
                <div className="common-row-inner">
                  {mainState?.enclosures?.data.map((enclosure, i) => (
                    <EnclosureCard key={'enclosure-card-' + i} {...enclosure} />
                  ))}
                </div>
              ) : (
                <div className="empty-placeholder">
                  <h5>Empty</h5>
                </div>
              )}
            </CommonRow>
          </section>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
