import React, { useState } from 'react';
import { DEFAULT_IMAGES } from '../shared/constants';
import { LifeformCard as LifeformCardModel } from 'cobranetics-types/types';

import { ReactComponent as PetIcon } from '../icons/pet.svg';
import { ReactComponent as MaleIcon } from '../icons/male.svg';
import { ReactComponent as FemaleIcon } from '../icons/female.svg';
import { ReactComponent as MenuIcon } from '../icons/three-dot-menu.svg';
import { Paths } from '../routes/paths';
import { useMainContext } from '../context/context';
import { FavoriteLifeformButton } from './favorites';

export const AnimalCard = (props: LifeformCardModel): JSX.Element => {
  const [mainState] = useMainContext();
  const [isFavoriteLifeform, setIsFavoriteLifeform] = useState(false);
  const [favoriteLifeformID, setFavoriteLifeformID] = useState('');

  return (
    <div className="animal-card common-card standard-card">
      <section className="top-content">
        <a href={`${Paths.viewAnimal}/${props.id}`}>
          <div className="card-identifier">
            <PetIcon />
          </div>
        </a>

        <div className="card-menu">
          <MenuIcon />
        </div>
      </section>
      <div className="card-content-wrapper">
        <a href={`${Paths.viewAnimal}/${props.id}`}>
          <section className="main-content">
            <img src={props.image_url || DEFAULT_IMAGES.animal} />
          </section>
        </a>

        <section className="bottom-content">
          <div className="titles">
            <div className="bottom-card-title">
              <p>{props.pet_name}</p>
              {props.sex === 'male' ? (
                <MaleIcon className="icon male-icon" />
              ) : (
                <></>
              )}
              {props.sex === 'female' ? (
                <FemaleIcon className="icon female-icon" />
              ) : (
                <></>
              )}
            </div>
            <div className="bottom-card-sub-titles">
              <p>{props.common_name}</p>
              <p>{props.scientific_name}</p>
            </div>
          </div>
          <div className="action-btn-wrapper">
            <FavoriteLifeformButton
              isFavoriteLifeform={isFavoriteLifeform}
              favoriteLifeformID={favoriteLifeformID}
              lifeformID={props.id}
              accountID={mainState.account?.id ?? 0}
              setIsFavoriteLifeform={setIsFavoriteLifeform}
              setFavoriteLifeformID={setFavoriteLifeformID}
            />
          </div>
        </section>
      </div>
    </div>
  );
};
