import {
  EnclosureHumidityReading,
  EnclosureLightLuxReading,
  EnclosureLightUvReading,
  EnclosureTemperatureReading,
  LifeformSizeReading,
  LifeformWeightReading,
} from "cobranetics-types/types";
import { ListOutput } from "../actions";

export enum MeasurementType {
  HUMIDITY = "humidity",
  TEMPERATURE = "temperature",
  UV = "uv",
  LUX = "lux",
  SIZE = "size",
  WEIGHT = "weight",
}

export enum MeasurementUnit {
  percentage = "percentage",
  fahrenheit = "fahrenheit",
  celsius = "celsius",
  lux = "lx",
  uv = "µws/cm2",

  // size - UnitSystem.Imperial
  inch = "in",
  foot = "ft",
  yard = "yd",
  mile = "mile",

  // size - UnitSystem.Metric
  millimeter = "mm",
  centimeter = "cm",
  meter = "m",
  kilometer = "km",

  // weight - UnitSystem.Imperial
  ounce = "oz",
  pound = "Lb",
  Ton = "t",

  // weight - UnitSystem.Metric
  milligram = "mg",
  gram = "g",
  kilogram = "kg",
}

export interface Reading {
  id: string;
  created_on: string;
  updated_on: string;
  recorded_on: string;
  weight?: number;
  lifeform_id?: string;
  length?: number;
  width?: number;
  height?: number;
  girth?: number;
  humidity?: number;
  lux?: number;
  uv?: number;
  temperature?: number;
  enclosure_id?: string;
  value?: string;
  measurement_configuration_id: string;
}

export interface EnclosureHumidityReadingAction {
  enclosureHumidityReadings: ListOutput<EnclosureHumidityReading>;
}

export interface EnclosureLightLuxReadingAction {
  enclosureLightLuxReadings: ListOutput<EnclosureLightLuxReading>;
}

export interface EnclosureLightUvReadingAction {
  enclosureLightUvReadings: ListOutput<EnclosureLightUvReading>;
}

export interface EnclosureTemperatureReadingAction {
  enclosureTemperatureReadings: ListOutput<EnclosureTemperatureReading>;
}

export interface LifeformSizeReadingAction {
  lifeformSizeReadings: ListOutput<LifeformSizeReading>;
}

export interface LifeformWeightReadingAction {
  lifeformWeightReadings: ListOutput<LifeformWeightReading>;
}
