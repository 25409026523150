import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { axiosInstance } from '../../shared/axios';
import { useMainContext } from '../../context/context';
import { Header } from '../../components/header';
import { Dispatch, MainAction } from '../../typings/main_context_reducer';
import { Paths, PathsAPI } from '../../routes/paths';
import { LOGIN } from '../../actions';
import { PasswordInput, UsernameEmailInput } from '../../components/input';
import packageJson from '../../../package.json';
import { getCheck } from '../../actions/check';
import { BRAND_NAME } from '../../shared/constants';
import { errorNotification } from '../../components/notification';

export const submitLogin = async (
  dispatch: Dispatch,
  navigate: any,
  loginData: {
    usernameEmail: string;
    password: string;
  },
): Promise<void> => {
  try {
    const response = await axiosInstance.post(PathsAPI.login, {
      ...loginData,
    });

    const action = {
      type: LOGIN,
      LoginAction: {
        account: response.data.account,
      },
    } as MainAction;

    dispatch(action);
    navigate(Paths.home);
  } catch (error: any) {
    errorNotification('Wrong username or password.');
  }
};

export const Login = (): JSX.Element => {
  const navigate = useNavigate();
  const [, dispatch] = useMainContext();
  const [usernameEmail, setUsernameEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const loadData = async () => {
      try {
        await getCheck(navigate, false);
        navigate(Paths.home);
      } catch (error) {
        //
      }
    };

    loadData();
  }, []);

  return (
    <div className="login">
      <Header hideLinks={false} />
      <section className="login-background">
        <div className="login-overlay">
          <div className="page-header">
            <h4>Login</h4>
            <h6>Welcome back!</h6>
          </div>
          <div className="form-wrapper">
            <form
              onSubmit={async (event) => {
                event.preventDefault();

                await submitLogin(dispatch, navigate, {
                  usernameEmail,
                  password,
                });
              }}
            >
              <UsernameEmailInput
                usernameEmail={usernameEmail}
                setUsernameEmail={setUsernameEmail}
              />
              <PasswordInput password={password} setPassword={setPassword} />
              <a className="forgot-password-link" href={Paths.forgotPassword}>
                Forgot your password?
              </a>
              <button type="submit" className="btn-primary">
                Login
              </button>
              <p className="signup-link">
                Don’t have an account? <a href={Paths.signup}>Signup</a>
              </p>
            </form>
          </div>
          <footer>
            <p>
              © {new Date().getFullYear()} {BRAND_NAME}
            </p>
            <p className="app-version">v {packageJson.version}</p>
          </footer>
        </div>
      </section>
    </div>
  );
};
