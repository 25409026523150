import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Select from 'react-select';
import { DatePicker } from 'antd';
import { Lifeform as LifeformModel } from 'cobranetics-types/types';
import dayjs from 'dayjs';

import { GET_LIFEFORM } from '../../actions';
import {
  updateLifeform,
  CreateLifeformParams,
  Kingdom,
  createLifeform,
  getAnimals,
} from '../../actions/animals';
import { FetchEnclosureParams, getEnclosures } from '../../actions/enclosure';
import { useMainContext } from '../../context/context';
import { getOption } from '../../shared/utils';
import { AddAnimalTabs, GeneralProps } from '../../typings/animal';
import { SelectListOption } from '../../typings/common';
import { SexTypes } from '../../typings/lifeform';
import { MainAction } from '../../typings/main_context_reducer';
import { formatDateString, formatDate } from '../../utils/dates';
import { NameInput, selectStyle } from '../input';
import { Paths } from '../../routes/paths';
import { infoNotification, successNotification } from '../notification';

export const GeneralSection = (props: GeneralProps): JSX.Element => {
  const {
    name,
    setName,
    scientificName,
    setScientificName,
    commonName,
    setCommonName,
    sex,
    setSex,
    isEdit,
    dob,
    setDOB,
    setActiveTab,
    enclosureID,
    setEnclosureID,
    isVisible,
    setIsVisible,
    setOpenModal,
    navigate,
  } = props;

  const sexOptions = [
    { value: 'female', label: 'Female' },
    { value: 'male', label: 'Male' },
    { value: 'unknown', label: 'Unknown' },
  ];
  const visibilityOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];
  const [enclosureOptions, setEnclosureOptions] = useState(
    [] as SelectListOption[],
  );
  const [mainState, dispatch] = useMainContext();
  const [reachedLimit, setReachedLimit] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (!isEdit) {
        const lifeforms = await getAnimals({
          account_id: mainState.account?.id,
          ignore_archived: true,
        });
        const lifeformCount = lifeforms.total || 0;
        const countLimit =
          mainState.membershipConfig?.countLimits.lifeform || 0;
        const overCountLimit = lifeformCount >= countLimit;

        if (overCountLimit) {
          infoNotification(
            'Upgrade Membership: Sorry, you have reached your animal limit.',
          );
        }

        setReachedLimit(overCountLimit);
      }

      const enclosureParams: FetchEnclosureParams = {
        currentPage: 1,
        perPage: 10,
        sort_by: '-id',
        is_archived: false,
        account_id: mainState.account?.id,
      };

      const enclosures = await getEnclosures(enclosureParams);
      const options = enclosures.data.map((enclosure) => ({
        value: `${enclosure.id}`,
        label: enclosure.name ?? 'Unknown',
      }));
      setEnclosureOptions(options);
      setEnclosureID;
    };
    loadData();
  }, []);

  return (
    <section className="general-section common-section">
      <div className="top">
        <div className="title-input-wrapper">
          <p className="title">Pet Name</p>
          <NameInput name={name} setName={setName} placeholder="Name" />
        </div>
        <div className="title-input-wrapper species-name">
          <p className="title">Scientific & Common Name</p>
          <div className="dual-input">
            <NameInput
              name={scientificName}
              setName={setScientificName}
              placeholder="Scientific Name"
              inputName="scientificName"
            />
            <NameInput
              name={commonName}
              setName={setCommonName}
              placeholder="Common Name"
              inputName="commonName"
            />
          </div>
        </div>
        <div className="title-input-wrapper sex-dob">
          <p className="title">Sex & Date of birth</p>
          <div className="dual-input">
            <Select
              name="sex"
              id="sex"
              styles={selectStyle}
              options={sexOptions}
              value={getOption(sex, sexOptions)}
              onChange={(target) =>
                setSex((target?.value as SexTypes) ?? SexTypes.Unknown)
              }
            />
            <DatePicker
              defaultValue={dob ? dayjs(dob) : undefined}
              onChange={(date, dateString) => {
                setDOB(dateString as string);
              }}
            />
          </div>
        </div>
        <div className="title-input-wrapper">
          <p className="title">Enclosure</p>
          <div className="dual-input">
            <Select
              isClearable
              name="enclosure"
              id="enclosure"
              styles={selectStyle}
              options={enclosureOptions}
              placeholder={'Select an enclosure'}
              value={getOption(`${enclosureID}`, enclosureOptions)}
              onChange={(target) => setEnclosureID(target?.value ?? '')}
            />
          </div>
        </div>
        {mainState.membershipConfig?.flags.privacy ? (
          <div className="title-input-wrapper">
            <p className="title">Public</p>
            <Select
              name="visibility"
              id="visibility"
              styles={selectStyle}
              options={visibilityOptions}
              value={getOption(isVisible ? 'yes' : 'no', visibilityOptions)}
              onChange={(target) => setIsVisible(target?.value === 'yes')}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="bottom">
        {isEdit ? (
          <>
            <button
              className="btn-delete"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Delete
            </button>
            <button
              className="btn-done"
              onClick={async () => {
                if (
                  name.length === 0 &&
                  commonName === '' &&
                  scientificName === ''
                ) {
                  return;
                }

                const data: Partial<LifeformModel> = {
                  ...(mainState?.lifeform?.common_name !== commonName
                    ? { common_name: commonName }
                    : {}),
                  ...(mainState?.lifeform?.pet_name !== name
                    ? { pet_name: name }
                    : {}),
                  ...(mainState?.lifeform?.scientific_name !== scientificName
                    ? { scientific_name: scientificName }
                    : {}),
                  ...(formatDate(moment(mainState?.lifeform?.dob)) !== dob
                    ? { dob: formatDateString(moment(dob)) }
                    : {}),
                  ...(mainState?.lifeform?.is_public !== isVisible
                    ? { is_public: isVisible }
                    : {}),
                  ...(mainState?.lifeform?.sex !== sex ? { sex } : {}),
                  ...(mainState?.lifeform?.enclosure_id !== enclosureID &&
                  enclosureID !== ''
                    ? { enclosure_id: enclosureID }
                    : {}),
                };

                if (Object.keys(data).length > 0) {
                  const updatedLifeform = await updateLifeform({
                    ...data,
                    id: mainState?.lifeform?.id,
                  });
                  const lifeformAction = {
                    type: GET_LIFEFORM,
                    lifeformAction: {
                      lifeform: updatedLifeform,
                    },
                  } as MainAction;

                  dispatch(lifeformAction);
                }

                successNotification('Successfully Updated');
                navigate(`${Paths.viewAnimal}/${mainState?.lifeform?.id ?? 0}`);
              }}
            >
              Save
            </button>
          </>
        ) : (
          <button
            className={`btn-next`}
            disabled={reachedLimit}
            onClick={async () => {
              if (
                (name.length === 0 &&
                  commonName === '' &&
                  scientificName === '') ||
                reachedLimit
              ) {
                return;
              }

              const lifeformParams: CreateLifeformParams = {
                is_archived: false,
                is_public: isVisible,
                pet_name: name,
                common_name: commonName,
                scientific_name: scientificName,
                sex,
                dob: formatDateString(moment(dob)),
                enclosure_id: enclosureID !== '' ? enclosureID : undefined,
                kingdom: Kingdom.Animalia,
              };
              const lifeform = await createLifeform(lifeformParams);
              const lifeformAction = {
                type: GET_LIFEFORM,
                lifeformAction: {
                  lifeform,
                },
              } as MainAction;
              dispatch(lifeformAction);
              setActiveTab(AddAnimalTabs.images);
            }}
          >
            Next
          </button>
        )}
      </div>
    </section>
  );
};
