import React from 'react';
import { ReactComponent as LogoIcon } from '../icons/logo/EcoZoo - Black.svg';
import { Paths } from '../routes/paths';

export const LogoHeader = (props: { hideLinks: boolean }): JSX.Element => {
  return (
    <>
      {props.hideLinks ? (
        <>
          <LogoIcon className="logo" />
          <h5 className="company-name">
            <span className="red-text">Eco</span>Zoo
            <span className="beta">BETA</span>
          </h5>
        </>
      ) : (
        <>
          <a href={Paths.home}>
            <LogoIcon className="logo" />
          </a>
          <h5 className="company-name">
            <a href={Paths.home}>
              <span className="red-text">Eco</span>Zoo
              <span className="beta">BETA</span>
            </a>
          </h5>
        </>
      )}
    </>
  );
};
