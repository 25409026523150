import React from 'react';

import { Paths } from '../routes/paths';
import { ReactComponent as MenuIcon } from '../icons/three-dot-menu.svg';
import { ReactComponent as PetIcon } from '../icons/pet.svg';
import { ReactComponent as EnclosureIcon } from '../icons/enclosure.svg';
import { ReactComponent as ObservationIcon } from '../icons/observation.svg';
import { ReactComponent as ImageIcon } from '../icons/image.svg';

export interface GalleryInterface {
  id: string;
  image: string;
  category: 'lifeform' | 'enclosure' | 'observation';
}

export const GalleryCard = (props: GalleryInterface): JSX.Element => {
  return (
    <div className="gallery-card common-card standard-card">
      <section className="top-content">
        <a href={`${Paths.viewGallery}/${props.category}/${props.id}`}>
          <div className="card-identifier">
            {props.category === 'enclosure' ? <EnclosureIcon /> : <></>}
            {props.category === 'lifeform' ? <PetIcon /> : <></>}
            {props.category === 'observation' ? <ObservationIcon /> : <></>}
          </div>
          <div className="card-identifier">
            <ImageIcon />
          </div>
        </a>

        <div className="card-menu">
          <MenuIcon />
        </div>
      </section>
      <div className="card-content-wrapper">
        <a href={`${Paths.viewGallery}/${props.category}/${props.id}`}>
          <section className="main-content">
            <img src={props.image} />
          </section>
        </a>
      </div>
    </div>
  );
};
