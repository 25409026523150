/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  Feeding,
  FeedingCard as FeedingCardModel,
  Lifeform as LifeformModel,
} from 'cobranetics-types/types';
import { Table } from 'antd';

import { CHECK, GET_FEEDINGS } from '../../actions';
import { getAnimal } from '../../actions/animals';
import {
  FeedingAttribute,
  FetchFeedingParams,
  getFeedingAttributes,
  getFeedings,
} from '../../actions/feeding';
import { getFeedingSchedule } from '../../actions/feeding_schedule';
import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { useMainContext } from '../../context/context';
import { MainAction } from '../../typings/main_context_reducer';
import { authRefresh } from '../../actions/check';
import { formateDateSimple } from '../../shared/utils';
import { FailedAuthCheck } from '../../shared/errors';
import { getFeedingScheduleMenus } from '../../shared/menu';
import { getPeople } from '../../actions/person';
import { AttributeCard } from '../../components/attributes';
import { getLifeformGalleryImages } from '../../actions/gallery';
import { DEFAULT_IMAGES } from '../../shared/constants';
import { ArchiveOnCard } from '../../components/archive';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { Paths } from '../../routes/paths';
import { errorNotification } from '../../components/notification';

interface FeedingTableData {
  meal: string;
  ate: string;
  lastFeed: string;
}

export const convertFeedingTableData = (
  feedings: FeedingCardModel[],
): FeedingTableData[] => {
  const tableData: FeedingTableData[] = feedings.map((feeding) => {
    return {
      meal: feeding.meal,
      ate: feeding.ate ? 'Yes' : feeding.ate === false ? 'No' : 'Not Yet',
      lastFeed: moment(feeding.feed_on).format('MMM DD, YYYY'),
    };
  });
  return tableData;
};

export const ViewFeedingScheduleItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();

  const [isBusy, setBusy] = useState(true);
  const [animal, setAnimal] = useState({} as LifeformModel);
  const [username, setUsername] = useState('');
  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');
  const [mainImage, setMainImage] = useState(DEFAULT_IMAGES.animal);
  const [totalFeedings, setTotalFeedings] = useState(0);
  const [currentFeedingPage, setCurrentFeedingPage] = useState(1);
  const feedingsPerPage = 20;
  const [feedings, setFeedings] = useState<Feeding[]>([]);

  const feedingData: {
    feedOn: string;
    meal: string;
    ate: string;
    key: string;
  }[] = feedings.map((row) => ({
    feedOn: formateDateSimple(row.feed_on, 'ago', true),
    meal: row.meal,
    ate: row.ate ? 'Yes' : 'No',
    key: `${row.id}-${row.feed_on}`,
  }));
  let columns: { title: string; dataIndex: string; key: string }[] = [];

  const commonRowMenus = getFeedingScheduleMenus(
    navigate,
    animal.id ?? '',
    mainState?.account?.id === animal?.account_id,
    username,
  );

  const [feedingAttributes, setFeedingAttributes] = useState<
    FeedingAttribute[]
  >([]);

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  if (!!feedings.length) {
    columns = [
      {
        title: 'Date / Time',
        dataIndex: 'feedOn',
        key: 'feedOn',
      },
      {
        title: 'Meal',
        dataIndex: 'meal',
        key: 'meal',
      },
      {
        title: 'Ate',
        dataIndex: 'ate',
        key: 'ate',
      },
    ];
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        if (Number.isNaN(pageID)) {
          return;
        }

        const check = await authRefresh();
        const feedingParams: FetchFeedingParams = {
          currentPage: currentFeedingPage,
          perPage: feedingsPerPage,
          sort_by: '-feed_on',
          feeding_schedule_ids: `${paramsID}`,
        };

        const feedings = await getFeedings(feedingParams);
        const feedingSchedule = await getFeedingSchedule(`${paramsID}`);
        const animal = await getAnimal(feedingSchedule.lifeform_id);
        const animalImages = await getLifeformGalleryImages({
          lifeform_ids: `${feedingSchedule.lifeform_id}`,
          is_default_image: true,
        });
        const person = (await getPeople({ account_id: animal.account_id }))
          .data[0];
        const attributes = await getFeedingAttributes(`${paramsID}`);

        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;
        const feedingAction = {
          type: GET_FEEDINGS,
          feedingAction: {
            feedings,
          },
        } as MainAction;

        if (animalImages.data.length > 0) {
          setMainImage(animalImages.data[0].image_url);
        }

        dispatch(checkAction);
        dispatch(feedingAction);
        setAnimal(animal);
        setUsername(person.username);
        setTotalFeedings(feedings.total || totalFeedings);
        setFeedings(feedings.data);
        setFeedingAttributes(attributes);
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="view-feeding-schedule">
      <Header hideLinks={false} title={'Feeding Schedule'} />
      {!isBusy ? (
        <>
          <div className="desktop-wrapper">
            <section className="view-feeding-schedule-background">
              <PageHeader
                title="Feeding Schedule"
                showBackBtn={true}
                activeTab={''}
                menu={commonRowMenus.mainMenu}
              />
              <section className="view-feeding-schedule-item">
                <div className="main-info">
                  <div className="image-wrapper">
                    {animal.is_archived ? (
                      <ArchiveOnCard is_archived={animal.is_archived} />
                    ) : (
                      <></>
                    )}
                    <img src={mainImage} alt="" className="display-image" />
                  </div>

                  <div className="animal-name-wrapper">
                    <h5 className="pet-name">{animal.pet_name ?? 'Unknown'}</h5>
                    <h6 className="scientific-name">
                      {animal.scientific_name ?? 'Unknown'}
                    </h6>
                  </div>
                </div>

                {!!feedingAttributes.length && (
                  <section className="attributes-wrapper">
                    <h5 className="title">Stats</h5>
                    <div className="attributes-inner-wrapper">
                      {feedingAttributes.map((row) => (
                        <AttributeCard
                          key={`${row.key?.replaceAll(
                            ' ',
                            '',
                          )}-${row.value?.replaceAll(' ', '')}`}
                          keyAttribute={row.key}
                          value={row.value}
                        />
                      ))}
                    </div>
                  </section>
                )}

                {!!feedings.length ? (
                  <section className="feeding-table-wrapper">
                    <div className="title-btn-wrapper">
                      <h5 className="title">Feedings</h5>
                      {mainState?.account?.id === animal?.account_id ? (
                        <a
                          href={`${Paths.addFeeding}?lifeform_id=${animal.id}`}
                        >
                          <button className="btn-add">
                            <AddIcon className="icon" />
                          </button>
                        </a>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="inner-table-wrapper">
                      <Table
                        dataSource={feedingData}
                        columns={columns}
                        pagination={{
                          total: totalFeedings,
                          pageSize: feedingsPerPage,
                          onChange: async (page, pageSize) => {
                            const feedingParams: FetchFeedingParams = {
                              currentPage: page,
                              perPage: pageSize,
                              sort_by: '-feed_on',
                              feeding_schedule_ids: `${paramsID}`,
                            };

                            const moreFeedings = await getFeedings(
                              feedingParams,
                            );
                            setCurrentFeedingPage(page);
                            setFeedings(moreFeedings.data);
                          },
                        }}
                      />
                    </div>
                  </section>
                ) : (
                  <></>
                )}
              </section>
            </section>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
