import imageCompression from "browser-image-compression";
import React, { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { ReactComponent as ImageIcon } from "../../icons/image.svg";
import { ImageProps } from "../../typings/inventory";

export const ImageSection = (props: ImageProps): JSX.Element => {
  const { activeImage, file, setFile, inventoryID } = props;

  const onDrop = async (acceptedFiles: any) => {
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1080,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(acceptedFiles[0], options);

      setFile(
        Object.assign(compressedFile, {
          preview: URL.createObjectURL(compressedFile),
        })
      );
    } catch (error) {
      console.log(error);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ["image/jpeg", "image/png"],
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => URL.revokeObjectURL(file.preview);
  }, [file]);

  return (
    <section className="add-image">
      <div
        className={`dropzone-images dropzone ${
          activeImage.length !== 0 || Object.keys(props.file).length
            ? "showing-image"
            : ""
        }`}
        {...getRootProps()}
      >
        {Object.keys(props.file).length ? (
          <img
            src={props.file.preview}
            onLoad={() => {
              URL.revokeObjectURL(props.file.preview);
            }}
          />
        ) : (
          <>
            {activeImage.length !== 0 ? (
              <div
                className="image-wrapper full"
                style={{ backgroundImage: `url(${activeImage})` }}
              />
            ) : (
              <>
                <ImageIcon className="image-icon" />
                <h6>Drop your image here or click to browse</h6>
              </>
            )}
          </>
        )}

        <input {...getInputProps()} />
      </div>
    </section>
  );
};
