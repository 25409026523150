import { FollowPairCard as FollowPairCardModel } from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';

export interface FetchFollowPairParams extends Params {
  person_id?: number;
  follower_id?: number;
}

export const getFollowPairCards = async (
  params: FetchFollowPairParams,
): Promise<ListOutput<FollowPairCardModel>> => {
  const response = await axiosInstance.get(PathsAPI.followPairCards, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export {};
