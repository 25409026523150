import {
  LifeformCard as LifeformCardModel,
  Lifeform as LifeformModel,
} from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { KingdomTypes, SexTypes } from '../typings/lifeform';

export enum Kingdom {
  Animalia = 'Animalia',
  Plantae = 'Plantae',
  Fungi = 'Fungi',
  Protista = 'Protista',
  Archaea = 'Archaea',
  Bacteria = 'Bacteria',
}

export interface FetchLifeformParams extends Params {
  is_archived?: boolean;
  enclosure_id?: string;
  pet_name?: string;
  common_name?: string;
  scientific_name?: string;
  kingdom?: Kingdom;
  account_id?: number;
  ids?: string;
  search?: string;
  ignore_archived?: boolean;
}

export interface CreateLifeformParams {
  is_archived: boolean;
  is_public: boolean;
  pet_name?: string;
  common_name?: string;
  scientific_name?: string;
  sex?: string;
  dob: string;
  enclosure_id?: string;
  kingdom: Kingdom;
}

export interface BulkCreateLifeformParams {
  is_archived?: boolean;
  is_public?: boolean;
  pet_name: string;
  common_name: string;
  scientific_name: string;
  sex?: SexTypes;
  dob?: string;
  enclosure_id?: string;
  kingdom?: KingdomTypes;
}

export const getAnimals = async (
  params: FetchLifeformParams,
): Promise<ListOutput<LifeformModel>> => {
  const response = await axiosInstance.get(PathsAPI.lifeform, { params });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getAnimalCards = async (
  params: FetchLifeformParams,
): Promise<ListOutput<LifeformCardModel>> => {
  const response = await axiosInstance.get(PathsAPI.lifeformCards, { params });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getAnimal = async (id: string): Promise<LifeformModel> => {
  const response = await axiosInstance.get(`${PathsAPI.lifeform}/${id}`);

  return response.data;
};

export const createLifeform = async (
  createLifeformParams: CreateLifeformParams,
): Promise<LifeformModel> => {
  const response = await axiosInstance.post(
    `${PathsAPI.lifeform}`,
    createLifeformParams,
  );

  return response.data;
};

export const updateLifeform = async (
  partialLifeform: Partial<LifeformModel>,
): Promise<LifeformModel> => {
  const response = await axiosInstance.put(
    `${PathsAPI.lifeform}`,
    partialLifeform,
  );

  return response.data;
};

export const deleteLifeform = async (id: string): Promise<void> => {
  await axiosInstance.delete(`${PathsAPI.lifeform}/${id}`);
};

export const bulkCreateLifeform = async (
  createParams: BulkCreateLifeformParams[],
): Promise<LifeformModel[]> => {
  const response = await axiosInstance.post(`${PathsAPI.lifeformBulk}`, {
    data: createParams,
  });

  return response.data;
};
