/* eslint-disable react/jsx-key */
import { Tabs, TabsProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Header } from '../../components/header';
import { FailedAuthCheck } from '../../shared/errors';
import { useMainContext } from '../../context/context';
import { useNavigate } from 'react-router-dom';
import { getCheck } from '../../actions/check';
import { CHECK } from '../../actions';
import { MainAction } from '../../typings/main_context_reducer';
import { PageHeader } from '../../components/page-header';
import { BulkExport } from './bulk-export';
import { BulkImport } from './bulk-import';

export const Bulk = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const [isBusy, setBusy] = useState(true);

  const items: TabsProps['items'] = [
    {
      key: 'import-tab',
      label: 'Import',
      children: <BulkImport />,
    },
    {
      key: 'export-tab',
      label: 'Export',
      children: <BulkExport accountID={mainState.account?.id} />,
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="bulk">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="bulk-export-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader title={'Bulk'} showBackBtn={true} activeTab={''} />

              <section className="common-section">
                <Tabs defaultActiveKey="1" items={items} />
              </section>
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
