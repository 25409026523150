export const validateEmail = (email: string): boolean => {
  const matches = String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

  return matches !== null;
};

export const validatePassword = (password: string): boolean => {
  const matches = String(password).match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/);
  return matches !== null;
};
