/* eslint-disable prefer-const */
import { ListOutput, Params, RequiredParams } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { SearchResult } from 'cobranetics-types/types/search';
import {
  FilterTypeOptions,
  FullSearchResults,
  ScopeOptions,
} from '../typings/search';
import { DEFAULT_SEARCH_PER_PAGE } from '../shared/constants';

export interface FetchSearchParams extends Params {
  scope: ScopeOptions;
  filterType: string;
  search: string;
  pageType?: FilterTypeOptions;
  pageID?: string;
}

export interface FetchUpdatedSearchParams extends RequiredParams {
  scope: ScopeOptions;
  filterType: string;
  search: string;
  pageType?: FilterTypeOptions;
  pageID?: string;
}

export const getSearchResults = async (
  params: FetchSearchParams,
): Promise<ListOutput<SearchResult>> => {
  const response = await axiosInstance.get(PathsAPI.search, { params });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const fetchFullResults = async (
  fullSearchResults: FullSearchResults,
  search: string,
  pageLocation: number,
  pageType: FilterTypeOptions | string,
): Promise<{
  currentSearchResults: FullSearchResults;
  innerPage: number;
}> => {
  let innerPage = pageLocation;
  let innerRemainingSearchCount = DEFAULT_SEARCH_PER_PAGE;

  for (const key of Object.keys(fullSearchResults)) {
    if (innerRemainingSearchCount === 0) {
      break;
    }

    if (
      pageType !== 'all' &&
      fullSearchResults[key].filterTypeOption !== pageType
    ) {
      continue;
    }

    if (fullSearchResults[key].hasNextPage) {
      let searchParams: {
        search?: string;
        currentPage: number;
        perPage: number;
        account_id?: number;
        sort_by: string;
      } = {
        currentPage: innerPage,
        perPage: DEFAULT_SEARCH_PER_PAGE,
        sort_by: '-updated_on',
      };

      if (!!search.length) {
        searchParams.search = search;
      }

      const response: ListOutput<SearchResult> = (
        await axiosInstance.get(fullSearchResults[key].endpoint, {
          params: searchParams,
        })
      ).data;

      fullSearchResults[key].hasNextPage = !!response.nextPage.length;
      fullSearchResults[key].data = [
        ...fullSearchResults[key].data,
        ...response.data.map((row) => ({
          ...row,
          filterTypeOption: fullSearchResults[key].filterTypeOption,
        })),
      ];

      if (!fullSearchResults[key].hasNextPage) {
        innerPage = 1;
        innerRemainingSearchCount = DEFAULT_SEARCH_PER_PAGE;
      } else {
        ++innerPage;
      }

      const remaining = innerRemainingSearchCount - response.data.length;
      innerRemainingSearchCount = remaining >= 0 ? remaining : 0;
    }
  }

  return { currentSearchResults: fullSearchResults, innerPage };
};

export const resetFullResults = (fullSearchResults: FullSearchResults) => {
  for (const key of Object.keys(fullSearchResults)) {
    fullSearchResults[key] = {
      data: [],
      hasNextPage: true,
      endpoint: fullSearchResults[key].endpoint,
      filterTypeOption: fullSearchResults[key].filterTypeOption,
    };
  }
};
