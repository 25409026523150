/* eslint-disable react/jsx-key */
import React from "react";
import { useTable, usePagination } from "react-table";
import { ReactComponent as ArrowIcon } from "../icons/arrow.svg";

export const Table = ({ columns, data }: any) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    state: {
      pageIndex, //pageSize
    },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    usePagination
  );

  // Render the UI for your table
  return (
    <>
      <table {...getTableProps()} className="table-with-pagination">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) =>
                column.hideHeader === true ? null : (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                )
              )}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell: any) => {
                  const cellType: any =
                    (cell.row.original.cellTypes &&
                      cell.row.original.cellTypes[cell.column.id]) ??
                    "text";
                  if (cellType === "input") {
                    return (
                      <td {...cell.getCellProps()}>
                        <input
                          type="text"
                          name={cell.column.id}
                          defaultValue={cell.row.values[cell.column.id]}
                        />
                      </td>
                    );
                  } else {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  }
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      <div className="pagination">
        <div className="go-to-page-wrapper">
          <span>
            Go to currentPage:{" "}
            <input
              type="number"
              value={
                pageIndex + 1 <= pageOptions.length
                  ? pageIndex + 1
                  : pageIndex - 1
              }
              min={1}
              max={pageOptions.length}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
            />
          </span>{" "}
        </div>

        <div className="page-number-wrapper">
          <button onClick={() => previousPage()} disabled={!canPreviousPage}>
            <ArrowIcon className="arrow-icon left" />
          </button>{" "}
          <ul>
            {pageIndex - 1 >= 0 ? <li>{pageIndex}</li> : null}
            <li className="current-page-number">{pageIndex + 1}</li>
            {pageIndex + 1 < pageOptions.length ? (
              <li>{pageIndex + 2}</li>
            ) : null}
          </ul>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <ArrowIcon className="arrow-icon right" />
          </button>{" "}
        </div>
      </div>
    </>
  );
};

export const TableNoPagination = ({ columns, data }: any) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any) =>
              column?.hideHeader === true ? null : (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              )
            )}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell: any) => {
                const cellType: any =
                  (cell.row.original.cellTypes &&
                    cell.row.original.cellTypes[cell.column.id]) ??
                  "text";
                if (cellType === "input") {
                  return (
                    <td {...cell.getCellProps()}>
                      <input
                        type="text"
                        name={cell.column.id}
                        defaultValue={cell.row.values[cell.column.id]}
                      />
                    </td>
                  );
                } else {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};
