import React, { useState } from 'react';
import Select from 'react-select';
import Creatable from 'react-select/creatable';

import { useMainContext } from '../../context/context';
import { Paths } from '../../routes/paths';
import { CharacteristicProps } from '../../typings/animal';
import { SelectListOption, UnitSystem } from '../../typings/common';
import { NameInput, NumberInput, selectStyle } from '../input';
import { MeasurementUnit } from '../../typings/readings';
import { getOption } from '../../shared/utils';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { Table } from 'antd';
import {
  createLifeformAttribute,
  updateLifeformAttribute,
} from '../../actions/lifeform-attribute';
import { splitFromFirstSpace } from '../../utils/strings';
import { successNotification } from '../notification';

export const CharacteristicSection = (
  props: CharacteristicProps,
): JSX.Element => {
  const { characteristics, setCharacteristics, navigate, isEdit } = props;
  const [mainState] = useMainContext();
  const [characteristicOptions, setCharacteristicOptions] = useState<
    SelectListOption[]
  >([
    {
      value: 'length',
      label: 'Length',
    },
    {
      value: 'weight',
      label: 'Weight',
    },
    {
      value: 'speed',
      label: 'Speed',
    },
    {
      value: 'activity level',
      label: 'Activity Level',
    },
    {
      value: 'curiosity',
      label: 'Curiosity',
    },
    {
      value: 'attitude',
      label: 'Attitude',
    },
  ]);
  const [
    selectedCharacteristicOption,
    setSelectedCharacteristicOption,
  ] = useState<SelectListOption | undefined>(characteristicOptions[0]);

  const lengthUnitsOptions: SelectListOption[] = [
    ...(mainState.settings?.unit_system === UnitSystem.Metric
      ? [
          {
            value: MeasurementUnit.millimeter,
            label: MeasurementUnit.millimeter,
          },
          {
            value: MeasurementUnit.centimeter,
            label: MeasurementUnit.centimeter,
          },
          {
            value: MeasurementUnit.meter,
            label: MeasurementUnit.meter,
          },
        ]
      : [
          {
            value: MeasurementUnit.inch,
            label: MeasurementUnit.inch,
          },
          {
            value: MeasurementUnit.foot,
            label: MeasurementUnit.foot,
          },
          {
            value: MeasurementUnit.yard,
            label: MeasurementUnit.yard,
          },
        ]),
  ];
  const weightUnitsOptions: SelectListOption[] = [
    ...(mainState.settings?.unit_system === UnitSystem.Metric
      ? [
          {
            value: MeasurementUnit.gram,
            label: MeasurementUnit.gram,
          },
          {
            value: MeasurementUnit.kilogram,
            label: MeasurementUnit.kilogram,
          },
          {
            value: MeasurementUnit.milligram,
            label: MeasurementUnit.milligram,
          },
        ]
      : [
          {
            value: MeasurementUnit.ounce,
            label: MeasurementUnit.ounce,
          },
          {
            value: 'lbs',
            label: 'lbs',
          },
        ]),
  ];

  const lengthStr = characteristics.find(
    (row) => row.key?.toLowerCase() === 'length',
  )?.value;
  const splitLength = lengthStr ? splitFromFirstSpace(lengthStr) : '';
  const matchLength = lengthUnitsOptions.find(
    (row) => row.value === splitLength[1],
  );

  const weightStr = characteristics.find(
    (row) => row.key?.toLowerCase() === 'weight',
  )?.value;
  const splitWeight = weightStr ? splitFromFirstSpace(weightStr) : '';
  const matchWeight = weightUnitsOptions.find(
    (row) => row.value === splitWeight[1],
  );

  const [selectedLengthUnitsOption, setSelectedLengthUnitsOption] = useState(
    matchLength ?? lengthUnitsOptions[1],
  );
  const [selectedWeightUnitsOption, setSelectedWeightUnitsOption] = useState(
    matchWeight ?? weightUnitsOptions[1],
  );

  const lengthNum = parseFloat(lengthStr ?? '0.0');
  const [lengthValue, setLengthValue] = useState(
    !isNaN(lengthNum) ? lengthNum : 0.0,
  );
  const columns = [
    { title: 'Name', dataIndex: 'name', key: 'name' },
    { title: 'Value', dataIndex: 'value', key: 'value' },
  ];

  const weightNum = parseFloat(weightStr ?? '0.0');
  const [weight, setWeightValue] = useState(
    !isNaN(weightNum) ? weightNum : 0.0,
  );
  const [value, setValue] = useState('');

  return (
    <section className="general-section common-section characteristics-section">
      <div className="top">
        <div className="title-input-wrapper">
          <p className="title">Characteristics</p>
          <Creatable
            isClearable
            className="select-wrapper"
            options={characteristicOptions}
            styles={selectStyle}
            placeholder="Select or type in a characteristic"
            name="characteristicOptions"
            id="characteristicOptions"
            value={selectedCharacteristicOption}
            onChange={(row) => {
              if ((row as any)?.__isNew__) {
                const opts = [...characteristicOptions];
                const selectedOpt = row
                  ? getOption(row?.value, characteristicOptions)
                  : undefined;

                if (selectedOpt) {
                  opts.push(selectedOpt);
                }

                setCharacteristicOptions(opts);
              }

              const value = row?.value || '';
              const label = row?.label || '';

              setSelectedCharacteristicOption({ value, label });
            }}
          />
        </div>
        {selectedCharacteristicOption?.value === 'length' ? (
          <div className="dual-input-wrapper">
            <NumberInput
              setNumber={setLengthValue}
              placeholder="0.00"
              inputName="lengthValue"
              value={lengthValue === 0 ? '' : lengthValue}
            />
            <div className="input-wrapper">
              <Select
                className="select-wrapper"
                options={lengthUnitsOptions}
                styles={selectStyle}
                placeholder="units"
                name="lengthUnitsOptions"
                id="lengthUnitsOptions"
                value={selectedLengthUnitsOption}
                onChange={(row) => {
                  setSelectedLengthUnitsOption(row ?? lengthUnitsOptions[0]);
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {selectedCharacteristicOption?.value === 'weight' ? (
          <div className="dual-input-wrapper">
            <NumberInput
              setNumber={setWeightValue}
              placeholder="0.00"
              inputName="weight"
              value={weight === 0 ? '' : weight}
            />
            <div className="input-wrapper">
              <Select
                className="select-wrapper"
                options={weightUnitsOptions}
                styles={selectStyle}
                placeholder="units"
                name="weightUnitsOptions"
                id="weightUnitsOptions"
                value={selectedWeightUnitsOption}
                onChange={(row) => {
                  setSelectedWeightUnitsOption(row ?? weightUnitsOptions[0]);
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}

        {!['weight', 'length'].includes(
          selectedCharacteristicOption?.value ?? '',
        ) ? (
          <div className="input-wrapper">
            <NameInput name={value} setName={setValue} placeholder="Value" />
          </div>
        ) : (
          <></>
        )}

        <button
          className="btn-done btn-add-characteristics"
          onClick={async () => {
            if (!selectedCharacteristicOption?.value) {
              return;
            }

            const createBody = {
              lifeform_id: mainState?.lifeform?.id || '',
              key: selectedCharacteristicOption.value,
            };

            if (selectedCharacteristicOption?.value === 'length') {
              const matchingAttribute = characteristics.find(
                (row) => row.key === 'length',
              );

              if (!matchingAttribute) {
                const lifeformAttribute = await createLifeformAttribute({
                  ...createBody,
                  value: `${lengthValue} ${selectedLengthUnitsOption.value}`,
                });

                setCharacteristics([...characteristics, lifeformAttribute]);
              } else {
                const lifeformAttribute = await updateLifeformAttribute({
                  ...createBody,
                  id: matchingAttribute.id,
                  value: `${lengthValue} ${selectedLengthUnitsOption.value}`,
                });

                setCharacteristics([
                  ...characteristics.filter((row) => row.key !== 'length'),
                  lifeformAttribute,
                ]);
              }
            }

            if (selectedCharacteristicOption?.value === 'weight') {
              const matchingAttribute = characteristics.find(
                (row) => row.key === 'weight',
              );

              if (!matchingAttribute) {
                const lifeformAttribute = await createLifeformAttribute({
                  ...createBody,
                  value: `${weight} ${selectedWeightUnitsOption.value}`,
                });

                setCharacteristics([...characteristics, lifeformAttribute]);
              } else {
                const lifeformAttribute = await updateLifeformAttribute({
                  ...createBody,
                  id: matchingAttribute.id,
                  value: `${weight} ${selectedWeightUnitsOption.value}`,
                });

                setCharacteristics([
                  ...characteristics.filter((row) => row.key !== 'weight'),
                  lifeformAttribute,
                ]);
              }
            }

            if (
              !['weight', 'length'].includes(
                selectedCharacteristicOption?.value ?? '',
              )
            ) {
              const matchingAttribute = characteristics.find(
                (row) => row.key === selectedCharacteristicOption.value,
              );

              if (!matchingAttribute) {
                const lifeformAttribute = await createLifeformAttribute({
                  ...createBody,
                  value: value,
                });

                setCharacteristics([...characteristics, lifeformAttribute]);
              } else {
                const lifeformAttribute = await updateLifeformAttribute({
                  ...createBody,
                  id: matchingAttribute.id,
                  value: value,
                });

                setCharacteristics([
                  ...characteristics.filter(
                    (row) => row.key !== selectedCharacteristicOption.value,
                  ),
                  lifeformAttribute,
                ]);
              }
            }

            successNotification('Successfully added a new characteristic');
          }}
        >
          <AddIcon className="add-icon" />
        </button>
      </div>
      <div className="bottom">
        <div className="middle">
          {!!characteristics.length && (
            <Table
              dataSource={characteristics.map((row) => ({
                key: `${row.key}-${row.value}`.replaceAll(' ', ''),
                name: row.key,
                value: row.value,
              }))}
              columns={columns}
              pagination={{ hideOnSinglePage: true }}
            />
          )}
        </div>
        <button
          className="btn-next"
          onClick={() => {
            navigate(`${Paths.viewAnimal}/${mainState?.lifeform?.id ?? 0}`);
          }}
        >
          Done
        </button>
      </div>
    </section>
  );
};
