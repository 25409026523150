import {
  FavoriteEnclosureGallery as FavoriteEnclosureGalleriesModel,
  FavoriteEnclosure as FavoriteEnclosuresModel,
  FavoriteLifeformGallery as FavoriteLifeformGalleriesModel,
  FavoriteLifeform as FavoriteLifeformsModel,
} from "cobranetics-types/types";
import { Params } from ".";
import { PathsAPI } from "../routes/paths";
import { axiosInstance } from "../shared/axios";

export interface FavoriteEnclosuresParams extends Params {
  enclosure_id?: string;
}

export interface FavoriteEnclosureGalleriesParams extends Params {
  enclosure_gallery_id?: string;
}

export interface FavoriteLifeformsParams extends Params {
  lifeform_id?: string;
}

export interface FavoriteLifeformGalleriesParams extends Params {
  lifeform_gallery_id?: string;
}

// Fetch

export const getFavoriteEnclosures = async (
  params: FavoriteEnclosuresParams
): Promise<FavoriteEnclosuresModel[]> => {
  const response = await axiosInstance.get(PathsAPI.favoriteEnclosure, {
    params,
  });

  return response.data.data;
};

export const getFavoriteEnclosureGalleries = async (
  params: FavoriteEnclosureGalleriesParams
): Promise<FavoriteEnclosureGalleriesModel[]> => {
  const response = await axiosInstance.get(PathsAPI.favoriteEnclosureGallery, {
    params,
  });

  return response.data.data;
};

export const getFavoriteLifeforms = async (
  params: FavoriteLifeformsParams
): Promise<FavoriteLifeformsModel[]> => {
  const response = await axiosInstance.get(PathsAPI.favoriteLifeform, {
    params,
  });

  return response.data.data;
};

export const getFavoriteLifeformGalleries = async (
  params: FavoriteLifeformGalleriesParams
): Promise<FavoriteLifeformGalleriesModel[]> => {
  const response = await axiosInstance.get(PathsAPI.favoriteLifeformGallery, {
    params,
  });

  return response.data.data;
};

// Create

export const postFavoriteEnclosures = async (body: {
  enclosure_id: string;
  account_id: number;
}): Promise<FavoriteEnclosuresModel> => {
  const response = await axiosInstance.post(PathsAPI.favoriteEnclosure, body);

  return response.data;
};

export const postFavoriteEnclosureGalleries = async (body: {
  enclosure_gallery_id: string;
  account_id: number;
}): Promise<FavoriteEnclosureGalleriesModel> => {
  const response = await axiosInstance.post(
    PathsAPI.favoriteEnclosureGallery,
    body
  );

  return response.data;
};

export const postFavoriteLifeforms = async (body: {
  lifeform_id: string;
  account_id: number;
}): Promise<FavoriteLifeformsModel> => {
  const response = await axiosInstance.post(PathsAPI.favoriteLifeform, body);

  return response.data;
};

export const postFavoriteLifeformGalleries = async (body: {
  lifeform_gallery_id: string;
  account_id: number;
}): Promise<FavoriteLifeformGalleriesModel> => {
  const response = await axiosInstance.post(
    PathsAPI.favoriteLifeformGallery,
    body
  );

  return response.data;
};

// Delete

export const deleteFavoriteEnclosures = async (id: string) => {
  const response = await axiosInstance.delete(
    `${PathsAPI.favoriteEnclosure}/${id}`
  );

  return response.data;
};

export const deleteFavoriteEnclosureGalleries = async (id: string) => {
  const response = await axiosInstance.delete(
    `${PathsAPI.favoriteEnclosureGallery}/${id}`
  );

  return response.data;
};

export const deleteFavoriteLifeforms = async (id: string) => {
  const response = await axiosInstance.delete(
    `${PathsAPI.favoriteLifeform}/${id}`
  );

  return response.data;
};

export const deleteFavoriteLifeformGalleries = async (id: string) => {
  const response = await axiosInstance.delete(
    `${PathsAPI.favoriteLifeformGallery}/${id}`
  );

  return response.data;
};
