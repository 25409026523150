import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Setting } from 'cobranetics-types/types';
import { Header } from '../../components/header';
import { useMainContext } from '../../context/context';
import { ChangePasswordModal } from '../../components/modals/change-password';
import { closeModal, openModal } from '../../components/modals/modal';
import { MainAction } from '../../typings/main_context_reducer';
import { PageHeader } from '../../components/page-header';

import { EmailInput, selectStyle } from '../../components/input';
import { getCheck } from '../../actions/check';
import { CHECK } from '../../actions';
import { updateAccount } from '../../actions/account';
import { Account } from '../../typings/account';
import { updateSettings } from '../../actions/settings';
import { UnitSystem, Notification, NamePreference } from '../../typings/common';
import { FailedAuthCheck } from '../../shared/errors';
import { getSearchParams } from '../../shared/utils';
import {
  errorNotification,
  successNotification,
} from '../../components/notification';

export const GeneralSection = (): JSX.Element => {
  const [isBusy, setBusy] = useState(true);
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();

  const unitOptions = [
    { value: UnitSystem.Metric, label: UnitSystem.Metric },
    { value: UnitSystem.Imperial, label: UnitSystem.Imperial },
  ];
  const notificationOptions = [
    { value: Notification.email, label: Notification.email },
  ];
  const animalNamingPreferenceOptions = [
    { value: NamePreference.scientific, label: NamePreference.scientific },
    { value: NamePreference.common, label: NamePreference.common },
    { value: NamePreference.pet, label: NamePreference.pet },
  ];
  const [unitOption, setUnitOption] = useState(unitOptions[0]);
  const [notificationOption, setNotificationOption] = useState(
    notificationOptions[0],
  );
  const [
    animalNamingPreferenceOption,
    setAnimalNamingPreferenceOption,
  ] = useState(animalNamingPreferenceOptions[0]);

  const submit = async () => {
    const settings: Partial<Setting> = {
      id: mainState?.settings?.id,
      ...(mainState?.settings?.unit_system !== unitOption.value && {
        unit_system: unitOption.value,
      }),
      ...(mainState?.settings?.notification !== notificationOption.value && {
        notification: notificationOption.value,
      }),
      ...(mainState?.settings?.name_preference !==
        animalNamingPreferenceOption.value && {
        name_preference: animalNamingPreferenceOption.value,
      }),
    };
    let updatedSettings = null;

    if (Object.keys(settings).length > 0) {
      updatedSettings = await updateSettings(settings);
    }

    const checkAction = {
      type: CHECK,
      checkAction: {
        account: mainState.account,
        person: mainState.person,
        settings: updatedSettings ?? mainState.settings,
      },
    } as MainAction;

    dispatch(checkAction);
    successNotification('Successfully Updated');
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        if (mainState?.account) {
          setBusy(false);
          return;
        }

        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;

        setUnitOption({
          value: check.settings.unit_system,
          label: check.settings.unit_system,
        });
        setNotificationOption({
          value: check.settings.notification,
          label: check.settings.notification,
        });
        setAnimalNamingPreferenceOption({
          value: check.settings.name_preference,
          label: check.settings.name_preference,
        });

        dispatch(checkAction);
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.error(error);
        errorNotification('Failed to load data.');
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <section className="general-section common-section">
      {!isBusy ? (
        <>
          <div className="top">
            <div className="title-input-wrapper">
              <p className="title">Measurement System</p>
              <Select
                name="unitOptions"
                id="unitOptions"
                styles={selectStyle}
                options={unitOptions}
                value={unitOption}
                onChange={(target) => setUnitOption(target as any)}
              />
            </div>
            <div className="title-input-wrapper">
              <p className="title">Notifications</p>
              <Select
                name="notificationOptions"
                id="notificationOptions"
                styles={selectStyle}
                options={notificationOptions}
                value={notificationOption}
                onChange={(target) => setNotificationOption(target as any)}
              />
            </div>
            <div className="title-input-wrapper">
              <p className="title">Animal Naming Preference</p>
              <Select
                name="animalNamingPreferenceOptions"
                id="animalNamingPreferenceOptions"
                styles={selectStyle}
                options={animalNamingPreferenceOptions}
                value={animalNamingPreferenceOption}
                onChange={(target) =>
                  setAnimalNamingPreferenceOption(target as any)
                }
              />
            </div>
          </div>
          <div className="bottom">
            <button
              className="btn-done btn-save"
              onClick={async () => {
                await submit();
              }}
            >
              Save
            </button>
          </div>
        </>
      ) : (
        <></>
      )}
    </section>
  );
};

export const AccountSection = (): JSX.Element => {
  const [isBusy, setBusy] = useState(true);
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [changePasswordModalIsOpen, changePasswordModalSetIsOpen] = useState(
    false,
  );

  const submit = async () => {
    const account: Partial<Account> = {
      ...(mainState?.account?.email !== email && { email }),
    };
    let updatedAccount = null;

    if (Object.keys(account).length > 0) {
      updatedAccount = await updateAccount(account);
    }

    const checkAction = {
      type: CHECK,
      checkAction: {
        account: updatedAccount ?? mainState.account,
        person: mainState.person,
      },
    } as MainAction;

    dispatch(checkAction);
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        if (mainState?.account) {
          setBusy(false);
          setEmail(mainState?.account?.email ?? '');
          return;
        }

        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;

        setEmail(check.account.email);
        dispatch(checkAction);
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.error(error);
        errorNotification('Failed to load data.');
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <section className="general-section common-section">
      {!isBusy ? (
        <>
          <div className="top flex">
            <div className="title-input-wrapper">
              <p className="title">Email</p>
              <EmailInput email={email} setEmail={setEmail} />
            </div>
            <div className="title-input-wrapper flex">
              <div>
                <p className="title">Membership</p>
                <h6>{mainState?.account?.membership_name}</h6>
              </div>
              <button
                className="btn-change-membership btn-default"
                onClick={() => {}}
                disabled={true}
              >
                Change Membership
              </button>
            </div>
            <div className="title-input-wrapper flex">
              <div>
                <p className="title">Password</p>
                <h6>**************</h6>
              </div>
              <button
                className="btn-change-password btn-default"
                onClick={() => {
                  openModal(changePasswordModalSetIsOpen)();
                }}
              >
                Change Password
              </button>
            </div>
          </div>
          <div className="bottom">
            <button
              className="btn-done btn-save"
              onClick={async () => {
                await submit();
              }}
            >
              Save
            </button>
          </div>
          <ChangePasswordModal
            modalIsOpen={changePasswordModalIsOpen}
            closeModal={closeModal(changePasswordModalSetIsOpen)}
          />
        </>
      ) : (
        <></>
      )}
    </section>
  );
};

export enum SettingTabs {
  general = 'General',
  account = 'Account',
}

export const SettingsView = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = getSearchParams(searchParams);

  // Tabs
  let tab = SettingTabs.general;
  switch (queryParams.tab) {
    case SettingTabs.account:
      tab = SettingTabs.account;
      break;
    default:
      break;
  }
  const [activeTab, setActiveTab] = useState(tab);
  const tabs = [
    {
      title: SettingTabs.general,
      clickTab: () => {
        setActiveTab(SettingTabs.general);
        setSearchParams({ tab: SettingTabs.general });
      },
    },
    {
      title: SettingTabs.account,
      clickTab: () => {
        setActiveTab(SettingTabs.account);
        setSearchParams({ tab: SettingTabs.account });
      },
    },
  ];

  return (
    <div className="settings">
      <Header hideLinks={false} />
      <div className="desktop-wrapper">
        <section className="settings-background common-background">
          <section className="common-inner-wrapper">
            <PageHeader
              title={'Settings'}
              showBackBtn={true}
              tabs={tabs}
              activeTab={activeTab}
            />
            {activeTab === SettingTabs.general ? <GeneralSection /> : <></>}
            {activeTab === SettingTabs.account ? <AccountSection /> : <></>}
          </section>
        </section>
      </div>
    </div>
  );
};
