import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { getOption } from '../../shared/utils';
import { AddObservationTabs, GeneralProps } from '../../typings/observation';
import { DateTimeInput, NameInput, selectStyle } from '../input';
import { getObservations } from '../../actions/observation';
import { useMainContext } from '../../context/context';
import { infoNotification } from '../notification';

export const GeneralSection = (props: GeneralProps): JSX.Element => {
  const {
    name,
    setName,
    selectedType,
    setSelectedType,
    animalOptions,
    selectedAnimalOption,
    setSelectedAnimalOption,
    isEdit,
    recording,
    setRecording,
    setOpenDeleteModal,
    saveObservation,
    observationType,
    setActiveTab,
    dateTime,
    setDateTime,
  } = props;
  const [mainState] = useMainContext();

  const [reachedLimit, setReachedLimit] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (isEdit) {
        return;
      }

      const rows = await getObservations({
        account_id: mainState.account?.id,
      });
      const count = rows.total || 0;
      const countLimit =
        mainState.membershipConfig?.countLimits.observations || 0;
      const overCountLimit = count >= countLimit;

      if (overCountLimit) {
        infoNotification(
          `Upgrade Membership: Sorry, you have reached your enclosure limit.`,
        );
      }

      setReachedLimit(overCountLimit);
    };
    loadData();
  }, []);

  return (
    <section className="general-section common-section">
      <div className="top">
        <div className="title-input-wrapper">
          <p className="title">Name</p>
          <NameInput
            name={name}
            setName={setName}
            placeholder="Name"
            inputName="observation-name"
          />
        </div>
        <div className="title-input-wrapper">
          <p className="title">Category</p>
          <Select
            isClearable
            options={observationType}
            styles={selectStyle}
            placeholder="Select a category"
            name="observationType"
            id="observationType"
            value={selectedType}
            onChange={(row) => {
              setSelectedType(
                row ? getOption(row?.value, observationType) : undefined,
              );
            }}
          />
        </div>
        <div className="title-input-wrapper">
          <p className="title">Animal</p>
          <Select
            name="animalOptions"
            id="animalOptions"
            styles={selectStyle}
            options={animalOptions}
            value={selectedAnimalOption}
            onChange={(row) => {
              setSelectedAnimalOption(
                row ? getOption(row?.value, animalOptions) : undefined,
              );
            }}
          />
        </div>
        <div className="title-input-wrapper">
          <p className="title">Recording</p>
          <textarea
            name="recording"
            id="recording"
            value={recording}
            onChange={(e) => setRecording(e.target.value)}
            placeholder="What did you observe?"
          />
        </div>
        <div className="title-input-wrapper">
          <p className="title">Observed On</p>
          <DateTimeInput
            dateTime={dateTime}
            setDateTime={setDateTime}
            placeholder=""
            inputName="recording-time"
          />
        </div>
      </div>
      {isEdit ? (
        <div className="bottom two">
          <button className="btn-done" onClick={saveObservation}>
            Save
          </button>
          <button
            className="btn-delete"
            onClick={() => {
              setOpenDeleteModal(true);
            }}
          >
            Delete
          </button>
        </div>
      ) : (
        <div className="bottom">
          <button
            className="btn-done"
            disabled={reachedLimit}
            onClick={() => {
              saveObservation();
              setActiveTab(AddObservationTabs.images);
            }}
          >
            Next
          </button>
        </div>
      )}
    </section>
  );
};
