export enum UnitSystem {
  Metric = "Metric",
  Imperial = "Imperial",
}

export enum Notification {
  email = "Email",
  sms = "SMS",
}

export enum NamePreference {
  pet = "Pet",
  common = "Common",
  scientific = "Scientific",
}

export interface SelectListOption {
  value: string;
  label: string;
}

export enum GeneralImageTabs {
  general = "General",
  images = "Images",
}

export interface BaseImageProps {
  images: { id: string; src: string }[];
  activeImage: string;
  setImages: (images: { id: string; src: string }[]) => void;
  setActiveImage: (image: string) => void;
  navigate: any;
}
