import { LifeformAttribute } from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from 'cobranetics-types';
import { axiosInstance } from '../shared/axios';

export interface FetchLifeformAttributeParams extends Params {
  lifeform_id: string;
  search?: string;
}

export interface CreateLifeformAttributeParams {
  key: string;
  value: string;
  lifeform_id: string;
}

export const getLifeformAttributes = async (
  params: FetchLifeformAttributeParams,
): Promise<ListOutput<LifeformAttribute>> => {
  const response = await axiosInstance.get(PathsAPI.lifeformAttribute, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const createLifeformAttribute = async (
  createLifeformAttributeParams: CreateLifeformAttributeParams,
): Promise<LifeformAttribute> => {
  const response = await axiosInstance.post(
    `${PathsAPI.lifeformAttribute}`,
    createLifeformAttributeParams,
  );

  return response.data;
};

export const updateLifeformAttribute = async (
  partialLifeformAttribute: Partial<LifeformAttribute>,
): Promise<LifeformAttribute> => {
  const response = await axiosInstance.put(
    `${PathsAPI.lifeformAttribute}`,
    partialLifeformAttribute,
  );

  return response.data;
};

export const deleteLifeformAttribute = async (id: string): Promise<void> => {
  await axiosInstance.delete(`${PathsAPI.lifeformAttribute}/${id}`);
};
