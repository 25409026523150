/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { CHECK, GET_ANIMALS } from '../../actions';
import {
  deleteObservation,
  getObservationCard,
  getObservationGallery,
  getObservationGalleryCount,
} from '../../actions/observation';
import { Header } from '../../components/header';

import { PageHeader } from '../../components/page-header';
import { useMainContext } from '../../context/context';
import { Dispatch, MainAction } from '../../typings/main_context_reducer';
import {
  ObservationCard as ObservationCardModel,
  ObservationGallery,
} from 'cobranetics-types/types';
import { authRefresh } from '../../actions/check';
import { FailedAuthCheck } from '../../shared/errors';
import { getObservationMenus } from '../../shared/menu';
import {
  DEFAULT_IMAGES,
  DEFAULT_IMAGES_PER_PAGE,
  REACT_APP_UI_BASE_PATH,
} from '../../shared/constants';
import { Paths } from '../../routes/paths';
import { FetchLifeformParams, getAnimalCards } from '../../actions/animals';
import { getPeople } from '../../actions/person';
import { DeleteModal } from '../../components/modals/delete';
import moment from 'moment';
import { formatDefaultDateTime } from '../../utils/dates';
import { GalleryCard } from '../../components/gallery';
import { errorNotification } from '../../components/notification';

export const loadData = async (
  observationID: string,
  search: string,
  dispatch: Dispatch,
  setSearchParams: (obj: { [key: string]: string }) => void,
) => {
  try {
    // do stuff

    setSearchParams({ search });
  } catch (error) {
    console.error(error);
    errorNotification('Failed to load data.');
  }
};

export const ViewObservationItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');

  const [isBusy, setBusy] = useState(true);
  const [observation, setObservation] = useState({} as ObservationCardModel);
  const [username, setUsername] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  const [observationImages, setObservationImages] = useState<
    ObservationGallery[]
  >([]);
  const [hasMoreImages, setHasMoreImages] = useState(false);
  const [loadMoreImages, setLoadMoreImages] = useState(false);
  const [currentImagePage, setCurrentImagePage] = useState(1);

  const commonRowMenus = getObservationMenus(
    navigate,
    paramsID ?? '',
    mainState?.account?.id === observation?.account_id,
    username,
    () => {
      setOpenModal(true);
    },
  );
  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteObservation(`${pageID}`);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'observation',
    name: observation.title,
  };

  let animalName = observation?.pet_name ?? `Unknown`;

  if (animalName !== 'Unknown') {
    animalName = `${animalName} (${
      observation?.scientific_name || observation?.common_name || 'Unknown'
    })`;
  }

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  useEffect(() => {
    const initialFetch = async () => {
      try {
        if (Number.isNaN(pageID)) {
          return;
        }
        const check = await authRefresh();
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;
        const observation = await getObservationCard(pageID);
        const person = (await getPeople({ account_id: observation.account_id }))
          .data[0];

        const animalCardParams: FetchLifeformParams = {
          currentPage: 1,
          perPage: 1,
          sort_by: '-id',
          ids: `${observation.lifeform_id}`,
        };
        const animals = await getAnimalCards(animalCardParams);
        const animalAction = {
          type: GET_ANIMALS,
          animalAction: {
            animals,
          },
        } as MainAction;

        const observationImageList = await getObservationGallery({
          observation_ids: `${pageID}`,
          perPage: DEFAULT_IMAGES_PER_PAGE,
          currentPage: currentImagePage,
        });

        const galleryImageCount = await getObservationGalleryCount(
          observation.id,
        );
        setImageCount(galleryImageCount);

        dispatch(animalAction);
        dispatch(checkAction);
        setObservation(observation);
        setUsername(person.username);

        setObservationImages(observationImageList.data);
        setHasMoreImages(!!observationImageList.nextPage.length);
        setCurrentImagePage(currentImagePage + 1);
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    initialFetch();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (!hasMoreImages || !loadMoreImages) {
        return;
      }

      // Fetch more images
      const observationImageList = await getObservationGallery({
        observation_ids: `${pageID}`,
        perPage: DEFAULT_IMAGES_PER_PAGE,
        currentPage: currentImagePage,
      });

      setObservationImages([
        ...observationImages,
        ...observationImageList.data,
      ]);
      setCurrentImagePage(currentImagePage + 1);
      setLoadMoreImages(false);
      setHasMoreImages(
        observationImageList.data.length === DEFAULT_IMAGES_PER_PAGE,
      );
    };

    loadData();
  }, [loadMoreImages]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      setLoadMoreImages(true);
    }
  };

  return (
    <div className="view-observation-reading">
      <Header hideLinks={false} title={'Observation'} />
      {!isBusy ? (
        <>
          <div className="desktop-wrapper">
            <section
              className="view-observation-background observation-item-background"
              onScroll={(e: any) => {
                handleScroll(e);
              }}
            >
              <PageHeader
                title={observation?.title ?? 'Unknown'}
                subTitle={animalName}
                showBackBtn={true}
                activeTab={''}
                menu={commonRowMenus.mainMenu}
              />
              <section className="view-observation-item">
                <div className="main-info">
                  <div className="image-wrapper">
                    <img
                      src={observation.image_url || DEFAULT_IMAGES.observation}
                      alt=""
                      className="display-image"
                    />
                  </div>
                  <div className="info">
                    <div className="info-item">
                      <p className="title">Created On</p>
                      <p className="value">
                        {formatDefaultDateTime(moment(observation.created_on))}
                      </p>
                    </div>
                    <div className="info-item">
                      <p className="title">Image Count</p>
                      <p className="value">{imageCount}</p>
                    </div>
                  </div>

                  <div className="text-body">
                    <p className="recording-text">{observation.recording}</p>
                  </div>
                </div>
                <>
                  {mainState.animals?.data.map((row, index) => (
                    <section
                      className={`related-item-highlight ${
                        index % 2 === 0 ? '' : 'reverse'
                      }`}
                      key={`related-item-${row.id}`}
                    >
                      {index % 2 === 0 ? (
                        <></>
                      ) : (
                        <>
                          <div className="go-to-item">
                            <p className="go-to-title">
                              {row?.pet_name ?? 'Unknown'}
                            </p>
                            <a
                              href={`${REACT_APP_UI_BASE_PATH}${
                                Paths.viewAnimal
                              }${row.id ? `/${row.id}` : ''}`}
                            >
                              <button className="btn-action">
                                View Animal
                              </button>
                            </a>
                          </div>
                        </>
                      )}
                      <div className="image-wrapper">
                        <img src={row?.image_url || DEFAULT_IMAGES.animal} />
                      </div>
                      {index % 2 === 0 ? (
                        <>
                          <div className="go-to-item">
                            <p className="go-to-title">
                              {row?.pet_name ?? 'Unknown'}
                            </p>
                            <a
                              href={`${REACT_APP_UI_BASE_PATH}${
                                Paths.viewAnimal
                              }${row.id ? `/${row.id}` : ''}`}
                            >
                              <button className="btn-action">
                                View Animal
                              </button>
                            </a>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </section>
                  ))}
                </>

                {!!observationImages.length && (
                  <section className="simple-items-wrapper">
                    <h5 className="title">Images</h5>
                    <div className="inner-wrapper">
                      {observationImages.map((row) => (
                        <GalleryCard
                          key={`image-card-${row.image_url}`}
                          id={row.id}
                          image={row.image_url ?? ''}
                          category={'observation'}
                        />
                      ))}
                    </div>
                  </section>
                )}
              </section>
            </section>
          </div>
        </>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
