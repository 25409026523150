import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Enclosure as EnclosureModel } from 'cobranetics-types/types';
import { GET_ENCLOSURE } from '../../actions';
import {
  createEnclosure,
  getEnclosures,
  updateEnclosure,
} from '../../actions/enclosure';
import { useMainContext } from '../../context/context';
import {
  metricUnitDimensionOptions,
  imperialUnitDimensionOptions,
  unitTemperatureOptions,
  getUnitOption,
  unitHumidityOptions,
} from '../../pages/create_edit/enclosure';
import { Paths } from '../../routes/paths';
import { getOption } from '../../shared/utils';
import {
  GeneralProps,
  CreateEnclosureTabs,
  CreateEnclosureParams,
} from '../../typings/enclosure';
import { MainAction } from '../../typings/main_context_reducer';
import {
  NameInput,
  selectStyle,
  LengthInput,
  WidthInput,
  HeightInput,
  NumberInput,
} from '../input';
import { ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT } from './constants';
import {
  errorNotification,
  infoNotification,
  successNotification,
} from '../notification';

export const GeneralSection = (props: GeneralProps): JSX.Element => {
  const {
    name,
    setName,
    length,
    setLength,
    width,
    setWidth,
    height,
    setHeight,
    isMetric,
    enclosureUnits,
    setEnclosureUnits,
    setActiveTab,
    isEdit,
    enclosureID,
    navigate,
    isVisible,
    setIsVisible,
    temperature,
    setTemperature,
    temperatureUnits,
    setTemperatureUnits,
    humidity,
    setHumidity,
    humidityUnits,
    setHumidityUnits,
    setOpenModal,
  } = props;
  const visibilityOptions = [
    { value: 'yes', label: 'Yes' },
    { value: 'no', label: 'No' },
  ];
  const unitsOptions = isMetric
    ? metricUnitDimensionOptions
    : imperialUnitDimensionOptions;
  const [mainState, dispatch] = useMainContext();
  const maxTemperature = temperature ? temperature.split(' - ')[0] : '';
  const minTemperature = temperature ? temperature.split(' - ')[1] : '';
  const enclosureTemperature = `${maxTemperature || '?'} - ${
    minTemperature || '?'
  }${temperatureUnits.value}`;
  const maxHumidity = humidity ? humidity.split(' - ')[0] : '';
  const minHumidity = humidity ? humidity.split(' - ')[1] : '';
  const enclosureHumidity = `${maxHumidity || '?'} - ${minHumidity || '?'}${
    humidityUnits.value
  }`;

  const [reachedLimit, setReachedLimit] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      if (isEdit) {
        return;
      }

      const rows = await getEnclosures({
        account_id: mainState.account?.id,
        ignore_archived: true,
      });
      const count = rows.total || 0;
      const countLimit =
        mainState.membershipConfig?.countLimits.enclosures || 0;
      const overCountLimit = count >= countLimit;

      if (overCountLimit) {
        infoNotification(
          `Upgrade Membership: Sorry, you have reached your enclosure limit.`,
        );
      }

      setReachedLimit(overCountLimit);
    };
    loadData();
  }, []);

  return (
    <section className="general-section common-section">
      <div className="top">
        <div className="title-input-wrapper">
          <p className="title">Name</p>
          <NameInput name={name} setName={setName} placeholder="Name" />
        </div>

        <div className="title-input-wrapper">
          <p className="title">Dimensions (Length x Width x Height)</p>
          <div className="input-row">
            <LengthInput length={length} setLength={setLength} />
            <WidthInput width={width} setWidth={setWidth} />
            <HeightInput height={height} setHeight={setHeight} />
          </div>
          <Select
            name="enclosure-units"
            id="enclosure-units"
            styles={selectStyle}
            options={unitsOptions}
            value={getUnitOption(enclosureUnits, isMetric)}
            onChange={(target) => setEnclosureUnits(target?.value ?? '')}
          />
        </div>
        <div className="title-input-wrapper">
          <p className="title">Temperature Range (Optional)</p>
          <div className="input-row">
            <NumberInput
              inputName="Max Temperature"
              value={+maxTemperature > 0 ? +maxTemperature : ''}
              setNumber={(val) => {
                const updatedTemperature = temperature.split(
                  ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT,
                );

                if (updatedTemperature.length === 0) {
                  return;
                }

                if (updatedTemperature.length === 1) {
                  updatedTemperature[1] = '0';
                }

                updatedTemperature[0] = `${val}`;

                setTemperature(
                  updatedTemperature.join(
                    ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT,
                  ),
                );
              }}
              placeholder={'Max'}
            />
            <p className="dash">-</p>
            <NumberInput
              inputName="Min Temperature"
              value={+minTemperature > 0 ? +minTemperature : ''}
              setNumber={(val) => {
                const updatedTemperature = temperature.split(
                  ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT,
                );

                if (updatedTemperature.length === 0) {
                  return;
                }

                if (updatedTemperature.length === 1) {
                  updatedTemperature[0] = '0';
                }

                updatedTemperature[1] = `${val}`;

                setTemperature(
                  updatedTemperature.join(
                    ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT,
                  ),
                );
              }}
              placeholder={'Min'}
            />
          </div>
          <Select
            name="temperature-units"
            id="temperature-units"
            styles={selectStyle}
            options={unitTemperatureOptions as any}
            value={temperatureUnits}
            onChange={(target: any) => {
              setTemperatureUnits(target);
            }}
          />
        </div>
        <div className="title-input-wrapper">
          <p className="title">Humidity Range (Optional)</p>
          <div className="input-row">
            <NumberInput
              inputName="Max Humidity"
              value={+maxHumidity > 0 ? +maxHumidity : ''}
              setNumber={(val) => {
                const updatedHumidity = humidity.split(
                  ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT,
                );

                if (updatedHumidity.length === 0) {
                  return;
                }

                if (updatedHumidity.length === 1) {
                  updatedHumidity[1] = '0';
                }

                updatedHumidity[0] = `${val}`;

                setHumidity(
                  updatedHumidity.join(ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT),
                );
              }}
              placeholder={'Max'}
            />
            <p className="dash">-</p>
            <NumberInput
              inputName="Min Humidity"
              value={+minHumidity > 0 ? +minHumidity : ''}
              setNumber={(val) => {
                const updatedHumidity = humidity.split(
                  ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT,
                );

                if (updatedHumidity.length === 0) {
                  return;
                }

                if (updatedHumidity.length === 1) {
                  updatedHumidity[0] = '0';
                }

                updatedHumidity[1] = `${val}`;

                setHumidity(
                  updatedHumidity.join(ENCLOSURE_TEMPERATURE_HUMIDITY_FORMAT),
                );
              }}
              placeholder={'Min'}
            />
          </div>
          <Select
            name="humidity-units"
            id="humidity-units"
            styles={selectStyle}
            options={unitHumidityOptions as any}
            value={humidityUnits}
            onChange={(target: any) => {
              setHumidityUnits(target);
            }}
          />
        </div>
        {mainState.membershipConfig?.flags.privacy ? (
          <div className="title-input-wrapper">
            <p className="title">Public</p>
            <Select
              name="enclosure-visibility"
              id="enclosure-visibility"
              styles={selectStyle}
              options={visibilityOptions}
              value={getOption(isVisible ? 'yes' : 'no', visibilityOptions)}
              onChange={(target) => setIsVisible(target?.value === 'yes')}
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="bottom">
        {isEdit ? (
          <>
            <button
              className="btn-delete"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              Delete
            </button>
            <button
              className={`btn-done ${name.length === 0 ? 'disabled' : ''}`}
              onClick={async () => {
                const hasTemperature =
                  minTemperature !== '' && maxTemperature !== '';
                const hasHumidity = minHumidity !== '' && maxHumidity !== '';

                if (
                  name.length === 0 ||
                  length.length === 0 ||
                  height.length === 0 ||
                  width.length === 0
                ) {
                  return;
                }

                if (hasTemperature && +maxTemperature <= +minTemperature) {
                  errorNotification(
                    'Max temperature must be greater then your minimum temperature.',
                  );
                  return;
                }

                if (hasHumidity && +maxHumidity <= +minHumidity) {
                  errorNotification(
                    'Max humidity must be greater then your minimum humidity.',
                  );
                  return;
                }

                const dimensions = `${length} x ${width} x ${height} ${enclosureUnits}`;
                const data: Partial<EnclosureModel> = {
                  id: mainState?.enclosure?.id || '',
                  is_archived: false,
                  ...(mainState?.enclosure?.is_public !== isVisible
                    ? { is_public: isVisible }
                    : {}),
                  ...(mainState?.enclosure?.dimensions !== dimensions
                    ? { dimensions: dimensions }
                    : {}),
                  ...(mainState?.enclosure?.name !== name
                    ? { name: name }
                    : {}),
                  ...(hasTemperature
                    ? { temperature: enclosureTemperature }
                    : {}),
                  ...(hasHumidity ? { humidity: enclosureHumidity } : {}),
                };

                if (Object.keys(data).length > 0) {
                  const updatedEnclosure = await updateEnclosure(data);
                  const enclosureAction = {
                    type: GET_ENCLOSURE,
                    enclosureSingleAction: {
                      enclosure: updatedEnclosure,
                    },
                  } as MainAction;

                  dispatch(enclosureAction);
                }

                successNotification('Successfully updated enclosure');

                navigate(
                  mainState?.enclosure?.id
                    ? `${Paths.viewEnclosure}/${mainState?.enclosure?.id ?? 0}`
                    : Paths.viewEnclosure,
                );
              }}
            >
              Save
            </button>
          </>
        ) : (
          <button
            className={`btn-next ${
              name.length === 0 || reachedLimit ? 'disabled' : ''
            }`}
            disabled={reachedLimit}
            onClick={async () => {
              const hasTemperature =
                minTemperature !== '' && maxTemperature !== '';
              const hasHumidity = minHumidity !== '' && maxHumidity !== '';

              if (
                name.length === 0 ||
                length.length === 0 ||
                height.length === 0 ||
                width.length === 0
              ) {
                errorNotification(
                  'You must have a name, length, height, and width.',
                );

                return;
              }

              if (hasTemperature && +maxTemperature <= +minTemperature) {
                errorNotification(
                  'Max temperature must be greater then your minimum temperature.',
                );

                return;
              }

              if (hasHumidity && +maxHumidity <= +minHumidity) {
                errorNotification(
                  'Max humidity must be greater then your minimum humidity.',
                );

                return;
              }

              const enclosureParams: CreateEnclosureParams = {
                is_archived: false,
                is_public: isVisible,
                dimensions: `${length} x ${width} x ${height} ${enclosureUnits}`,
                name,
                ...(hasTemperature
                  ? { temperature: enclosureTemperature }
                  : {}),
                ...(hasHumidity ? { humidity: enclosureHumidity } : {}),
              };
              const enclosure = await createEnclosure(enclosureParams);
              const enclosureAction = {
                type: GET_ENCLOSURE,
                enclosureSingleAction: {
                  enclosure,
                },
              } as MainAction;

              dispatch(enclosureAction);
              setActiveTab(CreateEnclosureTabs.images);
            }}
          >
            Next
          </button>
        )}
      </div>
    </section>
  );
};
