export const openModal = (setFunc: (arg0: boolean) => void) => {
  return () => {
    setFunc(true);
  };
};

export const closeModal = (setFunc: (arg0: boolean) => void) => {
  return () => {
    setFunc(false);
  };
};
