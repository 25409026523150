import React, { useEffect, useState } from 'react';
import { AlignType } from 'rc-table/lib/interface';
import { Modal, Select, Table, Popconfirm } from 'antd';
import { LifeformCard } from 'cobranetics-types/types';

import { ReactComponent as DeleteIcon } from '../../icons/delete.svg';
import { ModalProps } from '../../typings/modal';
import { NamePreference, SelectListOption } from '../../typings/common';
import { NameInput } from '../input';
import {
  createFeedingScheduleGroup,
  deleteFeedingScheduleGroupByID,
  deleteFeedingScheduleGroupByName,
  getFeedingScheduleGroups,
  updateFeedingScheduleGroup,
} from '../../actions/feeding_schedule_group';
import { useMainContext } from '../../context/context';
import { successNotification } from '../notification';
import { DEFAULT_FEEDING_GROUP_ROWS_PER_PAGE } from '../../shared/constants';

export interface CreateGroupModalProps extends ModalProps {
  groups: { feeding_schedule_id: string; animalName: string }[];
  existingNames: string[];
  animalGroupOptions: SelectListOption[];
  setAnimalGroupOptions: (val: SelectListOption[]) => void;
  setSelectedAnimalOption: (val: SelectListOption) => void;
}

export interface EditGroupModalProps extends ModalProps {
  groups: { feeding_schedule_id: string; animalName: string }[];
  existingNames: string[];
  animalGroupOptions: SelectListOption[];
  selectedAnimalOption: SelectListOption;
  animals: LifeformCard[];
  setAnimalGroupOptions: (val: SelectListOption[]) => void;
  setSelectedAnimalOption: (val: SelectListOption) => void;
}

export const CreateGroupModal = (props: CreateGroupModalProps) => {
  const [data, setData] = useState(props.groups);
  const [name, setName] = useState('');

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      align: 'right' as AlignType,
    },
  ];
  const dataSource = data.map((row) => ({
    key: `${row.animalName}-${row.feeding_schedule_id}`,
    name: row.animalName,
    delete: (
      <button
        className="btn-transparent btn-delete"
        onClick={() => {
          const rows = data.filter(
            (item) => item.feeding_schedule_id !== row.feeding_schedule_id,
          );
          setData(rows);
        }}
      >
        <DeleteIcon className="delete-icon" />
      </button>
    ),
  }));

  useEffect(() => {
    setData(props.groups);
  }, [props.groups]);

  return (
    <Modal
      open={props.modalIsOpen}
      onCancel={props.closeModal}
      title="Create Group"
      className="create-modal create-edit-group-modal"
      footer={null}
    >
      <div className="modal-body">
        <NameInput
          name={name}
          setName={setName}
          placeholder="Name"
          inputName="group-name"
        />
        <div className="table-wrapper">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
        <button
          className="full btn-done"
          disabled={name.trim() === ''}
          onClick={async () => {
            if (!data.length) {
              props.closeModal();
            }

            if (!data.length || name.trim() === '') {
              return;
            }

            await createFeedingScheduleGroup({
              name,
              feeding_schedules: data.map((row) => row.feeding_schedule_id),
            });

            props.setAnimalGroupOptions([
              ...props.animalGroupOptions,
              {
                value: name,
                label: name,
              },
            ]);

            props.setSelectedAnimalOption({
              value: name,
              label: name,
            });

            props.closeModal();
          }}
        >
          Save
        </button>
      </div>
    </Modal>
  );
};

export const EditGroupModal = (props: EditGroupModalProps) => {
  const [mainState] = useMainContext();
  const [data, setData] = useState(props.groups);
  const [name, setName] = useState(props.selectedAnimalOption.value);
  const [animalOpts, setAnimalOpts] = useState(
    props.animals.map((row) => ({
      value: row.feeding_schedule_id,
      label: `${row.pet_name} (${
        mainState?.settings?.name_preference === NamePreference.scientific
          ? row.scientific_name
          : row.common_name
      })`,
    })),
  );
  const [selectedAnimalOpt, setSelectedAnimalOpt] = useState<string[]>([]);
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      align: 'right' as AlignType,
    },
  ];
  const dataSource = data.map((row) => ({
    key: `${row.animalName}-${row.feeding_schedule_id}`,
    name: row.animalName,
    delete: (
      <button
        className="btn-transparent btn-delete"
        onClick={async () => {
          const dataWithoutRow = data.filter(
            (item) => item.feeding_schedule_id !== row.feeding_schedule_id,
          );
          const fetchedItems = await getFeedingScheduleGroups({
            name: props.selectedAnimalOption.value,
            feeding_schedule_id: row.feeding_schedule_id,
          });

          if (!!fetchedItems.data.length) {
            await deleteFeedingScheduleGroupByID(fetchedItems.data[0].id);
          }

          if (!dataWithoutRow.length) {
            // Remove from main page animal group select list
            props.setAnimalGroupOptions([
              ...props.animalGroupOptions.filter(
                (innerRow) => innerRow.value !== row.animalName,
              ),
            ]);
            props.setSelectedAnimalOption({
              value: 'All',
              label: 'All',
            });
            props.closeModal();
          } else {
            setData(dataWithoutRow);
          }
        }}
      >
        <DeleteIcon className="delete-icon" />
      </button>
    ),
  }));

  useEffect(() => {
    setData(props.groups);
  }, [props.groups]);

  useEffect(() => {
    setName(props.selectedAnimalOption.value);
  }, [props.selectedAnimalOption]);

  useEffect(() => {
    setAnimalOpts(
      props.animals.map((row) => ({
        value: row.feeding_schedule_id,
        label: `${row.pet_name} (${
          mainState?.settings?.name_preference === NamePreference.scientific
            ? row.scientific_name
            : row.common_name
        })`,
      })),
    );
  }, [props.animals]);

  return (
    <Modal
      open={props.modalIsOpen}
      onCancel={props.closeModal}
      title="Edit Group"
      className="edit-modal create-edit-group-modal"
      footer={null}
    >
      <div className="modal-body">
        <NameInput
          name={name}
          setName={setName}
          placeholder="Name"
          inputName="group-name"
        />
        <div className="select-btn-wrapper">
          <Select
            mode="multiple"
            size="large"
            options={animalOpts}
            placeholder="Select animals"
            id="edit-animal-opts"
            value={selectedAnimalOpt}
            onChange={(row) => {
              setSelectedAnimalOpt(row);
            }}
          />
          <button
            className="full btn-done"
            onClick={async () => {
              const dataSourceFSIDs = props.groups.map(
                (row) => row.feeding_schedule_id,
              );
              const remainingIDs = selectedAnimalOpt.filter(
                (row) => !dataSourceFSIDs.includes(row),
              );

              if (!remainingIDs.length) {
                return;
              }

              const newGroups = await createFeedingScheduleGroup({
                name: props.selectedAnimalOption.value,
                feeding_schedules: remainingIDs,
              });
              const groupsToData = newGroups.map((row) => ({
                feeding_schedule_id: `${row.feeding_schedule_id}`,
                animalName: `${row.animal.pet_name} (${
                  mainState?.settings?.name_preference ===
                  NamePreference.scientific
                    ? row.animal.scientific_name
                    : row.animal.common_name
                })`,
              }));

              setData([...groupsToData, ...data]);
              setSelectedAnimalOpt([]);
            }}
          >
            Add
          </button>
        </div>

        <div className="table-wrapper">
          <Table
            dataSource={dataSource}
            columns={columns}
            pagination={{ hideOnSinglePage: true }}
          />
        </div>
        <div className="btn-wrapper">
          <Popconfirm
            title="Delete the task"
            description={`Are you sure to delete the group named '${props.selectedAnimalOption.value}'?`}
            okText="Yes"
            cancelText="No"
            onConfirm={async () => {
              await deleteFeedingScheduleGroupByName(
                props.selectedAnimalOption.value,
              );

              successNotification(
                `Successfully deleted group '${props.selectedAnimalOption.value}'`,
              );
            }}
          >
            <button className="full btn-delete">Delete</button>
          </Popconfirm>
          <button
            className="full btn-done"
            onClick={async () => {
              if (name !== props.selectedAnimalOption.value) {
                const groupRows = await getFeedingScheduleGroups({
                  name: props.selectedAnimalOption.value,
                  perPage: DEFAULT_FEEDING_GROUP_ROWS_PER_PAGE,
                });
                await updateFeedingScheduleGroup({
                  name,
                  ids: groupRows.data.map((row) => row.id),
                });
              }
              successNotification(`Successfully saved group '${name}'`);
            }}
          >
            Save
          </button>
        </div>
      </div>
    </Modal>
  );
};
