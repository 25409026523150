import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import Select from 'react-select';

import { ReactComponent as SearchIcon } from '../icons/search.svg';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ReactComponent as FilterIcon } from '../icons/filter.svg';
import { LoadingSpinner } from './loading';
import { getOption, toTitleCase } from '../shared/utils';
import { selectStyleSmall } from './input';
import { FilterTypeOptions, FullSearchResults } from '../typings/search';
import {
  AnimalsSearchResult,
  EnclosuresSearchResult,
  ImagesSearchResult,
  KeepersSearchResult,
  ObservationsSearchResult,
  SearchResult,
} from 'cobranetics-types/types/search';
import { Paths } from '../routes/paths';
import { REACT_APP_UI_BASE_PATH, SEARCH_TEMPLATE } from '../shared/constants';
import { fetchFullResults, resetFullResults } from '../actions/search';
import { useSearchParams } from 'react-router-dom';

export interface SearchProps {
  search: string;
  setSearch: (search: string) => void;
  placeholder?: string;
  quickTabs?: string[];
  activeTab?: string;
  setActiveTab?: Dispatch<SetStateAction<any>>;
  action?: () => void;
  showFilterButton?: boolean;
}

export interface SearchOptions {
  [key: string]: {
    url?: string;
    image?: string;
    title?: string;
    subTitle?: JSX.Element;
    action?: () => void;
    icon?: JSX.Element;
  }[];
}

export const formateSearchResults = (
  fullSearchResults: FullSearchResults,
): SearchOptions => {
  const searchResults = [];
  for (const key of Object.keys(fullSearchResults)) {
    searchResults.push(...fullSearchResults[key].data);
  }

  const searchOptionsUnfiltered: SearchOptions = {
    [FilterTypeOptions.animals]: [],
    [FilterTypeOptions.enclosures]: [],
    [FilterTypeOptions.feedings]: [],
    [FilterTypeOptions.images]: [],
    [FilterTypeOptions.inventory]: [],
    [FilterTypeOptions.keepers]: [],
    [FilterTypeOptions.observations]: [],
  };
  searchResults.forEach((row: SearchResult) => {
    switch ((row as any).filterTypeOption) {
      case FilterTypeOptions.animals:
        {
          const filterType: AnimalsSearchResult = row as any;
          searchOptionsUnfiltered[FilterTypeOptions.animals].push({
            url: `${REACT_APP_UI_BASE_PATH}${Paths.viewAnimal}/${filterType.id}`,
            image: filterType.image_url || '',
            title: filterType.pet_name || 'Unknown',
            subTitle: (
              <>
                <span>{`${filterType.common_name || 'Unknown'}`}</span>
                <i>{filterType.scientific_name || 'Unknown'}</i>
              </>
            ),
          });
        }
        break;
      case FilterTypeOptions.enclosures:
        {
          const filterType: EnclosuresSearchResult = row as any;
          searchOptionsUnfiltered[FilterTypeOptions.enclosures].push({
            url: `${REACT_APP_UI_BASE_PATH}${Paths.viewEnclosure}/${filterType.id}`,
            image: filterType.image_url || '',
            title: filterType.name,
            subTitle: <>{filterType.dimensions || undefined}</>,
          });
        }
        break;

      case FilterTypeOptions.images:
        {
          const filterType: ImagesSearchResult = row as any;
          const filterTypeItem = {
            url: '',
            image: filterType.image_url,
          };

          if (filterType.lifeform_id) {
            filterTypeItem.url = `${REACT_APP_UI_BASE_PATH}${Paths.viewLifeformGalleryRootPage}/${filterType.id}`;
          } else if (filterType.enclosure_id) {
            filterTypeItem.url = `${REACT_APP_UI_BASE_PATH}${Paths.viewEnclosureGalleryRootPage}/${filterType.id}`;
          }

          searchOptionsUnfiltered[FilterTypeOptions.images].push(
            filterTypeItem,
          );
        }
        break;
      case FilterTypeOptions.keepers:
        {
          const filterType: KeepersSearchResult = row as any;
          searchOptionsUnfiltered[FilterTypeOptions.keepers].push({
            url: `${REACT_APP_UI_BASE_PATH}${Paths.viewProfile}/${filterType.id}`,
            image: filterType.profile_image || '',
            title: filterType.username,
            subTitle: <>{filterType.name}</>,
          });
        }
        break;
      case FilterTypeOptions.observations:
        {
          const filterType: ObservationsSearchResult = row as any;
          searchOptionsUnfiltered[FilterTypeOptions.observations].push({
            url: `${REACT_APP_UI_BASE_PATH}${Paths.viewObservation}/${filterType.id}`,
            image: '',
            title: filterType.category,
            subTitle: <>{filterType.title}</>,
          });
        }
        break;
      default:
        break;
    }
  });

  const searchOptionFiltered: SearchOptions = {};
  Object.entries(searchOptionsUnfiltered).forEach(([key, val]) => {
    if (val.length > 0) {
      searchOptionFiltered[key] = val;
    }
  });

  return searchOptionFiltered;
};

export const SearchOptionsBody = (props: {
  searchOptions: SearchOptions;
  loadMore: boolean;
  search: string;
  filter: FilterTypeOptions | string;
}) => {
  return (
    <ul className="search-group-wrapper">
      <li className="search-opt">
        <a href={`?search=${encodeURI(props.search)}&filter=${props.filter}`}>
          <button className="view-btn">View Results</button>
        </a>
      </li>
      {Object.entries(props.searchOptions).map(([key, value], index) => (
        <li key={`search-group-${index}`}>
          <p className="label">{toTitleCase(key)}</p>
          <ul className={`search-group ${key}`}>
            {value.map((opt, innerIndex) => (
              <li key={`search-opt-${innerIndex}`} className="search-opt">
                {opt.url ? (
                  <a href={`${opt.url}`}>
                    {opt.image ? <img src={`${opt.image}`} /> : <></>}
                    {opt.icon ? (
                      <div className="icon-wrapper">{opt.icon}</div>
                    ) : (
                      <></>
                    )}
                    {!!opt.title || !!opt.subTitle ? (
                      <div className="title-wrapper">
                        {opt.title ? (
                          <p className="title">{opt.title}</p>
                        ) : (
                          <></>
                        )}
                        {opt.subTitle ? (
                          <p className="sub-title">{opt.subTitle}</p>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                  </a>
                ) : (
                  <div
                    className="action-no-url"
                    onClick={() => {
                      if (opt.action) {
                        opt.action();
                      }
                    }}
                  >
                    {opt.image ? <img src={`${opt.image}`} /> : <></>}
                    {opt.icon ? (
                      <div className="icon-wrapper">{opt.icon}</div>
                    ) : (
                      <></>
                    )}
                    <div className="title-wrapper">
                      {opt.title ? <p className="title">{opt.title}</p> : <></>}
                      {opt.subTitle ? (
                        <p className="sub-title">{opt.subTitle}</p>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        </li>
      ))}
      {props.loadMore ? <LoadingSpinner show={true} /> : <></>}
    </ul>
  );
};

export const SearchWrapper = (props: SearchProps): JSX.Element => {
  const firstUpdate = useRef(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const [inputValue, setInputValue] = useState(props.search);
  const [inFocus, setInFocus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SearchOptions>({});
  const filterTypeOptions = [
    { value: FilterTypeOptions.all, label: 'All' },
    { value: FilterTypeOptions.animals, label: 'Animals' },
    { value: FilterTypeOptions.enclosures, label: 'Enclosures' },
    { value: FilterTypeOptions.images, label: 'Images' },
    { value: FilterTypeOptions.inventory, label: 'Inventory' },
    { value: FilterTypeOptions.keepers, label: 'Keepers' },
    { value: FilterTypeOptions.observations, label: 'Observation' },
  ];
  const [filterTypeOpt, setFilterTypeOpt] = useState(
    getOption(
      props.activeTab || filterTypeOptions[0].value,
      filterTypeOptions,
    ) || filterTypeOptions[0],
  );

  const [currentPage, setCurrentPage] = useState(1);
  const [fullSearchResults, setFullSearchResults] = useState<FullSearchResults>(
    { ...SEARCH_TEMPLATE },
  );
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    const timer = setTimeout(async () => {
      props.setSearch(inputValue);

      if (!inputValue.trim().length) {
        resetFullResults(fullSearchResults);

        setCurrentPage(1);
        setLoadMore(false);
        setFullSearchResults(fullSearchResults);

        // Prevent fetching data when search is empty
        return;
      }

      if (props.search !== inputValue) {
        resetFullResults(fullSearchResults);

        setCurrentPage(1);
        setFullSearchResults(fullSearchResults);
      }

      const page = props.search !== inputValue ? 1 : currentPage;
      const { currentSearchResults } = await fetchFullResults(
        fullSearchResults,
        inputValue,
        page,
        filterTypeOpt.value,
      );

      setCurrentPage(page + 1);
      setFullSearchResults(currentSearchResults);
      setSearchOptions({
        ...formateSearchResults(currentSearchResults),
      });

      setIsLoading(false);
      setLoadMore(false);
    }, 750);

    return () => clearTimeout(timer);
  }, [inputValue, inFocus]);

  useEffect(() => {
    if (!loadMore) {
      return;
    }

    const loadData = async () => {
      const { currentSearchResults } = await fetchFullResults(
        fullSearchResults,
        inputValue,
        currentPage,
        filterTypeOpt.value,
      );

      setCurrentPage(currentPage + 1);
      setFullSearchResults(currentSearchResults);
      setSearchOptions({
        ...formateSearchResults(currentSearchResults),
      });

      setIsLoading(false);
      setLoadMore(false);
    };

    loadData();
  }, [loadMore]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      setLoadMore(true);
    }
  };

  return (
    <>
      <div className={`search-wrapper ${inFocus ? 'in-focus' : ''}`}>
        <div
          className="overlay-bkg"
          onClick={() => {
            setInFocus(false);
            setShowFilters(false);
          }}
        ></div>
        <div className="input-wrapper">
          <SearchIcon className="search-icon" />
          <input
            type="text"
            name="search"
            id="search"
            placeholder={`${props.placeholder ?? 'Search'}`}
            value={inputValue}
            onChange={(e) => {
              setIsLoading(e.target.value !== inputValue);
              setInputValue(e.target.value);
            }}
            autoComplete="off"
            onFocus={() => {
              setInFocus(true);
            }}
          />
          {inputValue !== '' ? (
            <button
              className="btn-transparent"
              onClick={() => {
                setInputValue('');
                setShowFilters(false);
                setInFocus(false);
                props.setSearch('');

                // remove ?search
                setSearchParams((params) => {
                  params.delete('search');
                  return params;
                });

                if (props.action) props.action();
              }}
            >
              <CloseIcon className="close-icon" />
            </button>
          ) : (
            <>
              {props.showFilterButton !== false ? (
                <button
                  className="btn-transparent"
                  onClick={() => {
                    setShowFilters(!showFilters);
                    setInFocus(true);
                  }}
                >
                  <FilterIcon className="filter-icon" />
                </button>
              ) : (
                <></>
              )}
            </>
          )}
        </div>

        {inFocus && inputValue !== '' ? (
          <div className="search-options" onScroll={handleScroll}>
            {isLoading ? (
              <LoadingSpinner show={isLoading} />
            ) : (
              <SearchOptionsBody
                searchOptions={searchOptions}
                loadMore={loadMore}
                search={inputValue}
                filter={filterTypeOpt.value}
              />
            )}
          </div>
        ) : (
          <></>
        )}
        {showFilters ? (
          <div className="additional-filters">
            <div className="top-title">
              <div className="left">
                <FilterIcon className="filter-icon" />
                <p>Filter</p>
              </div>
              <div className="right">
                <button
                  className="btn-transparent"
                  onClick={() => {
                    setShowFilters(false);
                  }}
                >
                  <CloseIcon className="close-icon" />
                </button>
              </div>
            </div>
            <div className="title-input-wrapper filter-type-input-wrapper">
              <div className="title-clear-wrapper">
                <p className="title">Category</p>
                {props.activeTab !== FilterTypeOptions.all ? (
                  <button
                    className="btn-transparent"
                    onClick={() => {
                      setFilterTypeOpt(filterTypeOptions[0]);

                      if (props.setActiveTab) {
                        props.setActiveTab(FilterTypeOptions.all);
                      }
                    }}
                  >
                    clear
                  </button>
                ) : (
                  <></>
                )}
              </div>
              <Select
                name="filter-type"
                id="filter-type"
                styles={selectStyleSmall}
                options={filterTypeOptions}
                value={filterTypeOpt}
                onChange={(val) => {
                  if (val && val.value !== filterTypeOpt.value) {
                    setFilterTypeOpt(val);

                    if (props.setActiveTab) {
                      props.setActiveTab(val.value);
                    }
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};
