/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Select, Table } from 'antd';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { useMainContext } from '../../context/context';
import { MainAction } from '../../typings/main_context_reducer';
import { CHECK } from '../../actions';
import { getCheck } from '../../actions/check';
import { FailedAuthCheck } from '../../shared/errors';
import { DateInput, NameInput } from '../../components/input';
import { NamePreference, SelectListOption } from '../../typings/common';
import {
  FetchFeedingScheduleGroupsParams,
  getFeedingScheduleGroups,
} from '../../actions/feeding_schedule_group';
import {
  formateDateSimple,
  getOption,
  randomTextGenerator,
} from '../../shared/utils';
import {
  CreateGroupModal,
  EditGroupModal,
} from '../../components/modals/create-edit-group';
import { getAnimalCards } from '../../actions/animals';
import { LifeformCard } from 'cobranetics-types/types';
import { DEFAULT_FEEDING_GROUP_ROWS_PER_PAGE } from '../../shared/constants';
import {
  errorNotification,
  successNotification,
} from '../../components/notification';
import { createFeeding } from '../../actions/feeding';
import { formateDateTimeForAPI } from '../../utils/dates';

interface AnimalRow {
  name: any;
  ate: any;
  meal: any;
  lastFed: string;
  animalName: string;
  feedingScheduleID: number;
  groupID: string;
}

interface AnimalInput {
  ate: SelectListOption;
  meal: string;
  date: string;
  feedingScheduleID: string;
}

export const AddFeedingScheduleGroup = (): JSX.Element => {
  const currentDate = new Date().toISOString().split('T')[0];
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const [isBusy, setBusy] = useState(true);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);

  const [animalGroupOptions, setAnimalGroupOptions] = useState<
    SelectListOption[]
  >([{ value: 'All', label: 'All' }]);
  const ateOptions: SelectListOption[] = [
    { value: 'Not Yet', label: 'Not Yet' },
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
  ];

  const [
    selectedAnimalOption,
    setSelectedAnimalOption,
  ] = useState<SelectListOption>(animalGroupOptions[0]);
  const [animals, setAnimals] = useState<AnimalRow[]>([]);
  const [allAnimals, setAllAnimals] = useState<LifeformCard[]>([]);
  const [selectedAteOption, setSelectedAteOption] = useState<SelectListOption>(
    ateOptions[0],
  );
  const [animalInputs, setAnimalInputs] = useState<AnimalInput[]>([]);
  const [selectedRows, setSelectedRows] = useState<
    { feeding_schedule_id: string; animalName: string }[]
  >([]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ate',
      dataIndex: 'ate',
      key: 'ate',
      render: (text: any, record: any) => (
        <Select
          options={ateOptions}
          id={`ateOptions-${record?.id || randomTextGenerator(8)}`}
          value={animalInputs[record.index]?.ate.value || ateOptions[0].value}
          onChange={(row) => {
            const targetInputFields = [...animalInputs];
            targetInputFields[record.index].ate =
              getOption(row, ateOptions) || ateOptions[0];
            setAnimalInputs(targetInputFields);
          }}
        />
      ),
    },
    {
      title: 'Meal',
      dataIndex: 'meal',
      key: 'meal',
      render: (text: any, record: any) => {
        return (
          <input
            className="meal-input"
            type="text"
            value={animalInputs[record.index]?.meal || ''}
            onChange={(e) => {
              const targetInputFields = [...animalInputs];
              targetInputFields[record.index].meal = e.target.value;
              setAnimalInputs(targetInputFields);
            }}
          />
        );
      },
    },
    {
      title: 'Feed On',
      dataIndex: 'date',
      key: 'date-feed-on',
      render: (text: any, record: any) => {
        return (
          <input
            type="date"
            className="date-input"
            value={animalInputs[record.index]?.date || ''}
            onChange={(e) => {
              const targetInputFields = [...animalInputs];
              targetInputFields[record.index].date = e.target.value;
              setAnimalInputs(targetInputFields);
            }}
          />
        );
      },
    },
    {
      title: 'Last Fed',
      dataIndex: 'lastFed',
      key: 'lastFed',
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: AnimalRow[]) => {
      setSelectedRows(
        selectedRows.map((row) => ({
          feeding_schedule_id: `${row.feedingScheduleID}`,
          animalName: row.animalName,
        })),
      );
    },
    getCheckboxProps: (record: AnimalRow) => ({
      name: record.name,
    }),
  };
  const [date, setDate] = useState(currentDate);
  const [meal, setMeal] = useState('');

  const existingNames = animalGroupOptions.map((row) => row.label);
  const createModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenCreateModal(false);
    },
    modalIsOpen: openCreateModal,
    groups: selectedRows, // selected rows
    existingNames,
    animalGroupOptions,
    setAnimalGroupOptions,
    setSelectedAnimalOption,
  };
  const editModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenEditModal(false);
    },
    modalIsOpen: openEditModal,
    groups: animals.map((row) => ({
      feeding_schedule_id: `${row.feedingScheduleID}`,
      animalName: row.animalName,
    })),
    existingNames,
    animalGroupOptions,
    setAnimalGroupOptions,
    setSelectedAnimalOption,
    selectedAnimalOption,
    animals: allAnimals,
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);

        const feedingGroupParams: FetchFeedingScheduleGroupsParams = {
          currentPage: 1,
          perPage: DEFAULT_FEEDING_GROUP_ROWS_PER_PAGE,
        };
        const groups = await getFeedingScheduleGroups(feedingGroupParams);
        const groupByCategory = groups.data.reduce(
          (group: any, product: any) => {
            const { name } = product;
            group[name] = group[name] ?? [];
            group[name].push(product);
            return group;
          },
          {},
        );
        const groupNames = Object.keys(groupByCategory);

        // Fetch all animals
        let animalRows: {
          key: string;
          index: number;
          groupID: string;
          feedingScheduleID: string;
          animalName: string;
          name: any;
          meal: string;
          ate: any;
          lastFed: string;
        }[] = [];

        if (selectedAnimalOption.value === 'All') {
          const animalParams = {
            currentPage: 1,
            perPage: DEFAULT_FEEDING_GROUP_ROWS_PER_PAGE,
            sort_by: '-id',
            account_id: check.account.id,
            include_prev_feed: true,
          };
          const animals = await getAnimalCards(animalParams);
          setAllAnimals(animals.data);
          animalRows = animals.data.map((row, index) => ({
            key: `animal-rows-${row.id}-${row.pet_name}`,
            index,
            groupID: row.id,
            feedingScheduleID: row.feeding_schedule_id || '',
            animalName: `${row.pet_name} (${
              check.settings.name_preference === NamePreference.scientific
                ? row.scientific_name
                : row.common_name
            })`,
            name: (
              <div>
                <p>{row.pet_name}</p>
                <p>{` (${
                  check.settings.name_preference === NamePreference.scientific
                    ? row.scientific_name
                    : row.common_name
                })`}</p>
              </div>
            ),
            meal: '',
            ate: ateOptions[0],
            lastFed: row.prev_feed_on
              ? formateDateSimple(row.prev_feed_on)
              : '-',
          }));
        } else {
          animalRows = groups.data.map((row, index) => ({
            key: `animal-rows-${row.id}-${row.animal.pet_name}`,
            index,
            groupID: row.id,
            feedingScheduleID: row.feeding_schedule_id,
            animalName: `${row.animal.pet_name} (${
              check.settings.name_preference === NamePreference.scientific
                ? row.animal.scientific_name
                : row.animal.common_name
            })`,
            name: (
              <div>
                <p>{row.animal.pet_name}</p>
                <p>{` (${
                  check.settings.name_preference === NamePreference.scientific
                    ? row.animal.scientific_name
                    : row.animal.common_name
                })`}</p>
              </div>
            ),
            meal: '',
            ate: ateOptions[0],
            lastFed: row.prev_feed_on
              ? formateDateSimple(row.prev_feed_on)
              : '-',
          }));
        }
        const initialAnimalInputs = animalRows.map((row) => ({
          ate: row.ate,
          meal: '',
          feedingScheduleID: row.feedingScheduleID,
          date: currentDate,
        }));

        setAnimalGroupOptions([
          animalGroupOptions[0],
          ...groupNames.map((row) => ({ value: row, label: row })),
        ]);
        setAnimals(animalRows as any[]);
        setAnimalInputs(initialAnimalInputs);
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (!mainState.account?.id) {
      return;
    }

    const loadData = async () => {
      // Fetch all animals
      let animalRows: {
        key: string;
        index: number;
        groupID: string;
        feedingScheduleID: string;
        animalName: string;
        name: any;
        meal: string;
        ate: any;
        lastFed: string;
      }[] = [];
      const feedingGroupParams: FetchFeedingScheduleGroupsParams = {
        currentPage: 1,
        perPage: DEFAULT_FEEDING_GROUP_ROWS_PER_PAGE,
        ...(selectedAnimalOption.label !== 'All'
          ? { name: selectedAnimalOption.value }
          : {}),
      };
      const groups = await getFeedingScheduleGroups(feedingGroupParams);

      if (selectedAnimalOption.value === 'All') {
        animalRows = allAnimals.map((row, index) => ({
          key: `animal-rows-${row.id}-${row.pet_name}`,
          index,
          groupID: row.id,
          feedingScheduleID: row.feeding_schedule_id || '',
          animalName: `${row.pet_name} (${
            mainState?.settings?.name_preference === NamePreference.scientific
              ? row.scientific_name
              : row.common_name
          })`,
          name: (
            <div>
              <p>{row.pet_name}</p>
              <p>{` (${
                mainState?.settings?.name_preference ===
                NamePreference.scientific
                  ? row.scientific_name
                  : row.common_name
              })`}</p>
            </div>
          ),
          meal: '',
          ate: ateOptions[0],
          lastFed: row.prev_feed_on ? formateDateSimple(row.prev_feed_on) : '-',
        }));
      } else {
        animalRows = groups.data.map((row, index) => ({
          key: `animal-rows-${row.id}-${row.animal.pet_name}`,
          index,
          groupID: row.id,
          feedingScheduleID: row.feeding_schedule_id,
          animalName: `${row.animal.pet_name} (${
            mainState?.settings?.name_preference === NamePreference.scientific
              ? row.animal.scientific_name
              : row.animal.common_name
          })`,
          name: (
            <div>
              <p>{row.animal.pet_name}</p>
              <p>{` (${
                mainState?.settings?.name_preference ===
                NamePreference.scientific
                  ? row.animal.scientific_name
                  : row.animal.common_name
              })`}</p>
            </div>
          ),
          meal: '',
          ate: ateOptions[0],
          lastFed: row.prev_feed_on ? formateDateSimple(row.prev_feed_on) : '-',
        }));
      }
      const initialAnimalInputs = animalRows.map((row) => ({
        ate: row.ate,
        meal: '',
        feedingScheduleID: row.feedingScheduleID,
        date: currentDate,
      }));

      setAnimals(animalRows as any[]);
      setAnimalInputs(initialAnimalInputs);
    };
    loadData();
  }, [selectedAnimalOption]);

  return (
    <div className="add-feeding-schedule-group">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="add-feeding-schedule-group-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title={`Add Feedings`}
                showBackBtn={true}
                activeTab={''}
              />
              <section className="common-section">
                <div className="title-input-wrapper">
                  <p className="title">Animal Groups</p>
                  <div className="dual-input-wrapper">
                    <Select
                      size="large"
                      options={animalGroupOptions}
                      placeholder="Select an animal"
                      id="animalGroupOptions"
                      value={selectedAnimalOption.value}
                      onChange={(row: string) => {
                        setSelectedAnimalOption(
                          getOption(row, animalGroupOptions) ||
                            animalGroupOptions[0],
                        );
                      }}
                    />
                    {animalGroupOptions[0].value ===
                    selectedAnimalOption.value ? (
                      <button
                        disabled={!selectedRows.length}
                        className="btn create-group-btn"
                        onClick={() => setOpenCreateModal(true)}
                      >
                        Create Group
                      </button>
                    ) : (
                      <button
                        className="btn edit-group-btn"
                        onClick={() => setOpenEditModal(true)}
                      >
                        Edit Group
                      </button>
                    )}
                  </div>
                </div>
                <div className="table-wrapper">
                  <Table
                    rowSelection={{
                      type: 'checkbox',
                      ...rowSelection,
                    }}
                    dataSource={[...animals]}
                    columns={columns}
                    pagination={{ hideOnSinglePage: true }}
                  />
                </div>
                <div className="triple-input-wrapper">
                  <div className="title-input-wrapper">
                    <p className="title">Ate</p>
                    <Select
                      size="large"
                      options={ateOptions}
                      id="ateOptions"
                      value={selectedAteOption.value}
                      onChange={(val) => {
                        const updatedAnimalRows: AnimalInput[] = [];
                        const ateOpt =
                          getOption(val, ateOptions) || ateOptions[0];
                        animals.forEach((row, index) => {
                          const isChecked = selectedRows
                            .map((sR) => sR.feeding_schedule_id)
                            .includes(`${row.feedingScheduleID}`);

                          if (isChecked) {
                            updatedAnimalRows.push({
                              ...row,
                              ate: ateOpt,
                              feedingScheduleID: `${row.feedingScheduleID}`,
                              meal: animalInputs[index].meal,
                              date: animalInputs[index].date,
                            });
                          } else {
                            updatedAnimalRows.push({
                              ...row,
                              ate: animalInputs[index].ate,
                              feedingScheduleID: `${row.feedingScheduleID}`,
                              meal: animalInputs[index].meal,
                              date: animalInputs[index].date,
                            });
                          }
                        });

                        setAnimalInputs(updatedAnimalRows);
                        setSelectedAteOption(ateOpt);
                      }}
                    />
                  </div>
                  <div className="title-input-wrapper">
                    <p className="title">Meal</p>
                    <NameInput
                      name={meal}
                      setName={setMeal}
                      placeholder=""
                      onChange={(value) => {
                        const updatedAnimalRows: AnimalInput[] = [];

                        animals.forEach((row, index) => {
                          const isChecked = selectedRows
                            .map((sR) => sR.feeding_schedule_id)
                            .includes(`${row.feedingScheduleID}`);

                          if (isChecked) {
                            updatedAnimalRows.push({
                              ...row,
                              ate: animalInputs[index].ate,
                              feedingScheduleID: `${row.feedingScheduleID}`,
                              meal: value,
                              date: animalInputs[index].date,
                            });
                          } else {
                            updatedAnimalRows.push({
                              ...row,
                              ate: animalInputs[index].ate,
                              feedingScheduleID: `${row.feedingScheduleID}`,
                              meal: animalInputs[index].meal,
                              date: animalInputs[index].date,
                            });
                          }
                        });

                        setAnimalInputs(updatedAnimalRows);
                      }}
                    />
                  </div>
                  <div className="title-input-wrapper">
                    <p className="title">Date</p>
                    <DateInput
                      date={date}
                      setDate={setDate}
                      placeholder={''}
                      inputName={'Date'}
                      onChange={(value) => {
                        const updatedAnimalRows: AnimalInput[] = [];

                        animals.forEach((row, index) => {
                          const isChecked = selectedRows
                            .map((sR) => sR.feeding_schedule_id)
                            .includes(`${row.feedingScheduleID}`);

                          if (isChecked) {
                            updatedAnimalRows.push({
                              ...row,
                              ate: animalInputs[index].ate,
                              feedingScheduleID: `${row.feedingScheduleID}`,
                              meal: animalInputs[index].meal,
                              date: value,
                            });
                          } else {
                            updatedAnimalRows.push({
                              ...row,
                              ate: animalInputs[index].ate,
                              feedingScheduleID: `${row.feedingScheduleID}`,
                              meal: animalInputs[index].meal,
                              date: animalInputs[index].date,
                            });
                          }
                        });

                        setAnimalInputs(updatedAnimalRows);
                      }}
                    />
                  </div>
                </div>
                <button
                  className="btn-done main-done-btn"
                  onClick={async () => {
                    const animalsWithMeals = animalInputs.filter(
                      (row) => !!row.meal,
                    );

                    if (!animalsWithMeals.length) {
                      errorNotification(
                        'At least one animal needs to have a meal before saving.',
                      );
                      return;
                    }

                    for (const animalInput of animalsWithMeals) {
                      let ate = null;

                      if (animalInput.ate.value === 'Yes') {
                        ate = true;
                      } else if (animalInput.ate.value === 'No') {
                        ate = false;
                      }

                      const preFeedOn = new Date(
                        `${animalInput.date} ${
                          new Date().toISOString().split('T')[1]
                        }`,
                      );
                      const feedOn = formateDateTimeForAPI(preFeedOn);

                      await createFeeding({
                        feed_on: feedOn,
                        meal: animalInput.meal,
                        ate,
                        feeding_schedule_id: animalInput.feedingScheduleID,
                      });
                    }

                    window.location.reload();
                  }}
                >
                  Save
                </button>
              </section>
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}

      <CreateGroupModal {...createModalProps} />
      <EditGroupModal {...editModalProps} />
    </div>
  );
};
