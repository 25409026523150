import { ListOutput } from '../actions';
import {
  EnclosureCard as EnclosureCardModel,
  Enclosure as EnclosureModel,
} from 'cobranetics-types/types';
import { SelectListOption } from './common';

export interface EnclosureAction {
  enclosures: ListOutput<EnclosureCardModel>;
}

export interface EnclosureSingleAction {
  enclosure: EnclosureModel;
}

export interface CreateEnclosureParams {
  is_archived: boolean;
  is_public: boolean;
  dimensions: string | null;
  name: string;
  temperature?: string;
  humidity?: string;
}

export enum CreateEnclosureTabs {
  general = 'General',
  images = 'Images',
}

export interface GeneralProps {
  name: string;
  setName: (name: string) => void;
  length: string;
  setLength: (length: string) => void;
  width: string;
  setWidth: (width: string) => void;
  height: string;
  setHeight: (height: string) => void;
  isMetric: boolean;
  enclosureUnits: string;
  setEnclosureUnits: (units: string) => void;
  setActiveTab: (tab: CreateEnclosureTabs) => void;
  isEdit: boolean;
  enclosureID: string;
  navigate: any;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  temperature: string;
  setTemperature: (temperature: string) => void;
  temperatureUnits: SelectListOption;
  setTemperatureUnits: (unit: SelectListOption) => void;
  humidity: string;
  setHumidity: (temperature: string) => void;
  humidityUnits: SelectListOption;
  setHumidityUnits: (unit: SelectListOption) => void;
  setOpenModal: (openModal: boolean) => void;
}

export interface ImageProps {
  enclosureID: string;
  images: { id: string; src: string }[];
  activeImage: string;
  setImages: (images: { id: string; src: string }[]) => void;
  setActiveImage: (length: string) => void;
  navigate: any;
  imageCount: number;
}
