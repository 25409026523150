/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  LifeformCard as LifeformCardModel,
  FeedingScheduleCard as FeedingScheduleCardModel,
  EnclosureCard as EnclosureCardProp,
  LifeformAttribute,
  ObservationCard,
  LifeformGallery,
  Enclosure,
  EnclosureCard,
} from 'cobranetics-types/types';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { ArchiveOnCard } from '../../components/archive';
import { useMainContext } from '../../context/context';
import { MainAction } from '../../typings/main_context_reducer';
import {
  FetchFeedingSchedulesParams,
  getFeedingScheduleCards,
} from '../../actions/feeding_schedule';
import { deleteLifeform, getAnimalCards } from '../../actions/animals';
import { CHECK, ListOutput } from '../../actions';
import {
  DEFAULT_IMAGES,
  DEFAULT_IMAGES_PER_PAGE,
} from '../../shared/constants';
import { FavoriteLifeformButton } from '../../components/favorites';
import { Paths } from '../../routes/paths';
import { authRefresh } from '../../actions/check';
import { FailedAuthCheck } from '../../shared/errors';
import { getAnimalMenus } from '../../shared/menu';
import { getEnclosureCards } from '../../actions/enclosure';
import { formateDateSimple } from '../../shared/utils';
import { ReactComponent as OpenIcon } from '../../icons/open.svg';
import { getPeople } from '../../actions/person';
import { DeleteModal } from '../../components/modals/delete';
import { getLifeformAttributes } from '../../actions/lifeform-attribute';
import { Attribute } from '../../typings/attribute';
import { AttributeCard } from '../../components/attributes';
import { getObservationCards } from '../../actions/observation';
import { ObservationCard as ObservationTile } from '../../components/observation';
import { getLifeformGalleryCards } from '../../actions/gallery';
import { GalleryCard } from '../../components/gallery';
import { errorNotification } from '../../components/notification';

export const ViewAnimalItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');

  const [isBusy, setBusy] = useState(true);
  const [animal, setAnimal] = useState({} as LifeformCardModel);
  const [feedingSchedule, setFeedingSchedule] = useState(
    {} as FeedingScheduleCardModel,
  );
  const [isFavoriteLifeform, setIsFavoriteLifeform] = useState(false);
  const [favoriteLifeformID, setFavoriteLifeformID] = useState('');
  const [enclosure, setEnclosure] = useState({} as EnclosureCardProp);
  const [username, setUsername] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [lifeformAttributes, setLifeformAttributes] = useState<
    LifeformAttribute[]
  >([]);
  const [enclosureAttributes, setEnclosureAttributes] = useState<Attribute[]>(
    [],
  );
  const [feedingScheduleAttributes, setFeedingScheduleAttributes] = useState<
    Attribute[]
  >([]);
  const [observations, setObservations] = useState<ObservationCard[]>([]);
  const [lifeformImages, setLifeformImages] = useState<LifeformGallery[]>([]);
  const [hasMoreImages, setHasMoreImages] = useState(false);
  const [loadMoreImages, setLoadMoreImages] = useState(false);
  const [currentImagePage, setCurrentImagePage] = useState(1);

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteLifeform(`${pageID}`);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'animal',
    name:
      animal.pet_name || animal.common_name || animal.scientific_name || '?',
  };
  const commonRowMenus = getAnimalMenus(
    navigate,
    paramsID ?? '',
    mainState?.account?.id === animal?.account_id,
    username,
    () => {
      setOpenModal(true);
    },
  );

  if (Number.isNaN(pageID)) {
    errorNotification('Page ID in the URL must be a number');
  }

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;
        dispatch(checkAction);

        if (Number.isNaN(pageID)) {
          return;
        }

        const feedingScheduleParams: FetchFeedingSchedulesParams = {
          currentPage: 1,
          perPage: 20,
          sort_by: '-id',
          lifeform_ids: `${pageID}`,
        };

        const feedingSchedule = await getFeedingScheduleCards(
          feedingScheduleParams,
        );

        if (feedingSchedule.data.length > 0) {
          setFeedingSchedule(feedingSchedule.data[0]);
        }
        const animalData = (await getAnimalCards({ ids: paramsID ?? '' }))
          .data[0];

        if (!animalData) {
          return;
        }

        const person = (await getPeople({ account_id: animalData.account_id }))
          .data[0];
        let enclosures: ListOutput<EnclosureCard> = {
          data: [],
          nextPage: '',
          prevPage: '',
        };

        if (animalData.enclosure_id) {
          enclosures = await getEnclosureCards({
            ids: `${animalData.enclosure_id}`,
          });
        }

        const observationCards = await getObservationCards({
          lifeform_id: `${pageID}`,
          perPage: 100,
          currentPage: 1,
        });

        const lifeformImageList = await getLifeformGalleryCards({
          lifeform_ids: `${pageID}`,
          perPage: DEFAULT_IMAGES_PER_PAGE,
          currentPage: currentImagePage,
        });

        // Fetching Attributes
        const attributesLifeform = await getLifeformAttributes({
          lifeform_id: `${pageID}`,
          perPage: 100,
        });
        const attributesEnclosure: Attribute[] = [];
        const attributesFeedingSchedule: Attribute[] = [];

        if (feedingSchedule.data[0]?.last_feed_on) {
          attributesFeedingSchedule.push(
            {
              keyAttribute: 'Fed On',
              value: feedingSchedule.data[0]?.last_feed_on
                ? formateDateSimple(feedingSchedule.data[0]?.last_feed_on)
                : 'Unknown',
            },
            {
              keyAttribute: 'Previous Meal',
              value: feedingSchedule.data[0]?.last_meal ?? '',
            },
            {
              keyAttribute: 'Ate Previous Meal',
              value: feedingSchedule.data[0]?.ate ? 'Yes' : 'No',
            },
          );
        }

        enclosures.data.forEach((row) => {
          if (row.dimensions) {
            attributesEnclosure.push({
              keyAttribute: 'Dimensions',
              value: row.dimensions,
            });
          }

          if (row.humidity) {
            attributesEnclosure.push({
              keyAttribute: 'Humidity',
              value: row.humidity,
            });
          }

          if (row.temperature) {
            attributesEnclosure.push({
              keyAttribute: 'Temperature',
              value: row.temperature,
            });
          }
        });

        attributesLifeform.data.push({
          key: `Sex`,
          value: animalData.sex,
          created_on: '',
          updated_on: '',
          lifeform_id: `${pageID}`,
          id: '',
        });

        attributesLifeform.data.push({
          key: `Age`,
          value: animalData.dob
            ? formateDateSimple(animalData.dob, 'old')
            : 'Unknown',
          created_on: '',
          updated_on: '',
          lifeform_id: `${pageID}`,
          id: '',
        });

        setEnclosure(enclosures.data[0] || {});
        setAnimal(animalData);
        setUsername(person.username);
        setLifeformAttributes(attributesLifeform.data);
        setEnclosureAttributes(attributesEnclosure);
        setFeedingScheduleAttributes(attributesFeedingSchedule);
        setObservations(observationCards.data);
        setLifeformImages(lifeformImageList.data);
        setHasMoreImages(!!lifeformImageList.nextPage.length);
        setCurrentImagePage(currentImagePage + 1);
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.error(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (!hasMoreImages || !loadMoreImages) {
        return;
      }

      // Fetch more images
      const lifeformImageList = await getLifeformGalleryCards({
        lifeform_ids: `${pageID}`,
        perPage: DEFAULT_IMAGES_PER_PAGE,
        currentPage: currentImagePage,
      });

      setLifeformImages([...lifeformImages, ...lifeformImageList.data]);
      setCurrentImagePage(currentImagePage + 1);
      setLoadMoreImages(false);
      setHasMoreImages(
        lifeformImageList.data.length === DEFAULT_IMAGES_PER_PAGE,
      );
    };

    loadData();
  }, [loadMoreImages]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      setLoadMoreImages(true);
    }
  };

  return (
    <div className="view-animal">
      <Header hideLinks={false} title={'Animal'} />
      {!isBusy ? (
        <div className="desktop-wrapper full-width">
          <section
            className="view-animal-background animal-item-background"
            onScroll={(e: any) => {
              handleScroll(e);
            }}
          >
            <PageHeader
              title={animal.pet_name ?? 'Pet Name'}
              subTitle={animal.scientific_name ?? 'Scientific Name'}
              showBackBtn={true}
              activeTab={''}
              menu={commonRowMenus.mainMenu}
            />
            <section className="view-animal-item">
              <div className="main-info">
                <div className="image-wrapper">
                  {animal.is_archived ? (
                    <ArchiveOnCard is_archived={animal.is_archived} />
                  ) : (
                    <></>
                  )}

                  {mainState.account?.id ? (
                    <FavoriteLifeformButton
                      {...{
                        isFavoriteLifeform,
                        favoriteLifeformID,
                        lifeformID: animal.id,
                        accountID: mainState.account?.id ?? 0,
                        setIsFavoriteLifeform,
                        setFavoriteLifeformID,
                      }}
                    />
                  ) : (
                    <></>
                  )}

                  <img
                    src={animal.image_url || DEFAULT_IMAGES.animal}
                    alt=""
                    className="display-image"
                  />
                </div>
              </div>

              {!!lifeformAttributes.length && (
                <section className="attributes-wrapper">
                  <h5 className="title">Characteristics</h5>
                  <div className="attributes-inner-wrapper">
                    {lifeformAttributes.map((row) => (
                      <AttributeCard
                        key={`${row.key?.replaceAll(
                          ' ',
                          '',
                        )}-${row.value?.replaceAll(' ', '')}`}
                        keyAttribute={row.key}
                        value={row.value}
                      />
                    ))}
                  </div>
                </section>
              )}

              {!!enclosureAttributes.length && (
                <section className="attributes-wrapper">
                  <h5 className="title">
                    <a
                      className="open-icon-link"
                      href={`${Paths.viewEnclosure}/${enclosure.id}`}
                    >
                      Enclosure
                      <OpenIcon className="open-icon" />
                    </a>
                  </h5>
                  {enclosure.image_url ? (
                    <img src={enclosure.image_url} className="sub-view-image" />
                  ) : (
                    <></>
                  )}
                  <div className="attributes-inner-wrapper">
                    {enclosureAttributes.map((row) => (
                      <AttributeCard
                        key={`${row.keyAttribute?.replaceAll(
                          ' ',
                          '',
                        )}-${row.value?.replaceAll(' ', '')}`}
                        keyAttribute={row.keyAttribute}
                        value={row.value}
                      />
                    ))}
                  </div>
                </section>
              )}

              {!!feedingScheduleAttributes.length && (
                <section className="attributes-wrapper">
                  <h5 className="title">
                    <a
                      className="open-icon-link"
                      href={`${Paths.viewFeedingSchedule}/${feedingSchedule.id}`}
                    >
                      Feeding Schedule
                      <OpenIcon className="open-icon" />
                    </a>
                  </h5>
                  <div className="attributes-inner-wrapper">
                    {feedingScheduleAttributes.map((row) => (
                      <AttributeCard
                        key={`${row.keyAttribute?.replaceAll(
                          ' ',
                          '',
                        )}-${row.value?.replaceAll(' ', '')}`}
                        keyAttribute={row.keyAttribute}
                        value={row.value}
                      />
                    ))}
                  </div>
                </section>
              )}

              {!!observations.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Observations</h5>
                  <div className="inner-wrapper">
                    {observations.map((row) => (
                      <ObservationTile
                        key={row.recording.replaceAll(' ', '')}
                        {...row}
                      />
                    ))}
                  </div>
                </section>
              )}

              {!!lifeformImages.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Images</h5>
                  <div className="inner-wrapper">
                    {lifeformImages.map((row) => (
                      <GalleryCard
                        key={`image-card-${row.image_url}`}
                        id={row.id}
                        image={row.image_url ?? ''}
                        category={'lifeform'}
                      />
                    ))}
                  </div>
                </section>
              )}
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
