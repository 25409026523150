import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as MenuIcon } from '../icons/menu.svg';
import { ReactComponent as CloseIcon } from '../icons/close.svg';
import { ReactComponent as ArrowIcon } from '../icons/arrow.svg';
import { ReactComponent as AnimalIcon } from '../icons/pet.svg';
import { ReactComponent as EnclosureIcon } from '../icons/enclosure.svg';
import { ReactComponent as InventoryIcon } from '../icons/inventory.svg';
import { ReactComponent as ObservationIcon } from '../icons/observation.svg';
import { ReactComponent as FeedingIcon } from '../icons/feeding.svg';
import { ReactComponent as ProfileIcon } from '../icons/profile.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as SettingIcon } from '../icons/settings.svg';
import { ReactComponent as BulkIcon } from '../icons/export.svg';
import { Paths, PathsAPI } from '../routes/paths';
import { MobileMenu } from './menu';
import { checkIsDesktop } from '../shared/utils';
import { useMainContext } from '../context/context';
import { BRAND_NAME, DEFAULT_IMAGES } from '../shared/constants';
import { isCreatePage } from '../typings/pages';
import { axiosInstance } from '../shared/axios';
import { LogoHeader } from './logo';

export const Header = (props: {
  hideLinks: boolean;
  title?: string;
}): JSX.Element => {
  const [mainState] = useMainContext();
  const location = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [createExpandedNewMenu, setCreateExpandedNewMenu] = useState(false);
  const [createExpandedProfile, setCreateExpandedProfile] = useState(false);
  const windowSize: { current: [number, number] } = useRef([
    window.innerWidth,
    window.innerHeight,
  ]);
  const [isDesktop, setIsDesktop] = useState(
    checkIsDesktop(windowSize.current),
  );
  const includesCreatePages = isCreatePage(location.pathname);
  const profilePages: string[] = [Paths.viewProfile, Paths.editProfileBase];

  useEffect(() => {
    document.title = `${BRAND_NAME}${props.title ? ` - ${props.title}` : ''}`;
    const handleHeaderResize = () => {
      const windowSize: [number, number] = [
        window.innerWidth,
        window.innerHeight,
      ];

      setIsDesktop(checkIsDesktop(windowSize));
    };

    window.addEventListener('resize', handleHeaderResize);
    return () => window.removeEventListener('resize', handleHeaderResize);
  }, []);

  return (
    <header className="">
      {isDesktop ? (
        <>
          <div className="logo-title">
            <LogoHeader hideLinks={props.hideLinks} />
          </div>
          {!props.hideLinks ? (
            <>
              <ul className="middle-menu">
                {mainState.account?.id ? (
                  <li>
                    <a
                      href={Paths.home}
                      className={
                        location.pathname === Paths.home ? 'active' : ''
                      }
                    >
                      Home
                    </a>
                  </li>
                ) : (
                  <></>
                )}

                <li>
                  <a
                    href={Paths.browse}
                    className={
                      location.pathname === Paths.browse ? 'active' : ''
                    }
                  >
                    Browse
                  </a>
                </li>
                {mainState.account?.id ? (
                  <li
                    onClick={() => {
                      setCreateExpandedNewMenu(!createExpandedNewMenu);
                    }}
                    className={
                      includesCreatePages
                        ? 'main-list-item active'
                        : 'main-list-item'
                    }
                  >
                    Add{' '}
                    <ArrowIcon
                      className={`arrow-icon menu-icon ${
                        createExpandedNewMenu ? 'open' : 'closed'
                      }`}
                    />
                    <ul
                      className={`sub-pages ${
                        createExpandedNewMenu ? 'open' : ''
                      }`}
                    >
                      <li
                        className={`sub-page-list-item ${
                          Paths.addAnimal === location.pathname ? 'active' : ''
                        }`}
                        key={`sub-page-${'Animal'}`}
                      >
                        <a href={Paths.addAnimal}>
                          <AnimalIcon className="animal-icon icon" />
                          <h6>Animal</h6>
                        </a>
                      </li>

                      <li
                        className={`sub-page-list-item ${
                          Paths.createEnv === location.pathname ? 'active' : ''
                        }`}
                        key={`sub-page-${'Enclosures'}`}
                      >
                        <a href={Paths.createEnv}>
                          <EnclosureIcon className="enclosure-icon icon" />
                          <h6>Enclosure</h6>
                        </a>
                      </li>

                      <li
                        className={`sub-page-list-item ${
                          Paths.addInventory === location.pathname
                            ? 'active'
                            : ''
                        }`}
                        key={`sub-page-${'Inventory'}`}
                      >
                        <a href={Paths.addInventory}>
                          <InventoryIcon className="inventory-icon icon" />
                          <h6>Inventory Group</h6>
                        </a>
                      </li>

                      <li
                        className={`sub-page-list-item ${
                          Paths.addObservation === location.pathname
                            ? 'active'
                            : ''
                        }`}
                        key={`sub-page-${'Observation'}`}
                      >
                        <a href={Paths.addObservation}>
                          <ObservationIcon className="observation-icon icon" />
                          <h6>Observation</h6>
                        </a>
                      </li>

                      <li
                        className={`sub-page-list-item ${
                          Paths.addFeeding === location.pathname ? 'active' : ''
                        }`}
                        key={`sub-page-${'Feeding'}`}
                      >
                        <a href={Paths.addFeedingScheduleGroup}>
                          <FeedingIcon className="feeding-icon icon" />
                          <h6>Feedings</h6>
                        </a>
                      </li>

                      <li
                        className={`sub-page-list-item ${
                          Paths.bulk === location.pathname ? 'active' : ''
                        }`}
                        key={`sub-page-${'bulk'}`}
                      >
                        <a href={Paths.bulk}>
                          <BulkIcon className="bulk-icon icon" />
                          <h6>Bulk</h6>
                        </a>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
              {mainState.account?.id ? (
                <div className="profile-wrapper">
                  <div
                    className={`profile-menu ${
                      profilePages.some((page) =>
                        location.pathname.includes(page),
                      )
                        ? 'active'
                        : ''
                    }`}
                    onClick={() => {
                      setCreateExpandedProfile(!createExpandedProfile);
                    }}
                  >
                    <img
                      className="profile-image"
                      src={
                        mainState?.person?.profile_image ??
                        DEFAULT_IMAGES.person
                      }
                    />
                    <ArrowIcon
                      className={`arrow-icon menu-icon ${
                        createExpandedProfile ? 'open' : 'closed'
                      }`}
                    />
                    <ul
                      className={`sub-pages ${
                        createExpandedProfile ? 'open' : ''
                      }`}
                    >
                      <li
                        className={`sub-page-list-item ${
                          location.pathname.includes(Paths.viewProfile)
                            ? 'active'
                            : ''
                        }`}
                      >
                        <a
                          href={`${Paths.viewProfile}/${mainState?.person?.username}`}
                        >
                          <ProfileIcon className="profile-icon icon" />
                          <h6>Profile</h6>
                        </a>
                      </li>

                      <li
                        className={`sub-page-list-item ${
                          location.pathname === Paths.settings ? 'active' : ''
                        }`}
                      >
                        <a href={Paths.settings}>
                          <SettingIcon className="setting-icon icon" />
                          <h6>Setting</h6>
                        </a>
                      </li>

                      <li className={`sub-page-list-item`}>
                        <a
                          onClick={async () => {
                            await axiosInstance.get(PathsAPI.logout);
                            navigate(Paths.login);
                          }}
                        >
                          <LogoutIcon className="logout-icon icon" />
                          <h6>Logout</h6>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                <div className="login-wrapper">
                  <a href={Paths.login}>Login</a>
                  <a href={Paths.signup}>
                    <button className="btn">Sign Up</button>
                  </a>
                </div>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className="logo-title">
            <LogoHeader hideLinks={props.hideLinks} />
          </div>
          <div className="header-action-wrapper">
            {mainState.account?.id ? (
              <>
                <button
                  className="mobile-menu-btn"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {!isOpen ? (
                    <MenuIcon className="menu-icon" />
                  ) : (
                    <CloseIcon className="close-icon" />
                  )}
                </button>
                <MobileMenu
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                  currentPage={location.pathname}
                />
              </>
            ) : (
              <div className="login-wrapper">
                <a href={Paths.login}>Login</a> \{' '}
                <a href={Paths.signup}>
                  <button className="btn">Sign Up</button>
                </a>
              </div>
            )}

            {location.pathname === Paths.signup ? (
              <a href={Paths.login}>
                <h6>Login</h6>
              </a>
            ) : (
              <></>
            )}
            {location.pathname === Paths.login ? (
              <a href={Paths.signup}>
                <h6>Signup</h6>
              </a>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </header>
  );
};
