import moment from 'moment';
import { SelectListOption } from '../typings/common';
import { DESKTOP_WIDTH } from './constants';

export const formatDateForAPI = (date: Date): string =>
  date.toISOString().replace('Z', '');

export const padTo2Digits = (num: any) => num.toString().padStart(2, '0');
export const formatDate = (date: Date) =>
  [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join('-') +
  ' ' +
  [
    padTo2Digits(date.getHours()),
    padTo2Digits(date.getMinutes()),
    // padTo2Digits(date.getSeconds()),  // 👈️ can also add seconds
  ].join(':');

export const formateDateSimple = (
  dateString: string | undefined,
  endString = 'ago',
  limit?: boolean,
): string => {
  const inputDate = moment(dateString);
  const currentDate = moment();
  const secondsSince = currentDate.diff(inputDate, 'seconds');
  const minutesSince = currentDate.diff(inputDate, 'minute');
  const hoursSince = currentDate.diff(inputDate, 'hour');
  const daysSince = currentDate.diff(inputDate, 'day');
  const weeksSince = currentDate.diff(inputDate, 'week');
  const monthSince = currentDate.diff(inputDate, 'month');
  const yearSince = currentDate.diff(inputDate, 'year');

  const minutesInHour = 60;
  const hoursInDay = 24;
  const daysInWeek = 7;
  const weeksInMonth = 4;
  const monthsInYear = 12;

  if (minutesSince < minutesInHour && minutesSince === 0) {
    return `${secondsSince} second${secondsSince > 1 ? 's' : ''} ${endString}`;
  }

  if (minutesSince < minutesInHour) {
    return `${minutesSince} minute${minutesSince > 1 ? 's' : ''} ${endString}`;
  }

  if (hoursSince < hoursInDay) {
    return `${hoursSince} hour${hoursSince > 1 ? 's' : ''} ${endString}`;
  }

  if (daysSince < daysInWeek) {
    return `${daysSince} day${daysSince > 1 ? 's' : ''} ${endString}`;
  }

  if (limit) {
    return inputDate.format('MMM DD, YY HH:mm a');
  }

  if (
    weeksSince < weeksInMonth ||
    (weeksSince === weeksInMonth && monthSince < monthsInYear)
  ) {
    return `${weeksSince} week${weeksSince > 1 ? 's' : ''} ${endString}`;
  }

  if (monthSince < monthsInYear) {
    return `${monthSince} month${monthSince > 1 ? 's' : ''} ${endString}`;
  }

  return `${yearSince} year${yearSince > 1 ? 's' : ''} ${endString}`;
};

export const ensureTextLength = (text: string, maxLength: number) => {
  if (text.length <= maxLength) {
    return text;
  }
  const result = `${text.substring(0, maxLength).trim()}...`;

  return result;
};

export const shuffleArr = (array: any[]) => {
  for (let i = array.length - 1; i > 0; i--) {
    const rand = Math.floor(Math.random() * (i + 1));
    [array[i], array[rand]] = [array[rand], array[i]];
  }
};

export const roundCurrency = (value: number) => {
  return Math.round(value * 100) / 100;
};

export const getOption = (value: string, options: SelectListOption[]) => {
  return options.find((option) => option.value === value);
};

export const formatCountBubble = (count: number): string => {
  return `${count}${count > 99 ? '+' : ''}`;
};

export const toTitleCase = (str: string): string => {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const getSearchParams = (
  searchParams: URLSearchParams,
): { tab: string; search: string } => {
  const params = { tab: '', search: '' };

  for (const entry of searchParams.entries()) {
    if (entry[0] === 'tab') {
      params.tab = entry[1];
    }

    if (entry[0] === 'search') {
      params.search = entry[1];
    }
  }

  return params;
};

type Width = number;
type Height = number;

export const checkIsDesktop = (windowSize: [Width, Height]) => {
  const [width, height] = windowSize;
  return width >= DESKTOP_WIDTH;
};

export const randomTextGenerator = (length: number) => {
  let result = '';
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};
