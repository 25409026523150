/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { LifeformAttribute } from 'cobranetics-types/types';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { DEFAULT_IMAGES } from '../../shared/constants';
import {
  getLifeformGalleryCards,
  getLifeformGalleryImages,
} from '../../actions/gallery';
import { deleteLifeform, getAnimal } from '../../actions/animals';
import { AddAnimalTabs } from '../../typings/animal';
import { getCheck } from '../../actions/check';
import { CHECK, GET_LIFEFORM } from '../../actions';
import { MainAction } from '../../typings/main_context_reducer';
import { useMainContext } from '../../context/context';
import { formatDate } from '../../utils/dates';
import { GeneralSection } from '../../components/create-animal/general';
import { CharacteristicSection } from '../../components/create-animal/characteristics';
import { ImageSection } from '../../components/create-animal/images';
import { FailedAuthCheck } from '../../shared/errors';
import { SexTypes } from '../../typings/lifeform';
import { getSearchParams } from '../../shared/utils';
import { DeleteModal } from '../../components/modals/delete';
import { Paths } from '../../routes/paths';
import { getLifeformAttributes } from '../../actions/lifeform-attribute';
import { errorNotification } from '../../components/notification';

export const AddAnimal = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = getSearchParams(searchParams);
  const { paramsID } = useParams();
  const isEdit = paramsID !== undefined;
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const pageID = isEdit ? Number.parseInt(paramsID) : 0;
  const [isBusy, setBusy] = useState(true);

  // Tabs
  let tab = AddAnimalTabs.general;
  switch (queryParams.tab) {
    case AddAnimalTabs.images:
      tab = AddAnimalTabs.images;
      break;
    case AddAnimalTabs.characteristic:
      tab = AddAnimalTabs.characteristic;
      break;
    default:
      break;
  }
  const [activeTab, setActiveTab] = useState(
    isEdit ? tab : AddAnimalTabs.general,
  );

  // General
  const [name, setName] = useState('');
  const [scientificName, setScientificName] = useState('');
  const [commonName, setCommonName] = useState('');
  const [sex, setSex] = useState(SexTypes.Unknown);
  const [dob, setDOB] = useState('');
  const [enclosureID, setEnclosureID] = useState('');
  const [isVisible, setIsVisible] = useState(true);
  const [openModal, setOpenModal] = useState(false);

  // Images
  const [images, setImages] = useState([] as { id: string; src: string }[]);
  const [activeImage, setActiveImage] = useState(DEFAULT_IMAGES.animal);
  const [imageCount, setImageCount] = useState(0);

  // Trait
  const [characteristics, setCharacteristics] = useState<
    Partial<LifeformAttribute>[]
  >([]);

  const generalProps = {
    name,
    setName,
    scientificName,
    setScientificName,
    commonName,
    setCommonName,
    sex,
    setSex,
    dob,
    setDOB,
    isEdit,
    setActiveTab,
    enclosureID,
    setEnclosureID,
    isVisible,
    setIsVisible,
    setOpenModal,
    navigate,
  };
  const imageProps = {
    images,
    activeImage,
    setImages,
    setActiveImage,
    setActiveTab,
    isEdit,
    imageCount,
    navigate,
  };
  const characteristicProps = {
    characteristics,
    setCharacteristics,
    navigate,
    isEdit,
  };

  const tabs = [
    {
      title: AddAnimalTabs.general,
      clickTab: () => {
        setActiveTab(AddAnimalTabs.general);
        if (isEdit) {
          setSearchParams({ tab: AddAnimalTabs.general });
        }
      },
    },
    {
      title: AddAnimalTabs.images,
      clickTab: () => {
        if (name.length === 0 && commonName === '' && scientificName === '') {
          errorNotification('You must fill out all name fields.');
          return;
        }

        setActiveTab(AddAnimalTabs.images);

        if (isEdit) {
          setSearchParams({ tab: AddAnimalTabs.images });
        }
      },
    },
    {
      title: AddAnimalTabs.characteristic,
      clickTab: () => {
        if (name.length === 0 && commonName === '' && scientificName === '') {
          errorNotification('You must fill out all name fields.');
          return;
        }

        setActiveTab(AddAnimalTabs.characteristic);

        if (isEdit) {
          setSearchParams({ tab: AddAnimalTabs.characteristic });
        }
      },
    },
  ];

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteLifeform(mainState?.lifeform?.id ?? '');
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'animal',
    name: name || commonName || scientificName || '?',
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);

        const imageRows = await getLifeformGalleryImages({
          account_id: check.account.id,
          perPage: 1,
        });
        setImageCount(imageRows.total || 0);

        if (isEdit) {
          if (Number.isNaN(pageID)) {
            return;
          }

          const lifeform = await getAnimal(paramsID);
          const lifeformAction = {
            type: GET_LIFEFORM,
            lifeformAction: {
              lifeform,
            },
          } as MainAction;
          dispatch(lifeformAction);

          const characteristicParams = {
            currentPage: 1,
            perPage: 10,
            sort_by: '-id',
            lifeform_id: lifeform.id,
          };
          const characteristics = await getLifeformAttributes(
            characteristicParams,
          );

          const lifeformGalleryParams = {
            currentPage: 1,
            perPage: 10,
            sort_by: '-id',
          };
          const lifeformGallery = await getLifeformGalleryCards({
            ...lifeformGalleryParams,
            lifeform_ids: `${lifeform.id}`,
          });
          const activeImage =
            lifeformGallery.data.filter((img) => img.is_default_image)[0]
              ?.image_url || DEFAULT_IMAGES.animal;

          setName(lifeform.pet_name || '');
          setScientificName(lifeform.scientific_name || '');
          setCommonName(lifeform.common_name || '');
          setSex(lifeform.sex || 'unknown');
          setDOB(formatDate(moment(lifeform.dob)));
          setIsVisible(lifeform.is_public);
          setCharacteristics(characteristics.data);
          setImages(
            lifeformGallery.data.map((galleryImage) => ({
              id: galleryImage.id,
              src: galleryImage.image_url,
            })),
          );
          setActiveImage(activeImage);
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="add-animal">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="add-animal-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title={`${isEdit ? 'Edit' : 'Add'} Animal`}
                showBackBtn={true}
                tabs={tabs}
                activeTab={activeTab}
              />
              {activeTab === AddAnimalTabs.general ? (
                <GeneralSection {...generalProps} />
              ) : (
                <></>
              )}
              {activeTab === AddAnimalTabs.images ? (
                <ImageSection {...imageProps} />
              ) : (
                <></>
              )}
              {activeTab === AddAnimalTabs.characteristic ? (
                <CharacteristicSection {...characteristicProps} />
              ) : (
                <></>
              )}
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
