import { deleteSensor } from '../actions/sensor';
import { Paths } from '../routes/paths';

export const getFavoriteMenus = (navigate: any) => ({
  animalMenu: [
    {
      text: 'Animals',
      onClick: () => {
        navigate(Paths.viewAnimal);
      },
    },
    {
      text: 'Add Animal',
      onClick: () => {
        navigate(Paths.addAnimal);
      },
    },
  ],

  enclosureMenu: [
    {
      text: 'Enclosures',
      onClick: () => {
        navigate(Paths.viewEnclosure);
      },
    },
    {
      text: 'Add Enclosure',
      onClick: () => {
        navigate(Paths.createEnv);
      },
    },
  ],

  galleryMenu: [
    {
      text: 'Images',
      onClick: () => {
        navigate(Paths.viewGallery);
      },
    },
  ],
});

export const getArchivedMenus = (navigate: any) => ({
  animalMenu: [
    {
      text: 'Animals',
      onClick: () => {
        navigate(Paths.viewAnimal);
      },
    },
    {
      text: 'Add Animal',
      onClick: () => {
        navigate(Paths.addAnimal);
      },
    },
  ],

  enclosureMenu: [
    {
      text: 'Enclosures',
      onClick: () => {
        navigate(Paths.viewEnclosure);
      },
    },
    {
      text: 'Add Enclosure',
      onClick: () => {
        navigate(Paths.createEnv);
      },
    },
  ],
});

export const getAnimalMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
  keeperUsername: string,
  deleteAction: () => void,
) => ({
  mainMenu: [
    ...(ownsEntry
      ? [
          {
            text: 'Edit',
            link: Paths.editAnimal.replace(':id', pageID),
            onClick: () => {},
          },
          {
            text: 'Add Feeding',
            link: `${Paths.addFeeding}?lifeform_id=${pageID}`,
            onClick: () => {},
          },
          {
            text: 'Delete',
            onClick: deleteAction,
          },
        ]
      : []),
    {
      text: 'Keeper',
      link: Paths.viewProfileItem.replace(':id', `${keeperUsername}`),
      onClick: () => {},
    },
  ],
});

export const getEnclosureMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
  keeperUsername: string,
  deleteAction: () => void,
) => ({
  mainMenu: [
    ...(ownsEntry
      ? [
          {
            text: 'Edit',
            link: `${Paths.editEnvBase}/${pageID}`,
            onClick: () => {},
          },
          {
            text: 'Delete',
            onClick: deleteAction,
          },
        ]
      : []),
    {
      text: 'Keeper',
      link: Paths.viewProfileItem.replace(':id', `${keeperUsername}`),
      onClick: () => {},
    },
  ],
});

export const getFeedingScheduleMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
  keeperUsername: string,
) => ({
  mainMenu: [
    ...(ownsEntry
      ? [
          {
            text: 'Add Feeding',
            link: `${Paths.addFeeding}?lifeform_id=${pageID}`,
            onClick: () => {},
          },
        ]
      : []),

    {
      text: 'Animal',
      link: Paths.viewAnimalItem.replace(':id', `${pageID}`),
      onClick: () => {},
    },
    {
      text: 'Keeper',
      link: Paths.viewProfileItem.replace(':id', `${keeperUsername}`),
      onClick: () => {},
    },
  ],
});

export const getInventoryMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
  keeperUsername: string,
  deleteAction: () => void,
) => ({
  mainMenu: [
    ...(ownsEntry
      ? [
          {
            text: 'Add Transaction',
            link: `${Paths.addInventoryTransaction}?inventory_id=${pageID}`,
            onClick: () => {},
          },
          {
            text: 'Edit',
            link: `${Paths.editInventoryBase}/${pageID}`,
            onClick: () => {},
          },
          {
            text: 'Delete',
            onClick: deleteAction,
          },
        ]
      : []),
    {
      text: 'Keeper',
      link: Paths.viewProfileItem.replace(':id', `${keeperUsername}`),
      onClick: () => {},
    },
  ],
});

export const getObservationMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
  keeperUsername: string,
  deleteAction: () => void,
) => ({
  mainMenu: [
    {
      text: 'New',
      link: `${Paths.addObservation}`, // TODO: update to autofill observation selection
      onClick: () => {},
    },
    ...(ownsEntry
      ? [
          {
            text: 'Edit',
            link: `${Paths.editObservationBase}/${pageID}`,
            onClick: () => {},
          },
          {
            text: 'Delete',
            onClick: deleteAction,
          },
        ]
      : []),
    {
      text: 'Keeper',
      link: Paths.viewProfileItem.replace(':id', `${keeperUsername}`),
      onClick: () => {},
    },
  ],
});

export const getSensorMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
) => ({
  mainMenu: [
    {
      text: 'Add Readings',
      link: Paths.addReading,
      onClick: () => {},
    },
    {
      text: 'Edit Sensor',
      link: `${Paths.editSensorBase}/${pageID}`,
      onClick: () => {},
    },
    ...(ownsEntry
      ? [
          {
            text: 'Delete Sensor',
            onClick: async () => {
              // TODO - add confirmation
              await deleteSensor(pageID);
              navigate(0);
            },
          },
        ]
      : []),
  ],
});

export const getImageMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
  parentID: string,
  deleteAction: () => void,
) => ({
  mainMenuEnclosure: [
    {
      text: 'Enclosure',
      link: Paths.viewEnclosureItem.replace(':id', `${parentID}`),
      onClick: () => {},
    },
    ...(ownsEntry
      ? [
          {
            text: 'Delete',
            onClick: deleteAction,
          },
        ]
      : []),
  ],
  mainMenuAnimal: [
    {
      text: 'Animal',
      link: Paths.viewAnimalItem.replace(':id', `${parentID}`),
      onClick: () => {},
    },
    ...(ownsEntry
      ? [
          {
            text: 'Delete',
            onClick: deleteAction,
          },
        ]
      : []),
  ],
  mainMenuObservation: [
    {
      text: 'Observation',
      link: Paths.viewObservationItem.replace(':id', `${parentID}`),
      onClick: () => {},
    },
    ...(ownsEntry
      ? [
          {
            text: 'Delete',
            onClick: deleteAction,
          },
        ]
      : []),
  ],
});

export const getProfileMenus = (
  navigate: any,
  pageID: string,
  ownsEntry: boolean,
) => ({
  mainMenu: [
    ...(ownsEntry
      ? [
          {
            text: 'Edit',
            link: `${Paths.editProfileBase}/${pageID}`,
            onClick: () => {},
          },
        ]
      : []),
  ],
});
