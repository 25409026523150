import {
  EnclosureGallery,
  LifeformGallery,
  ObservationGallery,
} from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';

export interface FetchEnclosureGalleryParams extends Params {
  enclosure_ids?: string;
  enclosure_name?: string;
  account_id?: number;
}

export interface FetchLifeformGalleryParams extends Params {
  lifeform_ids?: string;
  search?: string;
  is_default_image?: boolean;
  account_id?: number;
}

export interface FetchObservationGalleryParams extends Params {
  observation_ids?: string;
  search?: string;
  account_id?: number;
}

export interface UpdateEnclosureGalleryParams {
  id: string;
  image_url?: string;
  is_default_image?: boolean;
}

export interface UpdateLifeformGalleryParams {
  id: string;
  image_url?: string;
  is_default_image?: boolean;
}

export interface UpdateGalleryParams {
  id: string;
  image_url?: string;
  is_default_image?: boolean;
}

export const getLifeformGalleryImages = async (
  params: FetchLifeformGalleryParams,
): Promise<ListOutput<LifeformGallery>> => {
  const response = await axiosInstance.get(PathsAPI.lifeformGallery, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getEnclosureGalleryImages = async (
  params: FetchEnclosureGalleryParams,
): Promise<ListOutput<EnclosureGallery>> => {
  const response = await axiosInstance.get(PathsAPI.enclosureGallery, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getObservationGalleryImages = async (
  params: FetchObservationGalleryParams,
): Promise<ListOutput<ObservationGallery>> => {
  const response = await axiosInstance.get(PathsAPI.observationGallery, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getEnclosureGalleryCards = async (
  params: FetchEnclosureGalleryParams,
): Promise<ListOutput<EnclosureGallery>> => {
  const response = await axiosInstance.get(PathsAPI.enclosureGallery, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const getLifeformGalleryCards = async (
  params: FetchLifeformGalleryParams,
): Promise<ListOutput<LifeformGallery>> => {
  const response = await axiosInstance.get(PathsAPI.lifeformGallery, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const getObservationGalleryCards = async (
  params: FetchObservationGalleryParams,
): Promise<ListOutput<ObservationGallery>> => {
  const response = await axiosInstance.get(PathsAPI.observationGallery, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const getEnclosureGallery = async (
  id: number,
): Promise<EnclosureGallery> => {
  const response = await axiosInstance.get(
    `${PathsAPI.enclosureGallery}/${id}`,
  );

  return response.data;
};

export const getLifeformGallery = async (
  id: number,
): Promise<LifeformGallery> => {
  const response = await axiosInstance.get(`${PathsAPI.lifeformGallery}/${id}`);

  return response.data;
};

export const updateEnclosureGallery = async (
  params: UpdateEnclosureGalleryParams,
) => {
  const response = await axiosInstance.put(
    `${PathsAPI.enclosureGallery}`,
    params,
  );

  return response.data;
};

export const updateLifeformGallery = async (
  params: UpdateLifeformGalleryParams,
) => {
  const response = await axiosInstance.put(
    `${PathsAPI.lifeformGallery}`,
    params,
  );

  return response.data;
};

export const updateObservationGallery = async (params: UpdateGalleryParams) => {
  const response = await axiosInstance.put(
    `${PathsAPI.observationGallery}`,
    params,
  );

  return response.data;
};

export const deleteEnclosureGallery = async (id: string) => {
  await axiosInstance.delete(`${PathsAPI.enclosureGallery}/${id}`);
};

export const deleteLifeformGallery = async (id: string) => {
  await axiosInstance.delete(`${PathsAPI.lifeformGallery}/${id}`);
};

export const deleteObservationGallery = async (id: string) => {
  await axiosInstance.delete(`${PathsAPI.observationGallery}/${id}`);
};
