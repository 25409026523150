import React, { useState } from 'react';

export const LoadingSpinner = (props: {
  show: boolean;
  includeText?: boolean;
}) => {
  // let i = 0;
  // const [dots, setDots] = useState('');

  // setInterval(() => {
  //   i = ++i % 4;
  //   setDots(Array(i + 1).join('.'));
  // }, 800);

  return (
    <>
      {props.show ? (
        <div className="loading-wrapper">
          <div className="loading-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <p>{props.includeText ? 'Loading...' : ''}</p>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};
