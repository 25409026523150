export const handleScroll = (setReachedBottom: (hrb: boolean) => void) => {
  return () => {
    const offsetHeight = document.documentElement.offsetHeight;
    const innerHeight = window.innerHeight;
    const scrollTop = document.documentElement.scrollTop;
    const hasReachedBottom = offsetHeight - (innerHeight + scrollTop) <= 10;

    setReachedBottom(hasReachedBottom);
  };
};
