import {
  CHANGE_ENTITIES,
  SIGN_UP,
  LOGIN,
  CHECK,
  GET_FEEDINGS,
  GET_ANIMALS,
  GET_ENCLOSURES,
  GET_INVENTORY,
  GET_SENSORS,
  GET_OBSERVATIONS,
  GET_FOLLOW_PAIR,
  GET_ENCLOSURE_GALLERY,
  GET_LIFEFORM_GALLERY,
  GET_FEEDING_SCHEDULE,
  HOME_ACTION,
  GET_INVENTORY_TRANSACTION,
  PROFILE_ACTION,
  FAVORITE_PAGE_ACTION,
  ARCHIVED_PAGE_ACTION,
  BROWSE_ACTION,
  PERSON_ACTION,
  FOLLOW_PAIR_ACTION,
  ENCLOSURE_HUMIDITY_READING_ACTION,
  ENCLOSURE_LIGHT_LUX_READING_ACTION,
  ENCLOSURE_LIGHT_UV_READING_ACTION,
  ENCLOSURE_TEMPERATURE_READING_ACTION,
  LIFEFORM_SIZE_READING_ACTION,
  LIFEFORM_WEIGHT_READING_ACTION,
  GET_OBSERVATION_GALLERY,
  GET_LIFEFORM,
  GET_ENCLOSURE,
} from '../actions';
import { MainState, MainAction } from '../typings/main_context_reducer';

export const MainReducer = (
  state: MainState,
  action: MainAction,
): MainState => {
  switch (action.type) {
    case CHANGE_ENTITIES:
      return {
        ...state,
      };
    case SIGN_UP:
      return {
        ...state,
        ...(action.signupAction ?? {}),
      };
    case LOGIN:
      return {
        ...state,
        ...(action.loginAction ?? {}),
      };
    case CHECK:
      return {
        ...state,
        ...(action.checkAction ?? {}),
      };
    case GET_FEEDINGS:
      return {
        ...state,
        ...(action.feedingAction ?? {}),
      };
    case GET_ANIMALS:
      return {
        ...state,
        ...(action.animalAction ?? {}),
      };
    case GET_ENCLOSURES:
      return {
        ...state,
        ...(action.enclosureAction ?? {}),
      };
    case GET_INVENTORY:
      return {
        ...state,
        ...(action.inventoryAction ?? {}),
      };
    case GET_INVENTORY_TRANSACTION:
      return {
        ...state,
        ...(action.inventoryTransactionAction ?? {}),
      };
    case GET_SENSORS:
      return {
        ...state,
        ...(action.sensorAction ?? {}),
      };
    case GET_OBSERVATIONS:
      return {
        ...state,
        ...(action.observationAction ?? {}),
      };
    case GET_OBSERVATION_GALLERY:
      return {
        ...state,
        ...(action.observationGalleryAction ?? {}),
      };
    case GET_FOLLOW_PAIR:
      return {
        ...state,
        ...(action.followPairAction ?? {}),
      };
    case GET_ENCLOSURE_GALLERY:
      return {
        ...state,
        ...(action.enclosureGalleryAction ?? {}),
      };
    case GET_LIFEFORM_GALLERY:
      return {
        ...state,
        ...(action.lifeformGalleryAction ?? {}),
      };
    case GET_FEEDING_SCHEDULE:
      return {
        ...state,
        ...(action.feedingScheduleAction ?? {}),
      };
    case HOME_ACTION:
      return {
        ...state,
        ...(action.homeAction ?? {}),
      };
    case PROFILE_ACTION:
      return {
        ...state,
        ...(action.profileAction ?? {}),
      };
    case FAVORITE_PAGE_ACTION:
      return {
        ...state,
        ...(action.favoritePageAction ?? {}),
      };
    case ARCHIVED_PAGE_ACTION:
      return {
        ...state,
        ...(action.archivedAction ?? {}),
      };
    case BROWSE_ACTION:
      return {
        ...state,
        ...(action.browseAction ?? {}),
      };
    case PERSON_ACTION:
      return {
        ...state,
        ...(action.personAction ?? {}),
      };
    case FOLLOW_PAIR_ACTION:
      return {
        ...state,
        ...(action.followPairAction ?? {}),
      };
    case ENCLOSURE_HUMIDITY_READING_ACTION:
      return {
        ...state,
        ...(action.enclosureHumidityReadingAction ?? {}),
      };
    case ENCLOSURE_LIGHT_LUX_READING_ACTION:
      return {
        ...state,
        ...(action.enclosureLightLuxReadingAction ?? {}),
      };
    case ENCLOSURE_LIGHT_UV_READING_ACTION:
      return {
        ...state,
        ...(action.enclosureLightUvReadingAction ?? {}),
      };
    case ENCLOSURE_TEMPERATURE_READING_ACTION:
      return {
        ...state,
        ...(action.enclosureTemperatureReadingAction ?? {}),
      };
    case LIFEFORM_SIZE_READING_ACTION:
      return {
        ...state,
        ...(action.lifeformSizeReadingAction ?? {}),
      };
    case LIFEFORM_WEIGHT_READING_ACTION:
      return {
        ...state,
        ...(action.lifeformWeightReadingAction ?? {}),
      };
    case GET_LIFEFORM:
      return {
        ...state,
        ...(action.lifeformAction ?? {}),
      };
    case GET_ENCLOSURE:
      return {
        ...state,
        ...(action.enclosureSingleAction ?? {}),
      };
    default:
      throw new Error();
  }
};
