export enum HomeFilterOptions {
  animals = 'animals',
  enclosures = 'enclosures',
  feedingSchedule = 'feedingSchedule',
  images = 'images',
  inventory = 'inventory',
  observations = 'observations',
  favorites = 'favorites',
  archive = 'archive',
}
