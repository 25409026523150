import React from 'react';
import moment from 'moment';
import {
  FeedingCard as FeedingCardModel,
  FeedingScheduleCard as FeedingScheduleCardModel,
} from 'cobranetics-types/types';

import { Paths } from '../routes/paths';
import { formateDateSimple } from '../shared/utils';
import { formatDefaultDateTime } from '../utils/dates';
import { ReactComponent as FeedingIcon } from '../icons/feeding.svg';
import { ReactComponent as MenuIcon } from '../icons/three-dot-menu.svg';
import { ReactComponent as MaleIcon } from '../icons/male.svg';
import { ReactComponent as FemaleIcon } from '../icons/female.svg';

export interface FeedingScheduleInterface {
  date: string;
  animals: {
    name: string;
    meal: string;
    ate: string;
  }[];
}

export interface FeedingCardModelProps extends FeedingCardModel {
  showTitle?: boolean;
}

export const FeedingCard = (props: FeedingCardModelProps) => {
  return (
    <div className="feeding-card common-card">
      <div className="item">
        <p className="title">Meal</p>
        <p className="value">{props.meal}</p>
      </div>

      <div className="item">
        <p className="title">Feed On</p>
        <p className="value">{formatDefaultDateTime(moment(props.feed_on))}</p>
      </div>

      <div className="row">
        <div className="item">
          <p className="title">Ate</p>
          <p className="value">
            {props.ate ? 'Yes' : props.ate === false ? 'No' : 'Not Yet'}
          </p>
        </div>
      </div>
    </div>
  );
};

export const FeedingScheduleCard = (
  props: FeedingScheduleCardModel,
): JSX.Element => {
  return (
    <div className="feeding-schedule-card common-card standard-card">
      <section className="top-content">
        <a href={`${Paths.viewFeedingSchedule}/${props.id}`}>
          <div className="card-identifier">
            <FeedingIcon />
          </div>
        </a>

        <div className="card-menu">
          <MenuIcon />
        </div>
      </section>
      <div className="card-content-wrapper">
        <a href={`${Paths.viewFeedingSchedule}/${props.id}`}>
          <section className="main-content">
            <div className="text-wrapper">
              <h5>{props.last_meal}</h5>
              <p>{formateDateSimple(props.last_feed_on ?? '')}</p>
            </div>
          </section>
        </a>

        <section className="bottom-content">
          <div className="titles">
            <div className="bottom-card-title">
              <p>{props.pet_name}</p>
              {props.sex === 'male' ? (
                <MaleIcon className="icon male-icon" />
              ) : (
                <></>
              )}
              {props.sex === 'female' ? (
                <FemaleIcon className="icon female-icon" />
              ) : (
                <></>
              )}
            </div>
            <div className="bottom-card-sub-titles">
              <p>{props.common_name}</p>
              <p>{props.scientific_name}</p>
            </div>
          </div>
          <div className="action-btn-wrapper"></div>
        </section>
      </div>
    </div>
  );
};
