import {
  ObservationGallery,
  Observation,
  ObservationCard as ObservationCardModel,
} from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { ObservationCategories } from '../typings/observation';

export interface FetchObservationParams extends Params {
  title?: string;
  is_public?: boolean;
  category?: string;
  account_id?: number;
  search?: string;
  lifeform_id?: string;
}

export interface FetchObservationGalleryParams extends Params {
  observation_ids?: string;
}

export interface BulkCreateObservationParams {
  title: string;
  category: ObservationCategories;
  lifeform_id: string;
  is_public?: boolean;
  recording: string;
}

export const getObservationCards = async (
  params: FetchObservationParams,
): Promise<ListOutput<ObservationCardModel>> => {
  const response = await axiosInstance.get(PathsAPI.observationCards, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getObservation = async (
  id: string | number,
): Promise<Observation> => {
  const response = await axiosInstance.get(`${PathsAPI.observation}/${id}`);

  return response.data;
};

export const getObservations = async (
  params: FetchObservationParams,
): Promise<ListOutput<Observation>> => {
  const response = await axiosInstance.get(PathsAPI.observation, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getObservationCard = async (
  id: string | number,
): Promise<ObservationCardModel> => {
  const response = await axiosInstance.get(
    `${PathsAPI.observationCards}/${id}`,
  );

  return response.data;
};

export const getObservationGallery = async (
  params: FetchObservationGalleryParams,
): Promise<ListOutput<ObservationGallery>> => {
  const response = await axiosInstance.get(PathsAPI.observationGallery, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const getObservationGalleryCount = async (
  id: string | number,
): Promise<number> => {
  const response = await axiosInstance.get(
    `${PathsAPI.observationGallery}/count/${id}`,
  );

  return response.data.count;
};

export const getObservationGalleryItem = async (
  id: string,
): Promise<ObservationGallery> => {
  const response = await axiosInstance.get(
    `${PathsAPI.observationGallery}/${id}`,
  );

  return response.data;
};

export const deleteObservation = async (id: string): Promise<void> => {
  await axiosInstance.delete(`${PathsAPI.observation}/${id}`);
};

export const createObservation = async (
  observation: Partial<Observation>,
): Promise<Observation> => {
  const response = await axiosInstance.post(
    `${PathsAPI.observation}`,
    observation,
  );

  return response.data;
};

export const updateObservation = async (
  observation: Partial<Observation>,
): Promise<Observation> => {
  const response = await axiosInstance.put(
    `${PathsAPI.observation}`,
    observation,
  );

  return response.data;
};

export const bulkCreateObservation = async (
  createParams: BulkCreateObservationParams[],
): Promise<Observation[]> => {
  const response = await axiosInstance.post(`${PathsAPI.observationBulk}`, {
    data: createParams,
  });

  return response.data;
};
