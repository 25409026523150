import { Store } from 'react-notifications-component';
import React from 'react';

import { ReactComponent as CheckMarkIcon } from '../icons/checkmark.svg';
import { ReactComponent as ErrorIcon } from '../icons/close.svg';
import { ReactComponent as InfoIcon } from '../icons/info.svg';

const DEFAULT_DURATION = 7500;
const DEFAULT_MAX_DURATION = 15000;

export const errorNotification = (message: string) => {
  Store.addNotification({
    message: (
      <div className="icon-message-wrapper">
        <div className="icon-wrapper">
          <ErrorIcon className="notification-icon" />
        </div>
        <div className="message">{message}</div>
      </div>
    ),
    type: 'danger',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: DEFAULT_DURATION,
      onScreen: true,
    },
  });
};

export const successNotification = (message: string) => {
  Store.addNotification({
    message: (
      <div className="icon-message-wrapper">
        <div className="icon-wrapper">
          <CheckMarkIcon className="notification-icon" />
        </div>
        <div className="message">{message}</div>
      </div>
    ),
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: DEFAULT_DURATION,
      onScreen: true,
    },
  });
};

export const infoNotification = (message: string) => {
  Store.addNotification({
    message: (
      <div className="icon-message-wrapper">
        <div className="icon-wrapper">
          <InfoIcon className="notification-icon" />
        </div>
        <div className="message">{message}</div>
      </div>
    ),
    type: 'info',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: DEFAULT_MAX_DURATION,
      onScreen: true,
    },
  });
};
