import { FeedingScheduleGroup } from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';

export interface FetchFeedingScheduleGroupsParams extends Params {
  name?: string;
  account_id?: string;
  feeding_schedule_id?: string;
}

export const getFeedingScheduleGroups = async (
  params: FetchFeedingScheduleGroupsParams,
): Promise<ListOutput<FeedingScheduleGroup>> => {
  const response = await axiosInstance.get(PathsAPI.feedingScheduleGroup, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const createFeedingScheduleGroup = async (feedingScheduleGroup: {
  name: string;
  feeding_schedules: string[];
}): Promise<FeedingScheduleGroup[]> => {
  const response = await axiosInstance.post(
    `${PathsAPI.feedingScheduleGroup}`,
    feedingScheduleGroup,
  );

  return response.data;
};

export const updateFeedingScheduleGroup = async (feedingScheduleGroup: {
  name: string;
  ids: string[];
}): Promise<FeedingScheduleGroup[]> => {
  const response = await axiosInstance.put(
    `${PathsAPI.feedingScheduleGroup}`,
    feedingScheduleGroup,
  );

  return response.data;
};

export const deleteFeedingScheduleGroupByName = async (name: string) => {
  await axiosInstance.delete(
    `${PathsAPI.feedingScheduleGroup}/name/${encodeURI(name)}`,
  );
};

export const deleteFeedingScheduleGroupByID = async (id: string) => {
  await axiosInstance.delete(`${PathsAPI.feedingScheduleGroup}/id/${id}`);
};
