import { SearchResult } from 'cobranetics-types/types';
import { PathsAPI } from '../routes/paths';

export interface SearchProps {
  showSearchMenu: boolean;
}

export interface SearchGroupProps {
  title: string;
  items: SearchItem[];
}

export interface SearchItem {
  title: string;
}

export interface SearchTopic {
  [index: string]: SearchItem[];
}

export enum ScopeOptions {
  Everyone = 'everyone',
  Owner = 'owner',
  Favorites = 'favorites',
  Archived = 'archived',
}

export enum FilterTypeOptions {
  all = 'all',
  animals = 'animals',
  enclosures = 'enclosures',
  feedings = 'feedings',
  feedingSchedule = 'feedingSchedule',
  images = 'images',
  inventory = 'inventory',
  keepers = 'keepers',
  observations = 'observations',
}

export interface FullSearchResults {
  [key: string]: {
    data: SearchResult[];
    hasNextPage: boolean;
    endpoint: PathsAPI;
    filterTypeOption: FilterTypeOptions;
  };
}
