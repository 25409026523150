import { SearchResult } from 'cobranetics-types/types';
import { HomeFilterOptions } from '../typings/dashboard';
import { axiosInstance } from '../shared/axios';
import { ListOutput, RequiredParams } from '.';
import { PathsAPI } from '../routes/paths';
import { FilterTypeOptions } from '../typings/search';

// const HomeFilterOptionsMapping = {
//   [HomeFilterOptions.animals]: PathsAPI.lifeformCards,
//   [HomeFilterOptions.archive]: PathsAPI,
//   [HomeFilterOptions.enclosures]: PathsAPI,
//   [HomeFilterOptions.favorites]: PathsAPI,
//   [HomeFilterOptions.feedingSchedule]: PathsAPI,
//   [HomeFilterOptions.images]: PathsAPI,
//   [HomeFilterOptions.inventory]: PathsAPI,
//   [HomeFilterOptions.observations]: PathsAPI,
// };

export interface FetchDataParams extends RequiredParams {
  search?: string;
  account_id?: number;
}

export const fetchData = async (
  filterOpt: HomeFilterOptions | string,
  params: FetchDataParams,
): Promise<SearchResult[]> => {
  let result: SearchResult[] = [];

  switch (filterOpt) {
    case HomeFilterOptions.animals: {
      const response: ListOutput<SearchResult> = (
        await axiosInstance.get(PathsAPI.lifeformCards, {
          params,
        })
      ).data;

      result = response.data.map((row) => ({
        ...row,
        filterTypeOption: FilterTypeOptions.animals,
      }));
      break;
    }
    case HomeFilterOptions.enclosures: {
      const response: ListOutput<SearchResult> = (
        await axiosInstance.get(PathsAPI.enclosureCards, {
          params,
        })
      ).data;

      result = response.data.map((row) => ({
        ...row,
        filterTypeOption: FilterTypeOptions.enclosures,
      }));
      break;
    }

    case HomeFilterOptions.feedingSchedule: {
      const response: ListOutput<SearchResult> = (
        await axiosInstance.get(PathsAPI.feedingScheduleCards, {
          params,
        })
      ).data;

      result = response.data.map((row) => ({
        ...row,
        filterTypeOption: FilterTypeOptions.feedingSchedule,
      }));
      break;
    }

    case HomeFilterOptions.images: {
      const lifeformImagesResponse: ListOutput<SearchResult> = (
        await axiosInstance.get(PathsAPI.lifeformGallery, {
          params,
        })
      ).data;
      const enclosureImagesResponse: ListOutput<SearchResult> = (
        await axiosInstance.get(PathsAPI.enclosureGallery, {
          params,
        })
      ).data;

      result = [
        ...lifeformImagesResponse.data,
        ...enclosureImagesResponse.data,
      ].map((row) => ({
        ...row,
        filterTypeOption: FilterTypeOptions.images,
      }));
      break;
    }

    case HomeFilterOptions.inventory: {
      const response: ListOutput<SearchResult> = (
        await axiosInstance.get(PathsAPI.inventory, {
          params,
        })
      ).data;

      result = response.data.map((row) => ({
        ...row,
        filterTypeOption: FilterTypeOptions.inventory,
      }));
      break;
    }

    case HomeFilterOptions.observations: {
      const response: ListOutput<SearchResult> = (
        await axiosInstance.get(PathsAPI.observationCards, {
          params,
        })
      ).data;

      result = response.data.map((row) => ({
        ...row,
        filterTypeOption: FilterTypeOptions.observations,
      }));
      break;
    }

    default:
      break;
  }

  return result;
};
