/* istanbul ignore file */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { Paths } from './paths';
import { ContextWrapper } from '../components/context_wrapper';

// Pages - Initial
import { Home } from '../pages/home';
import { Login } from '../pages/auth/login';
import { SignUp } from '../pages/auth/signup';
import { SettingsView } from '../pages/create_edit/settings';
import { CreateEnv } from '../pages/create_edit/enclosure';
import { Browse } from '../pages/browse';
import { ForgotPassword } from '../pages/auth/forgot-password';
import { PasswordReset } from '../pages/auth/password-reset';
import { AddAnimal } from '../pages/create_edit/animal';
import { AddInventory } from '../pages/create_edit/inventory';
import { ViewInventoryItem } from '../pages/views/inventory_item';
import { ViewEnclosureItem } from '../pages/views/enclosure_item';
import { ViewAnimalItem } from '../pages/views/animal_item';
import { ViewProfileItem } from '../pages/views/profile_item';
import { ViewFeedingScheduleItem } from '../pages/views/feeding_schedule_item';
import { EditProfile } from '../pages/create_edit/profile';
import {
  ViewEnclosureGalleryItem,
  ViewLifeformGalleryItem,
  ViewObservationReadingGalleryItem,
} from '../pages/views/gallery_item';
import { Favorites } from '../pages/favorites';
import { ViewObservationItem } from '../pages/views/observation_item';
import { Archived } from '../pages/archived';
import { AddObservation } from '../pages/create_edit/observation';
import { AddFeeding } from '../pages/create_edit/feeding';
import { AddInventoryTransaction } from '../pages/create_edit/inventory-transaction';
import { Bulk } from '../pages/create_edit/bulk';
import { AddFeedingScheduleGroup } from '../pages/create_edit/feeding-schedule-group';

export const AppRoutes = (): JSX.Element => {
  return (
    <div>
      <ReactNotifications />
      <ContextWrapper>
        <Routes>
          {/* Auth */}
          <Route path={Paths.login} Component={Login} />
          <Route path={Paths.signup} Component={SignUp} />
          <Route path={Paths.forgotPassword} Component={ForgotPassword} />
          <Route path={Paths.passwordReset} Component={PasswordReset} />

          {/* Base */}
          <Route path={Paths.home} Component={Home} />
          <Route path={Paths.browse} Component={Browse} />
          <Route path={Paths.favorites} Component={Favorites} />
          <Route path={Paths.archived} Component={Archived} />

          {/* Create / Edit */}
          <Route path={Paths.createEnv} Component={CreateEnv} />
          <Route path={Paths.editEnvBase}>
            <Route path={':paramsID'} Component={CreateEnv} />
          </Route>

          <Route path={Paths.addAnimal} Component={AddAnimal} />
          <Route path={Paths.editAnimalBase}>
            <Route path={':paramsID'} Component={AddAnimal} />
          </Route>

          <Route path={Paths.addObservation} Component={AddObservation} />
          <Route path={Paths.editObservationBase}>
            <Route path={':paramsID'} Component={AddObservation} />
          </Route>

          <Route path={Paths.addInventory} Component={AddInventory} />
          <Route path={Paths.editInventoryBase}>
            <Route path={':paramsID'} Component={AddInventory} />
          </Route>

          <Route path={Paths.editProfileBase}>
            <Route path={':paramsID'} Component={EditProfile} />
          </Route>
          <Route path={Paths.settings} Component={SettingsView} />

          <Route path={Paths.addFeeding} Component={AddFeeding} />
          <Route path={Paths.editFeeding}>
            <Route path={':paramsID'} Component={AddFeeding} />
          </Route>

          <Route
            path={Paths.addFeedingScheduleGroup}
            Component={AddFeedingScheduleGroup}
          />

          <Route path={Paths.bulk} Component={Bulk} />

          <Route
            path={Paths.addInventoryTransaction}
            Component={AddInventoryTransaction}
          />
          <Route path={Paths.editInventoryTransaction}>
            <Route path={':paramsID'} Component={AddInventoryTransaction} />
          </Route>

          {/* Views */}
          <Route path={Paths.viewInventory}>
            <Route path={':paramsID'} Component={ViewInventoryItem} />
          </Route>

          <Route path={Paths.viewEnclosure}>
            <Route path={':paramsID'} Component={ViewEnclosureItem} />
          </Route>

          <Route path={Paths.viewAnimal}>
            <Route path={':paramsID'} Component={ViewAnimalItem} />
          </Route>

          <Route path={Paths.viewProfile}>
            <Route path={':userNameEncoded'} Component={ViewProfileItem} />
          </Route>

          <Route path={Paths.viewFeedingSchedule}>
            <Route path={':paramsID'} Component={ViewFeedingScheduleItem} />
          </Route>

          <Route path={Paths.viewGallery}>
            <Route
              path={Paths.viewEnclosureGalleryItem}
              Component={ViewEnclosureGalleryItem}
            />
            <Route
              path={Paths.viewLifeformGalleryItem}
              Component={ViewLifeformGalleryItem}
            />
            <Route
              path={Paths.viewObservationGalleryItem}
              Component={ViewObservationReadingGalleryItem}
            />
          </Route>

          <Route path={Paths.viewObservation}>
            <Route path={':paramsID'} Component={ViewObservationItem} />
          </Route>
        </Routes>
      </ContextWrapper>
    </div>
  );
};
