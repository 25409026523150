import React from "react";
import { ReactComponent as ArchivedIcon } from "../icons/archived.svg";

export const ArchiveOnCard = (props: { is_archived: boolean }): JSX.Element => {
  return (
    <div className="archive-wrapper">
      <button className="archived-btn">
        <ArchivedIcon className="archived-icon" />
      </button>
      {props.is_archived ? <div className="card-overlay" /> : <></>}
    </div>
  );
};
