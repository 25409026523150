import React from 'react';
import { Modal } from 'antd';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import { ModalProps } from '../../typings/modal';

export const ForgotPasswordModal = (props: ModalProps) => {
  return (
    <Modal
      open={props.modalIsOpen}
      onCancel={props.closeModal}
      title="Forgot Password"
      className="forgot-password-modal"
      footer={null}
    >
      <h3 className="title">Forgot Password</h3>
      <button className="close-btn" onClick={props.closeModal}>
        <CloseIcon className="close-icon" />
      </button>
      <div className="forgot-password-wrapper">
        <div className="input-wrapper">
          <label>Email</label>
          <input type="email" name="email" id="email" />
        </div>
        <div className="modal-footer">
          <button className="btn-done">Save</button>
        </div>
      </div>
    </Modal>
  );
};
