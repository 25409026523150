import {
  FeedingSchedule,
  FeedingScheduleCard as FeedingScheduleCardModel,
} from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';

export interface FetchFeedingSchedulesParams extends Params {
  lifeform_ids?: string;
  account_id?: string;
  since?: string;
  search?: string;
}

export const getFeedingSchedule = async (
  id: string,
): Promise<FeedingSchedule> => {
  try {
    const response = await axiosInstance.get(
      `${PathsAPI.feedingSchedule}/${id}`,
    );

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error('Failed to fetch feeding schedule');
  }
};

export const getFeedingSchedules = async (
  params: FetchFeedingSchedulesParams,
): Promise<ListOutput<FeedingSchedule>> => {
  const response = await axiosInstance.get(PathsAPI.feedingSchedule, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const getFeedingScheduleCards = async (
  params: FetchFeedingSchedulesParams,
): Promise<ListOutput<FeedingScheduleCardModel>> => {
  const response = await axiosInstance.get(PathsAPI.feedingScheduleCards, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const createFeedingSchedule = async (
  feedingSchedule: Partial<FeedingSchedule>,
): Promise<FeedingSchedule> => {
  const response = await axiosInstance.post(
    `${PathsAPI.feedingSchedule}`,
    feedingSchedule,
  );

  return response.data;
};
