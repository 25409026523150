import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as HomeIcon } from '../icons/home.svg';
import { ReactComponent as CreateIcon } from '../icons/add.svg';
import { ReactComponent as BrowseIcon } from '../icons/explore.svg';
import { ReactComponent as ArrowIcon } from '../icons/arrow.svg';
import { ReactComponent as AnimalIcon } from '../icons/pet.svg';
import { ReactComponent as EnclosureIcon } from '../icons/enclosure.svg';
import { ReactComponent as InventoryIcon } from '../icons/inventory.svg';
import { ReactComponent as ObservationIcon } from '../icons/observation.svg';
import { ReactComponent as FeedingIcon } from '../icons/feeding.svg';
import { ReactComponent as BulkIcon } from '../icons/export.svg';
import { ReactComponent as LogoutIcon } from '../icons/logout.svg';
import { ReactComponent as SettingIcon } from '../icons/settings.svg';
import { Paths, PathsAPI } from '../routes/paths';
import { DropDownOptions } from '../typings/menu-dropdown';
import { useMainContext } from '../context/context';
import { DEFAULT_IMAGES } from '../shared/constants';
import { axiosInstance } from '../shared/axios';
import { isCreatePage } from '../typings/pages';
import { Footer } from './footer';

export const MobileMenu = (props: {
  isOpen: boolean;
  currentPage: string;
  setIsOpen: (isOpen: boolean) => void;
}): JSX.Element => {
  const [mainState] = useMainContext();
  const navigate = useNavigate();
  const profileMenu: DropDownOptions[] = [
    {
      text: 'Profile',
      link: `${Paths.viewProfile}/${mainState?.person?.username}`,
      onClick: () => {},
    },
    {
      text: 'Settings',
      link: Paths.settings,
      onClick: () => {},
    },
    {
      text: 'Logout',
      onClick: async () => {
        await axiosInstance.get(PathsAPI.logout);
        navigate(Paths.login);
      },
    },
  ];

  const includesCreatePages = isCreatePage(props.currentPage);
  const [createExpanded, setCreateExpanded] = useState(true);

  return (
    <section className={`mobile-menu ${props.isOpen ? 'open' : ''}`}>
      <div
        className="mobile-menu-overlay"
        onClick={() => {
          props.setIsOpen(false);
        }}
      />
      <div className="mobile-menu-body">
        <div className="profile-area">
          <div className="image-name">
            <a href={`${Paths.viewProfile}/${mainState?.person?.username}`}>
              <img
                src={mainState?.person?.profile_image || DEFAULT_IMAGES.person}
                alt="profile image"
              />
            </a>
            <a href={`${Paths.viewProfile}/${mainState?.person?.id}`}>
              <h6>{mainState?.person?.name ?? 'Anonymous'}</h6>
            </a>
            <a href={`${Paths.viewProfile}/${mainState?.person?.id}`}>
              <p>{mainState?.person?.username ?? 'anonymous'}</p>
            </a>
          </div>
          {mainState.account?.id ? (
            <></>
          ) : (
            <div className="login-wrapper">
              <a href={Paths.login}>Login</a>
              <a href={Paths.signup}>
                <button className="btn">Sign Up</button>
              </a>
            </div>
          )}
        </div>
        <ul className="menu-list">
          {mainState.account?.id ? (
            <li
              className={
                props.currentPage === Paths.home
                  ? 'main-list-item active'
                  : 'main-list-item'
              }
            >
              <a href={Paths.home}>
                <div className="title-icon">
                  <HomeIcon className="home-icon menu-icon" />
                  <h5>Home</h5>
                </div>
              </a>
            </li>
          ) : (
            <></>
          )}

          <li
            className={
              props.currentPage === Paths.browse
                ? 'main-list-item active'
                : 'main-list-item'
            }
          >
            <a href={Paths.browse}>
              <div className="title-icon">
                <BrowseIcon className="browse-icon menu-icon" />
                <h5>Browse</h5>
              </div>
            </a>
          </li>

          {mainState.account?.id ? (
            <li
              className={
                includesCreatePages ? 'main-list-item active' : 'main-list-item'
              }
            >
              <div
                className="row"
                onClick={() => {
                  setCreateExpanded(!createExpanded);
                }}
              >
                <div className="title-icon">
                  <CreateIcon className="create-icon menu-icon" />
                  <h5>Add</h5>
                </div>
                <ArrowIcon
                  className={`arrow-icon menu-icon ${
                    createExpanded ? 'open' : 'closed'
                  }`}
                />
              </div>
              <ul className={`sub-pages ${createExpanded ? 'open' : ''}`}>
                <li
                  className={`sub-page-list-item ${
                    Paths.addAnimal === props.currentPage ? 'active' : ''
                  }`}
                >
                  <a href={Paths.addAnimal}>
                    <AnimalIcon className="animal-icon icon" />
                    <h6>Animal</h6>
                  </a>
                </li>
                <li
                  className={`sub-page-list-item ${
                    Paths.createEnv === props.currentPage ? 'active' : ''
                  }`}
                >
                  <a href={Paths.createEnv}>
                    <EnclosureIcon className="enclosure-icon icon" />
                    <h6>Enclosure</h6>
                  </a>
                </li>
                <li
                  className={`sub-page-list-item ${
                    Paths.addInventory === props.currentPage ? 'active' : ''
                  }`}
                >
                  <a href={Paths.addInventory}>
                    <InventoryIcon className="inventory-icon icon" />
                    <h6>Inventory Group</h6>
                  </a>
                </li>
                <li
                  className={`sub-page-list-item ${
                    Paths.addObservation === props.currentPage ? 'active' : ''
                  }`}
                >
                  <a href={Paths.addObservation}>
                    <ObservationIcon className="observation-icon icon" />
                    <h6>Observation</h6>
                  </a>
                </li>
                <li
                  className={`sub-page-list-item ${
                    Paths.addFeeding === props.currentPage ? 'active' : ''
                  }`}
                >
                  <a href={Paths.addFeedingScheduleGroup}>
                    <FeedingIcon className="feeding-icon icon" />
                    <h6>Feedings</h6>
                  </a>
                </li>
                <li
                  className={`sub-page-list-item ${
                    Paths.bulk === props.currentPage ? 'active' : ''
                  }`}
                >
                  <a href={Paths.bulk}>
                    <BulkIcon className="bulk-icon icon" />
                    <h6>Bulk</h6>
                  </a>
                </li>
              </ul>
            </li>
          ) : (
            <></>
          )}

          {mainState.account?.id ? (
            <li
              className={
                props.currentPage === Paths.settings
                  ? 'main-list-item active'
                  : 'main-list-item'
              }
            >
              <a href={Paths.settings}>
                <div className="title-icon">
                  <SettingIcon className="icon settings-icon menu-icon" />
                  <h5>Settings</h5>
                </div>
              </a>
            </li>
          ) : (
            <></>
          )}

          {mainState.account?.id ? (
            <li
              className={
                props.currentPage === Paths.logout
                  ? 'main-list-item active'
                  : 'main-list-item'
              }
            >
              <a
                href={Paths.logout}
                onClick={async () => {
                  await axiosInstance.get(PathsAPI.logout);
                  navigate(Paths.login);
                }}
              >
                <div className="title-icon">
                  <LogoutIcon className="icon logout-icon menu-icon" />
                  <h5>Logout</h5>
                </div>
              </a>
            </li>
          ) : (
            <></>
          )}
        </ul>
      </div>
      <Footer />
    </section>
  );
};
