import React, { useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Header } from '../../components/header';
import {
  CodeInput,
  ConfirmPasswordInput,
  NewPasswordInput,
} from '../../components/input';
import packageJson from '../../../package.json';
import { Paths } from '../../routes/paths';
import { validatePassword } from '../../shared/validation';
import { errorNotification } from '../../components/notification';
import { resetPassword } from '../../actions/auth';
import { BRAND_NAME } from '../../shared/constants';

export const PasswordReset = (): JSX.Element => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  // Validate Token else redirect to login page
  if (!token) {
    navigate(Paths.login);
  }

  return (
    <div className="login password-reset">
      <Header hideLinks={false} />
      <section className="login-background">
        <div className="login-overlay">
          <div className="page-header">
            <h4>Password Reset Email Has Been Sent</h4>
            <h6>Please enter the code you received below.</h6>
          </div>
          <div className="form-wrapper">
            <form
              onSubmit={async (event) => {
                event.preventDefault();
              }}
            >
              <NewPasswordInput password={password} setPassword={setPassword} />
              <ConfirmPasswordInput
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
              />
              <button
                className="btn-primary"
                onClick={async () => {
                  if (
                    !token ||
                    password === '' ||
                    password !== confirmPassword ||
                    !validatePassword(password)
                  ) {
                    errorNotification(
                      'You must have a valid password to continue.',
                    );

                    return;
                  }

                  await resetPassword({
                    token,
                    confirmPassword,
                    newPassword: password,
                  });
                  navigate(Paths.home);
                }}
              >
                Reset Password
              </button>
            </form>
          </div>
          <footer>
            <p>
              © {new Date().getFullYear()} {BRAND_NAME}
            </p>
            <p className="app-version">v {packageJson.version}</p>
          </footer>
        </div>
      </section>
    </div>
  );
};
