import { Inventory, InventoryTransaction } from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { InventoryCategories } from '../typings/inventory';

export interface FetchInventoryParams extends Params {
  category?: string;
  item_name?: string;
  account_id?: number;
  search?: string;
  include_empty?: boolean;
  ignore_archived?: boolean;
}

export interface FetchInventoryTransactionParams extends Params {
  inventory_id?: string;
}

export interface BulkCreateInventoryParams {
  item_name?: string;
  is_archived?: boolean;
  is_public?: boolean;
  image_url?: string;
  quantity: number;
  average_cost_per_item: number;
  category: InventoryCategories;
}

export interface InventoryAttribute {
  id: string;
  key: string;
  value: string;
  created_on: string;
  updated_on: string;
}

export const getInventoryCards = async (
  params: FetchInventoryParams,
): Promise<ListOutput<Inventory>> => {
  const response = await axiosInstance.get(PathsAPI.inventory, { params });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getInventory = async (id: number | string): Promise<Inventory> => {
  const response = await axiosInstance.get(`${PathsAPI.inventory}/${id}`);

  return response.data;
};

export const getInventoryTransactions = async (
  params: FetchInventoryTransactionParams,
): Promise<ListOutput<InventoryTransaction>> => {
  const response = await axiosInstance.get(PathsAPI.inventoryTransaction, {
    params,
  });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
    total: response.data.total,
  };
};

export const getInventoryTransaction = async (
  id: number | string,
): Promise<InventoryTransaction> => {
  const response = await axiosInstance.get(
    `${PathsAPI.inventoryTransaction}/${id}`,
  );

  return response.data;
};

export const deleteInventory = async (id: number | string): Promise<void> => {
  await axiosInstance.delete(`${PathsAPI.inventory}/${id}`);
};

export const updateInventory = async (
  inventory: Partial<Inventory>,
): Promise<Inventory> => {
  const response = await axiosInstance.put(`${PathsAPI.inventory}`, inventory);

  return response.data;
};

export const createInventory = async (
  inventory: Partial<Inventory>,
): Promise<Inventory> => {
  const response = await axiosInstance.post(`${PathsAPI.inventory}`, inventory);

  return response.data;
};

export const createInventoryTransaction = async (
  inventoryTransaction: Partial<InventoryTransaction>,
): Promise<InventoryTransaction> => {
  const response = await axiosInstance.post(
    `${PathsAPI.inventoryTransaction}`,
    inventoryTransaction,
  );

  return response.data;
};

export const updateInventoryTransaction = async (
  inventoryTransaction: Partial<InventoryTransaction>,
): Promise<InventoryTransaction> => {
  const response = await axiosInstance.put(
    `${PathsAPI.inventoryTransaction}`,
    inventoryTransaction,
  );

  return response.data;
};

export const deleteInventoryTransaction = async (
  id: number | string,
): Promise<void> => {
  await axiosInstance.delete(`${PathsAPI.inventoryTransaction}/${id}`);
};

export const bulkCreateInventory = async (
  createParams: BulkCreateInventoryParams[],
): Promise<Inventory[]> => {
  const response = await axiosInstance.post(`${PathsAPI.inventoryBulk}`, {
    data: createParams,
  });

  return response.data;
};

export const getInventoryAttributes = async (
  id: string | number,
): Promise<InventoryAttribute[]> => {
  const response = await axiosInstance.get(
    `${PathsAPI.inventory}/${id}/attributes`,
  );

  return response.data;
};
