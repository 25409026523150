import { ObservationCard as ObservationCardModel } from 'cobranetics-types/types';
import { ListOutput } from '../actions';
import { SelectListOption } from './common';

export enum ObservationCategories {
  General = 'General',
  Activity = 'Activity',
  Ecdysis = 'Ecdysis',
  Behavior = 'Behavior',
  Eating = 'Eating',
  Basking = 'Basking',
  Sleeping = 'Sleeping',
}

export interface ObservationAction {
  observations: ListOutput<ObservationCardModel>;
}

export enum AddObservationTabs {
  general = 'General',
  images = 'Images',
}

export interface GeneralProps {
  name: string;
  setName: (name: string) => void;
  selectedType: SelectListOption | undefined;
  setSelectedType: (selectedType: SelectListOption | undefined) => void;
  animalOptions: SelectListOption[];
  selectedAnimalOption: SelectListOption | undefined;
  setSelectedAnimalOption: (animalOpt: SelectListOption | undefined) => void;
  isEdit: boolean;
  recording: string;
  setRecording: (recording: string) => void;
  setOpenDeleteModal: (open: boolean) => void;
  saveObservation: () => Promise<void>;
  observationType: {
    value: ObservationCategories;
    label: string;
  }[];
  setActiveTab: (tab: AddObservationTabs) => void;
  dateTime: string;
  setDateTime: (dT: string) => void;
}
export interface ImageProps {
  images: { id: string; src: string }[];
  activeImage: string;
  setImages: (images: { id: string; src: string }[]) => void;
  setActiveImage: (length: string) => void;
  setActiveTab: (tab: AddObservationTabs) => void;
  isEdit: boolean;
  observationID: string;
  navigate: any;
  imageCount: number;
}
