import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CHECK, FAVORITE_PAGE_ACTION } from '../actions';
import { getAnimalCards } from '../actions/animals';
import { getCheck } from '../actions/check';
import { getEnclosureCards } from '../actions/enclosure';
import {
  getFavoriteEnclosures,
  getFavoriteLifeforms,
} from '../actions/favorites';
import {
  getEnclosureGalleryCards,
  getLifeformGalleryCards,
} from '../actions/gallery';
import { AnimalCard } from '../components/animals';
import { CommonRow } from '../components/common-wrapper';
import { EnclosureCard } from '../components/enclosures';
import { GalleryCard } from '../components/gallery';
import { Header } from '../components/header';

import { useMainContext } from '../context/context';
import { Paths } from '../routes/paths';
import { FailedAuthCheck } from '../shared/errors';
import { getFavoriteMenus } from '../shared/menu';
import { MainAction } from '../typings/main_context_reducer';
import { errorNotification } from '../components/notification';

export const Favorites = (): JSX.Element => {
  const [isBusy, setBusy] = useState(true);
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();

  const enclosureGalleryLength = mainState?.enclosureGallery?.data.length ?? 0;
  const lifeformGalleryLength = mainState?.lifeformGallery?.data.length ?? 0;
  const totalGalleryLength = enclosureGalleryLength + lifeformGalleryLength;
  const gallery: {
    id: string;
    image: string;
    category: 'lifeform' | 'enclosure';
  }[] = [];

  const commonRowMenus = getFavoriteMenus(navigate);

  mainState?.lifeformGallery?.data.forEach((galleryItem) => {
    gallery.push({
      id: galleryItem.id,
      image: galleryItem.image_url,
      category: 'lifeform',
    });
  });

  mainState?.enclosureGallery?.data.forEach((galleryItem) => {
    gallery.push({
      id: galleryItem.id,
      image: galleryItem.image_url,
      category: 'enclosure',
    });
  });

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const baseParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
        };

        const favoriteEnclosuresParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
        };
        const favoriteEnclosures = await getFavoriteEnclosures(
          favoriteEnclosuresParams,
        );

        const favoriteLifeformsParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
        };

        const favoriteLifeforms = await getFavoriteLifeforms(
          favoriteLifeformsParams,
        );

        const animalParams = {
          ...baseParams,
          account_id: check.account.id,
          ids: favoriteLifeforms.map((fav) => fav.lifeform_id).join(','),
        };
        const enclosureParams = {
          ...baseParams,
          account_id: check.account.id,
          ids: favoriteEnclosures.map((fav) => fav.enclosure_id).join(','),
        };
        const animals = await getAnimalCards(animalParams);
        const enclosures = await getEnclosureCards(enclosureParams);

        const enclosureGalleryParams = {
          ...baseParams,
          enclosure_ids: enclosures.data
            .map((enclosure) => `${enclosure.id}`)
            .join(','),
        };
        const lifeformGalleryParams = {
          ...baseParams,
          lifeform_ids: animals.data.map((animal) => `${animal.id}`).join(','),
        };
        const enclosureGallery = await getEnclosureGalleryCards(
          enclosureGalleryParams,
        );
        const lifeformGallery = await getLifeformGalleryCards(
          lifeformGalleryParams,
        );

        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        const homeAction = {
          type: FAVORITE_PAGE_ACTION,
          favoritePageAction: {
            animals,
            enclosures,
            enclosureGallery,
            lifeformGallery,
          },
        } as MainAction;

        dispatch(checkAction);
        dispatch(homeAction);
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.error(error);
        errorNotification('Failed to load data.');
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="favorites">
      <Header hideLinks={false} title={'Favorites'} />
      {!isBusy ? (
        <>
          <div className="desktop-wrapper">
            <section className="favorites-background">
              <CommonRow
                title="Animals"
                titleLink={Paths.viewAnimal}
                showAddBtn={mainState?.animals?.data.length === 0}
                addBtnClick={() => {}}
                menu={commonRowMenus.animalMenu}
              >
                {(mainState?.animals?.data.length ?? 0) > 0 ? (
                  <div className="common-row-inner">
                    {mainState?.animals?.data.map((animal, i) => (
                      <AnimalCard key={'animal-card-' + i} {...animal} />
                    ))}
                  </div>
                ) : (
                  <div className="empty-placeholder">
                    <h5>Empty</h5>
                  </div>
                )}
              </CommonRow>
              <CommonRow
                title="Enclosures"
                titleLink={Paths.viewEnclosure}
                showAddBtn={mainState?.enclosures?.data.length === 0}
                addBtnClick={() => {}}
                menu={commonRowMenus.enclosureMenu}
              >
                {(mainState?.enclosures?.data.length ?? 0) > 0 ? (
                  <div className="common-row-inner">
                    {mainState?.enclosures?.data.map((enclosure, i) => (
                      <EnclosureCard
                        key={'enclosure-card-' + i}
                        {...enclosure}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empty-placeholder">
                    <h5>Empty</h5>
                  </div>
                )}
              </CommonRow>
              <CommonRow
                title="Images"
                titleLink={Paths.viewGallery}
                showAddBtn={totalGalleryLength === 0}
                addBtnClick={() => {}}
                menu={commonRowMenus.galleryMenu}
              >
                {totalGalleryLength > 0 ? (
                  <div className="common-row-inner">
                    {gallery.map((galleryItem, i) => (
                      <GalleryCard
                        key={'gallery-card-' + i}
                        id={galleryItem.id}
                        image={galleryItem.image}
                        category={galleryItem.category}
                      />
                    ))}
                  </div>
                ) : (
                  <div className="empty-placeholder">
                    <h5>Empty</h5>
                  </div>
                )}
              </CommonRow>
            </section>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
