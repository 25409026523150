import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CHECK } from '../actions';
import { authRefresh } from '../actions/check';
import { Header } from '../components/header';

import { SearchWrapper } from '../components/search';
import { useMainContext } from '../context/context';
import { FailedAuthCheck } from '../shared/errors';
import { MainAction } from '../typings/main_context_reducer';
import { ResultsWrapper } from '../components/results-wrapper';
import { fetchFullResults, resetFullResults } from '../actions/search';
import { FilterTypeOptions, FullSearchResults } from '../typings/search';
import { SearchResult } from 'cobranetics-types/types';
import { SEARCH_TEMPLATE } from '../shared/constants';
import { LoadingSpinner } from '../components/loading';
import { errorNotification } from '../components/notification';

export const Browse = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [search, setSearch] = useState(searchParams.get('search') || '');
  const filterParam = searchParams.get('filter');
  const [isBusy, setBusy] = useState(true);
  const [searchData, setSearchData] = useState<SearchResult[]>([]);
  const [, dispatch] = useMainContext();

  const quickTabs = Object.values(FilterTypeOptions).filter(
    (row) =>
      row !== FilterTypeOptions.feedings &&
      row !== FilterTypeOptions.feedingSchedule,
  );
  const tab = quickTabs?.[0] ?? '';
  const [activeTab, setActiveTab] = useState(filterParam || tab);

  const [loadMore, setLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [fullSearchResults, setFullSearchResults] = useState<FullSearchResults>(
    { ...SEARCH_TEMPLATE },
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();

        if (check !== undefined) {
          const checkAction = {
            type: CHECK,
            checkAction: {
              account: check.account,
              person: check.person,
              settings: check.settings,
              membershipConfig: check.membershipConfig,
            },
          } as MainAction;

          dispatch(checkAction);
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.error(error);
        errorNotification('Failed to load data.');
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      resetFullResults(fullSearchResults);

      const { currentSearchResults } = await fetchFullResults(
        fullSearchResults,
        search,
        currentPage,
        activeTab,
      );

      const searchResults = [];
      for (const key of Object.keys(currentSearchResults)) {
        searchResults.push(...currentSearchResults[key].data);
      }

      setFullSearchResults(currentSearchResults);
      setSearchData([...searchResults]);

      setCurrentPage(currentPage + 1);
      setLoadMore(false);
    };

    loadData();
  }, [activeTab]);

  useEffect(() => {
    if (!loadMore) {
      return;
    }

    const loadData = async () => {
      const { currentSearchResults, innerPage } = await fetchFullResults(
        fullSearchResults,
        search,
        currentPage,
        activeTab,
      );

      setCurrentPage(innerPage);
      setFullSearchResults(currentSearchResults);

      // format
      const searchResults = [];
      for (const key of Object.keys(currentSearchResults)) {
        searchResults.push(...currentSearchResults[key].data);
      }

      setSearchData([...searchResults]);
      setLoadMore(false);
    };

    loadData();
  }, [loadMore]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      setLoadMore(true);
    }
  };

  return (
    <div className="home">
      {!isBusy ? (
        <>
          <Header hideLinks={false} title="Browse" />
          <div
            className="desktop-wrapper"
            onScroll={(e: any) => {
              handleScroll(e);
            }}
          >
            <section
              className="home-background"
              onScroll={(e: any) => {
                handleScroll(e);
              }}
            >
              <section className="search-section">
                <h2 className={`search-page-title`}>
                  Browse
                  {activeTab !== FilterTypeOptions.all ? ` ${activeTab}` : ''}
                </h2>
                <SearchWrapper
                  search={search}
                  setSearch={setSearch}
                  quickTabs={quickTabs}
                  activeTab={activeTab}
                  setActiveTab={(activeTab) => {
                    setActiveTab(activeTab);
                    setSearchData([]);
                    setCurrentPage(1);
                    setSearchParams({
                      filter: activeTab,
                    });
                  }}
                  action={async () => {
                    resetFullResults(fullSearchResults);

                    const { currentSearchResults } = await fetchFullResults(
                      fullSearchResults,
                      '',
                      1,
                      activeTab,
                    );

                    const searchResults = [];
                    for (const key of Object.keys(currentSearchResults)) {
                      searchResults.push(...currentSearchResults[key].data);
                    }

                    setFullSearchResults(currentSearchResults);
                    setSearchData([...searchResults]);

                    setCurrentPage(2);
                    setLoadMore(false);
                  }}
                  showFilterButton={true}
                />
              </section>
              <ResultsWrapper
                data={searchData}
                hideSubtitles={activeTab !== FilterTypeOptions.all}
              />
              {isBusy ? (
                <LoadingSpinner show={true} includeText={true} />
              ) : (
                <></>
              )}
            </section>
          </div>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
