/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  EnclosureCard,
  FollowPairCard as FollowPairCardModel,
  Inventory,
  LifeformCard,
  ObservationCard,
} from 'cobranetics-types/types';

import { CHECK } from '../../actions';
import { getAnimalCards } from '../../actions/animals';
import { authRefresh } from '../../actions/check';
import { getEnclosureCards } from '../../actions/enclosure';
import { getFollowPairCards } from '../../actions/follow_pair';
import { getObservationCards } from '../../actions/observation';
import { getPerson } from '../../actions/person';
import { getSocial } from '../../actions/social';
import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { useMainContext } from '../../context/context';
import { PathsAPI } from '../../routes/paths';
import { axiosInstance } from '../../shared/axios';
import { DEFAULT_IMAGES } from '../../shared/constants';
import { FailedAuthCheck } from '../../shared/errors';
import { randomTextGenerator, shuffleArr } from '../../shared/utils';
import { MainAction } from '../../typings/main_context_reducer';
import { Person } from 'cobranetics-types/types';
import { SocialModel } from '../../typings/social';
import { getProfileMenus } from '../../shared/menu';
import { ProfileCard, SocialSection } from '../../components/profiles';
import { ObservationCard as ObservationTile } from '../../components/observation';
import { AnimalCard } from '../../components/animals';
import { EnclosureCard as EnclosureTile } from '../../components/enclosures';
import { getInventoryCards } from '../../actions/inventory';
import { InventoryCard as InventoryTile } from '../../components/inventory';
import { errorNotification } from '../../components/notification';

export const ViewProfileItem = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const { userNameEncoded } = useParams();
  const userName = decodeURI(userNameEncoded ?? '');
  console.log({ userNameEncoded });
  const perPage = 20;

  const [isBusy, setBusy] = useState(true);
  const [profile, setProfile] = useState({} as Person);
  const [social, setSocial] = useState({} as SocialModel);
  const [followCards, setFollowCards] = useState([] as FollowPairCardModel[]);
  const [observations, setObservations] = useState<ObservationCard[]>([]);
  const [animals, setAnimals] = useState<LifeformCard[]>([]);
  const [enclosures, setEnclosures] = useState<EnclosureCard[]>([]);
  const [inventory, setInventory] = useState<Inventory[]>([]);

  const [currentPage, setCurrentPage] = useState({
    animal: 1,
    enclosure: 1,
    observation: 1,
    inventory: 1,
    following: 1,
  });
  const [totalPages, setTotalPages] = useState({
    animal: 0,
    enclosure: 0,
    observation: 0,
    inventory: 0,
    following: 0,
  });

  const isCurrentUser = mainState?.person?.id === profile.id;
  const commonRowMenus = getProfileMenus(
    navigate,
    `${profile.id}`,
    isCurrentUser,
  );

  const gallery: {
    id: string;
    image: string;
    category: 'lifeform' | 'enclosure';
  }[] = [];

  mainState?.lifeformGallery?.data.forEach((galleryItem) => {
    gallery.push({
      id: galleryItem.id,
      image: galleryItem.image_url,
      category: 'lifeform',
    });
  });

  mainState?.enclosureGallery?.data.forEach((galleryItem) => {
    gallery.push({
      id: galleryItem.id,
      image: galleryItem.image_url,
      category: 'enclosure',
    });
  });

  shuffleArr(gallery);

  useEffect(() => {
    const loadData = async () => {
      try {
        // if (!userName) {
        //   return;
        // }

        const check = await authRefresh();
        const person = await getPerson(userName);
        const social = await getSocial(person.id);

        const baseParams = {
          perPage,
          sort_by: '-id',
        };
        const animalParams = {
          ...baseParams,
          currentPage: currentPage.animal,
          account_id: person.account_id,
          is_archived: false,
        };
        const enclosureParams = {
          ...baseParams,
          currentPage: currentPage.enclosure,
          account_id: person.account_id,
          is_archived: false,
        };
        const observationParams = {
          ...baseParams,
          currentPage: currentPage.observation,
          account_id: person.account_id,
        };
        const inventoryParams = {
          ...baseParams,
          currentPage: currentPage.inventory,
          account_id: person.account_id,
          is_archived: false,
        };
        const followPairParams = {
          ...baseParams,
          currentPage: currentPage.following,
          follower_id: profile.id,
        };

        const animalCards = await getAnimalCards(animalParams);
        const enclosureCards = await getEnclosureCards(enclosureParams);
        const observationCards = await getObservationCards(observationParams);
        const inventoryCards = await getInventoryCards(inventoryParams);
        const followPair = await getFollowPairCards(followPairParams);

        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;

        dispatch(checkAction);
        setProfile(person);
        setSocial(social[0] ?? {});
        setFollowCards(followPair.data);
        setObservations(observationCards.data);
        setAnimals(animalCards.data);
        setEnclosures(enclosureCards.data);
        setInventory(inventoryCards.data);
        setTotalPages({
          animal: animalCards.total || 0,
          enclosure: enclosureCards.total || 0,
          observation: observationCards.total || 0,
          inventory: inventoryCards.total || 0,
          following: followPair.total || 0,
        });
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="view-profile">
      <Header hideLinks={false} title={'Profile'} />
      <div className="desktop-wrapper  full-width">
        {!isBusy ? (
          <section className="view-profile-background">
            <PageHeader
              title={profile.username}
              showBackBtn={true}
              activeTab={''}
              menu={isCurrentUser ? commonRowMenus.mainMenu : undefined}
            />
            <section className="view-profile-item">
              <div className="main-info">
                <div className="image-wrapper">
                  <img
                    className="display-image"
                    src={profile.profile_image || DEFAULT_IMAGES.person}
                  />
                  {isCurrentUser || !mainState.account?.id ? (
                    <></>
                  ) : (
                    <>
                      {followCards.length > 0 ? (
                        <button
                          className="following-btn"
                          onClick={async () => {
                            await axiosInstance.delete(
                              `${PathsAPI.followPair}/${followCards[0].id}`,
                            );

                            setFollowCards([]);
                          }}
                        >
                          Following
                        </button>
                      ) : (
                        <button
                          className="follow-btn"
                          onClick={async () => {
                            const followPair: {
                              data: FollowPairCardModel;
                            } = await axiosInstance.post(
                              `${PathsAPI.followPair}`,
                              {
                                person_id: profile.id,
                              },
                            );

                            setFollowCards([followPair.data]);
                          }}
                        >
                          Follow
                        </button>
                      )}
                    </>
                  )}
                </div>
                <div className="social-bio-wrapper">
                  <p className="bio">{profile.bio}</p>
                  <SocialSection social={social} />
                </div>
              </div>

              {!!animals.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Animals</h5>
                  <div className="inner-wrapper">
                    {animals.map((row) => (
                      <AnimalCard
                        key={`animal-${row.created_on.replaceAll(
                          ' ',
                          '',
                        )}-${randomTextGenerator(4)}`}
                        {...row}
                      />
                    ))}

                    {animals.length < totalPages.animal ? (
                      <button
                        className="btn load-more-btn"
                        onClick={async () => {
                          if (animals.length >= totalPages.animal) {
                            return;
                          }

                          const nextPage = currentPage.animal + 1;
                          const params = {
                            perPage,
                            sort_by: '-id',
                            currentPage: nextPage,
                            account_id: profile.account_id,
                            is_archived: false,
                          };
                          const animalCards = await getAnimalCards(params);

                          setAnimals([...animals, ...animalCards.data]);
                          setCurrentPage({ ...currentPage, animal: nextPage });
                        }}
                      >
                        Load More
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              )}

              {!!enclosures.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Enclosures</h5>
                  <div className="inner-wrapper">
                    {enclosures.map((row) => (
                      <EnclosureTile
                        key={`enclosure-${row.created_on.replaceAll(
                          ' ',
                          '',
                        )}-${randomTextGenerator(4)}`}
                        {...row}
                      />
                    ))}

                    {enclosures.length < totalPages.enclosure ? (
                      <button
                        className="btn load-more-btn"
                        onClick={async () => {
                          if (enclosures.length >= totalPages.enclosure) {
                            return;
                          }

                          const nextPage = currentPage.enclosure + 1;
                          const params = {
                            perPage,
                            sort_by: '-id',
                            currentPage: nextPage,
                            account_id: profile.account_id,
                            is_archived: false,
                          };
                          const cards = await getEnclosureCards(params);

                          setEnclosures([...enclosures, ...cards.data]);
                          setCurrentPage({
                            ...currentPage,
                            enclosure: nextPage,
                          });
                        }}
                      >
                        Load More
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              )}

              {!!observations.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Observations</h5>
                  <div className="inner-wrapper">
                    {observations.map((row) => (
                      <ObservationTile
                        key={`observation-${row.recording.replaceAll(
                          ' ',
                          '',
                        )}-${randomTextGenerator(4)}`}
                        {...row}
                      />
                    ))}

                    {observations.length < totalPages.observation ? (
                      <button
                        className="btn load-more-btn"
                        onClick={async () => {
                          if (observations.length >= totalPages.observation) {
                            return;
                          }

                          const nextPage = currentPage.observation + 1;
                          const params = {
                            perPage,
                            sort_by: '-id',
                            currentPage: nextPage,
                            account_id: profile.account_id,
                          };
                          const cards = await getObservationCards(params);

                          setObservations([...observations, ...cards.data]);
                          setCurrentPage({
                            ...currentPage,
                            observation: nextPage,
                          });
                        }}
                      >
                        Load More
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              )}

              {!!inventory.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Inventory</h5>
                  <div className="inner-wrapper">
                    {inventory.map((row) => (
                      <InventoryTile
                        key={`inventory-${row.created_on.replaceAll(
                          ' ',
                          '',
                        )}-${randomTextGenerator(4)}`}
                        {...row}
                      />
                    ))}
                    {inventory.length < totalPages.inventory ? (
                      <button
                        className="btn load-more-btn"
                        onClick={async () => {
                          if (inventory.length >= totalPages.inventory) {
                            return;
                          }

                          const nextPage = currentPage.inventory + 1;
                          const params = {
                            perPage,
                            sort_by: '-id',
                            currentPage: nextPage,
                            account_id: profile.account_id,
                            is_archived: false,
                          };
                          const cards = await getInventoryCards(params);

                          setInventory([...inventory, ...cards.data]);
                          setCurrentPage({
                            ...currentPage,
                            inventory: nextPage,
                          });
                        }}
                      >
                        Load More
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              )}

              {!!followCards.length && (
                <section className="simple-items-wrapper">
                  <h5 className="title">Following</h5>
                  <div className="inner-wrapper">
                    {followCards.map((row) => (
                      <ProfileCard
                        key={`following-${row.created_on.replaceAll(
                          ' ',
                          '',
                        )}-${randomTextGenerator(4)}`}
                        {...row}
                        id={row.person_id}
                        followPairID={row.id}
                        name={row.person_name}
                        username={row.person_username}
                        bio={row.person_bio}
                        image={row.person_image_url}
                        isFollowing={true}
                        hideFollowBtn={true}
                        onClick={async () => {}}
                      />
                    ))}
                    {followCards.length < totalPages.following ? (
                      <button
                        className="btn load-more-btn"
                        onClick={async () => {
                          if (followCards.length >= totalPages.following) {
                            return;
                          }

                          const nextPage = currentPage.following + 1;
                          const params = {
                            perPage,
                            sort_by: '-id',
                            currentPage: nextPage,
                            follower_id: profile.id,
                          };
                          const cards = await getFollowPairCards(params);

                          setFollowCards([...followCards, ...cards.data]);
                          setCurrentPage({
                            ...currentPage,
                            following: nextPage,
                          });
                        }}
                      >
                        Load More
                      </button>
                    ) : (
                      <></>
                    )}
                  </div>
                </section>
              )}
            </section>
          </section>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};
