/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { getCheck } from '../../actions/check';
import { CHECK } from '../../actions';
import { FailedAuthCheck } from '../../shared/errors';
import { MainAction } from '../../typings/main_context_reducer';
import { useMainContext } from '../../context/context';
import { DateTimeInput, NameInput, selectStyle } from '../../components/input';
import { SelectListOption } from '../../typings/common';
import { formatDate, getOption } from '../../shared/utils';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { ReactComponent as EditIcon } from '../../icons/edit.svg';
import { Paths } from '../../routes/paths';
import { Table } from '../../components/tables';
import { Feeding } from 'cobranetics-types/types';
import { getAnimal, getAnimals } from '../../actions/animals';
import {
  createFeeding,
  deleteFeeding,
  FetchFeedingParams,
  getFeeding,
  getFeedings,
  updateFeeding,
} from '../../actions/feeding';
import {
  getFeedingSchedule,
  getFeedingSchedules,
} from '../../actions/feeding_schedule';
import { DeleteModal } from '../../components/modals/delete';
import { successNotification } from '../../components/notification';
import { formateDateTimeForAPI } from '../../utils/dates';

export const AddFeeding = (): JSX.Element => {
  const { paramsID } = useParams();
  const isEdit = paramsID !== undefined;
  const [, dispatch] = useMainContext();
  const navigate = useNavigate();
  const pageID = isEdit ? Number.parseInt(paramsID) : 0;
  const [isBusy, setBusy] = useState(true);
  const [searchParams] = useSearchParams();
  const [openModal, setOpenModal] = useState(false);
  const [meal, setMeal] = useState('');

  const [animalOptions, setAnimalOptions] = useState<SelectListOption[]>([]);
  const [selectedAnimalOption, setSelectedAnimalOption] = useState<
    SelectListOption | undefined
  >(undefined);

  const ateOptions = [
    {
      value: 'Yes',
      label: 'Yes',
    },
    {
      value: 'No',
      label: 'No',
    },
    {
      value: 'Not Yet',
      label: 'Not Yet',
    },
  ];
  const [selectedAteOption, setSelectedAteOption] = useState(ateOptions[0]);

  const [date, time] = formatDate(new Date()).split(' ');
  const [dateTime, setDateTime] = useState(date + 'T' + time);

  const [feedings, setFeedings] = useState([] as Feeding[]);
  const feedingColumns = React.useMemo(
    () => [
      {
        Header: '242341cac232',
        hideHeader: true,
        columns: [
          {
            Header: '',
            accessor: 'action',
          },
          {
            Header: 'Meal',
            accessor: 'meal',
          },
          {
            Header: 'Ate',
            accessor: 'ate',
          },
          {
            Header: 'Feed On',
            accessor: 'feedOn',
          },
        ],
      },
    ],
    [],
  );

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      if (!paramsID) return;

      const feeding = await getFeeding(paramsID);
      await deleteFeeding(paramsID);
      navigate(`${Paths.viewFeedingSchedule}/${feeding.feeding_schedule_id}`);
    },
    modalIsOpen: openModal,
    category: 'feeding',
    name: meal,
  };

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);

        const animalParams = {
          currentPage: 1,
          perPage: 10,
          sort_by: '-id',
          account_id: check.account.id,
          is_archived: false,
        };
        const animals = await getAnimals(animalParams);
        const animalOpts = animals.data.map((animal) => ({
          value: animal.id + '',
          label: `${animal.pet_name} (${
            check.settings.name_preference
              ? animal.scientific_name
              : animal.common_name
          })`,
        }));
        setAnimalOptions(animalOpts);

        if (isEdit) {
          if (Number.isNaN(pageID)) {
            return;
          }

          const feeding = await getFeeding(paramsID);
          const feedingSchedule = await getFeedingSchedule(
            feeding.feeding_schedule_id,
          );

          const animal = await getAnimal(feedingSchedule.lifeform_id);
          setSelectedAnimalOption(getOption(animal.id + '', animalOpts));
          setFeedings([feeding]);
          setMeal(feeding.meal);
        } else if (searchParams.get('lifeform_id')) {
          setSelectedAnimalOption(
            getOption(searchParams.get('lifeform_id') ?? '', animalOpts),
          );
        } else {
          setSelectedAnimalOption(animalOpts[0]);
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    const loadData = async () => {
      if (isEdit) {
        return;
      }

      const feedingParams: FetchFeedingParams = {
        currentPage: 1,
        perPage: 20,
        sort_by: '-feed_on',
        lifeform_id:
          selectedAnimalOption?.value ??
          searchParams.get('lifeform_id') ??
          undefined,
      };

      const feedings = await getFeedings(feedingParams);
      setFeedings(feedings.data);
    };

    loadData();
  }, [selectedAnimalOption]);

  return (
    <div className="add-feeding">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="add-feeding-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title={`${isEdit ? 'Edit' : 'Add'} Feeding`}
                showBackBtn={true}
                activeTab={''}
              />
              <section className="common-section">
                <div className="top">
                  <div className="add-recording-wrapper">
                    <div className="title-input-wrapper">
                      <p className="title">Animal</p>
                      <Select
                        options={animalOptions}
                        styles={selectStyle}
                        placeholder="Select an animal"
                        name="animalOptions"
                        id="animalOptions"
                        value={selectedAnimalOption}
                        onChange={(row) => {
                          setSelectedAnimalOption(
                            row
                              ? getOption(row?.value, animalOptions)
                              : undefined,
                          );
                        }}
                        isDisabled={isEdit}
                      />
                    </div>
                    <div className="dual-input-wrapper">
                      <div className="title-input-wrapper">
                        <p className="title">Feed On</p>
                        <DateTimeInput
                          dateTime={dateTime}
                          setDateTime={setDateTime}
                          placeholder=""
                          inputName="feeding-time"
                        />
                      </div>
                      <div className="title-input-wrapper">
                        <p className="title">Ate</p>
                        <Select
                          options={ateOptions}
                          styles={selectStyle}
                          placeholder="Has ate"
                          name="ateOptions"
                          id="ateOptions"
                          value={selectedAteOption}
                          onChange={(row) => {
                            setSelectedAteOption(row ?? ateOptions[0]);
                          }}
                        />
                      </div>
                    </div>
                    <div className="title-input-wrapper">
                      <p className="title">Meal</p>
                      <NameInput name={meal} setName={setMeal} placeholder="" />
                    </div>
                    {!isEdit ? (
                      <>
                        <button
                          className="btn-done btn-add-feeding"
                          onClick={async () => {
                            if (!selectedAnimalOption?.value || !meal.length) {
                              return;
                            }

                            const feedingSchedule = await (
                              await getFeedingSchedules({
                                lifeform_ids: selectedAnimalOption.value,
                              })
                            ).data[0];

                            const feedOn = formateDateTimeForAPI(
                              new Date(dateTime),
                            );
                            const newFeeding = await createFeeding({
                              feed_on: feedOn,
                              meal: meal,
                              ...(selectedAteOption.value === 'Yes'
                                ? { ate: true }
                                : {}),
                              ...(selectedAteOption.value === 'No'
                                ? { ate: false }
                                : {}),
                              ...(selectedAteOption.value === 'Not Yet'
                                ? { ate: null }
                                : {}),
                              feeding_schedule_id: feedingSchedule.id,
                            });

                            setFeedings([newFeeding, ...feedings]);
                            setMeal('');

                            successNotification(
                              'Successfully added a new feeding.',
                            );
                          }}
                        >
                          <AddIcon className="add-icon" />
                        </button>

                        {feedings.length ? (
                          <div className="title-input-wrapper">
                            <p className="title">Recent Feedings</p>
                            <Table
                              columns={feedingColumns}
                              data={feedings.map((row) => ({
                                action: (
                                  <div className="table-actions">
                                    <button
                                      className="btn-default btn-edit"
                                      onClick={() => {
                                        navigate(
                                          `${Paths.editFeeding}/${row.id}`,
                                        );
                                      }}
                                    >
                                      <EditIcon className="edit-icon" />
                                    </button>
                                  </div>
                                ),
                                meal: row.meal,
                                ate: row.ate
                                  ? 'Yes'
                                  : row.ate === false
                                  ? 'No'
                                  : 'Not Yet',
                                feedOn: moment(row.feed_on).format(
                                  'MMM DD, YYYY',
                                ),
                              }))}
                            />
                          </div>
                        ) : (
                          <div className="common-row">
                            <div className="empty-placeholder">
                              <h5>Empty</h5>
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                {isEdit ? (
                  <div className="bottom two">
                    <button
                      className="btn-done"
                      onClick={async () => {
                        // update
                        await updateFeeding({
                          id: paramsID,
                          feed_on: dateTime,
                          meal: meal,
                          ...(selectedAteOption.value === 'Yes'
                            ? { ate: true }
                            : {}),
                          ...(selectedAteOption.value === 'No'
                            ? { ate: false }
                            : {}),
                          ...(selectedAteOption.value === 'Not Yet'
                            ? { ate: null }
                            : {}),
                        });
                        navigate(-1);
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="btn-delete"
                      onClick={() => {
                        setOpenModal(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ) : (
                  <div className="bottom">
                    <button
                      className="btn-next"
                      onClick={async () => {
                        if (selectedAnimalOption) {
                          const feedingSchedule = await (
                            await getFeedingSchedules({
                              lifeform_ids: selectedAnimalOption.value,
                            })
                          ).data[0];
                          navigate(
                            `${Paths.viewFeedingSchedule}/${feedingSchedule.id}`,
                          );
                        } else {
                          navigate(`${Paths.home}`);
                        }
                      }}
                    >
                      Done
                    </button>
                  </div>
                )}
              </section>
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
