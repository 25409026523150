import { Setting } from "cobranetics-types/types";
import { PathsAPI } from "../routes/paths";
import { axiosInstance } from "../shared/axios";

export const getSettings = async (): Promise<Setting> => {
  const response = await axiosInstance.get(PathsAPI.setting);

  return response.data;
};

export const updateSettings = async (
  partialSettings: Partial<Setting>
): Promise<Setting> => {
  const response = await axiosInstance.put(PathsAPI.setting, partialSettings);

  return response.data;
};
