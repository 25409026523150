/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { Header } from '../../components/header';
import { PageHeader } from '../../components/page-header';
import { CreateEnclosureTabs } from '../../typings/enclosure';
import { deleteEnclosure, getEnclosure } from '../../actions/enclosure';
import { DEFAULT_IMAGES } from '../../shared/constants';
import { getSettings } from '../../actions/settings';
import {
  getEnclosureGalleryCards,
  getEnclosureGalleryImages,
} from '../../actions/gallery';
import { SelectListOption, UnitSystem } from '../../typings/common';
import { GeneralSection } from '../../components/create-enclosure/general';
import { ImageSection } from '../../components/create-enclosure/images';
import { useMainContext } from '../../context/context';
import { CHECK, GET_ENCLOSURE } from '../../actions';
import { getCheck } from '../../actions/check';
import { MainAction } from '../../typings/main_context_reducer';
import { FailedAuthCheck } from '../../shared/errors';
import { getSearchParams } from '../../shared/utils';
import { DeleteModal } from '../../components/modals/delete';
import { Paths } from '../../routes/paths';
import { errorNotification } from '../../components/notification';

export const imperialUnitDimensionOptions = [
  { value: 'in', label: 'Inch' },
  { value: 'ft', label: 'Foot' },
  { value: 'yd', label: 'Yard' },
  { value: 'mi', label: 'Mile' },
];
export const metricUnitDimensionOptions = [
  { value: 'mm', label: 'Millimeter' },
  { value: 'cm', label: 'Centimeter' },
  { value: 'm', label: 'Meter' },
  { value: 'km', label: 'Kilometer' },
];

export const unitTemperatureOptions: SelectListOption[] = [
  { value: '°F', label: 'fahrenheit' },
  { value: '°C', label: 'celsius' },
];

export const unitHumidityOptions: SelectListOption[] = [
  { value: '%', label: 'Percentage' },
];

export const getUnitOption = (
  value: string,
  isMetric: boolean,
): { value: string; label: string } => {
  if (isMetric) {
    return (
      metricUnitDimensionOptions.find((option) => option.value === value) ??
      metricUnitDimensionOptions[1]
    );
  }

  return (
    imperialUnitDimensionOptions.find((option) => option.value === value) ??
    imperialUnitDimensionOptions[0]
  );
};

export const checkUnits = (units: string): boolean => {
  return metricUnitDimensionOptions.map((opt) => opt.value).includes(units);
};

export const CreateEnv = (): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = getSearchParams(searchParams);
  const [, dispatch] = useMainContext();
  const [isBusy, setBusy] = useState(true);
  const navigate = useNavigate();
  const { paramsID } = useParams();
  const isEdit = paramsID !== undefined;
  const pageID = isEdit ? Number.parseInt(paramsID) : 0;

  // Tabs
  let tab = CreateEnclosureTabs.general;
  switch (queryParams.tab) {
    case CreateEnclosureTabs.images:
      tab = CreateEnclosureTabs.images;
      break;
    default:
      break;
  }
  const [activeTab, setActiveTab] = useState(
    isEdit ? tab : CreateEnclosureTabs.general,
  );

  // General
  const [enclosureID, setEnclosureID] = useState('');
  const [name, setName] = useState('');
  const [length, setLength] = useState('');
  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [enclosureUnits, setEnclosureUnits] = useState('');
  const [temperature, setTemperature] = useState('');
  const [humidity, setHumidity] = useState('');
  const [isMetric, setIsMetric] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [temperatureUnits, setTemperatureUnits] = useState<SelectListOption>(
    unitTemperatureOptions[0],
  );
  const [humidityUnits, setHumidityUnits] = useState<SelectListOption>(
    unitHumidityOptions[0],
  );
  const [openModal, setOpenModal] = useState(false);

  const deleteModalProps = {
    afterOpenModal: () => {},
    closeModal: () => {
      setOpenModal(false);
    },
    deleteItem: async () => {
      await deleteEnclosure(enclosureID);
      navigate(Paths.home);
    },
    modalIsOpen: openModal,
    category: 'enclosure',
    name: name,
  };

  const generalProps = {
    name,
    setName,
    length,
    setLength,
    width,
    setWidth,
    height,
    setHeight,
    isMetric,
    enclosureUnits,
    setEnclosureUnits,
    setActiveTab,
    isEdit,
    enclosureID,
    navigate,
    isVisible,
    setIsVisible,
    temperature,
    setTemperature,
    temperatureUnits,
    setTemperatureUnits,
    humidity,
    setHumidity,
    humidityUnits,
    setHumidityUnits,
    setOpenModal,
  };

  // Images
  const [images, setImages] = useState([] as { id: string; src: string }[]);
  const [activeImage, setActiveImage] = useState(DEFAULT_IMAGES.enclosure);
  const [imageCount, setImageCount] = useState(0);

  const imageProps = {
    enclosureID,
    images,
    activeImage,
    setImages,
    setActiveImage,
    navigate,
    imageCount,
  };

  const tabs = [
    {
      title: CreateEnclosureTabs.general,
      clickTab: () => {
        setActiveTab(CreateEnclosureTabs.general);

        if (isEdit) {
          setSearchParams({ tab: CreateEnclosureTabs.general });
        }
      },
    },
    {
      title: CreateEnclosureTabs.images,
      isDisabled: name.length === 0,
      clickTab: () => {
        setActiveTab(CreateEnclosureTabs.images);

        if (isEdit) {
          setSearchParams({ tab: CreateEnclosureTabs.images });
        }
      },
    },
  ];

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await getCheck(navigate);
        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;
        dispatch(checkAction);

        const imageRows = await getEnclosureGalleryImages({
          account_id: check.account.id,
          perPage: 1,
        });
        setImageCount(imageRows.total || 0);

        if (isEdit) {
          if (Number.isNaN(pageID)) {
            return;
          }

          const settings = await getSettings();

          setIsMetric(settings.unit_system === UnitSystem.Metric);
          setEnclosureUnits(
            getUnitOption(
              enclosureUnits,
              settings.unit_system === UnitSystem.Metric,
            ).value,
          );

          if (Number.isNaN(pageID)) {
            errorNotification('Page ID in the URL must be a number');
          }

          const enclosureGalleryParams = {
            currentPage: 1,
            perPage: 10,
            sort_by: '-id',
            enclosure_ids: `${pageID}`,
          };
          const enclosure = await getEnclosure(paramsID);
          const enclosureGallery = await getEnclosureGalleryCards(
            enclosureGalleryParams,
          );
          const [length, width, heightUnits] = enclosure?.dimensions?.split(
            ' x ',
          ) ?? [0, 0, `${0} ${enclosureUnits}`];
          const [height, units] = `${heightUnits}`.split(' ');
          const tempUnits = enclosure?.temperature?.match(/°[A-Za-z]/);
          const enclosureAction = {
            type: GET_ENCLOSURE,
            enclosureSingleAction: {
              enclosure,
            },
          } as MainAction;

          dispatch(enclosureAction);

          setEnclosureID(enclosure.id);
          setName(enclosure.name ?? '');
          setLength(`${length}`);
          setWidth(`${width}`);
          setHeight(`${height}`);
          setEnclosureUnits(`${units}`);
          setIsMetric(checkUnits(units));
          setImages(
            enclosureGallery.data.map((gallery) => ({
              id: gallery.id,
              src: gallery.image_url,
            })),
          );
          setActiveImage(
            enclosureGallery.data.find((gallery) => gallery.is_default_image)
              ?.image_url ?? '',
          );
          setIsVisible(enclosure.is_public);
          setTemperature(
            enclosure?.temperature?.split(/°[A-Za-z]+/).join('') || '',
          );
          setTemperatureUnits(
            tempUnits?.[0] === unitTemperatureOptions[0].value
              ? unitTemperatureOptions[0]
              : unitTemperatureOptions[1],
          );
          setHumidity(enclosure?.humidity?.split(/%/).join('') || '');
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, []);

  return (
    <div className="create-env">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="create-env-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title={`${isEdit ? 'Edit' : 'Add'} Enclosure`}
                showBackBtn={true}
                tabs={tabs}
                activeTab={activeTab}
              />
              {activeTab === CreateEnclosureTabs.general ? (
                <GeneralSection {...generalProps} />
              ) : (
                <></>
              )}
              {activeTab === CreateEnclosureTabs.images ? (
                <ImageSection {...imageProps} />
              ) : (
                <></>
              )}
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
      <DeleteModal {...deleteModalProps} />
    </div>
  );
};
