import { LifeformAttribute, SexTypes } from 'cobranetics-types/types';

export enum AddAnimalTabs {
  general = 'General',
  images = 'Images',
  characteristic = 'Characteristics',
}

export interface GeneralProps {
  name: string;
  setName: (name: string) => void;
  scientificName: string;
  setScientificName: (scientificName: string) => void;
  commonName: string;
  setCommonName: (commonName: string) => void;
  sex: SexTypes;
  setSex: (sex: SexTypes) => void;
  isEdit: boolean;
  dob: string;
  setDOB: (dob: string) => void;
  setActiveTab: (tab: AddAnimalTabs) => void;
  enclosureID: string;
  setEnclosureID: (id: string) => void;
  isVisible: boolean;
  setIsVisible: (isVisible: boolean) => void;
  setOpenModal: (openModal: boolean) => void;
  navigate: any;
}
export interface ImageProps {
  images: { id: string; src: string }[];
  activeImage: string;
  setImages: (images: { id: string; src: string }[]) => void;
  setActiveImage: (length: string) => void;
  setActiveTab: (tab: AddAnimalTabs) => void;
  isEdit: boolean;
  imageCount: number;
  navigate: any;
}

export interface CharacteristicProps {
  characteristics: Partial<LifeformAttribute>[];
  setCharacteristics: (characteristics: Partial<LifeformAttribute>[]) => void;
  navigate: any;
  isEdit: boolean;
}
