import React from 'react';
import { ReactComponent as WrongIcon } from '../icons/wrong.svg';
import { ReactComponent as CheckmarkIcon } from '../icons/checkmark.svg';

export const selectStyle = {
  option: (base: any, state: any) => ({
    ...base,
    color: state.isFocused ? '#FFFFFF' : '#2e2e2e',
    fontSize: '1.25rem',
    backgroundColor: state.isFocused ? '#338df7' : '#FFFFFF',
  }),
  container: (base: any) => ({
    ...base,
    fontSize: '1.25rem',
  }),
  control: (base: any) => ({
    ...base,
    height: 45,
    minHeight: 45,
  }),
};

export const selectStyleSmall = {
  option: (base: any, state: any) => ({
    ...base,
    color: state.isFocused ? '#FFFFFF' : '#2e2e2e',
    fontSize: '1.15rem',
    backgroundColor: state.isFocused ? '#338df7' : '#FFFFFF',
  }),
  container: (base: any) => ({
    ...base,
    fontSize: '1.15rem',
  }),
  control: (base: any) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
};

export const selectStyleSmaller = {
  option: (base: any, state: any) => ({
    ...base,
    color: state.isFocused ? '#FFFFFF' : '#2e2e2e',
    fontSize: '1.15rem',
    backgroundColor: state.isFocused ? '#338df7' : '#FFFFFF',
  }),
  container: (base: any) => ({
    ...base,
    fontSize: '1rem',
  }),
  control: (base: any) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
};

export const EmailInput = (props: {
  email: string;
  setEmail: (email: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="email"
        name="email"
        id="email"
        autoComplete="email"
        value={props.email}
        onChange={(e) => props.setEmail(e.target.value)}
        placeholder="Email"
      />
    </div>
  );
};

export const UsernameEmailInput = (props: {
  usernameEmail: string;
  setUsernameEmail: (usernameEmail: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="text"
        name="username-email"
        id="username-email"
        autoComplete="username"
        value={props.usernameEmail}
        onChange={(e) => props.setUsernameEmail(e.target.value)}
        placeholder="Username or Email"
      />
    </div>
  );
};

export const PhoneInput = (props: {
  phoneNumber: string;
  setPhoneNumber: (phoneNumber: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="tel"
        name="phone"
        id="phone"
        autoComplete="phone"
        value={props.phoneNumber}
        onChange={(e) => props.setPhoneNumber(e.target.value)}
        placeholder="555-123-4567"
        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
      />
    </div>
  );
};

export const PasswordInput = (props: {
  password: string;
  setPassword: (password: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="password"
        name="password"
        id="password"
        autoComplete="current-password"
        value={props.password}
        onChange={(e) => props.setPassword(e.target.value)}
        placeholder="Password"
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
        required
      />
    </div>
  );
};

export const NameInput = (props: {
  name: string;
  setName: (name: string) => void;
  placeholder?: string;
  inputName?: string;
  onChange?: (value: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="text"
        name={props.inputName ?? 'name'}
        id="name"
        autoComplete="name"
        value={props.name}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.value);
          }

          props.setName(e.target.value);
        }}
        placeholder={props.placeholder ?? 'First and Last Name'}
      />
    </div>
  );
};

export const NameInputRequired = (props: {
  name: string;
  setName: (name: string) => void;
  placeholder?: string;
  inputName?: string;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="text"
        name={props.inputName ?? 'name'}
        id="name"
        autoComplete="name"
        value={props.name}
        onChange={(e) => props.setName(e.target.value)}
        placeholder={props.placeholder ?? 'First and Last Name'}
        required
      />
    </div>
  );
};

export const UsernameInput = (props: {
  username: string;
  setUsername: (username: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="text"
        name="username"
        id="username"
        autoComplete="username"
        value={props.username}
        onChange={(e) => props.setUsername(e.target.value)}
        placeholder="Username"
      />
    </div>
  );
};

export const UsernameInputRequired = (props: {
  username: string;
  setUsername: (username: string) => void;
  isValid: boolean | null;
  isRequired?: boolean;
  currentUsername?: string;
}): JSX.Element => {
  return (
    <div className="input-wrapper username-wrapper">
      {props.isValid !== null ? (
        <>
          {!props.isValid ? (
            <div className="icon-wrapper invalid">
              <WrongIcon className="wrong-icon" />
            </div>
          ) : (
            <>
              {props.currentUsername === props.username ? (
                <></>
              ) : (
                <div className="icon-wrapper valid">
                  <CheckmarkIcon className="checkmark-icon" />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <></>
      )}

      {props.isRequired === false ? (
        <input
          type="text"
          name="username"
          id="username"
          autoComplete="username"
          value={props.username}
          onChange={(e) => props.setUsername(e.target.value)}
          placeholder="Username"
        />
      ) : (
        <input
          type="text"
          name="username"
          id="username"
          autoComplete="username"
          value={props.username}
          onChange={(e) => props.setUsername(e.target.value)}
          placeholder="Username"
          required
        />
      )}
    </div>
  );
};

export const BioInput = (props: {
  bio: string;
  setBio: (bio: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <textarea
        name="bio"
        id="bio"
        value={props.bio}
        onChange={(e) => props.setBio(e.target.value)}
        placeholder="Bio"
      />
    </div>
  );
};

export const ConfirmPasswordInput = (props: {
  confirmPassword: string;
  setConfirmPassword: (password: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="password"
        name="password"
        id="password"
        autoComplete="current-password"
        value={props.confirmPassword}
        onChange={(e) => props.setConfirmPassword(e.target.value)}
        placeholder="Confirm New Password"
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
        required
      />
    </div>
  );
};

export const CodeInput = (props: {
  code: string;
  setCode: (code: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="text"
        name="code"
        id="code"
        value={props.code}
        onChange={(e) => props.setCode(e.target.value)}
        placeholder="Code"
      />
    </div>
  );
};

export const CurrentPasswordInput = (props: {
  password: string;
  setPassword: (password: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="password"
        name="password"
        id="password"
        autoComplete="current-password"
        value={props.password}
        onChange={(e) => props.setPassword(e.target.value)}
        placeholder="Current Password"
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
        required
      />
    </div>
  );
};

export const NewPasswordInput = (props: {
  password: string;
  setPassword: (password: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="password"
        name="password"
        id="password"
        autoComplete="current-password"
        value={props.password}
        onChange={(e) => props.setPassword(e.target.value)}
        placeholder="New Password"
        pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
        title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters"
        required
      />
    </div>
  );
};

export const LengthInput = (props: {
  length: string;
  setLength: (length: string) => void;
  placeholder?: string;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="number"
        name="length"
        id="length"
        value={props.length}
        onChange={(e) => props.setLength(e.target.value)}
        placeholder={'Length'}
      />
    </div>
  );
};

export const WidthInput = (props: {
  width: string;
  setWidth: (width: string) => void;
  placeholder?: string;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="number"
        name="width"
        id="width"
        value={props.width}
        onChange={(e) => props.setWidth(e.target.value)}
        placeholder={'Width'}
      />
    </div>
  );
};

export const HeightInput = (props: {
  height: string;
  setHeight: (height: string) => void;
  placeholder?: string;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="number"
        name="height"
        id="height"
        value={props.height}
        onChange={(e) => props.setHeight(e.target.value)}
        placeholder={'Height'}
      />
    </div>
  );
};

export const NumberInput = (props: {
  setNumber: (number: number) => void;
  placeholder?: string;
  inputName: string;
  value?: number | string;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="number"
        name={props.inputName}
        id={`number-input-${props.inputName}`}
        value={props.value}
        onChange={(e) => props.setNumber(+e.target.value)}
        placeholder={props.placeholder}
        step={'0.01'}
      />
    </div>
  );
};

export const DOBInput = (props: {
  date: string;
  setDate: (date: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="date"
        name="dob"
        id="dob"
        value={props.date}
        onChange={(e) => props.setDate(e.target.value)}
        placeholder="Date of birth"
      />
    </div>
  );
};

export const TextInput = (props: {
  text: string;
  setText: (text: string) => void;
  placeholder: string;
  inputName: string;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="text"
        name={props.inputName}
        id={`text-input-${props.inputName}`}
        value={props.text}
        onChange={(e) => props.setText(e.target.value)}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export const DateTimeInput = (props: {
  dateTime: string;
  setDateTime: (dateTime: string) => void;
  placeholder: string;
  inputName: string;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="datetime-local"
        name={props.inputName}
        id={`text-input-${props.inputName}`}
        value={props.dateTime}
        onChange={(e) => props.setDateTime(e.target.value)}
        placeholder={props.placeholder}
      />
    </div>
  );
};

export const DateInput = (props: {
  date: string;
  setDate: (date: string) => void;
  placeholder: string;
  inputName: string;
  onChange?: (value: string) => void;
}): JSX.Element => {
  return (
    <div className="input-wrapper">
      <input
        type="date"
        name={props.inputName}
        id={`text-input-${props.inputName}`}
        value={props.date}
        onChange={(e) => {
          if (props.onChange) {
            props.onChange(e.target.value);
          }

          props.setDate(e.target.value);
        }}
        placeholder={props.placeholder}
      />
    </div>
  );
};
