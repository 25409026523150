import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import { LOGIN, SIGN_UP } from '../../actions';
import { Header } from '../../components/header';
import { useMainContext } from '../../context/context';
import { axiosInstance } from '../../shared/axios';
import { formatDateForAPI } from '../../shared/utils';
import { validateEmail, validatePassword } from '../../shared/validation';
import { Dispatch, MainAction } from '../../typings/main_context_reducer';
import { Paths, PathsAPI } from '../../routes/paths';
import {
  BioInput,
  ConfirmPasswordInput,
  EmailInput,
  NameInputRequired,
  PasswordInput,
  UsernameInputRequired,
} from '../../components/input';
import { errorNotification } from '../../components/notification';
import { ReactComponent as ImageIcon } from '../../icons/image.svg';
import imageCompression from 'browser-image-compression';
import { getCheck } from '../../actions/check';
import packageJson from '../../../package.json';
import { BRAND_NAME } from '../../shared/constants';

export const submitSignUp = async (
  dispatch: Dispatch,
  navigate: any,
  signUpData: {
    name: string;
    email: string;
    password: string;
  },
): Promise<void> => {
  try {
    const response = await axiosInstance.post(PathsAPI.signUp, {
      ...signUpData,
      created_on: formatDateForAPI(new Date()),
    });

    const action = {
      type: SIGN_UP,
      signupAction: {
        bearerToken: response.data.token,
        account: response.data.account,
      },
    } as MainAction;

    dispatch(action);
    navigate(Paths.createEnv);
  } catch (error: any) {
    errorNotification(error.name + ': ' + error.message);
  }
};

export const ProfileImage = (props: {
  file: any;
  setFile: (file: any) => void;
}): JSX.Element => {
  const onDrop = async (acceptedFiles: any) => {
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1080,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(acceptedFiles[0], options);

      // await uploadToServer(compressedFile);
      props.setFile(
        Object.assign(compressedFile, {
          preview: URL.createObjectURL(compressedFile),
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'],
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => URL.revokeObjectURL(props.file.preview);
  }, [props.file]);

  return (
    <div className="image-input profile-image-wrapper">
      {Object.keys(props.file).length ? (
        <img
          src={props.file.preview}
          onLoad={() => {
            URL.revokeObjectURL(props.file.preview);
          }}
        />
      ) : (
        <ImageIcon className="image-icon" />
      )}

      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export const SignUp = (): JSX.Element => {
  const [, dispatch] = useMainContext();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showProfile, setShowProfile] = useState(false);
  const [isUsernameValid, setIsUsernameValid] = useState<boolean | null>(null);
  const [file, setFile] = useState({} as any);

  useEffect(() => {
    const loadData = async () => {
      try {
        await getCheck(navigate, false);
        navigate(-1);
      } catch (error) {
        //
      }
    };

    loadData();
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      const checkUsername = async () => {
        try {
          const response = await axiosInstance.get(PathsAPI.checkUsername, {
            params: { username },
          });

          setIsUsernameValid(response.data);
        } catch (error: any) {
          errorNotification(error.name + ': ' + error.message);
        }
      };

      if (username.length > 0) {
        checkUsername();
      }
    }, 750);

    return () => clearTimeout(timer);
  }, [username]);

  return (
    <div className="login sign-up">
      <Header hideLinks={false} />
      <section className="login-background">
        <div className="login-overlay">
          <div className="page-header">
            <h4>Get Started</h4>
            <h6>Create an account</h6>
          </div>
          <div className="form-wrapper">
            <form
              onSubmit={async (event) => {
                event.preventDefault();
              }}
            >
              {!showProfile ? (
                <>
                  <EmailInput email={email} setEmail={setEmail} />
                  <PasswordInput
                    password={password}
                    setPassword={setPassword}
                  />
                  <ConfirmPasswordInput
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                  />
                  <p className="signup-link">
                    By selecting agree and continue below, I agree to{' '}
                    <a href="#">Terms of Service</a> and{' '}
                    <a href="#">Privacy Policy</a>.
                  </p>
                  <button
                    className="btn-next"
                    onClick={() => {
                      if (validateEmail(email) && validatePassword(password)) {
                        setShowProfile(true);
                        // create account
                      } else {
                        errorNotification(
                          'You must have a valid email and password to continue.',
                        );
                      }
                    }}
                  >
                    Agree & continue
                  </button>
                </>
              ) : (
                <>
                  <div className="name-image-wrapper">
                    <ProfileImage file={file} setFile={setFile} />
                    <div className="name-wrapper">
                      <NameInputRequired name={name} setName={setName} />
                      <UsernameInputRequired
                        username={username}
                        isValid={isUsernameValid}
                        setUsername={async (val) => {
                          if (val.length === 0) {
                            setIsUsernameValid(null);
                          }

                          setUsername(val);
                        }}
                      />
                    </div>
                  </div>
                  <BioInput bio={bio} setBio={setBio} />
                  <button
                    className="btn-primary"
                    onClick={async () => {
                      if (name.length > 0 && isUsernameValid) {
                        // submit create profile
                        try {
                          // create account
                          const responseAccount = await axiosInstance.post(
                            PathsAPI.account,
                            {
                              email,
                              password,
                            },
                          );

                          // Login
                          const responseLogin = await axiosInstance.post(
                            PathsAPI.login,
                            {
                              usernameEmail: email,
                              password,
                            },
                          );

                          const action = {
                            type: LOGIN,
                            LoginAction: {
                              account: responseLogin.data.account,
                            },
                          } as MainAction;

                          dispatch(action);

                          // create person
                          await axiosInstance.post(PathsAPI.person, {
                            name,
                            username,
                            bio,
                            profile_image: '',
                            account_id: responseAccount.data.id,
                          });

                          // Upload Profile Image
                          if (Object.keys(file).length !== 0) {
                            const formData = new FormData();
                            formData.append('file', file);

                            await axiosInstance.post(
                              `${PathsAPI.uploadProfileImage}`,
                              formData,
                              {
                                headers: {
                                  'Content-Type': 'multipart/form-data',
                                },
                              },
                            );
                          }

                          navigate(Paths.addAnimal);
                        } catch (error: any) {
                          errorNotification(error.name + ': ' + error.message);
                        }
                      } else {
                        errorNotification(
                          'You must have a valid name and username to continue.',
                        );
                      }
                    }}
                  >
                    Done
                  </button>
                </>
              )}
            </form>
          </div>
          <footer>
            <p>
              © {new Date().getFullYear()} {BRAND_NAME}
            </p>
            <p className="app-version">v {packageJson.version}</p>
          </footer>
        </div>
      </section>
    </div>
  );
};
