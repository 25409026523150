/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import imageCompression from 'browser-image-compression';
import { useDropzone } from 'react-dropzone';
import { Header } from '../../components/header';

import { PageHeader } from '../../components/page-header';
import {
  NameInput,
  BioInput,
  TextInput,
  UsernameInputRequired,
} from '../../components/input';
import { ReactComponent as AddIcon } from '../../icons/add.svg';
import { MainAction } from '../../typings/main_context_reducer';
import { getSocial } from '../../actions/social';
import { getCheck } from '../../actions/check';
import { useNavigate, useParams } from 'react-router-dom';
import { useMainContext } from '../../context/context';
import { CHECK } from '../../actions';
import { SocialModel } from '../../typings/social';
import { ReactComponent as ImageIcon } from '../../icons/image.svg';
import { axiosInstance } from '../../shared/axios';
import { Paths, PathsAPI } from '../../routes/paths';
import { errorNotification } from '../../components/notification';
import { FailedAuthCheck } from '../../shared/errors';

export const EditProfileImage = (props: {
  file: any;
  setFile: (file: any) => void;
  uri: string;
}): JSX.Element => {
  const onDrop = async (acceptedFiles: any) => {
    try {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 1080,
        useWebWorker: true,
      };
      const compressedFile = await imageCompression(acceptedFiles[0], options);

      // await uploadToServer(compressedFile);
      props.setFile(
        Object.assign(compressedFile, {
          preview: URL.createObjectURL(compressedFile),
        }),
      );
    } catch (error) {
      console.log(error);
    }
  };
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ['image/jpeg', 'image/png'],
  });

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => URL.revokeObjectURL(props.file.preview);
  }, [props.file]);

  return (
    <div className="image-input profile-image-wrapper">
      {Object.keys(props.file).length ? (
        <img
          src={props.file.preview}
          onLoad={() => {
            URL.revokeObjectURL(props.file.preview);
          }}
        />
      ) : (
        <>
          {props.uri ? (
            <img src={props.uri} />
          ) : (
            <ImageIcon className="image-icon" />
          )}
        </>
      )}

      <div className="dropzone" {...getRootProps()}>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export const EditProfile = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();

  const { paramsID } = useParams();
  const pageID = Number.parseInt(paramsID ?? '');

  const [profileImage, setProfileImage] = useState('');
  const [name, setName] = useState('');
  const [username, setUsername] = useState('');
  const [bio, setBio] = useState('');
  const [social, setSocial] = useState({} as SocialModel);
  const [isBusy, setBusy] = useState(true);
  const [file, setFile] = useState({} as any);
  const [isUsernameValid, setIsUsernameValid] = useState<boolean | null>(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        if (Number.isNaN(pageID)) {
          return;
        }

        const check = await getCheck(navigate);
        const social = await getSocial(pageID);

        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check.account,
            person: check.person,
            settings: check.settings,
            membershipConfig: check.membershipConfig,
          },
        } as MainAction;

        dispatch(checkAction);

        setSocial(social[0]);
        setName(check.person.name ?? '');
        setUsername(check.person.username ?? '');
        setBio(check.person.bio ?? '');
        setProfileImage(check.person.profile_image ?? '');
      } catch (error: any) {
        if (error === FailedAuthCheck) {
          return null;
        }

        console.log(error);
        errorNotification(error.message);
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (Object.keys(file).length) {
      console.log({ file }); // TODO: update
    }
  }, [file]);

  useEffect(() => {
    const timer = setTimeout(() => {
      const checkUsername = async () => {
        try {
          const response = await axiosInstance.get(PathsAPI.checkUsername, {
            params: { username },
          });

          setIsUsernameValid(response.data);
        } catch (error: any) {
          errorNotification(error.name + ': ' + error.message);
        }
      };

      if (username.length > 0 && username !== mainState.person?.username) {
        checkUsername();
      }
    }, 750);

    return () => clearTimeout(timer);
  }, [username]);

  return (
    <div className="edit-profile">
      <Header hideLinks={false} />
      {!isBusy ? (
        <div className="desktop-wrapper">
          <section className="edit-profile-background common-background">
            <section className="common-inner-wrapper">
              <PageHeader
                title="Edit Profile"
                showBackBtn={true}
                activeTab={''}
              />
              <section className="common-section">
                <div className="top">
                  <div className="add-image">
                    <EditProfileImage
                      file={file}
                      setFile={setFile}
                      uri={profileImage}
                    />
                    <button
                      className="btn-add"
                      onClick={() => {
                        (document.querySelector(
                          '.image-input .dropzone input',
                        ) as any)?.click();
                      }}
                    >
                      <AddIcon className="add-icon" />
                    </button>
                  </div>
                  <div className="title-input-wrapper">
                    <p className="title">Name</p>
                    <NameInput
                      name={name}
                      setName={setName}
                      placeholder="Full Name"
                      inputName="fullName"
                    />
                  </div>
                  <div className="title-input-wrapper">
                    <p className="title">Username</p>
                    {/* <UsernameInput
                    username={username}
                    setUsername={setUsername}
                  /> */}
                    <UsernameInputRequired
                      username={username}
                      isValid={isUsernameValid}
                      currentUsername={mainState.person?.username}
                      setUsername={async (val) => {
                        if (val.length === 0) {
                          setIsUsernameValid(null);
                        }

                        setUsername(val);
                      }}
                    />
                  </div>
                  <div className="title-input-wrapper">
                    <p className="title">Bio</p>
                    <BioInput bio={bio} setBio={setBio} />
                  </div>
                  <div className="dual-input">
                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Instagram</p>
                      <TextInput
                        text={social?.instagram ?? ''}
                        setText={(value) => {
                          setSocial({ ...social, instagram: value });
                        }}
                        placeholder="Instagram"
                        inputName="instagramSocial"
                      />
                    </div>

                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Reddit</p>
                      <TextInput
                        text={social?.reddit ?? ''}
                        setText={(value) => {
                          setSocial({ ...social, reddit: value });
                        }}
                        placeholder="Reddit"
                        inputName="redditSocial"
                      />
                    </div>

                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Morphmarket</p>
                      <TextInput
                        text={social?.morph_market ?? ''}
                        setText={(value) => {
                          setSocial({ ...social, morph_market: value });
                        }}
                        placeholder="Morphmarket"
                        inputName="morphmarketSocial"
                      />
                    </div>

                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Twitter</p>
                      <TextInput
                        text={social?.twitter ?? ''}
                        setText={(value) => {
                          setSocial({ ...social, twitter: value });
                        }}
                        placeholder="Twitter"
                        inputName="twitterSocial"
                      />
                    </div>

                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Facebook</p>
                      <TextInput
                        text={social?.facebook ?? ''}
                        setText={(value) => {
                          setSocial({ ...social, facebook: value });
                        }}
                        placeholder="Facebook"
                        inputName="facebookSocial"
                      />
                    </div>

                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">Pinterest</p>
                      <TextInput
                        text={social?.pinterest ?? ''}
                        setText={(value) => {
                          setSocial({ ...social, pinterest: value });
                        }}
                        placeholder="Pinterest"
                        inputName="pinterestSocial"
                      />
                    </div>

                    <div className="title-input-wrapper feeder-input-wrapper">
                      <p className="title">TicTok</p>
                      <TextInput
                        text={social?.tiktok ?? ''}
                        setText={(value) => {
                          setSocial({ ...social, tiktok: value });
                        }}
                        placeholder="TicTok"
                        inputName="tictokSocial"
                      />
                    </div>
                  </div>
                </div>
                <div className="bottom">
                  <button
                    className="btn-done btn-save"
                    onClick={async () => {
                      if (Object.keys(file).length !== 0) {
                        const formData = new FormData();
                        formData.append('file', file);

                        await axiosInstance.post(
                          `${PathsAPI.uploadProfileImage}`,
                          formData,
                          {
                            headers: {
                              'Content-Type': 'multipart/form-data',
                            },
                          },
                        );
                      }

                      await axiosInstance.put(PathsAPI.person, {
                        name,
                        username,
                        bio,
                      });

                      await axiosInstance.put(PathsAPI.social, {
                        id: social.id,
                        facebook: social.facebook,
                        twitter: social.twitter,
                        pinterest: social.pinterest,
                        instagram: social.instagram,
                        reddit: social.reddit,
                        morph_market: social.morph_market,
                        tiktok: social.tiktok,
                      });

                      navigate(`${Paths.viewProfile}/${mainState?.person?.id}`);
                    }}
                  >
                    Save
                  </button>
                </div>
              </section>
            </section>
          </section>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
