import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import { SearchResult } from 'cobranetics-types/types/search';

import { CHECK } from '../actions';
import { ReactComponent as SearchIcon } from '../icons/search.svg';
import { authRefresh } from '../actions/check';
import { Header } from '../components/header';
import { useMainContext } from '../context/context';
import { MainAction } from '../typings/main_context_reducer';
import { ResultsWrapper } from '../components/results-wrapper';
import { DEFAULT_HOME_PER_PAGE } from '../shared/constants';
import { LoadingSpinner } from '../components/loading';
import { selectStyle } from '../components/input';
import { HomeFilterOptions } from '../typings/dashboard';
import { fetchData } from '../actions/dashboard';
import { getOption, toTitleCase } from '../shared/utils';
import { Paths } from '../routes/paths';
import { errorNotification } from '../components/notification';

export const Home = (): JSX.Element => {
  const [mainState, dispatch] = useMainContext();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const filterParam = searchParams.get('filter');
  const [isBusy, setBusy] = useState(true);
  const [searchData, setSearchData] = useState<SearchResult[]>([]);

  const [loadMore, setLoadMore] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [noMoreData, setNoMoreData] = useState(false);
  const [search, setSearch] = useState('');

  const pageTabOptions = [
    { value: HomeFilterOptions.animals, label: HomeFilterOptions.animals },
    {
      value: HomeFilterOptions.enclosures,
      label: HomeFilterOptions.enclosures,
    },
    {
      value: HomeFilterOptions.feedingSchedule,
      label: 'Feeding Schedules',
    },
    { value: HomeFilterOptions.images, label: HomeFilterOptions.images },
    { value: HomeFilterOptions.inventory, label: HomeFilterOptions.inventory },
    {
      value: HomeFilterOptions.observations,
      label: HomeFilterOptions.observations,
    },
  ];
  const [pageTabOption, setPageTabOption] = useState(
    getOption(filterParam || pageTabOptions[0].value, pageTabOptions) ||
      pageTabOptions[0],
  );

  useEffect(() => {
    const loadData = async () => {
      try {
        const check = await authRefresh();

        if (check === undefined) {
          navigate(Paths.browse);
        }

        const checkAction = {
          type: CHECK,
          checkAction: {
            account: check?.account,
            person: check?.person,
            settings: check?.settings,
          },
        } as MainAction;

        const currentData = await fetchData(pageTabOption.value, {
          account_id: check?.account.id,
          currentPage: 1,
          perPage: DEFAULT_HOME_PER_PAGE,
          sort_by: '-updated_on',
          ...(search.length ? { search } : {}),
        });

        if (currentData.length < DEFAULT_HOME_PER_PAGE) {
          setNoMoreData(true);
        }

        setCurrentPage(2);
        setSearchData(currentData);

        dispatch(checkAction);
      } catch (error) {
        console.error(error);
        errorNotification('Failed to load data.');
      }

      setBusy(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (!loadMore || noMoreData) {
      return;
    }

    const loadData = async () => {
      const currentData = await fetchData(pageTabOption.value, {
        account_id: mainState.account?.id,
        currentPage,
        perPage: DEFAULT_HOME_PER_PAGE,
        sort_by: '-updated_on',
      });

      if (currentData.length < DEFAULT_HOME_PER_PAGE) {
        setNoMoreData(true);
      }

      setCurrentPage(currentPage + 1);
      setSearchData([...searchData, ...currentData]);
      setLoadMore(false);
    };

    loadData();
  }, [loadMore]);

  useEffect(() => {
    if (!mainState.account?.id) {
      return;
    }

    const loadData = async () => {
      if (noMoreData) {
        return;
      }

      const currentData = await fetchData(pageTabOption.value, {
        account_id: mainState.account?.id,
        currentPage: 1,
        perPage: DEFAULT_HOME_PER_PAGE,
        sort_by: '-updated_on',
      });

      if (currentData.length < DEFAULT_HOME_PER_PAGE) {
        setNoMoreData(true);
      }

      setCurrentPage(2);
      setSearchData(currentData);
    };

    loadData();
  }, [pageTabOption]);

  useEffect(() => {
    if (!mainState.account?.id) {
      return;
    }

    const loadData = async () => {
      const currentData = await fetchData(pageTabOption.value, {
        account_id: mainState.account?.id,
        currentPage: 1,
        perPage: DEFAULT_HOME_PER_PAGE,
        ...(search.length ? { search } : {}),
        sort_by: '-updated_on',
      });

      if (currentData.length < DEFAULT_HOME_PER_PAGE) {
        setNoMoreData(true);
      }

      setCurrentPage(2);
      setSearchData(currentData);
    };

    loadData();
  }, [search]);

  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;

    if (bottom) {
      setLoadMore(true);
    }
  };

  return (
    <div className="home">
      <Header hideLinks={false} />
      <div
        className="desktop-wrapper"
        onScroll={(e: any) => {
          handleScroll(e);
        }}
      >
        <section
          className="home-background desktop-background"
          onScroll={(e: any) => {
            handleScroll(e);
          }}
        >
          <h2 className={`search-page-title`}>
            Home<p>Your {toTitleCase(pageTabOption.label)}</p>
          </h2>

          <div className="search-filter-home-wrapper">
            <div className="search-wrapper">
              <div className="input-wrapper">
                <SearchIcon className="search-icon" />
                <input
                  type="text"
                  name="search"
                  id="search"
                  placeholder={'Search'}
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />{' '}
              </div>
            </div>
            <div className="select-wrapper">
              <Select
                name="page-tab-options"
                id="page-tab-options"
                className="page-tab-select"
                styles={selectStyle}
                options={pageTabOptions}
                value={pageTabOption}
                onChange={(target) => {
                  setNoMoreData(false);
                  setPageTabOption(target || pageTabOptions[0]);
                  setSearchParams({
                    filter: target?.value || pageTabOptions[0].value,
                  });
                }}
              />
            </div>
          </div>

          <ResultsWrapper data={searchData} />
          {(loadMore && !noMoreData) || isBusy ? (
            <LoadingSpinner show={true} includeText={true} />
          ) : (
            <></>
          )}
        </section>
      </div>
    </div>
  );
};
