import { Person, PersonCard } from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';

export interface FetchPeopleParams extends Params {
  name?: string;
  username?: string;
  account_id?: number;
  search?: string;
}

export const getPerson = async (userName: string): Promise<Person> => {
  const response = await axiosInstance.get(
    `${PathsAPI.person}/${encodeURI(userName)}`,
  );

  return response.data;
};

export const getPeople = async (
  params: FetchPeopleParams,
): Promise<ListOutput<PersonCard>> => {
  try {
    const response = await axiosInstance.get(PathsAPI.personCards, { params });

    return {
      data: response.data.data,
      nextPage: response.data.nextPage,
      prevPage: response.data.prevPage,
    };
  } catch (error) {
    console.log(error);
    throw new Error('Failed to fetch people');
  }
};

export const withoutCurrentPerson = (
  people: Person[],
  currentUserID: number,
): Person[] => {
  return people.filter((person) => person.id !== currentUserID);
};

export const updatePerson = async (
  partialPerson: Partial<Person>,
): Promise<Person> => {
  const response = await axiosInstance.put(PathsAPI.person, partialPerson);

  return response.data;
};
