import { Account } from "cobranetics-types/types";
import { PathsAPI } from "../routes/paths";
import { axiosInstance } from "../shared/axios";
import { ChangePasswordDTO } from "../typings/account";

export const updateAccount = async (
  partialAccount: Partial<Account>
): Promise<Account> => {
  const response = await axiosInstance.put(PathsAPI.account, partialAccount);

  return response.data;
};

export const changePassword = async (
  changePasswordDto: Partial<ChangePasswordDTO>
): Promise<Account> => {
  const response = await axiosInstance.put(
    PathsAPI.changePassword,
    changePasswordDto
  );

  return response.data;
};
