import React, { useState } from 'react';
import { Modal } from 'antd';
import { ModalProps } from '../../typings/modal';
import {
  ConfirmPasswordInput,
  CurrentPasswordInput,
  NewPasswordInput,
} from '../input';
import { ForgotPasswordModal } from './forgot-password';
import { closeModal, openModal } from './modal';
import { changePassword } from '../../actions/account';
import { errorNotification, successNotification } from '../notification';

export const ChangePasswordModal = (props: ModalProps) => {
  const [forgotPasswordModalIsOpen, forgotPasswordModalSetIsOpen] = useState(
    false,
  );
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  return (
    <>
      <Modal
        open={props.modalIsOpen}
        onCancel={props.closeModal}
        title="Change Password"
        className="change-password-modal"
        footer={null}
      >
        <div className="change-password-wrapper">
          <CurrentPasswordInput
            password={currentPassword}
            setPassword={setCurrentPassword}
          />
          <NewPasswordInput
            password={newPassword}
            setPassword={setNewPassword}
          />
          <ConfirmPasswordInput
            confirmPassword={confirmPassword}
            setConfirmPassword={setConfirmPassword}
          />
          <div className="modal-footer">
            <button
              className="btn-done"
              onClick={async () => {
                try {
                  if (currentPassword === '') {
                    errorNotification('You must enter your current password.');

                    return;
                  }

                  if (newPassword === '' || confirmPassword === '') {
                    errorNotification('New password is empty');

                    return;
                  }

                  if (newPassword !== confirmPassword) {
                    errorNotification(
                      "New password doesn't match the confirm password.",
                    );

                    return;
                  }

                  await changePassword({
                    currentPassword,
                    newPassword,
                  });

                  props.closeModal();
                  successNotification('Successfully saved new password');
                } catch (error) {
                  console.error(error);
                  errorNotification('Failed to save new password');
                }
              }}
            >
              Save
            </button>
            <button
              className="forgot-password-btn"
              onClick={() => {
                props.closeModal();
                openModal(forgotPasswordModalSetIsOpen)();
              }}
            >
              Forgot password
            </button>
          </div>
        </div>
      </Modal>
      <ForgotPasswordModal
        modalIsOpen={forgotPasswordModalIsOpen}
        closeModal={closeModal(forgotPasswordModalSetIsOpen)}
      />
    </>
  );
};
