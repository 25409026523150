import React from 'react';
import { BRAND_NAME } from '../shared/constants';
import packageJson from '../../package.json';
import moment from 'moment';

export const Footer = () => {
  return (
    <footer>
      <p className="copyright">{`© ${moment()
        .year()
        .toString()} ${BRAND_NAME}`}</p>
      <p className="version">{`v${packageJson.version}`}</p>
    </footer>
  );
};
