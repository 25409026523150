/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import Papa from 'papaparse';

import { FailedAuthCheck } from '../../shared/errors';
import { selectStyle } from '../../components/input';
import { getOption, toTitleCase } from '../../shared/utils';
import { Table } from 'antd';
import { getAnimals } from '../../actions/animals';
import { getEnclosures } from '../../actions/enclosure';
import { getFeedings } from '../../actions/feeding';
import { getInventoryCards } from '../../actions/inventory';
import { getObservations } from '../../actions/observation';

export const BulkExport = (props: {
  accountID: number | undefined;
}): JSX.Element => {
  const { accountID } = props;
  const categoryOptions = [
    { value: 'animal', label: 'Animal' },
    { value: 'enclosure', label: 'Enclosure' },
    { value: 'feeding', label: 'Feeding' },
    { value: 'inventory', label: 'Inventory' },
    { value: 'observation', label: 'Observation' },
  ];

  const importedColumns = {
    animal: [
      'id',
      'pet_name',
      'common_name',
      'scientific_name',
      'kingdom',
      'is_archived',
      'is_public',
      'sex',
      'dob',
      'enclosure_id',
      'account_id',
      'created_on',
      'updated_on',
    ],
    enclosure: [
      'id',
      'name',
      'dimensions',
      'temperature',
      'humidity',
      'is_archived',
      'is_public',
      'account_id',
      'created_on',
      'updated_on',
    ],
    feeding: [
      'id',
      'feed_on',
      'meal',
      'ate',
      'feeding_schedule_id',
      'account_id',
      'inventory_id',
      'created_on',
      'updated_on',
    ],
    inventory: [
      'id',
      'is_archived',
      'is_public',
      'item_name',
      'category',
      'image_url',
      'quantity',
      'average_cost_per_item',
      'account_id',
      'created_on',
      'updated_on',
    ],
    observation: [
      'id',
      'title',
      'category',
      'lifeform_id',
      'account_id',
      'is_public',
      'recording',
      'created_on',
      'updated_on',
    ],
  };

  const [isBusy, setBusy] = useState(true);
  const [category, setCategory] = useState(categoryOptions[0].value);
  const [animalData, setAnimalData] = useState([] as any[]);
  const [enclosureData, setEnclosureData] = useState([] as any[]);
  const [feedingData, setFeedingData] = useState([] as any[]);
  const [inventoryData, setInventoryData] = useState([] as any[]);
  const [observationData, setObservationData] = useState([] as any[]);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Fetch data based on selected category
        let currentPage = 1;
        const perPage = 1000;

        switch (category) {
          case 'animal': {
            if (!!animalData.length) {
              break;
            }

            const data = await getAnimals({
              account_id: accountID,
              currentPage,
              perPage,
            });
            const collectedData: any[] = [...data.data];
            let shouldContinue = data.data.length >= perPage;

            while (shouldContinue) {
              const innerData = await getAnimals({
                account_id: accountID,
                currentPage,
                perPage,
              });
              collectedData.push(...innerData.data);

              shouldContinue = innerData.data.length >= perPage;
              currentPage += 1;
            }

            setAnimalData(
              collectedData.map((row) => ({
                ...row,
                key: `${category}-${row.id}-table-row`,
              })),
            );
            break;
          }

          case 'enclosure': {
            if (!!enclosureData.length) {
              break;
            }

            const data = await getEnclosures({
              account_id: accountID,
              currentPage,
              perPage,
            });
            const collectedData: any[] = [...data.data];
            let shouldContinue = data.data.length >= perPage;

            while (shouldContinue) {
              const innerData = await getEnclosures({
                account_id: accountID,
                currentPage,
                perPage,
              });
              collectedData.push(...innerData.data);

              shouldContinue = innerData.data.length >= perPage;
              currentPage += 1;
            }

            setEnclosureData(
              collectedData.map((row) => ({
                ...row,
                key: `${category}-${row.id}-table-row`,
              })),
            );
            break;
          }

          case 'feeding': {
            if (!!feedingData.length) {
              break;
            }

            const data = await getFeedings({
              account_id: accountID,
              currentPage,
              perPage,
            });

            const collectedData: any[] = [...data.data];
            let shouldContinue = data.data.length >= perPage;

            while (shouldContinue) {
              const innerData = await getFeedings({
                account_id: accountID,
                currentPage,
                perPage,
              });
              collectedData.push(...innerData.data);

              shouldContinue = innerData.data.length >= perPage;
              currentPage += 1;
            }

            setFeedingData(
              collectedData.map((row) => {
                const result = {
                  ...row,
                  key: `${category}-${row.id}-table-row`,
                };

                if (row.ate === null) {
                  result.ate = 'Not Yet';
                } else {
                  result.ate = row.ate ? 'Yes' : 'No';
                }

                return result;
              }),
            );
            break;
          }

          case 'inventory': {
            if (!!inventoryData.length) {
              break;
            }

            const data = await getInventoryCards({
              account_id: accountID,
              currentPage,
              perPage,
            });
            const collectedData: any[] = [...data.data];
            let shouldContinue = data.data.length >= perPage;

            while (shouldContinue) {
              const innerData = await getInventoryCards({
                account_id: accountID,
                currentPage,
                perPage,
              });
              collectedData.push(...innerData.data);

              shouldContinue = innerData.data.length >= perPage;
              currentPage += 1;
            }

            setInventoryData(
              collectedData.map((row) => ({
                ...row,
                key: `${category}-${row.id}-table-row`,
              })),
            );
            break;
          }

          case 'observation': {
            if (!!observationData.length) {
              break;
            }

            const data = await getObservations({
              account_id: accountID,
              currentPage,
              perPage,
            });
            const collectedData: any[] = [...data.data];
            let shouldContinue = data.data.length >= perPage;

            while (shouldContinue) {
              const innerData = await getObservations({
                account_id: accountID,
                currentPage,
                perPage,
              });
              collectedData.push(...innerData.data);

              shouldContinue = innerData.data.length >= perPage;
              currentPage += 1;
            }

            setObservationData(
              collectedData.map((row) => ({
                ...row,
                key: `${category}-${row.id}-table-row`,
              })),
            );
            break;
          }

          default:
            break;
        }
      } catch (error) {
        if (error === FailedAuthCheck) {
          return null;
        }
      }

      setBusy(false);
    };

    loadData();
  }, [category]);

  return (
    <div className="bulk-export">
      {!isBusy ? (
        <section className="bulk-export-background common-background">
          <section className="common-inner-wrapper">
            <section className="common-section">
              <div className="title-input-wrapper">
                <p className="title">Category</p>
                <Select
                  name="category"
                  id="category"
                  className="export-select"
                  styles={selectStyle}
                  options={categoryOptions}
                  value={getOption(category, categoryOptions)}
                  onChange={(target) =>
                    setCategory(target?.value ?? categoryOptions[0].value)
                  }
                />
              </div>

              {category === categoryOptions[0].value && !!animalData.length ? (
                <>
                  <div className="table-wrapper">
                    <Table
                      dataSource={animalData}
                      columns={importedColumns.animal.map((str) => ({
                        title: toTitleCase(str.replaceAll('_', ' ')),
                        dataIndex: str,
                        key: `${category}-${str}-col`,
                        render: (text: any) => String(text),
                      }))}
                      pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomLeft'],
                      }}
                    />
                  </div>
                  <a
                    href={window.URL.createObjectURL(
                      new Blob([
                        Papa.unparse(
                          animalData.map((row) => {
                            const dupRow = {
                              id: row.id,
                              ...row,
                            };

                            delete dupRow.key;
                            return dupRow;
                          }),
                        ),
                      ]),
                    )}
                    download={`${category}.csv`}
                    className="download-link"
                  >
                    <button className="btn-download">Download CSV</button>
                  </a>
                </>
              ) : (
                <div className="no-data">
                  <h1>Empty</h1>
                </div>
              )}

              {category === categoryOptions[1].value &&
              !!enclosureData.length ? (
                <>
                  <div className="table-wrapper">
                    <Table
                      dataSource={enclosureData}
                      columns={importedColumns.enclosure.map((str) => ({
                        title: toTitleCase(str.replaceAll('_', ' ')),
                        dataIndex: str,
                        key: `${category}-${str}-col`,
                        render: (text: any) => String(text),
                      }))}
                      pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomLeft'],
                      }}
                    />
                  </div>
                  <a
                    href={window.URL.createObjectURL(
                      new Blob([
                        Papa.unparse(
                          enclosureData.map((row) => {
                            const dupRow = {
                              id: row.id,
                              ...row,
                            };

                            delete dupRow.key;
                            return dupRow;
                          }),
                        ),
                      ]),
                    )}
                    download={`${category}.csv`}
                    className="download-link"
                  >
                    <button className="btn-download">Download CSV</button>
                  </a>
                </>
              ) : (
                <></>
              )}

              {category === categoryOptions[2].value && !!feedingData.length ? (
                <>
                  <div className="table-wrapper">
                    <Table
                      dataSource={feedingData}
                      columns={importedColumns.feeding.map((str) => ({
                        title: toTitleCase(str.replaceAll('_', ' ')),
                        dataIndex: str,
                        key: `${category}-${str}-col`,
                        render: (text: any) => String(text),
                      }))}
                      pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomLeft'],
                      }}
                    />
                  </div>
                  <a
                    href={window.URL.createObjectURL(
                      new Blob([
                        Papa.unparse(
                          feedingData.map((row) => {
                            const dupRow = {
                              id: row.id,
                              ...row,
                            };

                            delete dupRow.key;
                            return dupRow;
                          }),
                        ),
                      ]),
                    )}
                    download={`${category}.csv`}
                    className="download-link"
                  >
                    <button className="btn-download">Download CSV</button>
                  </a>
                </>
              ) : (
                <></>
              )}

              {category === categoryOptions[3].value &&
              !!inventoryData.length ? (
                <>
                  <div className="table-wrapper">
                    <Table
                      dataSource={inventoryData}
                      columns={importedColumns.inventory.map((str) => ({
                        title: toTitleCase(str.replaceAll('_', ' ')),
                        dataIndex: str,
                        key: `${category}-${str}-col`,
                        render: (text: any) => String(text),
                      }))}
                      pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomLeft'],
                      }}
                    />
                  </div>
                  <a
                    href={window.URL.createObjectURL(
                      new Blob([
                        Papa.unparse(
                          inventoryData.map((row) => {
                            const dupRow = {
                              id: row.id,
                              ...row,
                            };

                            delete dupRow.key;
                            return dupRow;
                          }),
                        ),
                      ]),
                    )}
                    download={`${category}.csv`}
                    className="download-link"
                  >
                    <button className="btn-download">Download CSV</button>
                  </a>
                </>
              ) : (
                <></>
              )}

              {category === categoryOptions[4].value &&
              !!observationData.length ? (
                <>
                  <div className="table-wrapper">
                    <Table
                      dataSource={observationData}
                      columns={importedColumns.observation.map((str) => ({
                        title: toTitleCase(str.replaceAll('_', ' ')),
                        dataIndex: str,
                        key: `${category}-${str}-col`,
                        render: (text: any) => String(text),
                      }))}
                      pagination={{
                        hideOnSinglePage: true,
                        position: ['bottomLeft'],
                      }}
                    />
                  </div>
                  <a
                    href={window.URL.createObjectURL(
                      new Blob([
                        Papa.unparse(
                          observationData.map((row) => {
                            const dupRow = {
                              id: row.id,
                              ...row,
                            };

                            delete dupRow.key;
                            return dupRow;
                          }),
                        ),
                      ]),
                    )}
                    download={`${category}.csv`}
                    className="download-link"
                  >
                    <button className="btn-download">Download CSV</button>
                  </a>
                </>
              ) : (
                <></>
              )}
            </section>
          </section>
        </section>
      ) : (
        <></>
      )}
    </div>
  );
};
