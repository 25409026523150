import { Person, Setting, AccountRO } from 'cobranetics-types/types';
import { Paths, PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';
import { FailedAuthCheck } from '../shared/errors';
import { MembershipConfiguration } from 'cobranetics-types/types/membership_configuration';

export interface CheckResponse {
  account: AccountRO;
  person: Person;
  settings: Setting;
  membershipConfig: MembershipConfiguration;
}

export const getCheck = async (
  navigate: any,
  goToLoginOnError = true,
): Promise<CheckResponse> => {
  try {
    const response = await axiosInstance.get(PathsAPI.check);
    return {
      account: response.data.account,
      person: response.data.person,
      settings: response.data.settings,
      membershipConfig: response.data.membershipConfig,
    };
  } catch (error) {
    if (goToLoginOnError) {
      navigate(Paths.login);
    }

    throw FailedAuthCheck;
  }
};

export const authRefresh = async (): Promise<CheckResponse | undefined> => {
  try {
    const response = await axiosInstance.get(PathsAPI.check);
    return {
      account: response.data.account,
      person: response.data.person,
      settings: response.data.settings,
      membershipConfig: response.data.membershipConfig,
    };
  } catch (error) {
    // do nothing
  }
};
