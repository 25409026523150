import React from 'react';
import { ReactComponent as AddIcon } from '../icons/add.svg';
import { DropDownOptions } from '../typings/menu-dropdown';
import { MenuBtnThreeDots } from './menu-dropdown';

export const CommonWrapper = (props: {
  children: any;
  title: string;
  className: string;
  showAddBtn: boolean;
  addBtnClick: () => any;
}): JSX.Element => {
  return (
    <section className={`${props.className} common-wrapper`}>
      <div className="title-add-wrapper">
        {props.title !== '' ? (
          <h3 className="title">{props.title}</h3>
        ) : undefined}

        {props.showAddBtn ? (
          <button className="btn-add" onClick={props.addBtnClick}>
            +
          </button>
        ) : undefined}
      </div>
      <div className="body">{props.children}</div>
    </section>
  );
};

export const CommonRow = (props: {
  children: any;
  title: string;
  titleLink?: string;
  showAddBtn: boolean;
  addBtnClick: () => any;
  menu?: DropDownOptions[];
}): JSX.Element => {
  return (
    <section className="common-row">
      <div className="title-add-wrapper">
        {props.title !== '' ? (
          <h6 className="title">
            {props.titleLink ? (
              <a style={{ color: 'inherit' }} href={props.titleLink}>
                {props.title}
              </a>
            ) : (
              <>{props.title}</>
            )}
          </h6>
        ) : undefined}

        {props.showAddBtn ? (
          <button className="btn-add" onClick={props.addBtnClick}>
            <AddIcon className="add-icon" />
          </button>
        ) : (
          <>
            {props.menu ? (
              <MenuBtnThreeDots dropDownOptions={props.menu} />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <div className="body">{props.children}</div>
    </section>
  );
};
