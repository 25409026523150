import {
  Feeding,
  FeedingCard as FeedingCardModel,
} from 'cobranetics-types/types';
import { ListOutput, Params } from '.';
import { PathsAPI } from '../routes/paths';
import { axiosInstance } from '../shared/axios';

export interface FetchFeedingParams extends Params {
  since?: string;
  feeding_schedule_ids?: string;
  account_id?: number;
  lifeform_id?: string;
}

export interface BulkCreateFeedingParams {
  feed_on: string;
  meal: string;
  ate?: boolean;
  prey_weight?: string;
  feeding_schedule_id: string;
  item_count: number;
  prey_weight_units?: string;
  inventory_id?: string;
}

export interface FeedingAttribute {
  id: string;
  key: string;
  value: string;
  created_on: string;
  updated_on: string;
}

export const getFeedingCards = async (
  params: FetchFeedingParams,
): Promise<ListOutput<FeedingCardModel>> => {
  const response = await axiosInstance.get(PathsAPI.feedingCards, { params });

  return {
    data: response.data.data,
    nextPage: response.data.nextPage,
    prevPage: response.data.prevPage,
  };
};

export const getFeedings = async (
  params: FetchFeedingParams,
): Promise<ListOutput<Feeding>> => {
  try {
    const response = await axiosInstance.get(PathsAPI.feeding, { params });

    return {
      data: response.data.data,
      nextPage: response.data.nextPage,
      prevPage: response.data.prevPage,
      total: response.data.total,
    };
  } catch (error) {
    console.log(error);
    throw new Error('Failed to fetch feedings');
  }
};

export const getFeeding = async (id: string): Promise<Feeding> => {
  try {
    const response = await axiosInstance.get(`${PathsAPI.feeding}/${id}`);

    return response.data;
  } catch (error) {
    console.log(error);
    throw new Error('Failed to fetch feeding');
  }
};

export const createFeeding = async (
  feeding: Partial<Feeding>,
): Promise<Feeding> => {
  const response = await axiosInstance.post(`${PathsAPI.feeding}`, feeding);

  return response.data;
};

export const deleteFeeding = async (id: string): Promise<void> => {
  await axiosInstance.delete(`${PathsAPI.feeding}/${id}`);
};

export const updateFeeding = async (
  feeding: Partial<Feeding>,
): Promise<Feeding> => {
  const response = await axiosInstance.put(`${PathsAPI.feeding}`, feeding);

  return response.data;
};

export const bulkCreateFeeding = async (
  createParams: BulkCreateFeedingParams[],
): Promise<Feeding[]> => {
  const response = await axiosInstance.post(`${PathsAPI.feedingBulk}`, {
    data: createParams,
  });

  return response.data;
};

export const getFeedingAttributes = async (
  id: string | number,
): Promise<FeedingAttribute[]> => {
  const response = await axiosInstance.get(
    `${PathsAPI.feedingSchedule}/${id}/attributes`,
  );

  return response.data;
};
