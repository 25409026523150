import React, { useEffect, useRef, useState } from 'react';
import {
  LifeformCard,
  EnclosureCard as EnclosureCardModel,
  FeedingCard as FeedingCardModel,
  FeedingScheduleCard as FeedingScheduleCardModel,
  Inventory,
  PersonCard,
  ObservationCard as ObservationCardModel,
  InventoryTransaction,
  SearchResult,
} from 'cobranetics-types/types';
import { GalleryCard } from './gallery';
import { FilterTypeOptions } from '../typings/search';
import { AnimalCard } from './animals';
import { EnclosureCard } from './enclosures';
import { FeedingCard, FeedingScheduleCard } from './feeding-schedule';
import { InventoryCard, InventoryTransactionCard } from './inventory';
import { ObservationCard } from './observation';
import { ProfileCard } from './profiles';
import { addSpace } from '../utils/strings';
import { ExtendedPersonCard } from '../typings/person';
import { useMainContext } from '../context/context';
import { MainState } from '../typings/main_context_reducer';
import { randomTextGenerator } from '../shared/utils';

export interface GalleryCardModel {
  id: number;
  created_on: string;
  updated_on: string;
  image_url: string;
  is_default_image: boolean;
  filterTypeOption: FilterTypeOptions;
  enclosure_id?: string;
  lifeform_id?: string;
  observation_reading_id?: string;
}

export type ResultCard =
  | LifeformCard
  | EnclosureCardModel
  | FeedingCardModel
  | FeedingScheduleCardModel
  | Inventory
  | InventoryTransaction
  | PersonCard
  | ObservationCardModel
  | GalleryCardModel;

export interface ResultGroups {
  // Main group with underline
  [key: string]: {
    // Sub group of cards
    [key: string]: ResultCard[];
  };
}

export type ResultCardTitle = FilterTypeOptions | 'inventoryTransaction';

export interface ResultsWrapperProps {
  data: SearchResult[];
  hideSubtitles?: boolean;
}

export const CardsGroup = (props: {
  title: string;
  uniqueKey: string;
  cards: ResultCard[];
  mainState: MainState;
}): JSX.Element[][] => {
  const results = [];

  switch (props.title as ResultCardTitle) {
    case FilterTypeOptions.animals: {
      const cards = props.cards as LifeformCard[];

      results.push(
        cards.map((animal, i) => (
          <AnimalCard key={`animal-card-${i}-${props.uniqueKey}`} {...animal} />
        )),
      );
      break;
    }
    case FilterTypeOptions.enclosures: {
      const cards = props.cards as EnclosureCardModel[];

      results.push(
        cards.map((enclosure, i) => (
          <EnclosureCard
            key={`enclosure-card-${i}-${props.uniqueKey}`}
            {...enclosure}
          />
        )),
      );
      break;
    }
    case FilterTypeOptions.feedings: {
      const cards = props.cards as FeedingCardModel[];

      results.push(
        cards.map((feeding, i) => (
          <FeedingCard
            key={`feeding-card-${i}-${props.uniqueKey}`}
            {...feeding}
          />
        )),
      );
      break;
    }
    case FilterTypeOptions.feedingSchedule: {
      const cards = props.cards as FeedingScheduleCardModel[];

      results.push(
        cards.map((feedingSchedule, i) => (
          <FeedingScheduleCard
            key={`feeding-schedule-card-${i}-${props.uniqueKey}`}
            {...feedingSchedule}
          />
        )),
      );
      break;
    }
    case FilterTypeOptions.inventory: {
      const cards = props.cards as Inventory[];

      results.push(
        cards.map((item, i) => (
          <InventoryCard
            key={`inventory-card-${i}-${props.uniqueKey}`}
            {...item}
          />
        )),
      );
      break;
    }
    case FilterTypeOptions.keepers: {
      const cards = props.cards as ExtendedPersonCard[];

      results.push(
        cards.map((profile, i) => (
          <ProfileCard
            key={`profile-card-${i}-${props.uniqueKey}`}
            id={profile.id}
            followPairID={profile.follow_pair_id}
            name={profile.name}
            username={profile.username}
            bio={profile.bio}
            image={profile.profile_image}
            isFollowing={profile.is_following}
            isCurrentUser={props.mainState.person?.id === profile.id}
            onClick={async (isFollowing: boolean) => {}}
          />
        )),
      );
      break;
    }
    case FilterTypeOptions.observations: {
      const cards = props.cards as ObservationCardModel[];

      results.push(
        cards.map((observation, i) => (
          <ObservationCard
            key={`observation-card-${i}-${props.uniqueKey}`}
            {...observation}
          />
        )),
      );
      break;
    }
    case FilterTypeOptions.images: {
      const cards = props.cards as any[];

      results.push(
        cards.map((galleryItem, i) => {
          let category: 'lifeform' | 'enclosure' | 'observation' =
            'observation';

          if (Object.keys(galleryItem).includes('lifeform_id')) {
            category = 'lifeform';
          }

          if (Object.keys(galleryItem).includes('enclosure_id')) {
            category = 'enclosure';
          }

          return (
            <GalleryCard
              key={`gallery-card-${i}-${props.uniqueKey}`}
              id={galleryItem.id}
              image={galleryItem.image_url}
              category={category}
            />
          );
        }),
      );
      break;
    }
    case 'inventoryTransaction': {
      const cards = props.cards as InventoryTransaction[];

      results.push(
        cards.map((inventoryTransaction, i) => (
          <InventoryTransactionCard
            key={`inventory-transaction-card-${i}-${props.uniqueKey}`}
            {...inventoryTransaction}
          />
        )),
      );
      break;
    }
    default:
      break;
  }

  return results;
};

export const ResultsWrapper = (props: ResultsWrapperProps): JSX.Element => {
  const [mainState] = useMainContext();
  const searchResults: SearchResult[] = props.data;

  return (
    <>
      <section className="results-wrapper">
        <div className="main-group">
          <div className="main-group-results">
            {searchResults.map((row: any) => {
              const cardGroup = CardsGroup({
                title: row.filterTypeOption,
                cards: [row],
                uniqueKey: `main-group-1`,
                mainState,
              });

              return (
                <div
                  className="sub-group"
                  key={`sub-group-${row.filterTypeOption}-${randomTextGenerator(
                    5,
                  )}-${row.id}`}
                >
                  {cardGroup[0][0]}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};
